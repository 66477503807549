import { PatientSearchFormData } from 'app/shared/patientSearch/types';
import { Option, PatientSourceOption, TimeRange } from 'app/types';

export type SlotOption = {
  label: string;
  value: {
    start: string;
    end: string | null;
  };
  details?: {
    numberOfAppointments: number;
  };
};

export const AppointmentFormField = {
  branchId: 'branchId',
  date: 'date',
  doctorId: 'doctorId',
  doctorName: 'doctorName',
  note: 'note',
  patientSource: 'patientSource',
  product: 'product',
  referralId: 'referralId',
  slot: 'slot',
  speciality: 'speciality',
  subSpeciality: 'subSpeciality',
} as const;

export interface AppointmentFormData extends PatientSearchFormData {
  [AppointmentFormField.branchId]: string;
  [AppointmentFormField.date]: Date;
  [AppointmentFormField.doctorId]: string;
  [AppointmentFormField.doctorName]: string;
  [AppointmentFormField.note]?: string | null;
  [AppointmentFormField.patientSource]?: PatientSourceOption;
  [AppointmentFormField.product]: Option;
  [AppointmentFormField.referralId]?: string | null;
  [AppointmentFormField.slot]: SlotOption;
  [AppointmentFormField.speciality]: Option;
  [AppointmentFormField.subSpeciality]: Option;
}

export interface WorkingTimeOption extends Option {
  details: {
    from: Date;
    to: Date;
  };
}

export const WaitingAppointmentFormField = {
  branchId: 'branchId',
  date: 'date',
  doctorId: 'doctorId',
  doctorName: 'doctorName',
  note: 'note',
  patientSource: 'patientSource',
  preferredTimeRange: 'preferredTimeRange',
  product: 'product',
  referralId: 'referralId',
  speciality: 'speciality',
  subSpeciality: 'subSpeciality',
  workingTime: 'workingTime',
} as const;

export interface WaitingAppointmentFormData extends PatientSearchFormData {
  [WaitingAppointmentFormField.branchId]: string;
  [WaitingAppointmentFormField.date]: Date;
  [WaitingAppointmentFormField.doctorId]: string;
  [WaitingAppointmentFormField.doctorName]: string;
  [WaitingAppointmentFormField.note]?: string | null;
  [WaitingAppointmentFormField.patientSource]?: PatientSourceOption;
  [WaitingAppointmentFormField.preferredTimeRange]: TimeRange;
  [WaitingAppointmentFormField.product]: Option;
  [WaitingAppointmentFormField.referralId]?: string | null;
  [WaitingAppointmentFormField.speciality]: Option;
  [WaitingAppointmentFormField.subSpeciality]: Option;
  [WaitingAppointmentFormField.workingTime]: WorkingTimeOption;
}

export type CurrentParams = {
  branchId: string | undefined;
  date: string | null | undefined;
  doctorId: string | undefined;
  productId: string | null | undefined;
  specialityId: string | null | undefined;
  subSpecialityId: string | null | undefined;
};

export interface AppointmentAgeRestrictions {
  acceptablePatientAgeFrom?: number;
  acceptablePatientAgeTo?: number;
}
