import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import {
  getDoctorFullName,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useCurrentUser,
  useModalCleaner,
} from 'app/helpers';
import { FORMS_ADD_APPOINTMENT } from 'app/shared';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { addWaitingAppointment, resetPatient, setPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { WaitingAppointmentForm } from 'app/features/appointment/form';
import {
  generateWorkingTimeOption,
  onWaitingFormChange,
  useAppointmentFormStatus,
  useCreatePatientHandlers,
  useTimeRangeConfirmation,
  waitingAppointmentFormValuesAdapter,
} from 'app/features/appointment/form/helpers';
import { WaitingAppointmentFormData } from 'app/features/appointment/form/types';
import { CreatePatientModals } from 'app/features/appointment/shared';
import { formConfig } from 'app/features/bookAppointment/shared';

export const AddWaitingAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage, locale } = useAppIntl();
  const {
    currentUser: { allowedForBookAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.addWaitingAppointment.isOpen);
  const selectedWaitingSlot = useAppSelector((state) => state.calendar.selectedWaitingSlot);
  const submitting = !!useAppSelector((state) => state.form[FORMS_ADD_APPOINTMENT]?.submitting);
  const { data: selectedPatientData } = useAppSelector((state) => state.appointment.selectedPatient);
  const { isFormDisabled } = useAppointmentFormStatus(selectedWaitingSlot?.date);
  const referralInfo = useAppSelector((state) => state.bookAppointment.referralInfo);
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const selectedPatientNumber = useAppSelector(
    (state) => state.form[formConfig.form]?.values?.[PatientSearchField.selectedPhoneNumber],
  );

  // Variables
  const clinicId = selectedWaitingSlot?.doctor.clinic?.value || '';
  const isPhantom = !!selectedWaitingSlot?.isPhantom;
  const headerId = 'APPOINTMENTS.TEXT.ADD-WAITING-APPOINTMENT';
  const initialValues = useMemo((): Partial<WaitingAppointmentFormData> | undefined => {
    if (selectedWaitingSlot) {
      const { workingTimeId, workingTimeStart, workingTimeEnd } = selectedWaitingSlot;
      const workingTime =
        workingTimeId && workingTimeStart && workingTimeEnd
          ? generateWorkingTimeOption(workingTimeId, workingTimeStart, workingTimeEnd)
          : undefined;

      return {
        branchId: selectedWaitingSlot.branchId,
        date: moment(selectedWaitingSlot.date).toDate(),
        doctorId: selectedWaitingSlot.doctor.id,
        doctorName: getDoctorFullName(selectedWaitingSlot.doctor, locale),
        product: selectedWaitingSlot.product,
        referralId: referralInfo?.id || null,
        speciality: selectedWaitingSlot.speciality,
        subSpeciality: selectedWaitingSlot.subSpeciality,
        workingTime,
        selectedPhoneNumber: referralInfo?.patientMobileNumber || selectedPatientNumber,
        patientId: referralInfo?.patient.id || preselectedPatient?.id,
      };
    }

    return undefined;
  }, [locale, selectedWaitingSlot, referralInfo, preselectedPatient, selectedPatientNumber]);

  // Actions
  const toggle = useCallback(() => dispatch(toggleModal('addWaitingAppointment')), [dispatch]);
  const submitForm = useCallback(() => dispatch(submit(FORMS_ADD_APPOINTMENT)), [dispatch]);
  const onSubmit = useCallback(
    async (values: WaitingAppointmentFormData) =>
      await dispatch(addWaitingAppointment(waitingAppointmentFormValuesAdapter(values), isPhantom)),
    [dispatch, isPhantom],
  );

  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: FORMS_ADD_APPOINTMENT,
      setPatient,
      resetPatient,
    });

  // Time Range Confirmation State
  const { checkTimeRange, confirmationMessage, confirmationModal, closeConfirmationModal } = useTimeRangeConfirmation(
    FORMS_ADD_APPOINTMENT,
    onSubmit,
    isPhantom,
  );

  useModalCleaner('addWaitingAppointment', modalRef);

  return (
    <>
      <Modal ref={modalRef} backdrop="static" keyboard={false} isOpen={isOpen} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>{formatMessage({ id: headerId })}</ModalHeader>
        <ModalBody>
          {selectedWaitingSlot && (
            <WaitingAppointmentForm
              form={FORMS_ADD_APPOINTMENT}
              initialValues={initialValues}
              clinicId={clinicId}
              createdPatientMode={!!createdPatient}
              isFormDisabled={isFormDisabled || !allowedForBookAppointments}
              onAddNewPatient={onAddNewPatient}
              onChange={onWaitingFormChange}
              onSubmit={checkTimeRange}
              resetMode={onResetCreated}
              ageRestrictions={{
                acceptablePatientAgeFrom: selectedWaitingSlot.doctor.acceptablePatientAgeFrom,
                acceptablePatientAgeTo: selectedWaitingSlot.doctor.acceptablePatientAgeTo,
              }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
          <Button
            color="primary-gradient"
            disabled={isFormDisabled || submitting || !allowedForBookAppointments || !selectedPatientData}
            onClick={submitForm}
          >
            {formatMessage({ id: 'CORE.BUTTON.ADD' })}
          </Button>
        </ModalFooter>

        <CreatePatientModals
          initialPhoneNumber={initialPhoneNumber}
          clinicId={clinicId}
          setPatientData={setPatientData}
        />
      </Modal>

      {/* Time Range Confirmation Modal */}
      <Modal backdrop="static" keyboard={false} isOpen={confirmationModal} toggle={closeConfirmationModal}>
        <ModalHeader toggle={closeConfirmationModal}>{formatMessage({ id: 'CORE.TEXT.INFORMATION' })}</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
        <ModalFooter>
          <Button onClick={closeConfirmationModal}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
