export enum StorageEntityType {
  DoctorAssessment = '0',
  InvoiceRequest = '1',
  InvoiceDraft = '2',
  Invoice = '3',
  InsuranceApproval = '4',
}

export type StorageEntity = {
  storageId: string;
  storageName: string;
  storageLocation: string;
  availableQuantity: number;
  storageContact: string;
};

export type StorageReservationItem = {
  itemClinicyId: string;
  storageId: string;
  reservedQty: number;
};

export type ReservationBodyParams = {
  reservationId?: string;
  entityType: StorageEntityType;
  entityId?: string;
  isDeleted?: boolean;
  items: StorageReservationItem[];
};

export type CreateReservationResponse = {
  reservationId: string;
  success: boolean;
  errorMessage?: string;
  items: (StorageReservationItem & {
    remainingQty: number;
    success: boolean;
    errorMessage?: string;
  })[];
};

export type ReservationEntity = {
  reservationId: string;
  entityId: string;
  entityType: StorageEntityType;
  items: {
    billingItemClinicyId: string;
    storageId: string;
    reservedQty: number;
  }[];
};
