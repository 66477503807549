import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  GetServicesParams,
  GetServicesReportParams,
  GetSpecialitiesParams,
  GetSubSpecialitiesParams,
  ServiceBodyParams,
  SpecialityBodyParams,
  SubSpecialityBodyParams,
} from 'app/services/specialities/types';
import { QueryResponse, QueryResponseWithPagination, Service, Speciality, SubSpeciality } from 'app/types';

export const specialitiesApi = createApi({
  reducerPath: 'specialitiesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Specialities', 'Speciality', 'SubSpecialities', 'SubSpeciality', 'Services', 'Service'],
  endpoints: (builder) => ({
    // [EP-1]
    getSpecialities: builder.query<QueryResponseWithPagination<Speciality[]>, GetSpecialitiesParams>({
      query: ({ includeDisabled, params }) => ({
        url: '/specialities',
        method: 'GET',
        params: {
          ...params,
          includeDisabled,
        },
      }),
      providesTags: ['Specialities'],
    }),

    // [EP-2]
    addSpeciality: builder.mutation<void, SpecialityBodyParams>({
      query: (body) => ({
        url: '/specialities',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Specialities'],
    }),

    // [EP-3]
    editSpeciality: builder.mutation<void, { specialityId: string | number; body: SpecialityBodyParams }>({
      query: ({ specialityId, body }) => ({
        url: `/specialities/${specialityId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Specialities', 'Speciality'],
    }),

    // [EP-4]
    getSpeciality: builder.query<QueryResponse<Speciality>, string>({
      query: (specialityId) => ({
        url: `/specialities/${specialityId}`,
        method: 'GET',
      }),
      providesTags: ['Speciality'],
    }),

    // [EP-5]
    getSubSpecialities: builder.query<QueryResponseWithPagination<SubSpeciality[]>, GetSubSpecialitiesParams>({
      query: ({ specialityId, params, branchId, disabled, doctorId }) => ({
        url: `/specialities/${specialityId}/subspecialities`,
        method: 'GET',
        params: {
          ...params,
          branchId,
          disabled,
          doctorId,
        },
      }),
      providesTags: ['SubSpecialities'],
    }),

    // [EP-6]
    addSubSpeciality: builder.mutation<void, { specialityId: string; body: SubSpecialityBodyParams }>({
      query: ({ specialityId, body }) => ({
        url: `/specialities/${specialityId}/subspecialities`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SubSpecialities'],
    }),

    // [EP-7]
    editSubSpeciality: builder.mutation<void, { subSpecialityId: string; body: SubSpecialityBodyParams }>({
      query: ({ subSpecialityId, body }) => ({
        url: `/subspecialities/${subSpecialityId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SubSpecialities', 'SubSpeciality'],
    }),

    // [EP-8]
    getSubSpeciality: builder.query<QueryResponse<SubSpeciality>, string>({
      query: (subSpecialityId) => ({
        url: `/subspecialities/${subSpecialityId}`,
        method: 'GET',
      }),
    }),

    // [EP-9]
    getServices: builder.query<QueryResponseWithPagination<Service[]>, GetServicesParams>({
      query: ({ subSpecialityId, params }) => ({
        url: `/subspeciality/${subSpecialityId}/services`,
        method: 'GET',
        params,
      }),
      providesTags: ['Services'],
    }),

    // [EP-10]
    getServicesReport: builder.query<void, GetServicesReportParams>({
      query: ({ subSpecialityId, params }) => ({
        url: `/subspeciality/${subSpecialityId}/services/excel`,
        params,
      }),
    }),

    // [EP-11]
    addService: builder.mutation<void, { subSpecialityId: string; body: ServiceBodyParams }>({
      query: ({ subSpecialityId, body }) => ({
        url: `/subspeciality/${subSpecialityId}/services`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Services'],
    }),

    // [EP-12]
    editService: builder.mutation<void, { serviceId: string; body: ServiceBodyParams }>({
      query: ({ serviceId, body }) => ({
        url: `/subspeciality-services/${serviceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Services', 'Service'],
    }),
  }),
});

export const {
  useGetSpecialitiesQuery, // [EP-1]
  useAddSpecialityMutation, // [EP-2]
  useEditSpecialityMutation, // [EP-3]
  useGetSpecialityQuery, // [EP-4]
  useGetSubSpecialitiesQuery, // [EP-5]
  useAddSubSpecialityMutation, // [EP-6]
  useEditSubSpecialityMutation, // [EP-7]
  useGetSubSpecialityQuery, // [EP-8]
  useGetServicesQuery, // [EP-9]
  useLazyGetServicesReportQuery, // [EP-10]
  useAddServiceMutation, // [EP-11]
  useEditServiceMutation, // [EP-12]
} = specialitiesApi;
