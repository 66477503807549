import { AnyAction } from 'redux';
import * as types from 'app/redux/referrals/referrals.types';
import { Referral, RemoteData } from 'app/types';

interface ReferralsReducer {
  selectedReferral: {
    referral: Referral;
    rowIndex: number;
  } | null;
  referral: RemoteData<Referral | null>;
  referralId: string | null;
}

const initialState: ReferralsReducer = {
  selectedReferral: null,
  referral: {
    data: null,
    loading: false,
  },
  referralId: null,
};

export const referralsReducer = (state = initialState, action: AnyAction): ReferralsReducer => {
  switch (action.type) {
    case types.SET_SELECTED_REFERRAL:
      return {
        ...state,
        selectedReferral: action.payload,
      };
    case types.RESET_SELECTED_REFERRAL:
      return {
        ...state,
        selectedReferral: initialState.selectedReferral,
      };
    case types.FETCH_REFERRAL_REQUEST:
      return {
        ...state,
        referral: {
          data: null,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_REFERRAL_SUCCESS:
      return {
        ...state,
        referral: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_REFERRAL_FAILURE:
      return {
        ...state,
        referral: {
          data: null,
          loading: false,
          error: true,
        },
      };
    case types.SET_REFERRAL_ID:
      return {
        ...state,
        referralId: action.payload,
      };
    case types.RESET_REFERRAL:
      return {
        ...state,
        referral: initialState.referral,
      };

    default:
      return state;
  }
};
