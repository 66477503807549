import { useCallback, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { addInvoiceItem, deleteInvoiceItem } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import {
  InvoiceBillingItemOption,
  InvoiceDiscountType,
  InvoiceEligibility,
  InvoiceInsuranceReferralItem,
  InvoiceItem,
  InvoiceItemVat,
  InvoicePayBy,
  Locale,
} from 'app/types';
import { RequiredSectionHeading } from 'app/features/invoicingProcess/process/components/requiredSectionHeading/requiredSectionHeading';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const ReferralInfo = () => {
  const { formatMessage, locale } = useAppIntl();
  const dispatch = useAppDispatch();
  const { isCreatedAndNotCreateCreditNoteMode } = useInvoicingProcessState();
  const { data, loading } = useAppSelector((state) => state.invoicingProcess.insuranceReferralItems);
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const insuranceReferralApproval: PaymentModeFormData['insuranceReferralApproval'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceReferralApproval],
  );
  const insuranceCompany: PaymentModeFormData['insuranceCompany'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceCompany],
  );
  const isReferralIn = eligibility?.value === InvoiceEligibility.ReferralIn;
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;

  const onItemSelect = useCallback(
    (item: InvoiceInsuranceReferralItem, checked: boolean) => {
      if (checked) {
        const billingItem: InvoiceBillingItemOption = {
          details: {
            gtinCode: null,
            internalCode: item.internalCode,
            measureValue: null,
            medicalBillingNphiesCode: item.medicalBillingNphiesCode,
            price: Number(item.price),
            quantity: item.quantity,
            saudisVatExempted: item.saudisVatExempted,
            unitMeasure: null,
            vatExempted: item.vatExempted,
            billingItemType: null,
            availableQuantity: null,
            isInventoryItem: false,
          },
          label: locale === Locale.ar ? item.nameAr : item.nameEn,
          value: item.performerBillingItemId,
          translation: {
            ar: item.nameAr,
            en: item.nameEn,
          },
        };

        dispatch(
          addInvoiceItem({
            billingItem,
            billingItemSourceType: item.billingItemSourceType,
            discount: {
              discountTypeView: { label: '', value: InvoiceDiscountType.Amount },
              value: 0,
            },
            id: item.performerBillingItemId,
            price: Number(item.price),
            quantity: item.quantity,
            vat: InvoiceItemVat.Default,
          }),
        );
      } else {
        dispatch(deleteInvoiceItem(item.performerBillingItemId));
      }
    },
    [dispatch, locale],
  );

  const columns = useMemo<ColumnDescription<InvoiceInsuranceReferralItem, InvoiceItem[]>[]>(
    () => [
      {
        dataField: 'internalCode',
        text: formatMessage({ id: 'BILLING-ITEMS.LABEL.INTERNAL-CODE' }),
        formatter: (_, row) => row.internalCode || '-',
      },
      {
        dataField: 'name',
        text: formatMessage({ id: 'CORE.LABEL.NAME' }),
        formatter: (_, row) => (locale === Locale.ar ? row.nameAr : row.nameEn),
        style: { width: '40%' },
      },
      {
        dataField: 'quantity',
        text: formatMessage({ id: 'CORE.LABEL.QUANTITY' }),
      },
      {
        dataField: 'useInInvoice',
        text: formatMessage({ id: 'CORE.LABEL.USE-IN-INVOICE' }),
        formatter: (_, row, rowIndex, formatExtraData) => (
          <Input
            checked={formatExtraData?.some((item) => item.id === row.performerBillingItemId)}
            id={`useInInvoice-${row.performerBillingItemId}`}
            invalid={!formatExtraData?.length}
            onChange={(event) => onItemSelect(row, event.target.checked)}
            type="checkbox"
          />
        ),
        formatExtraData: items,
        classes: 'sticky sticky--end text-center',
        headerClasses: 'sticky sticky--end text-center',
      },
    ],
    [formatMessage, items, locale, onItemSelect],
  );

  const coveredItems = useMemo<InvoiceInsuranceReferralItem[]>(() => {
    return data.filter((item) => item.isCoveredByInsuranceClass);
  }, [data]);

  if (!isPayByInsurance || !isReferralIn || !insuranceReferralApproval || !insuranceCompany) {
    return null;
  }

  return (
    <Row className="mt-3 mb-4">
      <Col xs={12} xxl={9}>
        <RequiredSectionHeading title={formatMessage({ id: 'CORE.LABEL.REFERRAL-INFO' })} invalid={!items.length} />
        {!isCreatedAndNotCreateCreditNoteMode && (
          <>
            <h6 className="mb-1">{formatMessage({ id: 'CORE.LABEL.PROCEDURES-SERVICES' })}</h6>
            <LocalBootstrapTable
              classes="table-sm"
              columns={columns}
              data={coveredItems}
              id="invoiceReferralItems"
              keyField="performerBillingItemId"
              loading={loading}
              noDataComponent={
                <div className="fw-medium text-center small py-3">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
              }
            />
          </>
        )}
        <FormGroup className="mt-3">
          <Label>{formatMessage({ id: 'INSURANCE-APPROVALS.LABEL.INSURANCE-REMARKS' })}</Label>
          <Input disabled type="text" value={insuranceReferralApproval?.details?.remarks || ''} bsSize="sm" />
        </FormGroup>
        <FormGroup>
          <Label>{formatMessage({ id: 'CORE.LABEL.INTERNAL-NOTES' })}</Label>
          <Input disabled type="textarea" value={insuranceReferralApproval?.details?.internalNotes || ''} bsSize="sm" />
        </FormGroup>
        {insuranceReferralApproval?.details?.attachments && (
          <FormGroup>
            <Label>{formatMessage({ id: 'CORE.LABEL.ATTACHMENTS' })}</Label>
            {insuranceReferralApproval.details.attachments.map((attachment) => (
              <div>
                <Button
                  className="p-0 me-1"
                  color="link"
                  href={attachment.url}
                  rel="noopener noreferrer"
                  size="sm"
                  tag="a"
                  target="_blank"
                >
                  {attachment.name}
                </Button>
              </div>
            ))}
          </FormGroup>
        )}
      </Col>
    </Row>
  );
};
