import { useAppSelector } from 'app/helpers';
import { AssessmentReferral, DoctorAssessment, DoctorAssessmentParams } from 'app/redux/doctorAssessment/types';
import { BillingFormData } from 'app/features/doctorAssessment/components/billingSection/forms/billingForm/billingForm';
import { MedicalProfileFormData } from 'app/features/doctorAssessment/components/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { AssessmentOrdersFormData } from 'app/features/doctorAssessment/components/ordersSection/forms/ordersForm/types';
import { WrappedPrescriptionFormData } from 'app/features/doctorAssessment/components/prescriptionSection/types';
import { SignatureFormData } from 'app/features/doctorAssessment/components/signatureForm/types';
import {
  billingValuesAdapter,
  medicalProfileValuesAdapter,
  prescriptionValuesAdapter,
  signatureValuesAdapter,
} from 'app/features/doctorAssessment/helpers';
import { ordersValuesAdapter } from 'app/features/doctorAssessment/helpers/ordersValuesAdapter';
import { referralValuesAdapter } from 'app/features/doctorAssessment/helpers/referralValuesAdapter';

interface Props {
  doctorAssessmentData: DoctorAssessment | null;
  medicalProfileValues?: MedicalProfileFormData;
  billingValues?: BillingFormData;
  ordersValues?: AssessmentOrdersFormData;
  prescriptionValues?: WrappedPrescriptionFormData[];
  referralValues?: AssessmentReferral[];
  signatureValues?: SignatureFormData;
}

export const useCreateBodyParams = ({
  doctorAssessmentData,
  medicalProfileValues,
  billingValues,
  ordersValues,
  prescriptionValues,
  referralValues,
  signatureValues,
}: Props): DoctorAssessmentParams => {
  // Sections Params
  const medicalProfileParams = medicalProfileValuesAdapter(medicalProfileValues);
  const billingParams = billingValuesAdapter(billingValues);
  const ordersParams = ordersValuesAdapter(ordersValues);
  const prescriptionsParams = prescriptionValues && prescriptionValuesAdapter(prescriptionValues);
  const referralsParams = referralValuesAdapter(referralValues);
  const signatureParams = signatureValuesAdapter(signatureValues);

  const reservationId = useAppSelector((state) => state.doctorAssessment.reservationId) ?? undefined;
  const assessmentId = doctorAssessmentData?.id;

  return {
    isDoctorExcluded: !!doctorAssessmentData?.isDoctorExcluded,
    isSpecialityExcluded: !!doctorAssessmentData?.isSpecialityExcluded,
    appointmentId: doctorAssessmentData?.appointmentId,
    previousDoctorAssessmentId: doctorAssessmentData?.previousDoctorAssessmentId,
    assessmentPrescriptions: prescriptionsParams,
    ...billingParams,
    ...ordersParams,
    ...signatureParams,
    ...medicalProfileParams,
    referrals: referralsParams,
    ...(reservationId &&
      !assessmentId && {
        patientPaidItemsReservationId: reservationId,
      }),
  };
};
