import { Option } from 'app/types/Option';

export enum ProductStatus {
  Enabled = 1,
  Disabled = 0,
}

export type Product = {
  appearOnMobileApp: boolean | null;
  clinicyId: string;
  duration: number | null;
  id: string;
  nameAr: string;
  nameEn: string;
  status: ProductStatus;
  workingTimeId: string | null;
  isServiceActivatedForAllPractitionersOnSubSpecialty?: boolean;
};

export interface ProductDropdownOption extends Option {
  subSpeciality: Option;
  branch: Option;
}

export interface ProductOption extends Option {
  details?: {
    nameInAppointmentTicket?: Option;
    nameInBookingCalendar?: Option;
  };
}
