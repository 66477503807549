import { useAppSelector } from 'app/helpers';
import { InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { getReservationEntity } from 'app/features/invoicingProcess/process/helpers/getReservationEntity';

export const useInvoicingProcessState = () => {
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const mode = useAppSelector((state) => state.invoicingProcess.mode);
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const requestId = useAppSelector((state) => state.invoicingProcess.requestId);
  const invoicePackageSnapshotId = useAppSelector((state) => state.invoicingProcess.invoicePackageSnapshotId);
  const isCreated = !!(invoicingProcess && !invoicingProcess?.isDraft);
  const isCreateCreditNoteMode = mode === InvoicingProcessMode.CreateCreditNote;
  const isCreatedAndNotCreateCreditNoteMode = isCreated && !isCreateCreditNoteMode; // useful for disabling fields

  const { entityType, entityId } = getReservationEntity(invoicingProcess);
  const reservationId = useAppSelector((state) => state.invoicingProcess.reservationId);

  return {
    invoicePackageSnapshotId,
    isCreateCreditNoteMode,
    isCreated,
    isCreatedAndNotCreateCreditNoteMode,
    isDraft: !!invoicingProcess?.isDraft,
    entityType,
    entityId,
    mode,
    requestId,
    step,
    reservationId,
  };
};
