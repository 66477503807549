import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { FORMATS_EXTENDED_DATE_AND_TIME, RemoteTable } from 'app/shared';
import { fetchAppointmentActions } from 'app/redux/remoteData/remoteData.actions';
import { AppointmentAction } from 'app/types';
import { ActionDetailsModal } from './actionDetailsModal/actionDetailsModal';
import { actionFormatter } from './formatters';

export const Actions = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [isChangesModalOpen, setChangesModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState<AppointmentAction | null>(null);

  // Selectors
  const appointment = useAppSelector((state) => state.appointment.data);
  const { data, loading, params } = useAppSelector((state) => state.remoteData.appointmentActions);

  // Actions
  const toggleChangesModal = useCallback(() => setChangesModal((prevState) => !prevState), []);

  const openChangesModal = useCallback((action: AppointmentAction) => {
    setSelectedAction(action);
    setChangesModal(true);
  }, []);

  const fetchData = useCallback(
    (newParams) => {
      if (appointment) {
        return dispatch(fetchAppointmentActions(appointment.id, !!appointment.workingTimeId, newParams));
      }
    },
    [appointment, dispatch],
  );

  // Columns
  const columns = useMemo(
    (): ColumnDescription<AppointmentAction>[] => [
      {
        dataField: 'actionTaker',
        text: formatMessage({ id: 'CORE.LABEL.ACTION-TAKER' }),
      },
      {
        dataField: 'action',
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.ACTION-TYPE' }),
        formatter: actionFormatter,
        formatExtraData: { onClick: openChangesModal },
      },
      {
        dataField: 'time',
        classes: 'text-nowrap',
        text: formatMessage({ id: 'APPOINTMENTS.TEXT.ACTION-DATE' }),
        formatter: (_, row) => moment(row.time).format(FORMATS_EXTENDED_DATE_AND_TIME),
      },
    ],
    [formatMessage, openChangesModal],
  );

  return (
    <div className="mt-3">
      <RemoteTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        id="appointmentActions"
        initialFetch
        keyField="time"
        loading={loading}
        params={params}
      />
      {appointment && selectedAction && (
        <ActionDetailsModal
          action={selectedAction}
          appointmentNumber={appointment.number}
          isOpen={isChangesModalOpen}
          toggle={toggleChangesModal}
        />
      )}
    </div>
  );
};
