import { Option, Package, PackageItem } from 'app/types';

export interface BranchBillingPackagesState {
  packageItems: PackageItem[];
  selectedPackage: Package | null;
  expandedPerformer: Option | null;
  mode: ModalMode;
  isPristine: boolean;
}

export enum ModalMode {
  Unknown = 'Unknown',
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}
