import { useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { ChangeAction } from 'redux-form/lib/reduxForm';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { fetchWaitingAppointmentDetails } from 'app/redux/appointment/appointment.actions';
import { generateWorkingTimeOption } from 'app/features/appointment/form/helpers/generateWorkingTimeOption';
import { WorkingTimeOption } from 'app/features/appointment/form/types';
import { isPossibleToCreatePhantomWaiting, isPossibleToCreateWaiting } from 'app/features/calendar/helpers';

export const useWorkingTimeOptions = (change: ChangeAction) => {
  const dispatch = useAppDispatch();
  const appointment = useAppSelector((state) => state.appointment.data);
  const appointmentDetails = useAppSelector((state) => state.appointment.details);
  const day = useAppSelector((state) => state.appointment.day.data);
  const isPhantom = useAppSelector((state) => !!state.calendar.selectedWaitingSlot?.isPhantom);

  useEffect(() => {
    if (appointment) {
      // fetch WA details
      dispatch(fetchWaitingAppointmentDetails(appointment.id));
    }
  }, [appointment, dispatch]);

  return useMemo((): WorkingTimeOption[] => {
    if (
      appointmentDetails &&
      appointmentDetails.workingTimeId &&
      appointmentDetails.workingTimePeriodStart &&
      appointmentDetails.workingTimePeriodEnd
    ) {
      // Generate option for selected appointment
      const option = generateWorkingTimeOption(
        appointmentDetails.workingTimeId,
        appointmentDetails.workingTimePeriodStart,
        appointmentDetails.workingTimePeriodEnd,
      );
      // Select the generated option
      change('workingTime', option);
      return [option];
    }

    if (day) {
      // Select Working Times for the specific cases
      const workingTimesForWaiting = day.workingTimesWithSlots.filter((wt) => isPossibleToCreateWaiting(wt));
      const workingTimesForPhantomWaiting = day.workingTimesWithSlots.filter((wt) =>
        isPossibleToCreatePhantomWaiting(wt),
      );

      // Generate options
      const workingTimes = isPhantom ? workingTimesForPhantomWaiting : workingTimesForWaiting;
      const workingTimeOptions = workingTimes.map((wt) =>
        generateWorkingTimeOption(wt.workingTimeId, wt.workingTimeStart, wt.workingTimeEnd),
      );

      // Sort options
      return sortBy(workingTimeOptions, 'details.from');
    }

    return [];
  }, [appointmentDetails, change, day, isPhantom]);
};
