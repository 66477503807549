import { InputGroupText } from 'reactstrap';
import { Field } from 'redux-form';
import { SarCurrencySymbol } from 'app/components/sarCurrencySymbol/sarCurrencySymbol';
import { InputComponent, PRICE_VALUE_MAX, PRICE_VALUE_MIN } from 'app/shared';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Props {
  disabled: boolean;
  label: string;
  name: keyof PaymentModeFormData;
}
export const MaxPatientShareField = ({ disabled, label, name }: Props) => {
  return (
    <Field
      disabled={disabled}
      name={name}
      appendContent={
        <InputGroupText>
          <SarCurrencySymbol />
        </InputGroupText>
      }
      component={InputComponent}
      label={label}
      min={PRICE_VALUE_MIN}
      max={PRICE_VALUE_MAX}
      step={0.01}
      type="number"
    />
  );
};
