import { AnyAction } from 'redux';
import { generateOptions } from 'app/helpers';
import * as authStorageKeys from 'app/redux/auth/auth.constants';
import * as types from 'app/redux/auth/auth.types';
import { setTokens } from 'app/redux/auth/helpers';
import { Option, User, UserRoles } from 'app/types';

const ROLE_NONE: Option<UserRoles> = {
  label: 'None',
  value: UserRoles.None,
  translation: {
    ar: 'None',
    en: 'None',
  },
};

export interface AuthState {
  current: User;
  specialityOptions: Option[];
  token: string | null;
  /* format: ISO_8601 */
  tokenExpiryDate: string | null;
  refreshToken: string | null;
  whileFetching: boolean;
}

export const initialState: AuthState = {
  current: {
    branches: [],
    roles: [ROLE_NONE],
    filtersDefaults: null,
    allowedForEMR: false,
    allowedForInsuranceApproval: false,
    allowedForInsuranceManagement: false,
    allowedForTakeActionReferral: false,
    allowedForViewReferral: false,
    createdAt: '',
    lastModifiedAt: null,
  },
  specialityOptions: [],
  token: localStorage.getItem(authStorageKeys.STORAGE_TOKEN_KEY),
  tokenExpiryDate: localStorage.getItem(authStorageKeys.STORAGE_TOKEN_EXPIRATION_DATE),
  refreshToken: localStorage.getItem(authStorageKeys.STORAGE_REFRESH_TOKEN_KEY),
  whileFetching: false,
};

export const authReducer = (state = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case types.SET_TOKENS:
      return {
        ...state,
        ...setTokens(action.payload),
      };
    case types.REPLACE_TOKENS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        tokenExpiryDate: action.payload.tokenExpiryDate,
      };
    case types.LOGIN_USER_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...setTokens(action.payload),
        whileFetching: false,
      };
    case types.LOGIN_USER_FAILURE:
      return initialState;
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        ...setTokens(action.payload),
      };
    case types.LOGOUT:
      return initialState;
    case types.SOFT_LOGOUT:
      return initialState;
    case types.FETCH_CURRENT_USER_REQUEST:
      return {
        ...state,
        whileFetching: true,
      };
    case types.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        current: {
          ...action.payload.data.userInfo,
          hasToChangePassword: action.payload.data.hasToChangePassword,
        },
        whileFetching: false,
      };
    case types.FETCH_CURRENT_USER_FAILURE:
      return initialState;
    case types.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        current: action.payload.data,
      };

    // TODO: These cases should be removed in the future (specialityOptions, current.branches)
    case types.FETCH_SPECIALITY_OPTIONS_REQUEST:
      return {
        ...state,
        specialityOptions: initialState.specialityOptions,
      };
    case types.FETCH_SPECIALITY_OPTIONS_SUCCESS:
      return {
        ...state,
        specialityOptions: generateOptions(action.payload.data),
      };
    case types.FETCH_OWNER_BRANCH_OPTIONS_REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          branches: [],
        },
      };
    case types.FETCH_OWNER_BRANCH_OPTIONS_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          branches: generateOptions(action.payload),
        },
      };
    default:
      return state;
  }
};
