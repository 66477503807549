export const institutionLabels = {
  addressAr: 'CORE.LABEL.ADDRESS-AR',
  addressEn: 'CORE.LABEL.ADDRESS-EN',
  appointmentReminderBefore: 'CLINICS.TEXT.APPOINTMENT-REMINDER',
  city: 'CORE.LABEL.CITY',
  clinicType: 'CLINICS.TEXT.TYPE',
  descriptionAr: 'CORE.LABEL.CLINIC-DESCRIPTION-AR',
  descriptionEn: 'CORE.LABEL.CLINIC-DESCRIPTION-EN',
  images: 'CORE.LABEL.LOGO',
  location: 'CORE.LABEL.LOCATION',
  logo: 'CORE.LABEL.LOGO',
  managementSystem: 'CLINICS.TEXT.CURRENT-SYSTEM',
  medicalLicenseExpiryDate: 'CORE.LABEL.MEDICAL-LICENSE-EXPIRY-DATE',
  medicalLicenseFile: 'CORE.LABEL.MEDICAL-LICENSE-IMAGE',
  medicalLicenseNumber: 'CORE.LABEL.MEDICAL-LICENSE-NUMBER',
  nameAr: 'CORE.LABEL.CLINIC-NAME-AR',
  nameEn: 'CORE.LABEL.CLINIC-NAME-EN',
  otherClinicType: 'CORE.LABEL.OTHER-CLINIC-TYPE',
  otherManagementSystem: 'CORE.LABEL.OTHER-MANAGEMENT-SYSTEM',
  phoneNumber: 'CORE.LABEL.PHONE-NUMBER',
  protectPhoneNumber: 'CLINICS.TEXT.PROTECT-PHONE-NUMBERS',
  requireSaudiIdDuringPatientRegistration: 'CLINICS.TEXT.REQUIRED-NATIONAL-ID',
  specialities: 'CORE.LABEL.MEDICAL-SPECIALITIES',
  status: 'CORE.LABEL.STATUS',
  vatRegistrationNumber: 'CORE.LABEL.VAT-NUMBER',
  allowedForEmr: 'MODULES.LABEL.EMR',
  allowedForInsuranceManagement: 'CORE.LABEL.INSURANCE-MANAGEMENT',
  nphiesId: 'CORE.LABEL.NPHIES-ID',
  crNumber: 'CORE.LABEL.CR-NUMBER',
  branchHasDifferentInfoThanMI: 'BRANCHES.LABEL.IS-BRANCH-INDEPENDENT',
  crExpiryDate: 'CORE.LABEL.CR-EXPIRY-DATE',
  vatNumber: 'CORE.LABEL.VAT-NUMBER',
  crFile: 'CORE.LABEL.CR-FILE',
  nphiesEnabled: 'CORE.LABEL.NPHIES-ENABLED',
  manySpecialtiesCount: 'CORE.LABEL.MANY-SPECIALITIES-COUNT',
  licenseNumber: 'CORE.LABEL.MEDICAL-LICENSE-NUMBER',
  isFatherNameRequiredForPatients: 'CLINICS.TEXT.REQUIRED-PATIENT-FATHER-NAME',
  isGrandFatherNameRequiredForPatients: 'CLINICS.TEXT.REQUIRED-PATIENT-GRAND-FATHER-NAME',
  ownerAllowedForTreatmentView: 'CORE.TEXT.OWNER-ALLOWED-FOR-TREATMENT',
  ownerAllowedForOrders: 'CORE.TEXT.OWNER-ALLOWED-FOR-ORDERS',
};

export const ownerLabels = {
  crExpiryDate: 'CLINICS.TEXT.CR-EXPIRY',
  crFile: 'CORE.LABEL.CR-FILE',
  crNumber: 'CORE.LABEL.CR-NUMBER',
  email: 'CLINICS.TEXT.OWNER-EMAIL',
  nameAr: 'CLINICS.TEXT.OWNER-NAME-AR',
  nameEn: 'CLINICS.TEXT.OWNER-NAME-EN',
  officePhoneNumber: 'CLINICS.TEXT.OWNER-PHONE',
  phoneNumber: 'CLINICS.TEXT.OWNER-MOBILE',
};

export const branchLabels = {
  addressAr: 'CORE.LABEL.ADDRESS-AR',
  addressEn: 'CORE.LABEL.ADDRESS-EN',
  city: 'CORE.LABEL.CITY',
  images: 'CORE.LABEL.LOGO',
  location: 'CORE.LABEL.LOCATION',
  medicalLicenseExpiryDate: 'CORE.LABEL.MEDICAL-LICENSE-EXPIRY-DATE',
  medicalLicenseFile: 'CORE.LABEL.MEDICAL-LICENSE-IMAGE',
  medicalLicenseNumber: 'CORE.LABEL.MEDICAL-LICENSE-NUMBER',
  nameAr: 'CORE.LABEL.BRANCH-NAME-AR',
  nameEn: 'CORE.LABEL.BRANCH-NAME-EN',
  phoneNumber: 'CORE.LABEL.PHONE-NUMBER',
  nphiesId: 'CORE.LABEL.NPHIES-ID',
  branchHasDifferentInfoThanMI: 'BRANCHES.TEXT.BRANCH-INFO',
  crExpiryDate: 'CLINICS.TEXT.CR-EXPIRY',
  crFile: 'CORE.LABEL.CR-FILE',
  crNumber: 'CORE.LABEL.CR-NUMBER',
  logo: 'CORE.LABEL.LOGO',
  specialities: 'CORE.LABEL.MEDICAL-SPECIALITY',
  vatNumber: 'CORE.LABEL.VAT-NUMBER',
};
