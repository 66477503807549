import React, { useCallback } from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { useAppIntl } from 'app/helpers';
import { CustomLabel } from 'app/components/customLabel/customLabel';
import { QuantityInput } from 'app/components/quantityInput/quantityInput';

export interface QuantityInputComponentProps {
  disabled?: boolean;
  isRequired?: boolean;
  label?: string;
  size?: 'sm' | 'lg';
  onEnterKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  initialValue?: number;
  min?: number;
  max?: number;
  info?: string;
  noMargin?: boolean;
  onChange?: () => void;
}

export const QuantityInputComponent = ({
  input,
  disabled,
  isRequired,
  label,
  meta,
  size,
  info,
  max,
  min = 0,
  fullWidth = false,
  initialValue,
  noMargin = false,
  ...rest
}: WrappedFieldProps & QuantityInputComponentProps) => {
  const invalid = !!meta.error && meta.touched;
  const { formatMessage } = useAppIntl();

  const updateQuantity = useCallback(
    (value: number) => {
      const clampedValue = Math.min(Math.max(value, min), max ?? Infinity);
      input.onChange(clampedValue);
    },
    [input, max, min],
  );

  return (
    <FormGroup noMargin={noMargin}>
      {label && <CustomLabel fieldId={input.name} info={info} isRequired={isRequired} label={label} />}
      <QuantityInput
        {...input}
        name={input.name}
        disabled={disabled}
        size={size}
        quantity={input.value}
        fullWidth={fullWidth}
        updateQuantity={updateQuantity}
        initialValue={initialValue ?? min}
        onChange={input.onChange}
        min={min}
        max={max}
        {...rest}
      />
      {invalid && <FormFeedback className="d-block">{formatMessage({ id: meta.error })}</FormFeedback>}
    </FormGroup>
  );
};
