export const TOGGLE_MODAL = 'modals/TOGGLE_MODAL';
export const TOGGLE_CHANGE_PASSWORD_MODAL = 'modals/TOGGLE_CHANGE_PASSWORD_MODAL';
export const TOGGLE_ADD_BRANCH_MODAL = 'modals/TOGGLE_ADD_BRANCH_MODAL';
export const TOGGLE_EDIT_SYSTEMOWNER_MODAL = 'modals/TOGGLE_EDIT_SYSTEMOWNER_MODAL';
export const TOGGLE_ADD_DOCTOR_MODAL = 'modals/TOGGLE_ADD_DOCTOR_MODAL';
export const TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL = 'modals/TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL';
export const TOGGLE_BRANCH_IMAGES_MODAL = 'modals/TOGGLE_BRANCH_IMAGES_MODAL';
export const TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL = 'modals/TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL';
export const TOGGLE_EDIT_PATIENT_MODAL = 'modals/TOGGLE_EDIT_PATIENT_MODAL';
export const TOGGLE_EDIT_PATIENT_NUMBER_MODAL = 'modals/TOGGLE_EDIT_PATIENT_NUMBER_MODAL';
export const TOGGLE_ADD_PATIENT_MODAL = 'modals/TOGGLE_ADD_PATIENT_MODAL';
export const TOGGLE_BLOCK_PATIENT_MODAL = 'modals/TOGGLE_BLOCK_PATIENT_MODAL';
export const TOGGLE_EDIT_PROFILE_MODAL = 'modals/TOGGLE_EDIT_PROFILE_MODAL';
export const TOGGLE_EXPORT_TO_XLS_MODAL = 'modals/TOGGLE_EXPORT_TO_XLS_MODAL';
export const TOGGLE_DELETED_DOCTOR_MODAL = 'modals/TOGGLE_DELETED_DOCTOR_MODAL';
export const TOGGLE_ADD_USER_ROLE_MODAL = 'modals/TOGGLE_ADD_USER_ROLE_MODAL';
export const TOGGLE_EDIT_USER_ROLE_MODAL = 'modals/TOGGLE_EDIT_USER_ROLE_MODAL';
export const TOGGLE_CONFIRMATION_MODAL = 'modals/TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_ADD_PRODUCT_MODAL = 'modals/TOGGLE_ADD_PRODUCT_MODAL';
export const TOGGLE_UNBLOCK_PATIENT_MODAL = 'modals/TOGGLE_UNBLOCK_PATIENT_MODAL';
export const TOGGLE_EDIT_DRAFT_ITEM_MODAL = 'modals/TOGGLE_EDIT_DRAFT_ITEM_MODAL';
export const TOGGLE_ADD_INVOICE_ITEM_MODAL = 'modals/TOGGLE_ADD_INVOICE_ITEM_MODAL';
export const TOGGLE_EDIT_INVOICE_ITEM_MODAL = 'modals/TOGGLE_EDIT_INVOICE_ITEM_MODAL';
