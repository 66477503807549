import { GovernmentIssuedIdType, PatientBadges, PatientGender, PatientStatus, PatientType } from 'app/types/Patient';
import { PatientSourceOption } from 'app/types/PatientSources';
import { InvoiceStatus } from './Invoice';
import { Location } from './Location';
import { Option } from './Option';
import { RemoteFile } from './RemoteFile';

export enum AppointmentStatus {
  Attended = '1',
  Booked = '0',
  BookedBefore24 = '8',
  CancelledByAdmin = '5',
  CancelledByMedicalInstitution = '2',
  CancelledByPatient = '4',
  Confirmed = '6',
  ConfirmedByStaff = '7',
  NoShow = '3',
}

export enum AppointmentTimeStatus {
  All = '0',
  Regular = '1',
  WithoutTime = '2',
}

export enum WaitingAppointmentStatus {
  Active = '0',
  Promoted = '1',
  Cancelled = '2',
}

export interface AppointmentProductOption extends Option {
  details: {
    nameInAppointmentTicket: Option;
  };
}

export type Appointment = {
  branchHasDifferentInfoThanMI: boolean;
  acceptablePatientAgeFrom?: number;
  acceptablePatientAgeTo?: number;
  branch: Option;
  branchAddress: string;
  branchLocation: Location;
  /** Not available for the Waiting Appointment */
  canBeModified?: boolean;
  checkedIn: boolean;
  clinic: Option;
  branchLogo: RemoteFile;
  clinicLogo: RemoteFile;
  coordinatorPhoneNumber: string | null;
  createdAt: string;
  createdBy: string | null;
  date: string;
  doctorFocus: string;
  doctorId: string;
  doctorName: string;
  doctorRank: Option;
  doctorTitle: Option;
  end: string | null;
  governmentIssuedId: string | null;
  governmentIssuedIdType: Option<GovernmentIssuedIdType> | null;
  guardianId: string | null;
  guardianIsDeleted: boolean;
  guardianName: string;
  hasMergePatientsBadge: boolean;
  id: string;
  incompleteDemographicInfo: boolean;
  internalFileNumber: string | null;
  invoiceId: string | null;
  isConflicting: boolean;
  isPhantom?: boolean;
  isVerificationRequired: boolean;
  note: string | null;
  number: string;
  patientBadges?: PatientBadges;
  patientClinicyId: string;
  patientClinicyMrn?: string;
  patientDateOfBirth?: string;
  patientGender: Option<PatientGender>;
  patientId: string;
  patientIsDeleted: boolean;
  patientName: string;
  patientPhoneNumber: string;
  patientSource?: PatientSourceOption;
  patientStatus: Option<PatientStatus>;
  patientType: Option<PatientType>;
  product: AppointmentProductOption;
  referralId: string | null;
  speciality: Option;
  start: string;
  status?: Option<AppointmentStatus>;
  subSpeciality: Option;
  workingTimeId?: string;
  /** @example 0000-00-00T00:00:00 */
  workingTimePeriodEnd?: string;
  /** @example 0000-00-00T00:00:00 */
  workingTimePeriodStart?: string;
};

export enum AppointmentActionType {
  AppointmentUpdate = 'AppointmentUpdate',
  WaitingAppointmentUpdate = 'WaitingAppointmentUpdate',
}

export enum AppointmentsCategory {
  Branch = 'Branch',
  Conflicting = 'Conflicting',
  Doctor = 'Doctor',
  General = 'General',
  Patient = 'Patient',
  PatientStatistics = 'PatientStatistics',
}

export type AppointmentsCategoryData = {
  appointmentCategory: AppointmentsCategory;
  id?: string;
};

export interface AppointmentActionDetails extends Omit<Appointment, 'product'> {
  productNameAr: string;
  productNameEn: string;
}

export type AppointmentAction = {
  action: string;
  actionTaker: string;
  newObject: AppointmentActionDetails | null;
  oldObject: AppointmentActionDetails | null;
  time: string;
  type: AppointmentActionType;
};

export enum AppointmentType {
  Standard = 'appointments',
  Waiting = 'waiting-appointments',
}

// TODO: it is possible to remove?
export enum AppointmentTimeType {
  Past = 'passed',
  Upcoming = 'upcoming',
  Current = 'current',
}

export enum AppointmentModificationSource {
  Portal = '0',
  App = '1',
  Ticket = '2',
}

export interface AppointmentInvoice {
  appointmentId: string | null;
  billingItemsBased: boolean;
  createdBy: Option;
  draftNumber: string | null;
  hasMergePatientsBadge: boolean;
  id: string;
  invoiceNumber: string | null;
  isDraft: boolean;
  status: Option<InvoiceStatus> | null;
  /* ISOString */
  timeOfCreation: string;
}

export enum AppointmentManagementSystem {
  PaperBased = '1',
  Excel = '2',
  Nicto = '3',
  Easy = '4',
  Horizon = '5',
  Videoleo = '6',
  Oracle = '7',
  Other = '8',
}
