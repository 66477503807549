import { Appointment } from 'app/types';
import { AppointmentFormData } from 'app/features/appointment/form/types';

type PatientData = Pick<AppointmentFormData, 'patientId' | 'selectedPhoneNumber' | 'selectedGuardian'>;

export const getAppointmentPatientFormData = (appointment: Appointment): PatientData => {
  const data: PatientData = {
    patientId: appointment.patientId,
    selectedPhoneNumber: { label: appointment.patientPhoneNumber, value: appointment.patientPhoneNumber },
  };
  if (appointment.guardianId) {
    data.patientId = appointment.guardianId;
    data.selectedGuardian = { label: appointment.guardianName, value: appointment.guardianId };
  }

  return data;
};
