import React, { ReactNode, useCallback } from 'react';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import styles from 'app/components/step/step.module.scss';
import { CompletionStatusIcon } from 'app/features/bookingWizardModal/bookingWizard/shared';

interface Props<T extends string | number> {
  children?: ReactNode;
  className?: string;
  name: T;
  activeStep: any;
  completed: boolean;
  heading: string;
  text?: string;
  changeStepLogic?: boolean;
  changeStep: (name: T) => void;
}

export function Step<T extends string | number>({
  children,
  name,
  activeStep,
  completed,
  heading,
  changeStep,
  className,
  text,
  changeStepLogic,
}: Props<T>) {
  //Variables
  const isActive = activeStep === name;

  const changeStepHandler = useCallback(
    () =>
      changeStepLogic
        ? activeStep !== name && changeStepLogic && changeStep(name)
        : activeStep !== name && changeStep(name),
    [activeStep, changeStepLogic, changeStep, name],
  );

  return (
    <>
      <div className={classnames(styles.step, { [styles.active]: isActive }, className)}>
        <div className={styles.header} onClick={changeStepHandler}>
          <h4 className={styles.title}>
            <CompletionStatusIcon complete={completed} />
            {heading}
            {text && <span className="selected-value ms-2">{text}</span>}
          </h4>
        </div>
        <Collapse isOpen={activeStep === name}>
          <div className={styles.content}>{children}</div>
        </Collapse>
      </div>
    </>
  );
}
