import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  InvoicesDraftsQueryParams,
  InvoicesQueryParams,
  InvoicesRequestsQueryParams,
} from 'app/services/invoices/types';
import { DraftInvoiceWithoutDetails, InvoiceRequest, InvoiceWithoutDetails } from 'app/redux/invoice/types';
import { QueryParams, QueryResponseWithPagination } from 'app/types';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['DraftInvoices'],
  endpoints: (builder) => ({
    getInvoices: builder.query<QueryResponseWithPagination<InvoiceWithoutDetails[]>, InvoicesQueryParams | undefined>({
      query: (params) => ({ url: 'invoices', params }),
    }),
    getPatientInvoices: builder.query<QueryResponseWithPagination<InvoiceWithoutDetails[]>, QueryParams | undefined>({
      query: (params) => ({ url: 'invoices', params }),
    }),
    getInvoicesDrafts: builder.query<
      QueryResponseWithPagination<DraftInvoiceWithoutDetails[]>,
      InvoicesDraftsQueryParams | undefined
    >({
      query: (params) => ({ url: 'draft-invoices', params }),
      providesTags: ['DraftInvoices'],
    }),
    deleteInvoiceDraft: builder.mutation<void, string>({
      query: (id) => ({
        url: `draft-invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DraftInvoices'],
    }),
    getInvoicesRequests: builder.query<
      QueryResponseWithPagination<InvoiceRequest[]>,
      InvoicesRequestsQueryParams | undefined
    >({
      query: (params) => ({ url: 'invoice-requests', params }),
    }),
    exportInvoices: builder.mutation<void, InvoicesQueryParams | undefined>({
      query: (params) => ({
        url: 'invoices/excel',
        method: 'GET',
        params,
      }),
    }),
    exportInvoicesRequests: builder.mutation<void, InvoicesRequestsQueryParams | undefined>({
      query: (params) => ({
        url: 'invoice-requests/excel',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useDeleteInvoiceDraftMutation,
  useExportInvoicesMutation,
  useGetInvoicesDraftsQuery,
  useGetInvoicesQuery,
  useGetInvoicesRequestsQuery,
  useExportInvoicesRequestsMutation,
  useGetPatientInvoicesQuery,
} = invoicesApi;
