import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import { ParsedQs } from 'qs';
import { getApiTime } from 'app/helpers/dateAndTime/dateAndTime';
import { Option, QueryParams, TimeRange } from 'app/types';

type CoreQueryParams = Pick<QueryParams, 'locale' | 'order' | 'orderAsc' | 'pageNumber' | 'pageSize'>;

export const getCoreQueryParams = (params: void | QueryParams | undefined): CoreQueryParams =>
  omitBy(
    {
      locale: params?.locale,
      order: params?.order,
      orderAsc: params?.orderAsc,
      pageNumber: params?.pageNumber,
      pageSize: params?.pageSize,
    },
    isNil,
  );

export const getNonCoreQueryParams = (params: void | QueryParams | undefined): QueryParams => {
  return omit(params || {}, ['locale', 'order', 'orderAsc', 'pageNumber', 'pageSize']);
};

export const getNonEmptyQueryParams = (params: void | QueryParams | undefined): QueryParams => {
  return omitBy(params || {}, isEmpty);
};

export const getQueryParamsWithoutLabels = (params: void | QueryParams | undefined): QueryParams => {
  return omitBy(params || {}, (_, key) => key.includes('Label') || key.includes('Labels'));
};

export const convertOptionToQueryParams = <T extends string>(name: string, option: Option<T>): QueryParams => {
  return {
    [name]: option.value,
    [`${name}Label`]: option.label,
  };
};

export const convertOptionsToQueryParams = (name: string, options: Option[]): QueryParams => {
  return {
    [name]: options.map((o) => o.value),
    [`${name}Labels`]: options.map((o) => o.label),
  };
};

export const convertTimeRangeToQueryParams = (name: string, timeRange: TimeRange): QueryParams => {
  const fromKey = `${name}From`;
  const toKey = `${name}To`;

  return {
    [fromKey]: getApiTime(timeRange.from),
    [toKey]: getApiTime(timeRange.to),
  };
};

export const getQueryParamsFromOption = (name: string, urlParams: ParsedQs): QueryParams => {
  const key = `${name}Label`;
  return {
    [name]: urlParams[name],
    [key]: urlParams[key],
  };
};

export const getQueryParamsFromOptions = (name: string, urlParams: ParsedQs): QueryParams => {
  const key = `${name}Labels`;
  return {
    [name]: urlParams[name],
    [key]: urlParams[key],
  };
};

export const getQueryParamsFromTimeRange = (name: string, urlParams: ParsedQs): QueryParams => {
  const fromKey = `${name}From`;
  const toKey = `${name}To`;
  return {
    [fromKey]: urlParams[fromKey],
    [toKey]: urlParams[toKey],
  };
};
