import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import { useAppDispatch, useAppSelector, useCurrentUser, useModalCleaner } from 'app/helpers';
import { FORMS_PROMOTE_WAITING } from 'app/shared';
import { promoteWaitingAppointment, resetPatient, setPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { AppointmentForm } from 'app/features/appointment/form';
import {
  appointmentFormValuesAdapter,
  getAppointmentPatientFormData,
  useAppointmentFormStatus,
  useCreatePatientHandlers,
} from 'app/features/appointment/form/helpers';
import { AppointmentFormData } from 'app/features/appointment/form/types';
import { CreatePatientModals } from 'app/features/appointment/shared';

export const PromoteWaitingAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage } = useIntl();
  const {
    currentUser: { allowedForEditAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.promoteWaitingAppointment.isOpen);
  const appointment = useAppSelector((state) => state.appointment.data);
  const submitting = !!useAppSelector((state) => state.form[FORMS_PROMOTE_WAITING]?.submitting);
  const { isFormDisabled } = useAppointmentFormStatus(appointment?.start);

  // Values
  const clinicId = appointment?.clinic.value;
  const initialValues = useMemo((): Partial<AppointmentFormData> | undefined => {
    if (appointment) {
      return {
        branchId: appointment.branch.value,
        date: moment(appointment.start).toDate(),
        doctorId: appointment.doctorId,
        doctorName: appointment.doctorName,
        note: appointment.note,
        patientSource: appointment.patientSource,
        product: appointment.product,
        speciality: appointment.speciality,
        subSpeciality: appointment.subSpeciality,
        ...getAppointmentPatientFormData(appointment),
      };
    }

    return undefined;
  }, [appointment]);

  // Actions
  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: FORMS_PROMOTE_WAITING,
      setPatient,
      resetPatient,
    });
  const toggle = useCallback(() => dispatch(toggleModal('promoteWaitingAppointment')), [dispatch]);
  const submitForm = useCallback(() => dispatch(submit(FORMS_PROMOTE_WAITING)), [dispatch]);
  const onSubmit = useCallback(
    async (values: AppointmentFormData) => {
      if (appointment) {
        return await dispatch(promoteWaitingAppointment(appointment, appointmentFormValuesAdapter(values)));
      }
    },
    [appointment, dispatch],
  );

  useModalCleaner('promoteWaitingAppointment', modalRef);

  return (
    <Modal ref={modalRef} backdrop="static" keyboard={false} isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'APPOINTMENTS.TEXT.MOVE-WA-TO-REGULAR' })}</ModalHeader>
      <ModalBody>
        {appointment && clinicId && (
          <AppointmentForm
            form={FORMS_PROMOTE_WAITING}
            initialValues={initialValues}
            clinicId={clinicId}
            createdPatientMode={!!createdPatient}
            isEdit
            isFormDisabled={isFormDisabled}
            onAddNewPatient={onAddNewPatient}
            onSubmit={onSubmit}
            showFullSlots
            showSlotsWithNumber
            resetMode={onResetCreated}
            ageRestrictions={{
              acceptablePatientAgeFrom: appointment.acceptablePatientAgeFrom,
              acceptablePatientAgeTo: appointment.acceptablePatientAgeTo,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
        <Button
          color="primary-gradient"
          disabled={isFormDisabled || submitting || !allowedForEditAppointments}
          onClick={submitForm}
        >
          {formatMessage({ id: 'CORE.BUTTON.SAVE-CHANGES' })}
        </Button>
      </ModalFooter>

      <CreatePatientModals
        initialPhoneNumber={initialPhoneNumber}
        clinicId={clinicId}
        setPatientData={setPatientData}
      />
    </Modal>
  );
};
