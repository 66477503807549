import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import {
  getDoctorFullName,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useCurrentUser,
  useModalCleaner,
} from 'app/helpers';
import { FORMS_FULL_EDIT_APPOINTMENT } from 'app/shared';
import { editAppointment, resetPatient, setPatient } from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { AppointmentForm } from 'app/features/appointment/form';
import {
  appointmentFormValuesAdapter,
  generateSlotOption,
  getAppointmentPatientFormData,
  useAppointmentFormStatus,
  useCreatePatientHandlers,
} from 'app/features/appointment/form/helpers';
import { AppointmentFormData } from 'app/features/appointment/form/types';
import { CreatePatientModals } from 'app/features/appointment/shared';

export const FullEditAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage, locale } = useAppIntl();
  const {
    currentUser: { allowedForEditAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.fullEditAppointment.isOpen);
  const selectedSlot = useAppSelector((state) => state.calendar.selectedSlot);
  const editedAppointment = useAppSelector((state) => state.appointment.fullEdit.data);
  const submitting = !!useAppSelector((state) => state.form[FORMS_FULL_EDIT_APPOINTMENT]?.submitting);
  const { isFormDisabled } = useAppointmentFormStatus(editedAppointment?.start);

  // Values
  const clinicId = editedAppointment?.clinic.value;
  const initialValues = useMemo((): Partial<AppointmentFormData> | undefined => {
    if (editedAppointment && selectedSlot) {
      return {
        branchId: selectedSlot.branchId,
        date: moment(selectedSlot.date).toDate(),
        doctorId: selectedSlot.doctor.id,
        doctorName: getDoctorFullName(selectedSlot.doctor, locale),
        note: editedAppointment.note,
        patientSource: editedAppointment.patientSource,
        product: selectedSlot.product,
        slot: selectedSlot.start ? generateSlotOption(selectedSlot.start, selectedSlot.end) : undefined,
        speciality: selectedSlot.speciality,
        subSpeciality: selectedSlot.subSpeciality,
        ...getAppointmentPatientFormData(editedAppointment),
      };
    }

    return undefined;
  }, [editedAppointment, selectedSlot, locale]);

  // Actions
  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: FORMS_FULL_EDIT_APPOINTMENT,
      setPatient,
      resetPatient,
    });
  const toggle = useCallback(() => dispatch(toggleModal('fullEditAppointment')), [dispatch]);
  const submitForm = useCallback(() => dispatch(submit(FORMS_FULL_EDIT_APPOINTMENT)), [dispatch]);
  const onSubmit = useCallback(
    async (values: AppointmentFormData) => {
      if (editedAppointment) {
        return await dispatch(editAppointment(editedAppointment.id, appointmentFormValuesAdapter(values)));
      }
    },
    [editedAppointment, dispatch],
  );

  useModalCleaner('fullEditAppointment', modalRef);

  return (
    <Modal ref={modalRef} backdrop="static" keyboard={false} isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'APPOINTMENTS.TEXT.EDIT-APPOINTMENT' })}</ModalHeader>
      <ModalBody>
        {editedAppointment && clinicId && (
          <AppointmentForm
            form={FORMS_FULL_EDIT_APPOINTMENT}
            initialValues={initialValues}
            clinicId={editedAppointment.clinic.value}
            createdPatientMode={!!createdPatient}
            isFormDisabled={isFormDisabled}
            isFullEdit
            onAddNewPatient={onAddNewPatient}
            onSubmit={onSubmit}
            resetMode={onResetCreated}
            ageRestrictions={{
              acceptablePatientAgeFrom: editedAppointment.acceptablePatientAgeFrom,
              acceptablePatientAgeTo: editedAppointment.acceptablePatientAgeTo,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
        <Button
          color="primary-gradient"
          disabled={isFormDisabled || submitting || !allowedForEditAppointments}
          onClick={submitForm}
          data-cy="fullEditAppointmentModalSaveButton"
        >
          {formatMessage({ id: 'CORE.BUTTON.SAVE-CHANGES' })}
        </Button>
      </ModalFooter>

      <CreatePatientModals
        initialPhoneNumber={initialPhoneNumber}
        clinicId={clinicId}
        setPatientData={setPatientData}
      />
    </Modal>
  );
};
