import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FORMS_LOGIN_FORM } from 'app/shared';
import { login } from 'app/redux/auth/auth.actions';
import FormComponent from 'app/features/login/form/form.component';

const mapStateToProps = () => ({
  form: FORMS_LOGIN_FORM,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => dispatch(login(values)),
});

export const FormContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
)(FormComponent);
