import round from 'lodash/round';
import { FormErrors } from 'redux-form';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { ErrorType, InvoicePayBy } from 'app/types';
import { ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';

export const validate = (
  values: Partial<ItemFormData>,
  props: DecoratedFormProps<ItemFormData, ItemFormProps, ErrorType>,
): FormErrors<ItemFormData, ErrorType> => {
  let errors: Partial<{ [Property in keyof ItemFormData]: ErrorType }> = {};

  // #1. Check the Minimum Selling Price (if the payment mode is Pay by Patient, and it is not an invoice containing items from a package).
  if (props.invoicePayBy === InvoicePayBy.Patient && !props.invoicePackageSnapshotId) {
    const itemMinimumSellingPrice = values[ItemField.item]?.details.minimumSellingPrice;
    const itemQuantity = values[ItemField.totalQuantity];
    const totalPrice = values[ItemField.totalPrice];

    if (itemMinimumSellingPrice && itemQuantity && typeof totalPrice === 'number') {
      const minimumSellingPrice = round(itemMinimumSellingPrice * itemQuantity, 2);

      if (totalPrice < minimumSellingPrice) {
        errors = {
          [ItemField.totalPrice]: { message: 'ERRORS.MINIMUM-SELLING-PRICE', value: minimumSellingPrice },
        };
      }
    }
  }

  return errors;
};
