import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { GetErrorFileResponse } from 'app/services/massAdding/types';
import {
  AddServiceParams,
  ChangeStatusParams,
  EditServiceParams,
  ExportBranchServicesParams,
  GetErrorFileParams,
  GetFileParams,
  GetHistoryParams,
  GetResponseParams,
  ImportServicesParams,
  ItemDetailsResponse,
} from 'app/services/services/types';
import {
  MassAddingItem,
  MassAddingRow,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RemoteFile,
} from 'app/types';
import { Service } from 'app/types/Services';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Services', 'History'],
  endpoints: (builder) => ({
    getBranchServices: builder.query<
      QueryResponseWithPagination<Service[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/products`,
        params,
      }),
      providesTags: [{ type: 'Services' }],
    }),
    importBranchServices: builder.mutation<void, ImportServicesParams>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/services/mass-adding-attempt`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['History'],
    }),
    addBranchServices: builder.mutation<void, AddServiceParams>({
      query: ({ branchId, body, params }) => ({
        url: `branches/${branchId}/products`,
        method: 'POST',
        params: params,
        body,
      }),
      invalidatesTags: ['Services'],
    }),
    editBranchServices: builder.mutation<void, EditServiceParams>({
      query: ({ body, productId, branchId }) => ({
        url: `branches/${branchId}/products/${productId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Services'],
    }),
    changeStatus: builder.mutation<void, ChangeStatusParams>({
      query: ({ branchId, productId, params }) => ({
        url: `branches/${branchId}/products/${productId}/status`,
        params,
        method: 'PUT',
      }),
      invalidatesTags: ['Services'],
    }),
    getMassAddingHistory: builder.query<QueryResponseWithPagination<MassAddingItem[]>, GetHistoryParams>({
      query: ({ params, branchId }) => ({
        params,
        url: `branches/${branchId}/services/mass-adding-attempts`,
      }),
      providesTags: ['History'],
    }),
    getReport: builder.query<void, GetResponseParams>({
      query: ({ params, branchId }) => ({
        params,
        url: `branches/${branchId}/services/mass-adding-attempts/history-excel`,
      }),
    }),
    getFile: builder.query<QueryResponse<RemoteFile>, GetFileParams>({
      query: ({ branchId, attemptId }) => ({
        url: `/branches/${branchId}/services/mass-adding-attempts/${attemptId}/uploaded-file`,
      }),
    }),
    getErrorFile: builder.query<GetErrorFileResponse, GetErrorFileParams>({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/services/mass-adding-attempts/${massAddingAttemptId}/errors-excel`,
      }),
    }),
    getPreviousVersionFile: builder.query<QueryResponse<RemoteFile>, GetFileParams>({
      query: ({ branchId, attemptId }) => ({
        url: `/branches/${branchId}/services/mass-adding-attempts/${attemptId}/file-before-updates`,
      }),
    }),
    getDetails: builder.query<QueryResponse<ItemDetailsResponse>, GetFileParams>({
      query: ({ branchId, attemptId }) => ({
        url: `/branches/${branchId}/services/mass-adding-attempts/${attemptId}`,
      }),
    }),
    exportBranchServices: builder.mutation<void, ExportBranchServicesParams>({
      query: ({ branchId, params, itemsForExport }) => ({
        url: `/branches/${branchId}/products/excel`,
        params: {
          ...params,
          itemsForExport,
        },
        method: 'GET',
      }),
    }),
    getTemplateFile: builder.query<QueryResponse<RemoteFile>, { branchId: string }>({
      query: ({ branchId }) => ({
        url: `/branches/${branchId}/services/mass-adding-template`,
        method: 'GET',
      }),
    }),
    getServicesMassAddingAttemptItems: builder.query<
      QueryResponseWithPagination<MassAddingRow[]>,
      { branchId: string; massAddingAttemptId: string; params?: QueryParams }
    >({
      query: ({ branchId, massAddingAttemptId, params }) => ({
        url: `branches/${branchId}/services/mass-adding-attempts/${massAddingAttemptId}/rows`,
        method: 'GET',
        params,
      }),
    }),
    getServicesMassAddingErrorsFile: builder.query<
      GetErrorFileResponse,
      {
        branchId: string;
        massAddingAttemptId: string;
      }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/services/mass-adding-attempts/${massAddingAttemptId}/errors-excel`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetBranchServicesQuery,
  useImportBranchServicesMutation,
  useAddBranchServicesMutation,
  useChangeStatusMutation,
  useEditBranchServicesMutation,
  useLazyGetPreviousVersionFileQuery,
  useLazyGetReportQuery,
  useGetMassAddingHistoryQuery,
  useLazyGetErrorFileQuery,
  useLazyGetFileQuery,
  useGetDetailsQuery,
  useExportBranchServicesMutation,
  useLazyGetTemplateFileQuery,
  useGetServicesMassAddingAttemptItemsQuery,
  useLazyGetServicesMassAddingErrorsFileQuery,
} = servicesApi;
