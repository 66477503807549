import { StorageEntityType } from 'app/services/storages/types';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';

interface ReservationEntity {
  entityType: StorageEntityType;
  entityId?: string;
}

export const getReservationEntity = (invoicingProcess: InvoicingProcess | null): ReservationEntity => {
  if (!invoicingProcess) {
    return {
      entityType: StorageEntityType.Invoice,
    };
  }

  if (invoicingProcess.isDraft && invoicingProcess.draftInvoiceId) {
    return {
      entityType: StorageEntityType.InvoiceDraft,
      entityId: invoicingProcess.draftInvoiceId,
    };
  }

  if (invoicingProcess.invoiceRequestId) {
    return {
      entityType: StorageEntityType.InvoiceRequest,
      entityId: invoicingProcess.invoiceRequestId,
    };
  }

  return {
    entityType: StorageEntityType.Invoice,
    ...(invoicingProcess.invoiceId && { entityId: invoicingProcess.invoiceId }),
  };
};
