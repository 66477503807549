import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  CreateReservationResponse,
  ReservationBodyParams,
  ReservationEntity,
  StorageEntity,
  StorageEntityType,
} from 'app/services/storages/types';

export const storagesApi = createApi({
  reducerPath: 'storagesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['itemStorages', 'reservation'],
  endpoints: (builder) => ({
    getItemStorages: builder.query<
      StorageEntity[],
      { branchId: string; performerId: string; performerBillingItemId: string }
    >({
      query: ({ branchId, performerId, performerBillingItemId }) => ({
        url: `branches/${branchId}/performers/${performerId}/performer-billing-items/${performerBillingItemId}/storages`,
        method: 'GET',
      }),
      providesTags: ['itemStorages'],
    }),
    getReservation: builder.query<ReservationEntity[], { entityId: string; entityType?: StorageEntityType }>({
      query: ({ entityId, entityType }) => ({
        url: `reservation/${entityId}`,
        method: 'GET',
        params: { entityType },
      }),
      providesTags: ['reservation'],
    }),
    reserveStorage: builder.mutation<CreateReservationResponse, { body: ReservationBodyParams; branchId: string }>({
      query: ({ body, branchId }) => ({
        url: `reservation/${branchId}`,
        method: 'POST',
        body: {
          isDeleted: false,
          ...body,
        },
      }),
      invalidatesTags: ['itemStorages'],
    }),
  }),
});

export const {
  useGetItemStoragesQuery,
  useLazyGetItemStoragesQuery,
  useReserveStorageMutation,
  useGetReservationQuery,
  useLazyGetReservationQuery,
} = storagesApi;
