import React, { useCallback, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { getPatientFullName, getTranslation, useAppIntl, useCurrentUser } from 'app/helpers';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { PatientType } from 'app/types';
import { PrimaryPatientBaseInfo } from 'app/features/patient/patientForm/types';
import { PreSearchDependentResult } from 'app/features/patient/preSearchClinicPatientModal/form/types';

export const useColumns = (
  openAddDependentModal: (result: PreSearchDependentResult) => void,
  phoneNumber: string | undefined,
  closeModal: () => void,
) => {
  const { formatMessage, locale } = useAppIntl();
  const {
    currentUser: { allowedForViewPatientProfile },
  } = useCurrentUser();

  // Handlers
  const onDecideToCreateDependent = useCallback(
    async (primaryPatientData: PrimaryPatientBaseInfo) => {
      if (phoneNumber) {
        await closeModal();
        openAddDependentModal({ phoneNumber, primaryPatientData });
      }
    },
    [closeModal, openAddDependentModal, phoneNumber],
  );

  return useMemo<ColumnDescription<ClinicPatientShortInfo>[]>(
    () => [
      {
        dataField: 'clinicyMrn',
        sort: true,
        text: formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' }),
        formatter: (_, row) =>
          allowedForViewPatientProfile ? (
            <a href={`/clinics/${row.clinicId}/patients/${row.id}`} rel="noreferrer noopener" target="_blank">
              {row.clinicyMrn}
            </a>
          ) : (
            <span>{row.clinicyMrn}</span>
          ),
      },
      {
        dataField: 'name',
        sort: true,
        text: formatMessage({ id: 'CORE.LABEL.PATIENT-FULL-NAME' }),
        formatter: (_, row) => getPatientFullName(row, locale),
        classes: 'text-nowrap',
      },
      {
        dataField: 'patientType',
        text: formatMessage({ id: 'CORE.LABEL.PATIENT-TYPE' }),
        formatter: (_, row) => getTranslation(row.patientType, locale),
      },
      {
        dataField: 'action',
        text: formatMessage({ id: 'CORE.TABLE.ACTION' }),
        formatter: (_, row, __, { onDecideToCreateDependent }) => {
          if (row.patientType.value !== PatientType.Dependent) {
            return (
              <Button size="sm" color="primary-gradient" onClick={() => onDecideToCreateDependent(row)}>
                {formatMessage({ id: 'PATIENTS.BUTTON.ADD-DEPENDENT' })}
              </Button>
            );
          }
        },
        formatExtraData: { onDecideToCreateDependent },
        headerClasses: 'text-center',
        classes: 'text-center',
      },
    ],
    [allowedForViewPatientProfile, formatMessage, locale, onDecideToCreateDependent],
  );
};
