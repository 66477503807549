import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  ClassBillingItemsEditMedications,
  ClassBillingItemsEditPackages,
  ImportBillingItemsClassConfigurationParams,
  ImportClassConfigurationParams,
  PolicyClassBillingItemsGroup,
} from 'app/services/insuranceClassBillingItems/types';
import { InsuranceContractEntityActionLog, QueryParams, QueryResponseWithPagination } from 'app/types';
import {
  InsuranceBillingPackagesContract,
  InsuranceExtraBillingItemsContract,
} from 'app/types/InsuranceBillingItemsContract';

export const insuranceClassBillingItemsApi = createApi({
  reducerPath: 'insuranceClassBillingItemsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'insuranceClassBillingItems',
    'insuranceClassBillingItemsLogs',
    'insuranceInsurancePackagesLogs',
    'insuranceMedicationsLogs',
  ],
  endpoints: (builder) => ({
    getInsuranceClassBillingItems: builder.query<
      QueryResponseWithPagination<PolicyClassBillingItemsGroup[]>,
      { insuranceClassId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClassId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-items/group/statuses`,
        params,
      }),
      providesTags: ['insuranceClassBillingItems'],
    }),

    getClassBillingItem: builder.query<
      QueryResponseWithPagination<InsuranceExtraBillingItemsContract[]>,
      { insuranceClassId: string; typeId?: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClassId, typeId, params }) => ({
        params: {
          ...params,
          type: typeId,
        },
        url: `insurance-classes/${insuranceClassId}/branch-billing-items`,
      }),
      providesTags: ['insuranceClassBillingItems'],
    }),

    // [i]: Import class configuration for Item
    importBillingItemsClassConfiguration: builder.mutation<void, ImportBillingItemsClassConfigurationParams>({
      query: ({ importToClassId, importFromClassId, billingItemTypes }) => ({
        url: `insurance-classes/${importToClassId}/branch-billing-items/import-from-class`,
        method: 'POST',
        body: {
          insuranceClassId: importFromClassId,
          billingItemTypes,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    // [i]: Import class configuration for Packages
    importPackagesClassConfiguration: builder.mutation<void, ImportClassConfigurationParams>({
      query: ({ importToClassId, importFromClassId }) => ({
        url: `insurance-classes/${importToClassId}/billing-items-packages/import-from-class`,
        method: 'POST',
        body: {
          insuranceClassId: importFromClassId,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    // [i]: Import class configuration for Medications
    importMedicationsClassConfiguration: builder.mutation<void, ImportClassConfigurationParams>({
      query: ({ importToClassId, importFromClassId }) => ({
        url: `insurance-classes/${importToClassId}/billing-items-medications/import-from-class`,
        method: 'POST',
        body: {
          insuranceClassId: importFromClassId,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    classBillingItemsContractorsMassAdding: builder.mutation<
      void,
      { insuranceContractorId: string; body: FormData; typeId?: string }
    >({
      query: ({ insuranceContractorId, body, typeId }) => ({
        url: `insurance-classes/${insuranceContractorId}/branch-billing-items/mass-adding`,
        method: 'POST',
        body,
        params: {
          type: typeId,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),
    billingItemsPackagesMassAdding: builder.mutation<void, { insuranceContractorId: string; body: FormData }>({
      query: ({ insuranceContractorId, body }) => ({
        url: `insurance-classes/${insuranceContractorId}/packages/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),
    billingItemsMedicationsMassAdding: builder.mutation<void, { insuranceContractorId: string; body: FormData }>({
      query: ({ insuranceContractorId, body }) => ({
        url: `insurance-classes/${insuranceContractorId}/medications/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    getInsuranceClassBillingItemsActionLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      { insuranceClassId: string; pageName: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClassId, pageName, params }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/action-logs`,
        params,
      }),
      providesTags: [{ type: 'insuranceClassBillingItems' }],
    }),

    getInsuranceClassBillingPackagesItems: builder.query<
      QueryResponseWithPagination<InsuranceExtraBillingItemsContract[]>,
      { insuranceClassId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClassId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-packages`,
        params,
      }),
      providesTags: [{ type: 'insuranceClassBillingItems' }],
    }),

    getInsuranceClassBillingMedicationsItems: builder.query<
      QueryResponseWithPagination<InsuranceBillingPackagesContract[]>,
      { insuranceClassId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClassId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-medications`,
        params,
      }),
      providesTags: [{ type: 'insuranceClassBillingItems' }],
    }),

    classesBillingItemsPackages: builder.mutation<
      void,
      { insuranceClassId: string; body: ClassBillingItemsEditPackages }
    >({
      query: ({ insuranceClassId, body }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-packages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    classesBillingItemsMedications: builder.mutation<
      void,
      { insuranceClassId: string; body: ClassBillingItemsEditMedications }
    >({
      query: ({ insuranceClassId, body }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-medications`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    getInsuranceClassBillingItemsExcel: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      { insuranceClassId: string; pageName: string; params: QueryParams | { type?: string } | undefined }
    >({
      query: ({ insuranceClassId, pageName, params }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/excel`,
        params,
      }),
      providesTags: [{ type: 'insuranceClassBillingItems' }],
    }),

    activateClassBillingItem: builder.mutation<void, { insuranceClassId: string; typeId?: string }>({
      query: ({ insuranceClassId, typeId }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-items/group/activate`,
        method: 'PUT',
        params: {
          type: typeId,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    deactivateClassBillingItem: builder.mutation<void, { insuranceClassId: string; typeId?: string }>({
      query: ({ insuranceClassId, typeId }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-items/group/deactivate`,
        method: 'PUT',
        params: {
          type: typeId,
        },
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    enableIncludeClassBillingItem: builder.mutation<
      void,
      { insuranceClassId: string; branchBillingId?: string; pageName: string }
    >({
      query: ({ insuranceClassId, branchBillingId, pageName }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/${branchBillingId}/include/enable`,
        method: 'PUT',
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),

    disableIncludeClassBillingItem: builder.mutation<
      void,
      { insuranceClassId: string; branchBillingId?: string; pageName: string }
    >({
      query: ({ insuranceClassId, branchBillingId, pageName }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/${branchBillingId}/include/disable`,
        method: 'PUT',
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),
    enablePreAuthorizationClassBillingItem: builder.mutation<
      void,
      { insuranceClassId: string; branchBillingId?: string; pageName: string }
    >({
      query: ({ insuranceClassId, branchBillingId, pageName }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/${branchBillingId}/pre-authorization/enable`,
        method: 'PUT',
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),
    disablePreAuthorizationClassBillingItem: builder.mutation<
      void,
      { insuranceClassId: string; branchBillingId?: string; pageName: string }
    >({
      query: ({ insuranceClassId, branchBillingId, pageName }) => ({
        url: `insurance-classes/${insuranceClassId}/${pageName}/${branchBillingId}/pre-authorization/disable`,
        method: 'PUT',
      }),
      invalidatesTags: ['insuranceClassBillingItems'],
    }),
    getInsuranceClassBillingItemsLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      {
        insuranceClassId: string;
        billingItemId?: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ insuranceClassId, billingItemId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-items/${billingItemId}/action-logs`,
        params,
      }),
      providesTags: ['insuranceClassBillingItemsLogs'],
    }),
    getInsurancePackagesLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      {
        insuranceClassId: string;
        packageId?: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ insuranceClassId, packageId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-packages/${packageId}/action-logs`,
        params,
      }),
      providesTags: ['insuranceInsurancePackagesLogs'],
    }),
    getInsuranceMedicationsLogs: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityActionLog[]>,
      {
        insuranceClassId: string;
        medicationId?: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ insuranceClassId, medicationId, params }) => ({
        url: `insurance-classes/${insuranceClassId}/branch-billing-medications/${medicationId}/action-logs`,
        params,
      }),
      providesTags: ['insuranceMedicationsLogs'],
    }),
  }),
});

export const {
  // Import class configuration
  useImportBillingItemsClassConfigurationMutation,
  useImportPackagesClassConfigurationMutation,
  useImportMedicationsClassConfigurationMutation,
  useGetInsuranceClassBillingItemsQuery,
  useGetClassBillingItemQuery,
  useClassBillingItemsContractorsMassAddingMutation,
  useBillingItemsPackagesMassAddingMutation,
  useBillingItemsMedicationsMassAddingMutation,
  useGetInsuranceClassBillingItemsActionLogsQuery,
  useGetInsuranceClassBillingPackagesItemsQuery,
  useGetInsuranceClassBillingMedicationsItemsQuery,
  useClassesBillingItemsPackagesMutation,
  useClassesBillingItemsMedicationsMutation,
  useLazyGetInsuranceClassBillingItemsExcelQuery,
  useActivateClassBillingItemMutation,
  useDeactivateClassBillingItemMutation,
  useEnableIncludeClassBillingItemMutation,
  useDisableIncludeClassBillingItemMutation,
  useEnablePreAuthorizationClassBillingItemMutation,
  useDisablePreAuthorizationClassBillingItemMutation,
  useGetInsuranceClassBillingItemsLogsQuery,
  useGetInsurancePackagesLogsQuery,
  useGetInsuranceMedicationsLogsQuery,
} = insuranceClassBillingItemsApi;
