import { useCallback, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { IconBell } from '@tabler/icons-react';
import classnames from 'classnames';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useAppIntl, useCurrentUser, useQueryParams } from 'app/helpers';
import styles from 'app/components/header/generalNotifications/generalNotifications.module.scss';
import { useLiveRefresh } from 'app/components/header/generalNotifications/helpers';
import { Notification } from 'app/components/header/generalNotifications/notification/notification';
import {
  useGetNotificationsQuery,
  useMarkAllAsReadMutation,
} from 'app/services/generalNotifications/generalNotifications';

interface Props {
  className?: string;
}

export const GeneralNotifications = ({ className }: Props) => {
  const { formatMessage, isRtl } = useAppIntl();
  const [isDropdownOpen, setDropdown] = useState(false);
  const [markAllAsReadLoader, setMarkAllAsReadLoader] = useState(false);
  const {
    currentUser: { id: userId },
  } = useCurrentUser();
  const [markAllAsRead] = useMarkAllAsReadMutation();
  const { params, setParams } = useQueryParams('generalNotifications');
  const { data, refetch } = useGetNotificationsQuery(userId ? { userId: userId, params } : skipToken, {
    pollingInterval: 15000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: true,
  });
  const notifications = data?.data || [];
  const unread = data?.unread || 0;
  const pagination = data?.pagination;
  const hasMore = useMemo(() => !!pagination && pagination.totalItemCount > pagination.totalItems, [pagination]);

  // Actions
  const toggle = useCallback(() => setDropdown((prevState) => !prevState), []);

  const handleMarkAllAsRead = useCallback(async () => {
    if (userId) {
      setMarkAllAsReadLoader(true);
      try {
        await markAllAsRead(userId).unwrap();
        await refetch();
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
      setMarkAllAsReadLoader(false);
    }
  }, [userId, markAllAsRead, refetch]);

  const next = useCallback(() => {
    const defaultPageSize = 10; // this is not the same as paginationQueryParams
    const currentPageSize = Number(params?.pageSize || defaultPageSize);

    setParams({
      ...params,
      pageSize: String(currentPageSize + defaultPageSize),
    });
  }, [params, setParams]);

  useLiveRefresh(refetch);

  return (
    <Dropdown className={classnames(styles.dropdown, className)} isOpen={isDropdownOpen} toggle={toggle}>
      <DropdownToggle
        className={classnames(styles.toggle, {
          [styles.toggleWithPill]: !!unread,
        })}
        tag="div"
      >
        <IconBell size={24} strokeWidth={1.8} />
        {!!unread && <div className={styles.badge}>{unread >= 100 ? '99' : unread}</div>}
      </DropdownToggle>
      <DropdownMenu className={styles.menu} end={!isRtl} tag="div">
        <div className={styles.header}>
          <h4 className="mb-0">{formatMessage({ id: 'CORE.LINK.NOTIFICATIONS' })}</h4>
          {!markAllAsReadLoader && !!notifications.length && !!unread && (
            <Button
              className={classnames(styles.headerButton, 'btn')}
              color="flat"
              onClick={handleMarkAllAsRead}
              size="sm"
            >
              {formatMessage({ id: 'CORE.BUTTON.MARK-ALL-READ' })}
            </Button>
          )}
          {markAllAsReadLoader && <Spinner size="sm" />}
        </div>
        {!notifications.length && (
          <p className="text-center my-3">{formatMessage({ id: 'CORE.TEXT.NO-NOTIFICATIONS-TO-DISPLAY' })}</p>
        )}
        {!!notifications.length && (
          <SimpleBar
            className={classnames(styles.simpleBar, {
              [styles.emptySimpleBar]: !notifications.length,
            })}
            data-simplebar-direction={isRtl ? 'rtl' : 'ltr'}
            scrollableNodeProps={{ id: 'generalNotificationsScrollNode' }}
          >
            <InfiniteScroll
              dataLength={notifications.length}
              hasMore={hasMore}
              loader={
                <div className={styles.loader}>
                  <Spinner size="sm" />
                </div>
              }
              next={next}
              scrollableTarget="generalNotificationsScrollNode"
            >
              {notifications.map((notification) => (
                <Notification
                  key={notification.generalNotificationId}
                  notification={notification}
                  refreshNotifications={refetch}
                />
              ))}
            </InfiniteScroll>
          </SimpleBar>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
