import { useCallback } from 'react';
import { useAppDispatch } from 'app/helpers';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { PatientSourceStatus, RemoteDataParams } from 'app/types';

interface Props {
  branchIds?: string;
  clinicId?: string;
  practitionerIds?: string;
  specialityIds?: string;
  subSpecialityIds?: string;
  serviceIds?: string;
}

export const useFetchPatientSourceOptions = ({
  branchIds,
  clinicId,
  practitionerIds,
  specialityIds,
  subSpecialityIds,
  serviceIds,
}: Props) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (params: RemoteDataParams) => {
      if (clinicId) {
        return dispatch(
          fetchDropdownOptions(`/clinics/${clinicId}/patient-sources/dropdown`, {
            ...params,
            branchIds,
            practitionerIds,
            specialityIds,
            subSpecialityIds,
            serviceIds,
            statuses: PatientSourceStatus.Active,
          }),
        );
      }
      return [];
    },
    [dispatch, branchIds, clinicId, practitionerIds, specialityIds, subSpecialityIds, serviceIds],
  );
};
