import { useEffect, useMemo } from 'react';
import { useAppIntl } from 'app/helpers';
import { Locale } from 'app/types';
import { createTawkToScript } from 'app/features/tawkToChat/helpers/createTawkToScript';

export const TawkToChat = () => {
  const { locale } = useAppIntl();

  const src = useMemo(
    () => (locale === Locale.ar ? process.env.REACT_APP_TAWK_CHAT_URL_AR : process.env.REACT_APP_TAWK_CHAT_URL_EN),
    [locale],
  );

  useEffect(() => {
    if (src) {
      try {
        createTawkToScript(src);
      } catch (error) {
        console.error('tawk.to script could not be added');
      }
    }
  }, [src]);

  return null;
};
