import { put, takeEvery } from 'redux-saga/effects';
import { fetchClinicSystemOwner } from 'app/redux/clinic/clinic.actions';
import { ADD_CLINIC_SUCCESS, FETCH_CLINIC_SUCCESS } from 'app/redux/clinic/clinic.types';
import { refreshClinicList } from 'app/sagas/clinic/generators/refreshClinicList';

export function* afterAddClinicSuccess(action) {
  yield refreshClinicList(action);
}

export function* afterFetchClinicSuccess(action) {
  if (action.meta !== undefined) {
    const { clinicId } = action.meta;
    yield put(fetchClinicSystemOwner(clinicId));
  }
}

function* clinicSaga() {
  yield takeEvery(ADD_CLINIC_SUCCESS, afterAddClinicSuccess);
  yield takeEvery(FETCH_CLINIC_SUCCESS, afterFetchClinicSuccess);
}

export default clinicSaga;
