import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveDoctorsState } from 'app/services/referrals/types';
import { ReferralsState } from 'app/redux/referrals/types';

const initialState: ReferralsState = {
  warningReferral: null,
};

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setWarningReferral: (state, action: PayloadAction<ActiveDoctorsState>) => {
      state.warningReferral = action.payload;
    },
    resetWarningReferral: (state) => {
      state.warningReferral = null;
    },
  },
});

export const { setWarningReferral, resetWarningReferral } = referralsSlice.actions;
export default referralsSlice.reducer;
