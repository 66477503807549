import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchInvoiceShortInfo } from 'app/redux/invoice/invoice.actions';
import { DownloadDocumentPage } from 'app/features/historicalDocuments/components/downloadDocumentPage/downloadDocumentPage';

export const HistoricalInvoice = () => {
  const dispatch = useAppDispatch();
  const { token } = useCurrentUser();
  const { formatMessage, locale } = useAppIntl();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const loading = useAppSelector((state) => state.invoice.shortInfo.loading);
  const invoiceShortInfo = useAppSelector((state) => state.invoice.shortInfo.invoice);

  const downloadInvoice = useCallback(async () => {
    const billingItemsBased = !!invoiceShortInfo?.billingItemsBased;

    if (token) {
      setDownloading(true);
      await dispatch(
        download({
          token,
          locale,
          endpoint: `invoices/${invoiceId}${billingItemsBased ? '/v2' : ''}/print-view`,
          withOpening: false,
          body: false,
        }),
      );
      setDownloading(false);
    }
  }, [dispatch, invoiceId, invoiceShortInfo, locale, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchInvoiceShortInfo(invoiceId));
    }
  }, [dispatch, invoiceId, token]);

  return (
    <DownloadDocumentPage
      downloading={downloading}
      loading={loading}
      number={invoiceShortInfo?.invoiceNumber}
      onDownload={downloadInvoice}
      title={formatMessage({ id: 'CORE.LABEL.INVOICE-NUMBER', defaultMessage: 'Invoice Number' })}
    />
  );
};
