import React from 'react';
import baseDeepDiff from 'deep-diff';
import { FormattedMessage } from 'react-intl';
import { formattedDate, getTranslation } from 'app/helpers';
import { Avatar } from 'app/components';
import { branchLabels, FORMATS_DATE_AND_TIME, institutionLabels, ownerLabels } from 'app/shared/index';
import { ownerPrefix } from 'app/features/registration/registration.constants';

const fileFields = ['images', 'logo', 'medicalLicenseFile', 'crFile'];
//value and label are irrelevant because they goes along wih translated changes
const fieldsToSkip = ['value', 'label', 'name', 'id'];

const isDifferenceToDisplay = (item, locale = 'en') => {
  if (fieldsToSkip.some((field) => item.path.includes(field))) {
    return false;
  }
  if (item.path.includes('translation') && !item.path.includes(locale)) {
    return false;
  }
  return true;
};

function prepare(data) {
  return {
    ...data,
    owner: data.owner &&
      data.owner.crExpiryDate && {
        ...data.owner,
        crExpiryDate: formattedDate(data.owner.crExpiryDate, FORMATS_DATE_AND_TIME),
      },
    ...(data.medicalLicenseExpiryDate && {
      medicalLicenseExpiryDate: formattedDate(data.medicalLicenseExpiryDate, FORMATS_DATE_AND_TIME),
    }),
    ...(data.crExpiryDate && { crExpiryDate: formattedDate(data.crExpiryDate, FORMATS_DATE_AND_TIME) }),
    location: data.location && `${data.location.lat}/${data.location.lng}`,
    images: (data.images || []).reduce((acc, image) => {
      acc[image.url] = image.url;
      return acc;
    }, {}),
  };
}

export function deepDiff(oldData, newData, locale) {
  const [oldBaseData, newBaseData, removedSpecialities, addedSpecialities] = extractSpecialities(oldData, newData);
  const differences = [
    ...(baseDeepDiff(prepare(oldBaseData), prepare(newBaseData)) || []), //generate without specialities
    ...(baseDeepDiff({ specialities: removedSpecialities }, { specialities: [] }) || []), //generate as removed
    ...(baseDeepDiff({ specialities: [] }, { specialities: addedSpecialities }) || []), //generate as added
  ];
  return differences ? differences.filter((item) => isDifferenceToDisplay(item, locale)) : [];
}

function extractSpecialities(oldData, newData) {
  const old = { ...oldData, specialities: [] };
  const newest = { ...newData, specialities: [] };
  const removedSpecialities = oldData.specialities || [];
  const addedSpecialities = newData.specialities || [];
  return [old, newest, removedSpecialities, addedSpecialities];
}

export function renderDiffItemTitle(difference, isBranchDiff) {
  const isOwnerField = difference.path[0] === ownerPrefix;
  const fieldName = isOwnerField ? difference.path[1] : difference.path[0];
  const label = isBranchDiff
    ? branchLabels[fieldName]
    : isOwnerField
    ? ownerLabels[fieldName]
    : institutionLabels[fieldName];

  return label ? <FormattedMessage id={label} /> : <span>"-"</span>;
}

export function getFileChange(difference, diffState, locale) {
  if (fileFields.includes(difference.path[0]) || difference.path[1] === 'crFile') {
    return renderDiffForImage(difference, diffState, locale);
  }
}

export function isFileField(difference) {
  return fileFields.includes(difference.path[0]) || difference.path[1] === 'crFile';
}

export const renderDiffItem = (difference, diffState, locale = 'en') => {
  if (isFileField(difference)) {
    return getFileChange(difference, diffState, locale);
  }
  if (difference.path[0] === 'location') {
    return renderDiffForLocation(difference, diffState);
  }
  if (
    (typeof difference.path[0] === 'string' && difference.path[0].toLowerCase().includes('phonenumber')) ||
    (difference.path.length > 1 &&
      typeof difference.path[1] === 'string' &&
      difference.path[1].toLowerCase().includes('phonenumber'))
  ) {
    return renderDiffForPhoneNumber(difference, diffState);
  }

  return renderDiffForItem(difference, diffState, locale);
};

function renderDiffForPhoneNumber(difference, diffState) {
  return renderDiffForItem(difference, diffState, 'en');
}

function renderDiffForLocation(difference, diffState) {
  const oldLocation = difference.lhs?.split('/');
  const newLocation = difference.rhs.split('/');
  const isDiffStateBefore = diffState === 'before';

  return (
    <span className="info__item--value">
      {isDiffStateBefore && oldLocation && (
        <a
          href={`https://maps.google.com/?q=${oldLocation[0]},${oldLocation[1]}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="CORE.LABEL.SEE-ON-MAP" />
        </a>
      )}
      {isDiffStateBefore && !oldLocation && '-'}

      {!isDiffStateBefore && newLocation && (
        <a
          href={`https://maps.google.com/?q=${newLocation[0]},${newLocation[1]}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="CORE.LABEL.SEE-ON-MAP" />
        </a>
      )}
      {!isDiffStateBefore && !newLocation && '-'}
    </span>
  );
}

const getUrl = (field) => {
  return field && typeof field === 'object' ? field.url : field;
};

function renderDiffForImage(difference, diffState) {
  const renderLhs = getUrl(difference.lhs);
  const renderRhs = getUrl(difference.rhs);
  const isDiffStateBefore = diffState === 'before';

  switch (difference.kind) {
    case 'E':
      return (
        <span className="info__item--value">
          {isDiffStateBefore && (
            <a href={renderLhs} target="_blank" rel="noopener noreferrer">
              <Avatar size="md" className="rounded-2" imageSrc={renderLhs} />
            </a>
          )}
          {!isDiffStateBefore && (
            <a href={renderRhs} target="_blank" rel="noopener noreferrer">
              <Avatar size="md" className="rounded-2" imageSrc={renderRhs} />
            </a>
          )}
        </span>
      );
    case 'N':
      return (
        <span className="info__item--value">
          {isDiffStateBefore && '-'}
          {!isDiffStateBefore && (
            <a href={renderRhs} target="_blank" rel="noopener noreferrer">
              <Avatar size="md" className="rounded-2" imageSrc={renderRhs} />
            </a>
          )}
        </span>
      );
    case 'D':
      return (
        <span className="info__item--value">
          {isDiffStateBefore && (
            <a href={renderLhs} target="_blank" rel="noopener noreferrer">
              <Avatar size="md" className="rounded-2" imageSrc={renderLhs} />
            </a>
          )}
          {!isDiffStateBefore && '-'}
        </span>
      );
    default:
      return undefined;
  }
}

function renderDiffForItem(difference, diffState, locale) {
  switch (difference.kind) {
    case 'E':
      return renderDiffForEditedItem(difference, diffState, locale);
    case 'A':
      return renderDiffForArrayItem(difference, diffState, locale);
    case 'N':
      return renderDiffForNewItem(difference, diffState, locale);
    case 'D':
      return renderDiffForDeletedItem(difference, diffState, locale);
    default:
      return undefined;
  }
}

function renderValueForBooleanItem(boolean) {
  return boolean ? <FormattedMessage id="CORE.LABEL.YES" /> : <FormattedMessage id="CORE.LABEL.NO" />;
}

function renderDiffForEditedItem(difference, diffState, locale) {
  const isDiffStateBefore = diffState === 'before';
  const isDiffValueBoolean = typeof difference.lhs === 'boolean' || typeof difference.rhs === 'boolean';

  return (
    <>
      {isDiffValueBoolean && (
        <span className="info__item--value">
          {isDiffStateBefore && (
            <span dir={locale === 'ar' ? 'rtl' : 'ltr'}> {renderValueForBooleanItem(difference.lhs)} </span>
          )}
          {!isDiffStateBefore && (
            <span dir={locale === 'ar' ? 'rtl' : 'ltr'}>{renderValueForBooleanItem(difference.rhs)}</span>
          )}
        </span>
      )}
      {!isDiffValueBoolean && (
        <span className="info__item--value">
          {isDiffStateBefore && (
            <span dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.lhs === null ? 'null' : difference.lhs}</span>
          )}
          {!isDiffStateBefore && (
            <span dir={locale === 'ar' ? 'rtl' : 'ltr'}>{difference.rhs === null ? 'null' : difference.rhs}</span>
          )}
        </span>
      )}
    </>
  );
}

function renderDiffForDeletedItem(difference, diffState) {
  const isDiffStateBefore = diffState === 'before';
  const isBoolean = typeof difference.lhs === 'boolean';
  const isObject = typeof difference.lhs === 'object';
  const booleanTextId = !!difference.lhs ? 'CORE.LABEL.YES' : 'CORE.LABEL.NO';

  if (!isDiffStateBefore) return '-';

  if (isBoolean) return <FormattedMessage id={booleanTextId} />;
  if (isObject) return getTranslation(difference.lhs);

  return difference.lhs;
}

function renderDiffForNewItem(difference, diffState, locale) {
  const isDiffStateBefore = diffState === 'before';

  if (isDiffStateBefore) return '-';

  const value = difference.item || difference;
  const changedValue = value.lhs || value.rhs;
  const sign = value.kind === 'D' ? '-' : '+';
  let result = '';

  if (typeof changedValue === 'string') {
    result = changedValue;
  }
  if (typeof changedValue === 'object' && changedValue.translation) {
    result = changedValue.translation[locale];
  }
  return <span className="info__item--value">{`${sign} ${result}`}</span>;
}

function renderDiffForArrayItem(difference, diffState, locale) {
  const isDiffStateBefore = diffState === 'before';
  const diffKind = difference.item.kind;

  if (isDiffStateBefore) {
    if (diffKind === 'D')
      return typeof difference.item.lhs === 'string' ? difference.item.lhs : difference.item.lhs.translation?.[locale];
    if (diffKind === 'N') return '-';
  }

  if (diffKind === 'D') return '-';
  if (diffKind === 'N')
    return typeof difference.item.rhs === 'string' ? difference.item.rhs : difference.item.rhs.translation?.[locale];

  return <FormattedMessage id="MASS-ADDING.LABEL.ERROR" />;
}
