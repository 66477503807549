import { BranchChanges } from 'app/types/Branch';
import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';
import { ClinicRequestStatus, ClinicRequestType } from 'app/features/clinicsRequests/types';

export enum MedicalInstitutionStatus {
  Online = '0',
  Blocked = '2',
  Offline = '3',
  Deleted = '4',
  //Note: this is how backend sends Pending status, maybe we can prepare
  //task in the future for backend
  Pending = 'pendingRegistrationApproval',
}

export type MedicalInstitutionOwner = {
  crExpiryDate: string;
  crFile: RemoteFile;
  crNumber: string;
  email: string;
  nameAr: string;
  nameEn: string;
  officePhoneNumber: string;
  phoneNumber: string;
};

export type MedicalInstitution = {
  allowedForEMR: boolean;
  allowedForInsuranceManagement: boolean;
  approvalDate?: string;
  city: Option;
  clinicType: Option;
  clinicyId: string;
  declaredNumberOfBranches: number;
  descriptionAr: string;
  descriptionEn: string;
  id: string;
  isGrandFatherNameRequiredForPatients: boolean;
  isFatherNameRequiredForPatients: boolean;
  logo: RemoteFile;
  managementSystem: Option;
  manySpecialtiesCount: number;
  mode: Option;
  nameAr: string;
  nameEn: string;
  nphiesEnabled: boolean;
  numberOfClinicUsers: number;
  numberOfDoctors?: number;
  numberOfPatients?: number;
  numberOfRegisteredBranches?: number;
  otherClinicType?: string;
  otherManagementSystem?: string;
  owner: MedicalInstitutionOwner;
  ownerAllowedForTreatmentView: boolean;
  ownerAllowedForOrders: boolean;
  protectPhoneNumber: boolean;
  registrationDate: string;
  requireSaudiIdDuringPatientRegistration: boolean;
  status?: Option<MedicalInstitutionStatus>;
  vatNumber: string;
  hasActiveLoomxIntegration: boolean;
  erpIntegrationReady: boolean;
  balanceIntegrationStartDate: string | null;
};

export interface RejectedMedicalInstitution extends MedicalInstitution {
  rejectedByClinicyId: string;
  rejectedAt: string;
  reason: string;
}

export interface MedicalInstitutionRequest {
  action?: string;
  actionDate?: string;
  actionTakerClinicyId?: string;
  branchChanges?: BranchChanges;
  clinicChanges?: ClinicChanges;
  clinic: Option;
  id: string;
  number: string;
  numberOfEditedFields: number;
  requestCreationTime: string;
  requestMakerClinicyId: string;
  status: Option<ClinicRequestStatus>;
  type: Option<ClinicRequestType>;
  city?: Option;
}

export interface ClinicChanges {
  id: string;
  old: Partial<MedicalInstitution>;
  new: Partial<MedicalInstitution>;
}

export enum MedicalInstitutionType {
  Hospital = '1',
  Clinic = '2',
  MedicalCenter = '3',
  Dispensary = '4',
  MedicalGroup = '5',
  Laboratory = '6',
  PhysicalTherapyCenter = '7',
  Other = '8',
}

export enum MedicalInstitutionMode {
  Operational = '0',
  ViewOnly = '1',
}

export interface MedicalInstitutionOption extends Option {
  details: {
    mode: Option<MedicalInstitutionMode>;
  };
}
