import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  CreatePolicyClassParams,
  EditInsurancePoliciesParams,
  EditPoliciesAttachmentParams,
  EditPolicyClassParams,
  GetInsurancePoliciesParams,
  InsurancePoliciesActionLog,
  InsurancePolicyClassesParams,
  PoliciesExcelReportParams,
  RetrievePoliciesParams,
} from 'app/services/insurancePolicies/types';
import {
  InsuranceContractEntityAttachment,
  InsurancePolicies,
  InsurancePoliciesClasses,
  QueryParams,
  QueryResponseWithPagination,
} from 'app/types';

export const insurancePoliciesApi = createApi({
  reducerPath: 'insurancePoliciesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['InsurancePoliciesAndClasses'],
  endpoints: (builder) => ({
    getInsurancePolicyAttachments: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityAttachment[]>,
      { contractId: string; policyId?: string }
    >({
      query: ({ contractId, policyId }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/${policyId}/attachments`,
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),
    getInsurancePolicyClasses: builder.query<
      QueryResponseWithPagination<InsurancePoliciesClasses[]>,
      InsurancePolicyClassesParams
    >({
      query: ({ policyId, params, removedAndExpired }) => ({
        url: `insurance-policies/${policyId}/insurance-classes`,
        method: 'GET',
        params: {
          ...params,
          removedAndExpired,
        },
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),
    getInsurancePolicyClass: builder.query<
      { data: InsurancePoliciesClasses },
      { classId: string; params?: QueryParams | undefined }
    >({
      query: ({ classId, params }) => ({
        url: `insurance-classes/${classId}`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),

    getInsurancePolicyClassAttachments: builder.query<
      QueryResponseWithPagination<InsuranceContractEntityAttachment[]>,
      { id?: string; policyId?: string }
    >({
      query: ({ id, policyId }) => ({
        url: `insurance-policies/${id}/insurance-classes/${policyId}/attachments`,
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),

    editPolicyAttachments: builder.mutation<
      void,
      { contractId: string; policyId: string; body: EditPoliciesAttachmentParams }
    >({
      query: ({ contractId, policyId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/${policyId}/attachments`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsurancePoliciesAndClasses', contractId }],
    }),

    deactivatePolicyClass: builder.mutation<void, { body: InsurancePoliciesClasses }>({
      query: ({ body }) => ({
        url: `insurance-policies/${body.policyId}/insurance-classes/${body.id}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    retrievePolicy: builder.mutation<void, { contractId: string; policyId?: string; body: RetrievePoliciesParams }>({
      query: ({ contractId, policyId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/${policyId}/retrieve`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    retrievePolicyClass: builder.mutation<void, string>({
      query: (classId) => ({
        url: `insurance-classes/${classId}/retrieve`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    activatePolicyClass: builder.mutation<void, { body: InsurancePoliciesClasses }>({
      query: ({ body }) => ({
        url: `insurance-policies/${body.policyId}/insurance-classes/${body.id}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    deletePolicyClass: builder.mutation<void, { body: InsurancePoliciesClasses }>({
      query: ({ body }) => ({
        url: `insurance-policies/${body.policyId}/insurance-classes/${body.id}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    editPolicyClassAttachments: builder.mutation<
      void,
      { contractId: string; policyId: string; body: EditPoliciesAttachmentParams }
    >({
      query: ({ contractId, policyId, body }) => ({
        url: `insurance-policies/${contractId}/insurance-classes/${policyId}/attachments`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsurancePoliciesAndClasses', contractId }],
    }),

    getInsurancePoliciesActionLogs: builder.query<
      QueryResponseWithPagination<InsurancePoliciesActionLog[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: `insurance-policies/action-logs`,
        params,
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),

    getPoliciesClassesActionLogs: builder.query<
      QueryResponseWithPagination<InsurancePoliciesActionLog[]>,
      { params: QueryParams | undefined; contractId: string | undefined }
    >({
      query: ({ params, contractId }) => ({
        url: `insurance-classes/action-logs`,
        params: {
          ...params,
          contractId,
        },
      }),
      providesTags: ['InsurancePoliciesAndClasses'],
    }),

    addInsurancePolicies: builder.mutation<void, { contractId: string; body: EditInsurancePoliciesParams }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsurancePoliciesAndClasses', contractId }],
    }),

    addInsurancePoliciesExcel: builder.mutation<void, { contractId: string; body: PoliciesExcelReportParams }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/excel`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsurancePoliciesAndClasses', contractId }],
    }),

    getInsurancePolicies: builder.query<QueryResponseWithPagination<InsurancePolicies[]>, GetInsurancePoliciesParams>({
      query: ({ contractId, params, removedAndExpired }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies`,
        params: {
          ...params,
          removedAndExpired,
        },
      }),
      providesTags: [{ type: 'InsurancePoliciesAndClasses' }],
    }),

    createPolicyClass: builder.mutation<void, CreatePolicyClassParams>({
      query: ({ policyId, body }) => ({
        url: `insurance-policies/${policyId}/insurance-classes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    editPolicyClass: builder.mutation<void, EditPolicyClassParams>({
      query: ({ policyId, classId, body }) => ({
        url: `insurance-policies/${policyId}/insurance-classes/${classId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    activatePoliciesContractor: builder.mutation<void, { contractorId: string; policyId: string }>({
      query: ({ contractorId, policyId }) => ({
        url: `insurance-contracts/${contractorId}/insurance-policies/${policyId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { contractorId, policyId }) => [
        { type: 'InsurancePoliciesAndClasses', contractorId, policyId },
      ],
    }),

    deactivatePoliciesContractor: builder.mutation<void, { contractorId: string; policyId: string }>({
      query: ({ contractorId, policyId }) => ({
        url: `insurance-contracts/${contractorId}/insurance-policies/${policyId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { contractorId, policyId }) => [
        { type: 'InsurancePoliciesAndClasses', contractorId, policyId },
      ],
    }),

    removePoliciesContractor: builder.mutation<void, { contractorId: string; policyId: string }>({
      query: ({ contractorId, policyId }) => ({
        url: `insurance-contracts/${contractorId}/insurance-policies/${policyId}/remove`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { contractorId, policyId }) => [
        { type: 'InsurancePoliciesAndClasses', contractorId, policyId },
      ],
    }),

    editInsurancePolicies: builder.mutation<
      void,
      { contractId: string; policyId: string; body: EditInsurancePoliciesParams }
    >({
      query: ({ contractId, policyId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/${policyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { contractId }) => [{ type: 'InsurancePoliciesAndClasses', contractId }],
    }),

    getReportPolicy: builder.query<void, { contractId: string; params: QueryParams | undefined }>({
      query: ({ contractId, params }) => ({
        params,
        url: `insurance-contracts/${contractId}/insurance-policies/excel`,
      }),
    }),

    addReportPolicy: builder.mutation<void, { contractId: string; params: QueryParams | undefined }>({
      query: ({ contractId, params }) => ({
        params,
        url: `insurance-contracts/${contractId}/insurance-policies/excel`,
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),

    importPolicy: builder.mutation<void, { contractId: string; body: FormData }>({
      query: ({ contractId, body }) => ({
        url: `insurance-contracts/${contractId}/insurance-policies/mass-adding`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InsurancePoliciesAndClasses'],
    }),
  }),
});

export const {
  useCreatePolicyClassMutation,
  useEditPolicyClassMutation,
  useGetInsurancePolicyAttachmentsQuery,
  useGetInsurancePolicyClassAttachmentsQuery,
  useEditPolicyAttachmentsMutation,
  useActivatePolicyClassMutation,
  useDeactivatePolicyClassMutation,
  useDeletePolicyClassMutation,
  useEditPolicyClassAttachmentsMutation,
  useRetrievePolicyMutation,
  useRetrievePolicyClassMutation,
  useGetInsurancePoliciesActionLogsQuery,
  useGetPoliciesClassesActionLogsQuery,
  useAddInsurancePoliciesMutation,
  useGetInsurancePoliciesQuery,
  useActivatePoliciesContractorMutation,
  useDeactivatePoliciesContractorMutation,
  useEditInsurancePoliciesMutation,
  useRemovePoliciesContractorMutation,
  useAddInsurancePoliciesExcelMutation,
  useLazyGetReportPolicyQuery,
  useImportPolicyMutation,
  useGetInsurancePolicyClassesQuery,
  useGetInsurancePolicyClassQuery,
} = insurancePoliciesApi;
