import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  BaseExportParams,
  ClaimSupportingReportParams,
  ExportPolicyByNameReportParams,
  InsuranceClaimListExport,
  InsuranceClaimsQueryParams,
  MasterClaimSheetParams,
  UserLogsQueryParams,
} from 'app/services/insurances/types';
import {
  AddCommentBody,
  ApproveSClaimsByAmountBody,
  ApproveSelectedClaimsBody,
  BillingItemType,
  CancelledClaimsSummary,
  ClaimComment,
  ClaimedInsurance,
  ClaimedInsurancesStatement,
  InsuranceClaimDetails,
  InsuranceClaimsActionLog,
  InsuranceClaimSettlementFile,
  InsuranceStatementActionLog,
  MedicalAttachment,
  MoveToSharedBody,
  Order,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RemoteFile,
  SettledClaimsSummary,
  TreatmentPrescription,
  UnclaimedInsurance,
  UnclaimedSummary,
  UserLog,
} from 'app/types';
import { Details } from 'app/features/insuranceApprovals/modals/takeAction/modalBody/details/types';
import {
  BillingData,
  TakeActionForm,
} from 'app/features/insuranceApprovals/modals/takeAction/modalBody/takeActionForm/types';

export const claimsApi = createApi({
  reducerPath: 'claimsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Unclaimed', 'Claimed', 'Settlements', 'Canceled', 'Attachments'],
  endpoints: (builder) => ({
    getUnclaimedInsurances: builder.query<
      QueryResponseWithPagination<UnclaimedInsurance[]>,
      InsuranceClaimsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'insurance-claims/unclaimed',
        params,
      }),
      providesTags: [{ type: 'Unclaimed' }],
    }),
    getUnclaimedInsurancesSummary: builder.query<QueryResponse<UnclaimedSummary>, QueryParams | undefined>({
      query: (params) => ({
        url: '/insurance-claims/unclaimed/summary',
        params,
      }),
      providesTags: [{ type: 'Unclaimed' }],
    }),
    getClaimedInsurances: builder.query<
      QueryResponseWithPagination<ClaimedInsurancesStatement[]>,
      InsuranceClaimsQueryParams | undefined
    >({
      query: (params) => ({
        url: '/insurance-statements/claimed',
        params,
      }),
      providesTags: [{ type: 'Claimed' }],
    }),
    getClaimedInsurancesSummary: builder.query<QueryResponse<UnclaimedSummary>, QueryParams | undefined>({
      query: (params) => ({
        url: '/insurance-claims/claimed/summary',
        params,
      }),
      providesTags: [{ type: 'Claimed' }],
    }),
    getClaimedInsurancesById: builder.query<
      QueryResponseWithPagination<ClaimedInsurance[]>,
      {
        params: QueryParams | undefined;
        statementId: string;
      }
    >({
      query: ({ params, statementId }) => ({
        url: `insurance-statements/${statementId}/claimed`,
        params,
      }),
      providesTags: [{ type: 'Claimed' }],
    }),
    getSettlements: builder.query<
      QueryResponseWithPagination<ClaimedInsurancesStatement[]>,
      InsuranceClaimsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'insurance-statements/settled',
        params,
      }),
      providesTags: [{ type: 'Settlements' }],
    }),
    getSettledInsurancesSummary: builder.query<QueryResponse<SettledClaimsSummary>, QueryParams | undefined>({
      query: (params) => ({
        url: 'insurance-claims/settled/summary',
        params,
      }),
      providesTags: [{ type: 'Settlements' }],
    }),
    getSettlementsById: builder.query<
      QueryResponseWithPagination<UnclaimedInsurance[]>,
      {
        params: QueryParams | undefined;
        settlementId: string;
      }
    >({
      query: ({ params, settlementId }) => ({
        url: `insurance-statements/${settlementId}/settled`,
        params,
      }),
      providesTags: [{ type: 'Settlements' }],
    }),
    getCancelledInsurances: builder.query<
      QueryResponseWithPagination<UnclaimedInsurance[]>,
      InsuranceClaimsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'insurance-claims/cancelled',
        params,
      }),
      providesTags: [{ type: 'Canceled' }],
    }),
    getCancelledInsurancesSummary: builder.query<QueryResponse<CancelledClaimsSummary>, QueryParams | undefined>({
      query: (params) => ({
        url: 'insurance-claims/cancelled/summary',
        params,
      }),
      providesTags: [{ type: 'Canceled' }],
    }),
    getClaimDetailsWithItems: builder.query<QueryResponse<InsuranceClaimDetails>, { insuranceClaimId: string }>({
      query: ({ insuranceClaimId }) => ({
        url: `insurance-claims/${insuranceClaimId}/details-with-items`,
      }),
    }),
    getStatementLogs: builder.query<
      QueryResponseWithPagination<InsuranceStatementActionLog[]>,
      { params: QueryParams | undefined }
    >({
      query: ({ params }) => ({
        url: `/insurance-statements/action-logs`,
        params,
      }),
    }),
    getStatementLogsExport: builder.query<void, { params: QueryParams | undefined }>({
      query: ({ params }) => ({
        url: `insurance-statements/action-logs/excel-export`,
        params,
      }),
    }),
    getClaimsLogsExport: builder.query<void, { params: QueryParams | undefined }>({
      query: ({ params }) => ({
        url: `insurance-claims/action-logs/excel-export`,
        params,
      }),
    }),
    getClaimsLogs: builder.query<
      QueryResponseWithPagination<InsuranceClaimsActionLog[]>,
      { params: QueryParams | undefined; insuranceClaimIds?: string[] | undefined }
    >({
      query: ({ params, insuranceClaimIds }) => ({
        url: `insurance-claims/action-logs`,
        params: {
          ...params,
          insuranceClaimIds,
        },
      }),
    }),
    getUserLogs: builder.query<QueryResponseWithPagination<UserLog[]>, { params: UserLogsQueryParams | undefined }>({
      query: ({ params }) => ({
        url: `insurance-claims/user-logs`,
        params,
      }),
    }),
    getUserLogsExport: builder.query<void, { params: QueryParams | undefined }>({
      query: ({ params }) => ({
        url: `insurance-claims/user-logs/excel`,
        params,
      }),
    }),
    getUcafDcaf: builder.query<QueryResponse<Details>, { insuranceApprovalId: string }>({
      query: ({ insuranceApprovalId }) => ({
        url: `/insurance-claims/insurance-approvals/${insuranceApprovalId}/insurance-approval-response-form`,
        method: 'GET',
      }),
    }),
    getLabReport: builder.query<
      QueryResponseWithPagination<Order[]>,
      { params: QueryParams | undefined; appointmentId: string }
    >({
      query: ({ params, appointmentId }) => ({
        url: `insurance-claims/appointments/${appointmentId}/orders`,
        params: {
          billingItemType: BillingItemType.Lab,
          ...params,
        },
      }),
    }),
    getVisitLabReport: builder.query<
      QueryResponseWithPagination<Order[]>,
      { params: QueryParams | undefined; visitNumber: string }
    >({
      query: ({ params, visitNumber }) => ({
        url: `insurance-claims/visits/${visitNumber}/orders`,
        params: {
          billingItemType: BillingItemType.Lab,
          ...params,
        },
      }),
    }),
    getPrescriptions: builder.query<
      QueryResponseWithPagination<TreatmentPrescription[]>,
      { params: QueryParams | undefined; appointmentId: string }
    >({
      query: ({ params, appointmentId }) => ({
        url: `/insurance-claims/appointments/${appointmentId}/prescriptions`,
        params,
      }),
    }),
    getVisitPrescriptions: builder.query<
      QueryResponseWithPagination<TreatmentPrescription[]>,
      { params: QueryParams | undefined; visitNumber: string }
    >({
      query: ({ params, visitNumber }) => ({
        url: `/insurance-claims/visits/${visitNumber}/prescriptions`,
        params,
      }),
    }),
    getRadiologyReport: builder.query<
      QueryResponseWithPagination<Order[]>,
      { params: QueryParams | undefined; appointmentId: string }
    >({
      query: ({ params, appointmentId }) => ({
        url: `insurance-claims/appointments/${appointmentId}/orders`,
        params: {
          ...params,
          billingItemType: BillingItemType.Radiology,
        },
      }),
    }),
    getVisitRadiologyReport: builder.query<
      QueryResponseWithPagination<Order[]>,
      { params: QueryParams | undefined; visitNumber: string }
    >({
      query: ({ params, visitNumber }) => ({
        url: `insurance-claims/visits/${visitNumber}/orders`,
        params: {
          ...params,
          billingItemType: BillingItemType.Radiology,
        },
      }),
    }),
    getInsuranceApprovalsResponse: builder.query<QueryResponse<TakeActionForm>, { insuranceApprovalId: string }>({
      query: ({ insuranceApprovalId }) => ({
        url: `insurance-claims/insurance-approvals/${insuranceApprovalId}/insurance-approval-response`,
        method: 'GET',
      }),
    }),
    getInsuranceApprovalsItems: builder.query<QueryResponse<BillingData[]>, { insuranceApprovalId: string }>({
      query: ({ insuranceApprovalId }) => ({
        url: `insurance-claims/insurance-approvals/${insuranceApprovalId}/insurance-approval-items`,
        method: 'GET',
      }),
    }),
    getAttachedDocuments: builder.query<
      QueryResponseWithPagination<MedicalAttachment[]>,
      { appointmentId: string; params: QueryParams | undefined }
    >({
      query: ({ appointmentId, params }) => ({
        url: `insurance-claims/appointments/${appointmentId}/attachments`,
        params,
        method: 'GET',
      }),
    }),
    getVisitAttachedDocuments: builder.query<
      QueryResponseWithPagination<MedicalAttachment[]>,
      { visitNumber: string; params: QueryParams | undefined }
    >({
      query: ({ visitNumber, params }) => ({
        url: `insurance-claims/visits/${visitNumber}/attachments`,
        params,
        method: 'GET',
      }),
    }),
    getStatementAttachedDocuments: builder.query<
      QueryResponseWithPagination<MedicalAttachment[]>,
      { insuranceStatementId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceStatementId, params }) => ({
        url: `insurance-statements/${insuranceStatementId}/attachments`,
        params,
        method: 'GET',
        providesTags: ['Attachments'],
      }),
    }),
    getAttachment: builder.query<
      QueryResponse<RemoteFile>,
      {
        insuranceStatementId: string;
        attachmentFileId: string;
      }
    >({
      query: ({ insuranceStatementId, attachmentFileId }) => ({
        url: `/insurance-statements/${insuranceStatementId}/attachments/${attachmentFileId}`,
        method: 'GET',
      }),
    }),
    getMasterClaimSheet: builder.query<void, MasterClaimSheetParams | undefined>({
      query: (params) => ({
        url: 'insurance-claims/master-claim-sheet',
        method: 'GET',
        params,
      }),
    }),
    cancelClaim: builder.mutation<void, MoveToSharedBody>({
      query: (body) => ({
        method: 'POST',
        url: `insurance-claims/cancelled`,
        body,
      }),
    }),
    approveClaim: builder.mutation<void, MoveToSharedBody>({
      query: (body) => ({
        method: 'POST',
        url: `insurance-claims/approve`,
        body,
      }),
    }),
    partiallyApproveClaim: builder.mutation<void, { insuranceClaimId: string; body: MoveToSharedBody }>({
      query: ({ body, insuranceClaimId }) => ({
        method: 'POST',
        url: `insurance-claims/${insuranceClaimId}/partially-approve-by-amount`,
        body,
      }),
    }),
    partiallyApproveSelectedClaims: builder.mutation<
      void,
      { insuranceClaimId: string; body: ApproveSelectedClaimsBody }
    >({
      query: ({ body, insuranceClaimId }) => ({
        method: 'POST',
        url: `insurance-claims/${insuranceClaimId}/partially-approve-by-selected-items`,
        body,
      }),
    }),
    partiallyApproveByAmount: builder.mutation<
      void,
      {
        insuranceClaimId: string;
        body: ApproveSClaimsByAmountBody;
      }
    >({
      query: ({ body, insuranceClaimId }) => ({
        method: 'POST',
        url: `insurance-claims/${insuranceClaimId}/partially-approve-by-amount`,
        body,
      }),
    }),
    rejectClaim: builder.mutation<void, MoveToSharedBody>({
      query: (body) => ({
        method: 'POST',
        url: `insurance-claims/reject`,
        body,
      }),
    }),
    addComment: builder.mutation<void, { insuranceClaimId: string; body: AddCommentBody }>({
      query: ({ insuranceClaimId, body }) => ({
        method: 'POST',
        url: `insurance-claims/${insuranceClaimId}/comments`,
        body,
      }),
    }),
    moveToUnclaimed: builder.mutation<void, MoveToSharedBody>({
      query: (body) => ({
        method: 'POST',
        url: `insurance-claims/unclaimed`,
        body,
      }),
    }),
    moveToClaimed: builder.mutation<void, MoveToSharedBody>({
      query: (body) => ({
        method: 'POST',
        url: `insurance-claims/claimed`,
        body,
      }),
    }),
    uploadSettlement: builder.mutation<void, { contractorId: string; body: FormData }>({
      query: ({ contractorId, body }) => ({
        method: 'POST',
        url: `insurance-contractors/${contractorId}/settlement-files`,
        body: body,
      }),
    }),
    settleByAmount: builder.mutation<void, { insuranceStatementId: string; body: { amount: string } }>({
      query: ({ insuranceStatementId, body }) => ({
        method: 'POST',
        url: `insurance-statements/${insuranceStatementId}/approve-by-amount`,
        body,
      }),
    }),
    getReport: builder.query<void, InsuranceClaimListExport | undefined>({
      query: (params) => ({
        params,
        url: 'insurance-claims/base-excel-export',
      }),
    }),
    getComments: builder.query<
      QueryResponseWithPagination<ClaimComment[]>,
      { insuranceClaimId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceClaimId, params }) => ({
        url: `insurance-claims/${insuranceClaimId}/comments`,
        method: 'GET',
        params,
      }),
    }),
    getSupportingClaim: builder.query<void, ClaimSupportingReportParams | undefined>({
      query: (params) => ({
        url: `insurance-claims/claim-supporting-report`,
        method: 'GET',
        params,
      }),
    }),
    getInsuranceClaimPolicyGssReport: builder.query<void, ExportPolicyByNameReportParams | undefined>({
      query: (params) => ({
        url: `insurance-claims/policy-gss-excel-export`,
        method: 'GET',
        params,
      }),
    }),
    getSettlementFile: builder.query<
      QueryResponse<RemoteFile>,
      { insuranceContractorId: string; settlementFileId: string }
    >({
      query: ({ insuranceContractorId, settlementFileId }) => ({
        url: `insurance-contractors/${insuranceContractorId}/settlement-files/${settlementFileId}`,
        method: 'GET',
      }),
    }),
    getSettlement: builder.query<
      QueryResponseWithPagination<InsuranceClaimSettlementFile[]>,
      { insuranceContractorId: string; params: QueryParams | undefined }
    >({
      query: ({ insuranceContractorId, params }) => ({
        method: 'GET',
        url: `insurance-contractors/${insuranceContractorId}/settlement-files`,
        params,
      }),
    }),
    exportSettlementFile: builder.query<
      void,
      { insuranceClaimStatementId: string; params: BaseExportParams | undefined }
    >({
      query: ({ insuranceClaimStatementId, params }) => ({
        url: `insurance-statements/${insuranceClaimStatementId}/settlement-excel-report`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetUnclaimedInsurancesQuery,
  useGetUnclaimedInsurancesSummaryQuery,
  useGetClaimedInsurancesQuery,
  useGetClaimedInsurancesSummaryQuery,
  useGetClaimedInsurancesByIdQuery,
  useGetSettledInsurancesSummaryQuery,
  useGetSettlementsByIdQuery,
  useGetSettlementsQuery,
  useGetClaimDetailsWithItemsQuery,
  useGetUserLogsQuery,
  useGetCancelledInsurancesSummaryQuery,
  useGetCancelledInsurancesQuery,
  useGetUcafDcafQuery,
  useGetLabReportQuery,
  useGetVisitLabReportQuery,
  useGetInsuranceApprovalsResponseQuery,
  useGetInsuranceApprovalsItemsQuery,
  useGetRadiologyReportQuery,
  useGetVisitRadiologyReportQuery,
  useGetAttachedDocumentsQuery,
  useGetVisitAttachedDocumentsQuery,
  useLazyGetMasterClaimSheetQuery,
  useCancelClaimMutation,
  useUploadSettlementMutation,
  useGetStatementAttachedDocumentsQuery,
  useSettleByAmountMutation,
  useApproveClaimMutation,
  useRejectClaimMutation,
  useMoveToUnclaimedMutation,
  useMoveToClaimedMutation,
  usePartiallyApproveByAmountMutation,
  usePartiallyApproveSelectedClaimsMutation,
  useLazyGetReportQuery,
  useGetStatementLogsQuery,
  useLazyGetStatementLogsExportQuery,
  useLazyGetClaimsLogsExportQuery,
  useLazyGetUserLogsExportQuery,
  useGetClaimsLogsQuery,
  useGetPrescriptionsQuery,
  useGetVisitPrescriptionsQuery,
  useLazyGetAttachmentQuery,
  useLazyGetSupportingClaimQuery,
  useLazyGetInsuranceClaimPolicyGssReportQuery,
  useGetCommentsQuery,
  useLazyGetSettlementFileQuery,
  useGetSettlementQuery,
  useAddCommentMutation,
  useLazyExportSettlementFileQuery,
} = claimsApi;
