import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { change } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { useBranchERPSettings } from 'app/helpers/useBranchERPSettings/useBranchERPSettings';
import { useScrollTo } from 'app/helpers/useScrollTo/useScrollTo';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import {
  FORMS_INVOICING_PROCESS_ADD_ITEM,
  FORMS_INVOICING_PROCESS_EDIT_ITEM,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { useLazyGetReservationQuery } from 'app/services/storages/storages';
import { deleteInvoiceItem, selectItem, setReservationId } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoiceEligibility, InvoiceItem, InvoicePayBy } from 'app/types';
import { useStorageReservation } from 'app/features/inventoryStorage/helpers/useStorageReservation';
import { ZeroInventoryWarningModal } from 'app/features/inventoryStorage/modals/zeroInventoryWarning/zeroInventoryWarningModal';
import { checkIsInsuranceApprovalRequestBased } from 'app/features/invoicingProcess/helpers/checkIsInsuranceApprovalRequestBased';
import { EditFormWrapper } from 'app/features/invoicingProcess/process/components/editFormWrapper/editFormWrapper';
import { FreeFollowUpBadge } from 'app/features/invoicingProcess/process/components/freeFollowUpBadge/freeFollowUpBadge';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { AddItemForm } from 'app/features/invoicingProcess/process/items/addItemForm/addItemForm';
import { DeclarationForm } from 'app/features/invoicingProcess/process/items/declarationForm/declarationForm';
import { EditItemForm } from 'app/features/invoicingProcess/process/items/editItemForm/editItemForm';
import {
  useColumns,
  useExtendedItems,
  useExtendedItemsSnapshots,
} from 'app/features/invoicingProcess/process/items/helpers';
import { formatInvoicingProcessStorageReservationItems } from 'app/features/invoicingProcess/process/items/helpers/formatInvoicingProcessStorageReservationItems';
import { ItemField } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { FormMode } from 'app/features/invoicingProcess/process/items/types';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

const getAccordionId = (index: string | number) => `aid-${index}`;

export const Items = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [mode, setMode] = useState<FormMode>(FormMode.Add);
  const [headerRef, scrollToHeader] = useScrollTo<HTMLHeadingElement>();
  const { isCreated, invoicePackageSnapshotId, entityType, entityId, reservationId, isCreateCreditNoteMode } =
    useInvoicingProcessState();
  const [accordionId, setAccordionId] = useState<string>(getAccordionId(0));
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const selectedItem = useAppSelector((state) => state.invoicingProcess.selectedItem);
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.items);
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;
  const isInsuranceApprovalRequestBased = checkIsInsuranceApprovalRequestBased(invoicingProcess);
  const isReferralInOrPreviousApproval =
    isPayByInsurance &&
    (eligibility?.value === InvoiceEligibility.ReferralIn ||
      eligibility?.value === InvoiceEligibility.PreviousApproval);
  const isItemLimitReached =
    isPayByInsurance && eligibility?.value === InvoiceEligibility.Eligible && invoicingProcessItems.length > 0; // max 1 item for Eligible type
  const hasAddForm =
    mode === FormMode.Add &&
    !isCreated &&
    !isInsuranceApprovalRequestBased &&
    !isReferralInOrPreviousApproval &&
    !isItemLimitReached &&
    !invoicePackageSnapshotId;
  const hasEditForm = mode === FormMode.Edit && !isInsuranceApprovalRequestBased;
  const defaultInvoicingProcessItems = invoicingProcess?.defaultItems;
  const defaultAccordionId = getAccordionId('default');

  const { hasInventoryReservationEnabled } = useBranchERPSettings({ branchId });

  const [triggerGetReservation] = useLazyGetReservationQuery();

  useEffect(() => {
    const initReservation = async () => {
      if (!hasInventoryReservationEnabled || !entityId || reservationId || isCreateCreditNoteMode || isPayByInsurance) {
        return;
      }

      try {
        const reservation = await triggerGetReservation({ entityId, entityType }).unwrap();
        if (reservation.length > 0) {
          dispatch(setReservationId(reservation[0].reservationId));
        }
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
    };

    initReservation();
  }, [
    entityId,
    entityType,
    triggerGetReservation,
    dispatch,
    isCreateCreditNoteMode,
    isPayByInsurance,
    reservationId,
    hasInventoryReservationEnabled,
  ]);

  const { reserveStorage } = useStorageReservation({
    entityType,
    entityId,
    reservationId,
    setReservationId,
    branchId: invoicingProcess?.clinicBranch.value,
  });

  const onEditItemSubmitSuccess = useCallback(() => {
    setMode(FormMode.Add);
  }, []);

  const editItem = useCallback(
    (item: InvoiceItem) => {
      dispatch(selectItem(item));
      setMode(FormMode.Edit);
      scrollToHeader(true);
    },
    [dispatch, scrollToHeader],
  );

  const deleteItem = useCallback(
    (item: InvoiceItem) => {
      dispatch(selectItem(item));
      setDeleteConfirmationModal(true);
    },
    [dispatch],
  );

  const onConfirmDelete = useCallback(async () => {
    if (selectedItem) {
      setMode(FormMode.Add);
      dispatch(deleteInvoiceItem(selectedItem.id));

      const filteredInvoicingProcessItems = invoicingProcessItems?.filter((i) => i.id !== selectedItem.id) ?? [];
      const formattedItems = formatInvoicingProcessStorageReservationItems(filteredInvoicingProcessItems);
      const res = await reserveStorage(formattedItems, !formattedItems.length);
      setDeleteConfirmationModal(false);
      if (res && !res?.success) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
    }
  }, [dispatch, selectedItem, invoicingProcessItems, reserveStorage]);

  const toggleAccordionId = useCallback(
    (id: string) => {
      if (accordionId === id) {
        setAccordionId('');
      } else {
        setAccordionId(id);
      }
    },
    [accordionId],
  );

  const items = useExtendedItems(invoicingProcessItems);
  const defaultItems = useExtendedItems(defaultInvoicingProcessItems || []);
  const extendedItemsSnapshots = useExtendedItemsSnapshots();
  const columns = useColumns(editItem, deleteItem);

  const onCloseZeroInventoryWarningModal = useCallback(() => {
    dispatch(
      change(
        mode === FormMode.Edit ? FORMS_INVOICING_PROCESS_EDIT_ITEM : FORMS_INVOICING_PROCESS_ADD_ITEM,
        ItemField.item,
        undefined,
      ),
    );
  }, [dispatch, mode]);

  return (
    <>
      <SectionHeading
        title={formatMessage({ id: 'CORE.LABEL.INVOICE-ITEMS', defaultMessage: 'Invoice Items' })}
        ref={headerRef}
      />

      {hasAddForm && <AddItemForm />}
      {hasEditForm && (
        <EditFormWrapper onCancel={() => setMode(FormMode.Add)}>
          <EditItemForm onSubmitSuccess={onEditItemSubmitSuccess} />
        </EditFormWrapper>
      )}

      <FreeFollowUpBadge />

      {!!items.length && !extendedItemsSnapshots.length && (
        <>
          <LocalBootstrapTable
            classes="table-sm"
            columns={columns(items, !isInsuranceApprovalRequestBased)}
            data={items}
            id="items"
            keyField="id"
            loading={false}
            noDataComponent={<></>}
            wrapperClasses="my-2"
            showRowIdFooter
          />
        </>
      )}

      {!!items.length && !!extendedItemsSnapshots.length && (
        <>
          {/*// @ts-ignore - Reactstrap issue */}
          <Accordion flush open={accordionId} toggle={toggleAccordionId}>
            {extendedItemsSnapshots.map((snapshot, index) => {
              const id = getAccordionId(index);
              const data = index === 0 ? items : snapshot.items;

              return (
                <AccordionItem key={snapshot.creditNoteId}>
                  <AccordionHeader targetId={id}>
                    {formatMessage({ id: 'CORE.TEXT.CREDIT-NOTE', defaultMessage: 'Credit Note' })}
                    {': '}
                    {snapshot.creditNoteNumber}
                  </AccordionHeader>
                  <AccordionBody accordionId={id}>
                    <LocalBootstrapTable
                      classes="table-sm"
                      columns={columns(data, index === 0)} // display actions only for the latest item
                      data={data}
                      id={`items-${snapshot.creditNoteNumber}`}
                      keyField="id"
                      loading={false}
                      noDataComponent={<></>}
                      showRowIdFooter
                    />
                  </AccordionBody>
                </AccordionItem>
              );
            })}
            <AccordionItem>
              <AccordionHeader targetId={defaultAccordionId}>
                {formatMessage({ id: 'CORE.LABEL.DEFAULT-ITEMS', defaultMessage: 'Default Items' })}
              </AccordionHeader>
              <AccordionBody accordionId={defaultAccordionId}>
                <LocalBootstrapTable
                  classes="table-sm"
                  columns={columns(defaultItems, false)}
                  data={defaultItems}
                  id="items-default"
                  keyField="id"
                  loading={false}
                  noDataComponent={<></>}
                  showRowIdFooter
                />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </>
      )}
      <DeclarationForm />
      <ConfirmationModal
        isOpen={deleteConfirmationModal}
        onClose={() => setDeleteConfirmationModal(false)}
        onConfirm={onConfirmDelete}
        description={formatMessage({
          id: 'CORE.TEXT.DELETE-ITEM-CONFIRMATION',
          defaultMessage: 'Are you sure you want to delete this item?',
        })}
      />
      <ZeroInventoryWarningModal branchId={branchId} onClose={onCloseZeroInventoryWarningModal} />
    </>
  );
};
