import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { submit } from 'redux-form';
import { useAppDispatch, useAppSelector, useCurrentUser, useModalCleaner } from 'app/helpers';
import { FORMS_EDIT_APPOINTMENT } from 'app/shared';
import {
  editWaitingAppointment,
  resetAppointment,
  resetDetails,
  resetPatient,
  setPatient,
} from 'app/redux/appointment/appointment.actions';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { WaitingAppointmentForm } from 'app/features/appointment/form';
import {
  getAppointmentPatientFormData,
  onWaitingFormChange,
  useAppointmentFormStatus,
  useCreatePatientHandlers,
  waitingAppointmentFormValuesAdapter,
} from 'app/features/appointment/form/helpers';
import { WaitingAppointmentFormData } from 'app/features/appointment/form/types';
import { CreatePatientModals } from 'app/features/appointment/shared';

export const EditWaitingAppointmentModal = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  const { formatMessage } = useIntl();
  const {
    currentUser: { allowedForEditAppointments },
  } = useCurrentUser();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.editWaitingAppointment.isOpen);
  const appointment = useAppSelector((state) => state.appointment.data);
  const submitting = !!useAppSelector((state) => state.form[FORMS_EDIT_APPOINTMENT]?.submitting);
  const { isFormDisabled } = useAppointmentFormStatus(appointment?.start);

  // Values
  const clinicId = appointment?.clinic.value;
  const initialValues = useMemo((): Partial<WaitingAppointmentFormData> | undefined => {
    if (appointment) {
      return {
        branchId: appointment.branch.value,
        date: moment(appointment.start).toDate(),
        doctorId: appointment.doctorId,
        doctorName: appointment.doctorName,
        note: appointment.note,
        preferredTimeRange: appointment.end
          ? {
              from: moment(appointment.start).toDate(),
              to: moment(appointment.end).toDate(),
            }
          : undefined,
        product: appointment.product,
        speciality: appointment.speciality,
        subSpeciality: appointment.subSpeciality,
        ...getAppointmentPatientFormData(appointment),
      };
    }

    return undefined;
  }, [appointment]);

  // Actions
  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: FORMS_EDIT_APPOINTMENT,
      setPatient,
      resetPatient,
    });
  const toggle = useCallback(() => dispatch(toggleModal('editWaitingAppointment')), [dispatch]);
  const submitForm = useCallback(() => dispatch(submit(FORMS_EDIT_APPOINTMENT)), [dispatch]);
  const onSubmit = useCallback(
    async (values: WaitingAppointmentFormData) => {
      if (appointment) {
        return await dispatch(editWaitingAppointment(appointment.id, waitingAppointmentFormValuesAdapter(values)));
      }
    },
    [appointment, dispatch],
  );
  const onClosed = useCallback(() => {
    dispatch(resetAppointment());
    dispatch(resetDetails());
  }, [dispatch]);

  useModalCleaner('editWaitingAppointment', modalRef);

  return (
    <Modal
      ref={modalRef}
      backdrop="static"
      keyboard={false}
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'APPOINTMENTS.TEXT.EDIT-WAITING-APPOINTMENT' })}</ModalHeader>
      <ModalBody>
        {appointment && clinicId && (
          <WaitingAppointmentForm
            form={FORMS_EDIT_APPOINTMENT}
            initialValues={initialValues}
            clinicId={clinicId}
            createdPatientMode={!!createdPatient}
            isEdit
            isFormDisabled={isFormDisabled || !allowedForEditAppointments}
            onAddNewPatient={onAddNewPatient}
            onChange={onWaitingFormChange}
            onSubmit={onSubmit}
            resetMode={onResetCreated}
            ageRestrictions={{
              acceptablePatientAgeFrom: appointment.acceptablePatientAgeFrom,
              acceptablePatientAgeTo: appointment.acceptablePatientAgeTo,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
        <Button
          color="primary-gradient"
          disabled={isFormDisabled || submitting || !allowedForEditAppointments}
          onClick={submitForm}
        >
          {formatMessage({ id: 'CORE.BUTTON.SAVE-CHANGES' })}
        </Button>
      </ModalFooter>

      {/* Modals */}
      <CreatePatientModals
        initialPhoneNumber={initialPhoneNumber}
        clinicId={clinicId}
        setPatientData={setPatientData}
      />
    </Modal>
  );
};
