import { createApi } from '@reduxjs/toolkit/query/react';
import type { Appointment, QueryParams, QueryResponseWithPagination } from 'app/types';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AppointmentQueryMode,
  AppointmentsQueryParams,
  BulkActionParams,
  ProcessParams,
  TodayAppointmentsQueryParams,
  WaitingAppointmentsQueryParams,
} from 'app/services/appointments/types';
import { AppointmentModificationSource, PatientScope } from 'app/types';

export const appointmentsApi = createApi({
  reducerPath: 'appointmentsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Appointments', 'ConflictingAppointments'],
  endpoints: (builder) => ({
    // General Appointments
    getTodayAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      TodayAppointmentsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'appointments/current',
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getUpcomingAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      AppointmentsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'appointments/upcoming',
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastAppointments: builder.query<QueryResponseWithPagination<Appointment[]>, AppointmentsQueryParams | undefined>(
      {
        query: (params) => ({
          url: 'appointments/passed',
          params,
        }),
        providesTags: ['Appointments'],
      },
    ),
    getWaitingAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      WaitingAppointmentsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'waiting-appointments/upcoming',
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastWaitingAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      WaitingAppointmentsQueryParams | undefined
    >({
      query: (params) => ({
        url: 'waiting-appointments/passed',
        params,
      }),
      providesTags: ['Appointments'],
    }),

    // Branch Appointments
    getTodayBranchAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; params: TodayAppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: 'appointments/current',
        params: {
          ...params,
          branchesIds: [branchId],
        },
      }),
      providesTags: ['Appointments'],
    }),
    getUpcomingBranchAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `appointments/branch/${branchId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastBranchAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `appointments/branch/${branchId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getWaitingBranchAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `waiting-appointments/branch/${branchId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastWaitingBranchAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `waiting-appointments/branch/${branchId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),

    // Doctor Appointments
    getTodayDoctorAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { doctorId: string; params: TodayAppointmentsQueryParams | undefined }
    >({
      query: ({ doctorId, params }) => ({
        url: `appointments/doctor/${doctorId}/current`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getUpcomingDoctorAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { doctorId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ doctorId, params }) => ({
        url: `appointments/doctor/${doctorId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastDoctorAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { doctorId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ doctorId, params }) => ({
        url: `appointments/doctor/${doctorId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getWaitingDoctorAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { doctorId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ doctorId, params }) => ({
        url: `waiting-appointments/doctor/${doctorId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastWaitingDoctorAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { doctorId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ doctorId, params }) => ({
        url: `waiting-appointments/doctor/${doctorId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),

    // Patient Appointments
    getTodayPatientAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { patientId: string; params: TodayAppointmentsQueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `appointments/patient/${patientId}/current`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getUpcomingPatientAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { patientId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `appointments/patient/${patientId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastPatientAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { patientId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `appointments/patient/${patientId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getWaitingPatientAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { patientId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `waiting-appointments/patient/${patientId}/upcoming`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPastWaitingPatientAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { patientId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `waiting-appointments/patient/${patientId}/passed`,
        params,
      }),
      providesTags: ['Appointments'],
    }),
    getPatientStatisticsAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      {
        patientId: string;
        scope: PatientScope;
        mode: AppointmentQueryMode;
        source: AppointmentModificationSource | undefined;
        params: QueryParams | undefined;
      }
    >({
      query: ({ patientId, scope, mode, source, params }) => ({
        url: `patients/${patientId}/booking-statistic/appointments`,
        params: {
          ...params,
          appointmentSource: source,
          patientSearchingScope: scope,
          queryMode: mode,
        },
      }),
      providesTags: ['Appointments'],
    }),

    // Conflicting Appointments
    getUpcomingConflictingAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; doctorId: string; params: AppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, doctorId, params }) => ({
        url: 'conflicting-appointments',
        params: {
          ...params,
          branchId,
          doctorId,
        },
      }),
      providesTags: ['Appointments', 'ConflictingAppointments'],
    }),
    getConflictingWaitingAppointments: builder.query<
      QueryResponseWithPagination<Appointment[]>,
      { branchId: string; doctorId: string; params: WaitingAppointmentsQueryParams | undefined }
    >({
      query: ({ branchId, doctorId, params }) => ({
        url: 'conflicting-waiting-appointments',
        params: {
          ...params,
          branchId,
          doctorId,
        },
      }),
      providesTags: ['Appointments', 'ConflictingAppointments'],
    }),
    bulkAction: builder.mutation<void, BulkActionParams>({
      query: (params) => ({
        url: params.isWaiting ? 'conflicting-waiting-appointments/cancel-all' : 'conflicting-appointments/bulk-action',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['ConflictingAppointments'],
    }),
    rebookConflictingAppointment: builder.mutation<void, string>({
      query: (appointmentId) => ({
        url: `/conflicting-appointments/${appointmentId}/rebook`,
        method: 'POST',
      }),
      invalidatesTags: ['ConflictingAppointments'],
    }),

    // Export Appointments
    exportAppointments: builder.mutation<void, AppointmentsQueryParams>({
      query: (body) => {
        return {
          url: 'appointments/excel',
          method: 'POST',
          body,
        };
      },
    }),
    // General Actions
    verifyAndCheckIn: builder.mutation<void, ProcessParams>({
      query: ({ body, clinicPatientId, clinicId, appointmentId }) => ({
        url: `clinics/${clinicId}/clinic-patients/${clinicPatientId}/appointments/${appointmentId}/check-in`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Appointments'],
    }),
  }),
});

export const {
  useBulkActionMutation,
  useExportAppointmentsMutation,
  useGetConflictingWaitingAppointmentsQuery,
  useGetPastAppointmentsQuery,
  useGetPastBranchAppointmentsQuery,
  useGetPastDoctorAppointmentsQuery,
  useGetPastPatientAppointmentsQuery,
  useGetPastWaitingAppointmentsQuery,
  useGetPastWaitingBranchAppointmentsQuery,
  useGetPastWaitingDoctorAppointmentsQuery,
  useGetPastWaitingPatientAppointmentsQuery,
  useGetPatientStatisticsAppointmentsQuery,
  useGetTodayAppointmentsQuery,
  useGetTodayBranchAppointmentsQuery,
  useGetTodayDoctorAppointmentsQuery,
  useGetTodayPatientAppointmentsQuery,
  useGetUpcomingAppointmentsQuery,
  useGetUpcomingBranchAppointmentsQuery,
  useGetUpcomingConflictingAppointmentsQuery,
  useGetUpcomingDoctorAppointmentsQuery,
  useGetUpcomingPatientAppointmentsQuery,
  useGetWaitingAppointmentsQuery,
  useGetWaitingBranchAppointmentsQuery,
  useGetWaitingDoctorAppointmentsQuery,
  useGetWaitingPatientAppointmentsQuery,
  useRebookConflictingAppointmentMutation,
  useVerifyAndCheckInMutation,
} = appointmentsApi;
