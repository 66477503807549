import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loader, OrbitControls, useContextBridge } from '@react-three/drei';
import { Canvas, RootState } from '@react-three/fiber';
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events';
import gsap from 'gsap';
import delay from 'lodash/delay';
import { IntlContext } from 'react-intl';
import { OrbitControls as Controls } from 'three-stdlib/controls/OrbitControls';
import { generateTemporaryId, useAppIntl } from 'app/helpers';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { Actions } from 'app/components/locationSelector/components/actions/actions';
import { Toolbar } from 'app/components/locationSelector/components/toolbar/toolbar';
import { FemaleBodyModel } from 'app/components/locationSelector/procedureLocation/models/femaleBodyModel/femaleBodyModel';
import { MaleBodyModel } from 'app/components/locationSelector/procedureLocation/models/maleBodyModel/maleBodyModel';
import { PermanentTeethModel } from 'app/components/locationSelector/procedureLocation/models/permanentTeethModel/permanentTeethModel';
import { PrimaryTeethModel } from 'app/components/locationSelector/procedureLocation/models/primaryTeethModel/primaryTeethModel';
import {
  hasDefaultHelp,
  HelpModal,
} from 'app/components/locationSelector/procedureLocation/selector/helpModal/helpModal';
import { Points } from 'app/components/locationSelector/procedureLocation/selector/points/points';
import {
  Note,
  NoteStatus,
  Step,
  Template,
  TemplateConfiguration,
  TemplateNote,
} from 'app/components/locationSelector/procedureLocation/types';

const animationDuration = 0.8;

interface Props {
  close: () => void;
  isReadOnly: boolean;
  notes: Note[];
  preselectedNote?: Note;
  save: () => void;
  setNotes: Dispatch<SetStateAction<Note[]>>;
  setStep: Dispatch<SetStateAction<Step | null>>;
  template: Template;
  templateConfiguration: TemplateConfiguration;
  withStatus: boolean;
}

export const Selector = ({
  close,
  notes,
  preselectedNote,
  save,
  setNotes,
  setStep,
  template,
  templateConfiguration,
  withStatus,
  isReadOnly,
}: Props) => {
  const { formatMessage } = useAppIntl();
  const [helpModal, setHelpModal] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const cameraRef = useRef<RootState['camera'] | null>(null);
  const controlsRef = useRef<Controls>(null);
  const ContextBridge = useContextBridge(IntlContext);

  const templateNotes = useMemo<TemplateNote[]>(() => {
    const templateNotes: TemplateNote[] = notes.map((note, index) => ({ ...note, number: index + 1 }));
    return templateNotes.filter((note) => note.template === template);
  }, [notes, template]);

  const back = useCallback(() => {
    setStep(Step.SelectTemplate);
  }, [setStep]);

  const moveCameraToNote = useCallback((note: Note) => {
    if (cameraRef.current && controlsRef.current) {
      gsap.to(cameraRef.current.position, {
        duration: animationDuration,
        x: note.cameraPosition.x,
        y: note.cameraPosition.y,
        z: note.cameraPosition.z,
      });

      gsap.to(controlsRef.current.target, {
        duration: animationDuration,
        x: note.lookAt.x,
        y: note.lookAt.y,
        z: note.lookAt.z,
      });
    }
  }, []);

  const selectNote = useCallback<(id: string) => void>(
    (id) => {
      const note = notes.find((note) => note.id === id);
      if (note) {
        setSelectedNote(id);
        moveCameraToNote(note);
      }
    },
    [moveCameraToNote, notes],
  );

  const deleteNote = useCallback<() => void>(() => {
    setDeleteConfirmation(true);
  }, []);

  const onConfirmDelete = useCallback(() => {
    if (selectedNote) {
      setDeleteConfirmation(false);
      setNotes((prevState) => prevState.filter((note) => note.id !== selectedNote));
    }
  }, [selectedNote, setNotes]);

  const updateNote = useCallback<(note: Note) => void>(
    (note) => {
      setNotes((prevNotes) => {
        return prevNotes.map((prevNote) => {
          if (prevNote.id === note.id) {
            return note;
          }
          return prevNote;
        });
      });
    },
    [setNotes],
  );

  const closeNote = useCallback(() => {
    setSelectedNote(null);
  }, []);

  const resetCamera = useCallback(() => {
    if (cameraRef.current && controlsRef.current) {
      setSelectedNote(null);

      gsap.to(cameraRef.current.position, {
        duration: animationDuration,
        x: 0,
        y: 0,
        z: templateConfiguration.maxDistance,
      });

      gsap.to(controlsRef.current.target, {
        duration: animationDuration,
        x: 0,
        y: 0,
        z: 0,
      });
    }
  }, [templateConfiguration.maxDistance]);

  const zoom = useCallback((value: number) => {
    if (cameraRef.current) {
      gsap.to(cameraRef.current.position, {
        duration: 0.5,
        x: cameraRef.current.position.x * value,
        y: cameraRef.current.position.y * value,
        z: cameraRef.current.position.z * value,
      });
    }
  }, []);

  const moveVertically = useCallback((value: number) => {
    if (controlsRef.current) {
      gsap.to(controlsRef.current.target, {
        duration: 0.5,
        y: controlsRef.current.target.y + value,
      });
    }
  }, []);

  const onDoubleClick = useCallback<(event: ThreeEvent<MouseEvent>) => void>(
    (event) => {
      event.stopPropagation();

      if (isReadOnly) {
        return;
      }

      if (cameraRef.current) {
        const note: Note = {
          id: generateTemporaryId(),
          cameraPosition: {
            x: cameraRef.current.position.x,
            y: cameraRef.current.position.y,
            z: cameraRef.current.position.z,
          },
          element: event.object.name,
          lookAt: {
            x: event.point.x,
            y: event.point.y,
            z: event.point.z,
          },
          status: NoteStatus.Open,
          template,
          updateDate: new Date().toISOString(),
        };

        setNotes((prevState) => [...prevState, note]);
        setSelectedNote(note.id);
        moveCameraToNote(note);
      }
    },
    [isReadOnly, moveCameraToNote, setNotes, template],
  );

  const onCreated = useCallback<(state: RootState) => void>(
    (state) => {
      cameraRef.current = state.camera;

      if (preselectedNote && preselectedNote.template === template) {
        delay(() => selectNote(preselectedNote.id), 500); // delay is not required, but it improves visual effect
      }
    },
    [preselectedNote, selectNote, template],
  );

  useEffect(() => {
    if (hasDefaultHelp()) {
      setHelpModal(true);
    }
  }, []);

  return (
    <div className="h-100">
      <Canvas
        camera={{ position: [0, 0, templateConfiguration.maxDistance], fov: templateConfiguration.fov }}
        gl={{ powerPreference: 'high-performance' }}
        onCreated={onCreated}
        shadows
        style={{ zIndex: 10 }}
      >
        <Suspense fallback={null}>
          <ContextBridge>
            <OrbitControls
              makeDefault
              minDistance={templateConfiguration.minDistance}
              maxDistance={templateConfiguration.maxDistance}
              minPolarAngle={templateConfiguration.minPolarAngle}
              maxPolarAngle={templateConfiguration.maxPolarAngle}
              ref={controlsRef}
            />
            <ambientLight intensity={0.75} />
            <directionalLight position={[0, 10, -1]} intensity={1.5} />
            <directionalLight position={[1, 0.5, 0.5]} />
            <directionalLight position={[-1, 0.5, -0.5]} />
            {template === Template.MaleFullBody && <MaleBodyModel onDoubleClick={onDoubleClick} />}
            {template === Template.FemaleFullBody && <FemaleBodyModel onDoubleClick={onDoubleClick} />}
            {template === Template.AdultTeeth && <PermanentTeethModel onDoubleClick={onDoubleClick} />}
            {template === Template.PrimaryTeeth && <PrimaryTeethModel onDoubleClick={onDoubleClick} />}
            <Points
              closeNote={closeNote}
              deleteNote={deleteNote}
              notes={templateNotes}
              selectNote={selectNote}
              selectedNote={selectedNote}
              updateNote={updateNote}
              withStatus={withStatus}
              isReadOnly={isReadOnly}
            />
          </ContextBridge>
        </Suspense>
      </Canvas>
      <Loader
        barStyles={{ background: '#00d1cd' }}
        containerStyles={{ background: 'linear-gradient(#f8fafa, #f2f5f6)' }}
        dataInterpolation={() => formatMessage({ id: 'CORE.TEXT.LOADING', defaultMessage: 'Loading...' })}
        dataStyles={{ color: '#5e8086', fontSize: '0.75em' }}
        innerStyles={{ background: '#ffffff' }}
      />
      <Actions back={back} close={close} help={() => setHelpModal(true)} save={save} isReadOnly={isReadOnly} />
      <Toolbar
        moveDown={() => moveVertically(-0.2)}
        moveUp={() => moveVertically(0.2)}
        resetCamera={resetCamera}
        zoomIn={() => zoom(0.6)}
        zoomOut={() => zoom(1.6)}
      />
      <HelpModal close={() => setHelpModal(false)} isOpen={helpModal} />
      <ConfirmationModal
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        onConfirm={onConfirmDelete}
        description={formatMessage({
          id: 'CORE.TEXT.DELETE-NOTE-WARNING',
          defaultMessage: 'Are you sure you want to delete this note?',
        })}
      />
    </div>
  );
};
