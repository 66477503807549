import { useCallback, useRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector, useModalCleaner } from 'app/helpers';
import { Box } from 'app/components';
import { DescriptionList } from 'app/components/descriptionList';
import { Loader } from 'app/shared';
import { useGetPatientMergeBadgeDetailsQuery } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { setDestinationAndTargetPatients, setPatientBadgeMergeModalId } from 'app/redux/patient/patientSlice';
import { useItems } from 'app/features/patient/components/patientMergeBadgeModal/helpers/useItems';

export const PatientMergeBadgeModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const modalRef = useRef(null);

  // Selectors
  const { isOpen } = useAppSelector((state) => state.modal.patientMergeInfo);
  const patientBadgeMergeModalId = useAppSelector(
    (state) => state.patientSlice?.mergeProcess?.patientBadgeMergeModalId,
  );
  const mergedPatients = useAppSelector((state) => state.patientSlice?.mergeProcess?.mergedPatients);
  const { data, isFetching } = useGetPatientMergeBadgeDetailsQuery(
    patientBadgeMergeModalId && isOpen ? { entityId: patientBadgeMergeModalId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const items = useItems(data?.data);

  // Handlers
  const toggle = useCallback(() => {
    dispatch(toggleModal('patientMergeInfo'));
  }, [dispatch]);

  const onClosed = useCallback(() => {
    dispatch(setPatientBadgeMergeModalId(null));
    dispatch(setDestinationAndTargetPatients(null));
  }, [dispatch]);

  // Effects
  useModalCleaner('patientMergeInfo', modalRef);

  return (
    <Modal
      ref={modalRef}
      backdrop="static"
      keyboard={true}
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>
        {formatMessage({ id: mergedPatients ? 'CORE.LABEL.LATEST-MERGE' : 'CORE.LABEL.MERGE-HISTORY' })}
      </ModalHeader>
      <ModalBody>
        {isFetching && <Loader />}
        {data &&
          !isFetching &&
          items.map((item, index) => (
            <Box key={`descriptionList-${index}`}>
              <DescriptionList items={item} />
            </Box>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>{formatMessage({ id: 'CORE.BUTTON.CANCEL' })}</Button>
      </ModalFooter>
    </Modal>
  );
};
