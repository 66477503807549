import { skipToken } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { useGetItemStoragesQuery } from 'app/services/storages/storages';
import { getTotalStorageQuantity } from 'app/features/inventoryStorage/helpers/getTotalStorageQuantity';
import { useStorageColumns } from 'app/features/inventoryStorage/helpers/useStorageColumns';
import styles from 'app/features/inventoryStorage/storageTable/storageTable.module.scss';

interface StorageTableProps {
  form: string;
  branchId?: string;
  performerId?: string;
  performerBillingItemId?: string;
  totalQuantity?: number;
  storageQuantity?: Record<string, number>;
  storageQuantityField: string;
  withoutAddMultipleQuantityButton?: boolean;
}

export const StorageTable = ({
  form,
  branchId,
  performerId,
  performerBillingItemId,
  totalQuantity,
  storageQuantity = {},
  storageQuantityField,
  withoutAddMultipleQuantityButton = false,
}: StorageTableProps) => {
  const { formatMessage } = useAppIntl();

  const { data, isLoading, isFetching } = useGetItemStoragesQuery(
    !performerBillingItemId || !branchId || !performerId
      ? skipToken
      : {
          branchId,
          performerId,
          performerBillingItemId,
        },
  );

  const initialStorageQuantity: Record<string, number> =
    data?.reduce((acc, cur) => ({ ...acc, [cur.storageId]: 0 }), {}) || {};

  const columns = useStorageColumns({
    form,
    storageQuantity: initialStorageQuantity,
    totalQuantity,
    storageQuantityField,
  });

  const currentTotalQuantity = getTotalStorageQuantity(storageQuantity);

  if (!data || data.length === 0) return null;

  return (
    <div className="mb-2">
      <div className={classNames('mt-4 p-2 p-sm-3', styles.storageTable)}>
        <h6>
          {formatMessage(
            {
              id:
                totalQuantity && totalQuantity > 1
                  ? 'INVOICES.TEXT.SELECT-STORAGE-ITEMS'
                  : 'INVOICES.TEXT.SELECT-STORAGE-ITEM',
            },
            { quantity: totalQuantity || 1 },
          )}
          :
        </h6>
        <LocalBootstrapTable
          classes="table-sm"
          columns={columns}
          data={data}
          id="items"
          loading={isLoading || isFetching}
          keyField="id"
        />
      </div>
      {!withoutAddMultipleQuantityButton && totalQuantity && totalQuantity > 1 && (
        <div className="d-flex justify-content-end mt-3">
          <Button type="submit" color="primary" disabled={totalQuantity !== currentTotalQuantity}>
            {formatMessage({ id: 'CORE.LABEL.ADD-FROM-SPECIFIED-STORAGES' }, { value: totalQuantity })}
          </Button>
        </div>
      )}
    </div>
  );
};
