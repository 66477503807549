import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { BaseFieldProps, Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { DateSlider, DateSliderProps, FORMATS_DATE, InputComponent, required, SelectComponent } from 'app/shared';
import { ProductOption } from 'app/redux/appointment/types';
import { Option } from 'app/types';
import {
  useWaitingMedicalServiceOptions,
  useWaitingProductOptions,
  useWorkingTimeOptions,
} from 'app/features/appointment/form/helpers';
import {
  AppointmentAgeRestrictions,
  WaitingAppointmentFormData,
  WaitingAppointmentFormField,
  WorkingTimeOption,
} from 'app/features/appointment/form/types';
import { PatientSection } from 'app/features/appointment/shared';

interface Props {
  ageRestrictions?: AppointmentAgeRestrictions;
  clinicId: string;
  isEdit?: boolean;
  isFormDisabled: boolean;
  createdPatientMode?: boolean;
  onAddNewPatient: () => void;
  resetMode: () => void;
}

const colProps = { lg: 6 };

const FormComponent: FC<Props & InjectedFormProps<WaitingAppointmentFormData, Props>> = ({
  change,
  form,
  handleSubmit,
  ageRestrictions,
  clinicId,
  isEdit,
  isFormDisabled,
  createdPatientMode,
  onAddNewPatient,
  resetMode,
}) => {
  const { formatMessage } = useAppIntl();

  // Selectors
  const selectedPatient = useAppSelector((state) => state.appointment.selectedPatient.data);
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const workingTime: WorkingTimeOption | undefined = useAppSelector((state) => state.form[form]?.values?.workingTime);
  const subSpeciality: Option | null | undefined = useAppSelector((state) => state.form[form]?.values?.subSpeciality);
  const product: ProductOption | null | undefined = useAppSelector((state) => state.form[form]?.values?.product);

  // Options
  const workingTimeOptions = useWorkingTimeOptions(change);
  const subSpecialityOptions = useWaitingMedicalServiceOptions(workingTime?.value);
  const productOptions = useWaitingProductOptions(workingTime?.value, subSpeciality?.value);

  // Time Range
  const timeRange: { min: Date; max: Date; margin: number } | null = useMemo(() => {
    const productOption = productOptions.find((p) => p.value === product?.value);

    if (workingTime?.details) {
      return {
        min: workingTime?.details.from,
        max: workingTime?.details.to,
        margin: productOption?.details?.productDuration || 5,
      };
    }

    return null;
  }, [product, productOptions, workingTime]);

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <PatientSection
        showAppointments={false}
        showSearch={!isEdit && !preselectedPatient}
        formName={form}
        clinicId={clinicId}
        isFormDisabled={isFormDisabled}
        createdPatientMode={createdPatientMode}
        onAddNewPatient={onAddNewPatient}
        resetMode={resetMode}
        ageRestrictions={ageRestrictions}
      />

      {selectedPatient && (
        <>
          <hr />
          <Row>
            <Col {...colProps}>
              <Field
                name={WaitingAppointmentFormField.date}
                component={InputComponent}
                disabled
                format={(v: string) => moment(v).format(FORMATS_DATE)}
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.DATE' })}
                type="text"
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={WaitingAppointmentFormField.doctorName}
                component={InputComponent}
                disabled
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.DOCTOR-NAME' })}
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col {...colProps}>
              <Field
                name={WaitingAppointmentFormField.workingTime}
                component={SelectComponent}
                disabled
                isRequired
                isSearchable
                label={formatMessage({ id: 'CORE.LABEL.WORKING-HOURS-PERIOD' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                options={workingTimeOptions}
                placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-PERIOD' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={WaitingAppointmentFormField.subSpeciality}
                component={SelectComponent}
                disabled={!isEdit || isFormDisabled || !workingTime}
                isRequired
                isSearchable
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.SUB-SPECIALITY' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                options={subSpecialityOptions}
                placeholder={formatMessage({ id: 'CALENDAR.TEXT.SELECT-SUB-SPECIALITY' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field
                name={WaitingAppointmentFormField.product}
                component={SelectComponent}
                disabled={!isEdit || isFormDisabled || !workingTime || !subSpeciality}
                isRequired
                isSearchable
                label={formatMessage({ id: 'CORE.TEXT.SERVICE' })}
                noOptionsMessage={formatMessage({ id: 'CORE.TEXT.NO-OPTIONS' })}
                options={productOptions}
                placeholder={formatMessage({ id: 'CALENDAR.TEXT.SELECT-SERVICE' })}
                validate={[required]}
              />
            </Col>
            <Col {...colProps}>
              <Field<BaseFieldProps<DateSliderProps>>
                name={WaitingAppointmentFormField.preferredTimeRange}
                component={DateSlider}
                props={{
                  disabled: isFormDisabled || !workingTime || !product || !productOptions.length,
                  isRequired: true,
                  label: formatMessage({ id: 'CORE.LABEL.PREFERRED-TIME-RANGE' }),
                  margin: timeRange?.margin,
                  max: timeRange?.max,
                  min: timeRange?.min,
                  step: 5,
                }}
                validate={[required]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name={WaitingAppointmentFormField.note}
                component={InputComponent}
                disabled={isFormDisabled}
                label={formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' })}
                type="textarea"
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export const WaitingAppointmentForm = reduxForm<WaitingAppointmentFormData, Props>({})(FormComponent);
