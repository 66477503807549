import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppIntl } from 'app/helpers';
import { LanguageSelector, UnauthorizedPage } from 'app/components';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { Loader } from 'app/shared';
import { Appointment, Locale } from 'app/types';
import { Actions } from 'app/features/confirm-appointment/actions/actions';
import { Header } from 'app/features/confirm-appointment/header/header';
import { Items } from 'app/features/confirm-appointment/items/items';

type Params = {
  code: string;
};

interface Props {
  appointment: Appointment;
  appointmentLoading: boolean;
  changeLanguage: (code: string) => void;
  code: string;
  lang: Locale;
  onCancelAppointment: (id: string, params: Params) => void;
  onConfirmAppointment: (id: string, params: Params) => void;
  fetchAppointment: (id: string, params: Params) => void;
}

export const ConfirmAppointmentComponent = ({
  appointment,
  appointmentLoading,
  changeLanguage,
  code,
  fetchAppointment,
  lang,
  onCancelAppointment,
  onConfirmAppointment,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useAppIntl();
  const { id: appointmentId } = useParams<{ id: string }>();

  // Confirmation modal
  const [confirmation, setConfirmation] = useState(false);
  const showConfirmation = useCallback(() => setConfirmation(true), []);
  const closeConfirmation = useCallback(() => setConfirmation(false), []);

  // Actions
  const onConfirm = useCallback(async () => {
    if (code) {
      const params = { code: code };

      setLoading(true);
      await onConfirmAppointment(appointmentId, params);
      fetchAppointment(appointmentId, params);
      setLoading(false);
    }
  }, [appointmentId, code, fetchAppointment, onConfirmAppointment]);

  const onCancel = useCallback(async () => {
    if (code) {
      const params = { code: code };

      setLoading(true);
      await onCancelAppointment(appointmentId, params);
      fetchAppointment(appointmentId, params);
      closeConfirmation();
      setLoading(false);
    }
  }, [appointmentId, code, closeConfirmation, fetchAppointment, onCancelAppointment]);

  useEffect(() => {
    changeLanguage(lang);
  }, [lang, changeLanguage]);

  useEffect(() => {
    if (code) {
      fetchAppointment(appointmentId, { code });
    }
  }, [appointmentId, code, fetchAppointment]);

  return (
    <>
      <UnauthorizedPage boxSize="md" languageSelector={false}>
        {appointmentLoading && <Loader style={{ height: 350 }} />}

        {!appointmentLoading && appointment && (
          <>
            <Header appointment={appointment} />
            <Items lang={lang} />
            <Actions
              appointment={appointment}
              loading={loading}
              onConfirm={onConfirm}
              showConfirmation={showConfirmation}
            />
            <div className="d-flex justify-content-center">
              <LanguageSelector change={(l) => (window.location.search = `code=${code}&lang=${l}`)} />
            </div>
          </>
        )}

        {!appointment && !appointmentLoading && (
          <div className="text-center">{formatMessage({ id: 'CORE.LABEL.NO-DATA' })}</div>
        )}

        <ConfirmationModal
          description={formatMessage({ id: 'APPOINTMENTS.TEXT.CANCEL-CONFIRMATION' })}
          loading={loading}
          isOpen={confirmation}
          onCancel={closeConfirmation}
          onClose={closeConfirmation}
          onConfirm={onCancel}
        />
      </UnauthorizedPage>
    </>
  );
};
