import { useCallback, useState } from 'react';
import { change, submit } from 'redux-form';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { FORMS_PRE_SEARCH_CLINIC_PATIENT, phoneNumber as validatePhoneNumber } from 'app/shared';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { Patient } from 'app/types';
import { useSetPatientData } from 'app/features/appointment/form/helpers/useSetPatientData';

interface Props {
  formName: string;
  setPatient: (payload: PatientInfoShort) => { payload: PatientInfoShort; type: string };
  resetPatient: () => { type: string };
}

export const useCreatePatientHandlers = ({ formName, setPatient, resetPatient }: Props) => {
  const dispatch = useAppDispatch();

  // Local State
  const [createdPatient, setCreatedPatient] = useState<Patient | null>(null);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState<string | null>(null);

  // Selectors
  const phoneNumber = useAppSelector((state) => state.form[formName]?.values?.[PatientSearchField.searchPhoneNumber]);

  // Actions
  const setPatientData = useSetPatientData({ formName, setCreatedPatient, setPatient });

  // Handlers
  const onAddNewPatient = useCallback(async () => {
    setInitialPhoneNumber(null);

    if (!validatePhoneNumber(phoneNumber)) {
      dispatch(toggleModal('preSearchClinicPatient', true));
      dispatch(submit(FORMS_PRE_SEARCH_CLINIC_PATIENT));
    }
  }, [dispatch, phoneNumber]);

  const onResetCreated = useCallback(() => {
    if (createdPatient) {
      setCreatedPatient(null);
      dispatch(resetPatient());
      dispatch(change(formName, PatientSearchField.selectedPhoneNumber, null));
      dispatch(change(formName, PatientSearchField.selectedGuardian, null));
      dispatch(change(formName, PatientSearchField.patientId, null));
    }
  }, [dispatch, createdPatient, formName, resetPatient]);

  return {
    createdPatient,
    onAddNewPatient,
    onResetCreated,
    setPatientData,
    initialPhoneNumber,
  };
};
