import { useCallback } from 'react';
import { omit } from 'lodash';
import { useAppDispatch } from 'app/helpers/useTypedHooks/useTypedHooks';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { BillingItemNameScope, BillingItemSourceType, BillingItemType, RemoteDataParams } from 'app/types';

export interface PerformerBillingItemsDropdownParams {
  branchId?: string;
  performerId?: string;
  insuranceId: string;
  itemType: BillingItemType;
  appointmentIdToExclude: string;
  performerBillingItemIdsToExclude: string | string[];
  billingItemSourceType: BillingItemSourceType;
  nameScope: BillingItemNameScope;
}

export const useFetchPerformerBillingItemsOptions = (dropdownParams: Partial<PerformerBillingItemsDropdownParams>) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (params: RemoteDataParams) => {
      const { branchId, performerId } = dropdownParams;
      if (branchId && performerId) {
        return dispatch(
          fetchDropdownOptions(
            `/branches/${branchId}/performers/${performerId}/performer-billing-items/dropdown`,
            omit({ ...params, ...dropdownParams }, ['branchId', 'performerId']),
          ),
        );
      }
      return null;
    },
    [dispatch, dropdownParams],
  );
};
