import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { SubmissionError } from 'redux-form';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { generateTemporaryId, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_EDIT_ITEM } from 'app/shared';
import { editInvoiceItem, setReservationId } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { ErrorType, InvoiceItem } from 'app/types';
import { useGetStorageQuantityMap } from 'app/features/inventoryStorage/helpers/useGetStorageQuantityMap';
import { useStorageReservation } from 'app/features/inventoryStorage/helpers/useStorageReservation';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { getPriceAfterDiscount, usePayByValue } from 'app/features/invoicingProcess/process/items/helpers';
import { formatInvoicingProcessStorageReservationItems } from 'app/features/invoicingProcess/process/items/helpers/formatInvoicingProcessStorageReservationItems';
import { onChange } from 'app/features/invoicingProcess/process/items/itemForm/helpers/onChange';
import { ItemForm, ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';

interface Props {
  onSubmitSuccess: () => void;
}

export const EditItemForm = ({ onSubmitSuccess }: Props) => {
  const { isCreateCreditNoteMode, entityId, entityType, reservationId } = useInvoicingProcessState();

  const [storageQuantityMap, setStorageQuantityMap] = useState<Record<string, number> | null>(null);

  const invoiceItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.items);
  const selectedItem = useAppSelector((state) => state.invoicingProcess.selectedItem);
  const selectedItemId = selectedItem?.id;
  const invoicePackageSnapshotId = useAppSelector((state) => state.invoicingProcess.invoicePackageSnapshotId);
  const invoicePayBy = usePayByValue();
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const doctorId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.doctor.value);
  const invoiceItem = useMemo(
    () => invoiceItems?.find((item) => item.id === selectedItemId),
    [selectedItemId, invoiceItems],
  );

  const { reserveStorage } = useStorageReservation({
    entityType,
    entityId,
    branchId,
    reservationId,
    setReservationId,
  });

  const params = {
    clinicyId: selectedItem?.billingItem.clinicyId,
    branchId,
    performerId: doctorId,
    performerBillingItemId: selectedItem?.billingItem.value,
    entityId,
    entityType,
  };

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== undefined && value !== ''),
  );

  const { getStorageQuantityMap } = useGetStorageQuantityMap({
    ...filteredParams,
    entityType,
  });

  useEffect(() => {
    const fetchStorageQuantityMap = async () => {
      const map = await getStorageQuantityMap();
      setStorageQuantityMap(map);
    };

    fetchStorageQuantityMap();
  }, [getStorageQuantityMap]);

  const onSubmit = useCallback<FormSubmitHandler<ItemFormData, ItemFormProps, ErrorType>>(
    async (values, dispatch) => {
      if (selectedItem) {
        const newValues: Omit<InvoiceItem, 'id'> = {
          billingItem: values.item,
          billingItemSourceType: values.itemSourceType,
          discount: {
            discountTypeView: { label: '', value: values.discountType }, // label is not required
            value: Number(values.discountValue || 0),
          },
          price: Number(values.item.details.price),
          quantity: Number(values.totalQuantity),
          storageQuantity: values.storageQuantity,
          vat: selectedItem.vat,
        };

        const isReservation = !!values.storageQuantity && Object.keys(values.storageQuantity).length > 0;
        const upToDateItemList = [
          ...(invoiceItems?.filter((item) => item.id !== selectedItemId) ?? []),
          { ...newValues, id: generateTemporaryId() },
        ];

        if (isReservation) {
          const res = await reserveStorage(formatInvoicingProcessStorageReservationItems(upToDateItemList));

          if (!res?.success) {
            toast.error('CORE.TEXT.GENERAL-ERROR');
            return;
          }
        }

        // Total Price
        const prevTotalPrice = invoiceItem ? getPriceAfterDiscount(invoiceItem) : undefined;
        const totalPrice = getPriceAfterDiscount(newValues);

        if (prevTotalPrice !== undefined && totalPrice > prevTotalPrice && isCreateCreditNoteMode) {
          // Total Price cannot be higher than Previous Total Price in Credit Note mode
          throw new SubmissionError<ItemFormData, ErrorType>({
            [ItemField.totalPrice]: { message: 'ERRORS.TOTAL-PRICE-CANNOT-EXCEED', value: prevTotalPrice },
          });
        } else {
          dispatch(
            editInvoiceItem({
              ...selectedItem,
              ...newValues,
            }),
          );
          onSubmitSuccess();
          toast.success('CORE.TEXT.ITEM-UPDATED-SUCCESSFULLY');
        }
      }
    },
    [invoiceItems, isCreateCreditNoteMode, onSubmitSuccess, selectedItem, reserveStorage, invoiceItem, selectedItemId],
  );

  const initialValues = useMemo<Partial<ItemFormData> | undefined>(() => {
    if (selectedItem) {
      return {
        itemSourceType: selectedItem.billingItemSourceType,
        discountType: selectedItem.discount.discountTypeView.value,
        discountValue: String(selectedItem.discount.value),
        item: selectedItem.billingItem,
        totalQuantity: selectedItem.quantity,
        storageQuantity: selectedItem.storageQuantity ?? storageQuantityMap ?? {},
      };
    }

    return;
  }, [selectedItem, storageQuantityMap]);

  return (
    <ItemForm
      form={FORMS_INVOICING_PROCESS_EDIT_ITEM}
      initialValues={initialValues}
      invoicePackageSnapshotId={invoicePackageSnapshotId}
      invoicePayBy={invoicePayBy}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
