import { Location } from 'app/types/Location';
import { MedicalInstitutionStatus } from 'app/types/MedicalInstitution';
import { Option } from 'app/types/Option';
import { RemoteFile } from 'app/types/RemoteFile';

export enum BranchProductStatus {
  Enabled = '0',
  Disabled = '1',
}

export enum BranchStatus {
  Offline = '0',
  Online = '1',
  Deleted = '2',
  Blocked = '3',
}

export enum BranchClinicMode {
  Operational = 0,
  ViewOnly = 1,
}

export type Branch = {
  addressAr: string;
  addressEn: string;
  approvalDate: string;
  city: Option;
  clinic: Option;
  clinicStatus: Option<MedicalInstitutionStatus>;
  clinicyId: string;
  deletedAt: string | null;
  deletedByClinicyId: string | null;
  id: string;
  images: RemoteFile[];
  location: Location;
  medicalLicenseExpiryDate: string;
  medicalLicenseFile: RemoteFile;
  medicalLicenseNumber: string;
  nameAr: string;
  nameEn: string;
  numberOfAppointments: number;
  numberOfDoctors: number;
  phoneNumber: string;
  rating: number;
  nphiesId: string;
  status: Option<BranchStatus>;
  branchHasDifferentInfoThanMI: boolean;
  crExpiryDate?: string;
  crFile?: RemoteFile;
  crNumber?: string;
  logo?: RemoteFile;
  specialities: Option[];
  vatNumber?: string;
  hasActiveLoomxIntegration: boolean;
  clinicHasLoomxIntegration: boolean;
} & BranchIntegrationSettings;

export interface BranchChanges {
  id: string;
  old: Partial<Branch>;
  new: Partial<Branch>;
}

export type BranchDeclaration = {
  clinicBranchId: string;
  contentAr: string;
  contentEn: string;
  declarationLocation: Option<BranchDeclarationLocation>;
  declarationStatus: Option<BranchDeclarationStatus>;
  id: string;
  titleAr: string;
  titleEn: string;
};

export interface BranchIntegrationSettings {
  allowZeroCountItemsReservation: boolean;
  enableInventoryReservation: boolean;
}

export type BranchLoomxSettings = {
  hasActiveLoomxIntegration: boolean;
  clinicHasLoomxIntegration: boolean;
} & BranchIntegrationSettings;

export interface BranchProduct {
  id: string;
  clinicyId: string;
  subSpecialityId: number;
  branchId: string;
  nameEn: string;
  nameAr: string;
  descriptionEn?: string;
  descriptionAr?: string;
  isDefault: boolean;
  status: Option<BranchProductStatus>;
}

export type BranchDeclarationLogFromToModel = Pick<
  BranchDeclaration,
  'contentAr' | 'contentEn' | 'titleAr' | 'titleEn' | 'declarationLocation' | 'declarationStatus'
>;

export interface BranchDeclarationLogsItem {
  action: Option;
  actionDateTime: string;
  actionTaker: Option;
  branchDeclarationId: string;
  fromModel?: BranchDeclarationLogFromToModel;
  id: string;
  toModel?: BranchDeclarationLogFromToModel;
}

export enum BranchDeclarationStatus {
  Active = '0',
  Deleted = '1',
}

export enum BranchDeclarationLocation {
  Invoice = '0',
  AddCredit = '1',
}

export interface BranchDeclarationOption extends Option {
  details: {
    contentEn: string;
    contentAr: string;
    status: Option<BranchDeclarationStatus>;
  };
}

export interface MedicalDirectorInfo {
  id: string;
  fullNameEnglish: string;
  fullNameArabic: string;
  mobileNumber: string;
  email: string;
}
