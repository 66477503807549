import { PreferredLanguage } from 'app/shared';
import { Doctor } from 'app/types/Doctor';
import { Option } from './Option';
import { RemoteFile } from './RemoteFile';

export enum UserRoles {
  Doctor = '7',
  Manager = '5',
  ManagerAdmin = '2',
  MasterAdmin = '1',
  None = '0',
  Owner = '4',
  Reception = '6',
  ReceptionAdmin = '3',
  Viewer = '8',
  Nurse = '10',
}

export interface UserPermissions {
  allowedForAppointmentsActions: boolean;
  allowedForBilling: boolean;
  allowedForBookAppointments?: boolean;
  allowedForEditInvoice: boolean;
  allowedForCreateInvoice: boolean;
  allowedForCreateOrders: boolean;
  allowedForCreatePatientProfile: boolean;
  allowedForCreateSpecialityNotes?: boolean;
  allowedForDashboard: boolean;
  allowedForDeclarations: boolean;
  allowedForEMR: boolean;
  allowedForEditAppointments?: boolean;
  allowedForEditPatientProfile: boolean;
  allowedForExportAppointments: boolean;
  allowedForExportInsuranceClaim: boolean;
  allowedForInitialAssessment: boolean;
  allowedForInsuranceApproval: boolean;
  allowedForManageInsuranceApproval: boolean;
  allowedForInsuranceClaims: boolean;
  allowedForInsuranceManagement: boolean;
  allowedForManageInsuranceClaim: boolean;
  allowedForMassAdding: boolean;
  allowedForMergePatientProfile: boolean;
  allowedForNotEncryptedPhoneNumbers: boolean;
  allowedForTakeActionReferral: boolean;
  allowedForTreatmentView: boolean;
  allowedForUpdateOrders: boolean;
  allowedForUpdateSpecialityNotes?: boolean;
  allowedForViewInsuranceClaim: boolean;
  allowedForViewInsuranceContractsAndPolicies?: boolean;
  allowedForViewInvoice: boolean;
  allowedForViewOrders: boolean;
  allowedForViewPatientProfile: boolean;
  allowedForViewPatientSources: boolean;
  allowedForViewReferral: boolean;
  allowedForWriteInsuranceContractsAndPolicies?: boolean;
  allowedForWritePatientSources: boolean;
  allowedForReferralApproval: boolean;
  allowedForManageReferralApproval: boolean;
  allowedToViewClinicAccountBalance: boolean;
  allowedToViewOtherUserFinancials: boolean;
  allowedToExportClinicAccountBalance: boolean;
  allowedForViewAuthority: boolean;
  allowedForActionLogAccess: boolean;
  allowedForCreateAuthority: boolean;
  allowedForEditAuthority: boolean;
  allowedForOperateSameUserType: boolean;
  allowedForViewBranches: boolean;
  allowedForCreateBranch: boolean;
  allowedForEditBranch: boolean;
  allowedForViewAppointmentList: boolean;
  allowedForViewPatientWallet: boolean;
  allowedForAddCredit: boolean;
  allowedForWithdrawCredit: boolean;
  allowedForTransferCredit: boolean;
  allowedForSettleInvoices: boolean;
  allowedForViewMasterItems: boolean;
  allowedForCreateMasterItems: boolean;
  allowedForEditMasterItems: boolean;
  allowedForMassAddingMasterItems: boolean;
  allowedForViewBranchServices: boolean;
  allowedForCreateBranchServices: boolean;
  allowedForEditBranchServices: boolean;
  allowedForMassAddingBranchServices: boolean;
  allowedForMedicalRecordAttachments: boolean;
  allowedForLinkUnlinkPaymentTerminal: boolean;
  allowedForManagePatientStatus: boolean;
  allowedForUserLogAccess: boolean;
}

export type User = {
  assignedToAllBranches?: boolean;
  assignedToAllDoctors?: boolean;
  branches: Option[];
  clinic?: Option;
  clinicyId?: string;
  clinicMode?: Option;
  email?: string;
  emailConfirmed?: boolean;
  firstName?: string;
  firstNameEn?: string;
  firstNameAr?: string;
  hasToChangePassword?: boolean;
  id?: string;
  lastLogin?: string | null;
  lastName?: string;
  lastNameEn?: string;
  lastNameAr?: string;
  photo?: RemoteFile | null;
  phoneNumber?: string | null;
  preferredNotificationLanguage?: Option<PreferredLanguage>;
  roles: Option<UserRoles>[];
  username?: string;
  filtersDefaults: {
    defaultBranch: Option | null;
    defaultSpeciality: Option | null;
  } | null;
  createdAt: string;
  lastModifiedAt: string | null;
} & Partial<UserPermissions>;

export interface AdminUser extends User {
  allClinics: boolean;
  clinics: Option[];
  firstName?: string;
  lastName?: string;
}

export interface ClinicUser extends User {
  assignedToAllDoctors: boolean;
  firstName?: string;
  lastName?: string;
  doctors?: ClinicUserDoctor[];
}

export type ClinicUserDoctor = Pick<
  Doctor,
  'firstNameEn' | 'firstNameAr' | 'id' | 'lastNameEn' | 'lastNameAr' | 'title'
>;

export enum UserPermissionsFlag {
  AllSelected = 'allPermissionsSelected',
  ActionLogAccess = 'actionLogAccess',
  Actions = 'actions',
  AddCredit = 'addCredit',
  BookAppointment = 'bookAppointment',
  Create = 'create',
  CreateAndEdit = 'createAndEdit',
  CreateAndEditSameUserType = 'createAndEditSameUserType',
  Dashboard = 'dashboard',
  Declarations = 'declarations',
  Edit = 'edit',
  Export = 'export',
  InitialAssessment = 'initialAssessment',
  InsuranceApprovals = 'insuranceApprovals',
  ManagePaymentTerminal = 'managePaymentTerminal',
  ManagePatientStatus = 'managePatientStatus',
  ManageStatus = 'manageStatus',
  MassAdding = 'massAdding',
  MedicalRecordsAttachments = 'medicalRecordsAttachments',
  ViewOtherUserFinancials = 'viewOtherUserFinancials',
  SettleBulkInvoices = 'settleBulkInvoices',
  TransferCredit = 'transferCredit',
  TreatmentView = 'treatmentView',
  UserLogAccess = 'userLogAccess',
  WithdrawalCredit = 'withdrawalCredit',
  View = 'view',
  ViewAppointmentList = 'viewAppointmentList',
  Manage = 'manage',
}
