import { getApiDateAndTime } from 'app/helpers';
import { AppointmentValues, WaitingAppointmentValues } from 'app/redux/appointment/types';
import { AppointmentFormData, WaitingAppointmentFormData } from 'app/features/appointment/form/types';

export const appointmentFormValuesAdapter = (values: AppointmentFormData): AppointmentValues => {
  return {
    branchId: values.branchId,
    guardianId: values.selectedGuardian?.value || null,
    doctorId: values.doctorId,
    end: values.slot.value.end || null,
    note: values.note || '',
    patientId: values.patientId,
    productId: values.product?.value,
    start: values.slot.value.end === null ? getApiDateAndTime(values.date) : values.slot.value.start,
    subSpecialityId: values.subSpeciality?.value,
    referralId: values.referralId || null,
    patientSourceId: values.patientSource?.value,
    phoneNumber: values.selectedPhoneNumber.value,
  };
};

export const waitingAppointmentFormValuesAdapter = (values: WaitingAppointmentFormData): WaitingAppointmentValues => {
  return {
    branchId: values.branchId,
    guardianId: values.selectedGuardian?.value || null,
    doctorId: values.doctorId,
    end: getApiDateAndTime(values.preferredTimeRange.to),
    note: values.note || '',
    patientId: values.patientId,
    patientSourceId: values.patientSource?.value,
    productId: values.product.value,
    start: getApiDateAndTime(values.preferredTimeRange.from),
    subSpecialityId: values.subSpeciality.value,
    workingTimeId: values.workingTime.value,
    referralId: values.referralId || null,
    phoneNumber: values.selectedPhoneNumber.value,
  };
};
