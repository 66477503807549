import React, { useCallback, useRef } from 'react';
import { Alert, Badge, ModalBody, ModalHeader } from 'reactstrap';
import { useAppDispatch, useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { Loader } from 'app/shared';
import { changeInfoNotifications } from 'app/redux/patient/patient.actions';
import { Details } from 'app/features/appointment/ticket/details/details';
import { useAppointmentData } from 'app/features/appointment/ticket/helpers';

interface Props {
  toggle: () => void;
}

export const Ticket = ({ toggle }: Props) => {
  const { formatMessage } = useAppIntl();
  const { isViewer } = useCurrentUser();
  const dispatch = useAppDispatch();
  const sendingNotification = useRef<boolean>(false);
  const { data, loading } = useAppSelector((state) => state.appointment);

  const changeInfo = useCallback(async () => {
    if (sendingNotification.current) {
      return;
    }
    sendingNotification.current = true;
    const id = data?.guardianId ?? data?.patientId;
    await dispatch(changeInfoNotifications(id));
    sendingNotification.current = false;
  }, [data?.guardianId, data?.patientId, dispatch, sendingNotification]);

  useAppointmentData();

  if (!data || loading) {
    return <Loader />;
  }

  return (
    <>
      <ModalHeader toggle={toggle}>
        <span className="me-2">{formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-TICKET' })}:</span>
        <Badge className="fs-6 me-2" color="light-primary">
          {data.number}
        </Badge>
      </ModalHeader>
      <ModalBody>
        {data.incompleteDemographicInfo && !isViewer && (
          <Alert color="danger" className="mb-4 p-1">
            <span className="alert-link ms-2">
              {/* @ts-ignore - formatMessage with React Node */}
              {formatMessage(
                { id: 'CORE.LINK.PATIENT-DEMOGRAPHIC-INFORMATION' },
                {
                  link: (chunks) => (
                    <span className="text-decoration-underline cursor-pointer" onClick={changeInfo}>
                      {chunks}
                    </span>
                  ),
                },
              )}
            </span>
          </Alert>
        )}
        <Details />
      </ModalBody>
    </>
  );
};
