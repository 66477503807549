import { ChangeEvent, useCallback } from 'react';
import moment from 'moment';
import { ChangeAction } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { useDate } from 'app/helpers';
import { AppointmentFormField, SlotOption } from 'app/features/appointment/form/types';

interface Params {
  change: ChangeAction;
  selectedDate: Date | null;
  openDateChangeConfirmation: () => void;
}

export const useOnChangeFunctions = ({ change, selectedDate, openDateChangeConfirmation }: Params) => {
  const { todayStart } = useDate();
  const date = selectedDate || todayStart;

  const onSubSpecialityChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(AppointmentFormField.product, null);
    change(AppointmentFormField.date, date);
    change(AppointmentFormField.slot, null);
  }, [change, date]);

  const onProductChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(AppointmentFormField.date, date);
    change(AppointmentFormField.slot, null);
  }, [change, date]);

  const onDateChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(AppointmentFormField.slot, null);
  }, [change]);

  const onSlotChange: EventWithDataHandler<ChangeEvent> = useCallback(
    (_, option: SlotOption | undefined) => {
      if (option?.value && !moment(selectedDate).isSame(moment(option.value.start), 'day')) {
        // Show confirmation modal if selected slot is from different day than selected day (working hours passing through midnight)
        openDateChangeConfirmation();
      }
    },
    [openDateChangeConfirmation, selectedDate],
  );

  return {
    onDateChange,
    onProductChange,
    onSlotChange,
    onSubSpecialityChange,
  };
};
