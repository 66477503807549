import { useCallback } from 'react';
import { change } from 'redux-form';
import { useAppDispatch } from 'app/helpers';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { ClinicPatientShortInfo } from 'app/services/patients/types';

interface Props {
  formName: string;
  setPatient: (payload: PatientInfoShort) => { payload: PatientInfoShort; type: string };
}

export const useChangePatientHandler = ({ formName, setPatient }: Props) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (data: ClinicPatientShortInfo) => {
      const defaultNumber = data.phoneNumber.find((number) => number.details.isDefault) || data.phoneNumber[0] || null;
      dispatch(change(formName, PatientSearchField.patientId, data.id));
      dispatch(change(formName, PatientSearchField.selectedGuardian, null));
      dispatch(change(formName, PatientSearchField.selectedPhoneNumber, defaultNumber));
      dispatch(setPatient(data));
    },
    [dispatch, formName, setPatient],
  );
};
