import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import { clinicsApi } from 'app/services/clinics/clinics';
import { RootState } from 'app/types';

type Meta = {
  registration: boolean;
};

export function* refreshClinicList({ meta }: PayloadAction<undefined, string, Meta>) {
  const {
    queryParams: { clinics: params },
  }: RootState = yield select();

  if (meta && meta.registration) {
    yield put(push('/registration/confirmation'));
  } else {
    yield put<any>(
      clinicsApi.endpoints.getRegisteredClinics.initiate(
        { params },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  }
}
