import { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector, useForceReload, useTokenDetector } from 'app/helpers';
import { Layout, Notifications } from 'app/components';
import { ErrorBoundary, FullScreenLoader, TrainingLabel } from 'app/shared';
import { useGetDictionariesQuery } from 'app/services/general/general';
import { fetchCurrentUser } from 'app/redux/auth/auth.actions';
import { AppointmentConfirmation } from 'app/modals/appointmentConfirmation/appointmentConfirmation';
import { ChangePasswordModalContainer } from 'app/modals/change-password/change-password-modal.container';
import { AnalyticsComponent } from 'app/features/analytics/analytics.component';
import { Maintenance } from 'app/features/maintenance/maintenance';
import { PatientMergeBadgeModal } from 'app/features/patient/components/patientMergeBadgeModal/patientMergeBadgeModal';
import { PatientVerificationModal } from 'app/features/patient/patientVerificationModal/patientVerificationModal';
import { TawkToChat } from 'app/features/tawkToChat/tawkToChat';

interface Props {
  children: ReactNode;
}

const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE === 'true';

export const App = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector((state) => state.auth.whileFetching);

  const {
    data,
    isLoading: isDictionariesLoading,
    isFetching: isDictionariesFetching,
  } = useGetDictionariesQuery(undefined, {
    skip: isUnderMaintenance,
  });

  const hasDictionaries = !!data && !isDictionariesLoading && !isDictionariesFetching;
  const showLoader = userLoading || !hasDictionaries;

  useEffect(() => {
    if (!isUnderMaintenance) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useForceReload();
  useTokenDetector();

  if (isUnderMaintenance) {
    // Application is under maintenance
    return <Maintenance />;
  }

  if (showLoader) {
    // We do not want to show the application before loading dictionaries or while fetching user data
    return <FullScreenLoader />;
  }

  return (
    <ErrorBoundary>
      {/* Uncomment if needed */}
      {/*<SystemUpdateWarning />*/}

      <Layout>{children}</Layout>

      {/* Components */}
      <AnalyticsComponent />
      <Notifications />
      <TawkToChat />
      <TrainingLabel />

      {/* Modals */}
      <AppointmentConfirmation />
      <PatientVerificationModal />
      <ChangePasswordModalContainer />
      <PatientMergeBadgeModal />
    </ErrorBoundary>
  );
};
