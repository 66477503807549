import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers/useTypedHooks/useTypedHooks';
import { UserPermissions } from 'app/types';

export const useCurrentUserPermissions = () => {
  const currentUser = useAppSelector((state) => state.auth.current);

  return useMemo<UserPermissions>(
    () => ({
      allowedForActionLogAccess: !!currentUser.allowedForActionLogAccess,
      allowedForMassAddingMasterItems: !!currentUser.allowedForMassAddingMasterItems,
      allowedForAddCredit: !!currentUser.allowedForAddCredit,
      allowedForCreateAuthority: !!currentUser.allowedForCreateAuthority,
      allowedForCreateBranch: !!currentUser.allowedForCreateBranch,
      allowedForCreateMasterItems: !!currentUser.allowedForCreateMasterItems,
      allowedForCreateBranchServices: !!currentUser.allowedForCreateBranchServices,
      allowedForEditAuthority: !!currentUser.allowedForEditAuthority,
      allowedForEditBranch: !!currentUser.allowedForEditBranch,
      allowedForOperateSameUserType: !!currentUser.allowedForOperateSameUserType,
      allowedForEditMasterItems: !!currentUser.allowedForEditMasterItems,
      allowedForEditBranchServices: !!currentUser.allowedForEditBranchServices,
      allowedForMassAddingBranchServices: !!currentUser.allowedForMassAddingBranchServices,
      allowedForMedicalRecordAttachments: !!currentUser.allowedForMedicalRecordAttachments,
      allowedForSettleInvoices: !!currentUser.allowedForSettleInvoices,
      allowedForTransferCredit: !!currentUser.allowedForTransferCredit,
      allowedForViewAppointmentList: !!currentUser.allowedForViewAppointmentList,
      allowedForViewAuthority: !!currentUser.allowedForViewAuthority,
      allowedForViewBranches: !!currentUser.allowedForViewBranches,
      allowedForViewMasterItems: !!currentUser.allowedForViewMasterItems,
      allowedForViewPatientWallet: !!currentUser.allowedForViewPatientWallet,
      allowedForViewBranchServices: !!currentUser.allowedForViewBranchServices,
      allowedForWithdrawCredit: !!currentUser.allowedForWithdrawCredit,
      allowedForWriteInsuranceContractsAndPolicies: !!currentUser.allowedForWriteInsuranceContractsAndPolicies,
      allowedForBookAppointments: !!currentUser.allowedForBookAppointments,
      allowedForExportInsuranceClaim: !!currentUser.allowedForExportInsuranceClaim,
      allowedForManageInsuranceClaim: !!currentUser.allowedForManageInsuranceClaim,
      allowedForViewInsuranceClaim: !!currentUser.allowedForViewInsuranceClaim,
      allowedForAppointmentsActions: !!currentUser.allowedForAppointmentsActions,
      allowedForBilling: !!currentUser.allowedForBilling,
      allowedForEditInvoice: !!currentUser.allowedForEditInvoice,
      allowedForCreateInvoice: !!currentUser.allowedForCreateInvoice,
      allowedForCreateOrders: !!currentUser.allowedForCreateOrders,
      allowedForCreatePatientProfile: !!currentUser.allowedForCreatePatientProfile,
      allowedForCreateSpecialityNotes: !!currentUser.allowedForCreateSpecialityNotes,
      allowedForDashboard: !!currentUser.allowedForDashboard,
      allowedForDeclarations: !!currentUser.allowedForDeclarations,
      allowedForEMR: !!currentUser.allowedForEMR,
      allowedForEditAppointments: !!currentUser.allowedForEditAppointments,
      allowedForEditPatientProfile: !!currentUser.allowedForEditPatientProfile,
      allowedForExportAppointments: !!currentUser.allowedForExportAppointments,
      allowedForInitialAssessment: !!currentUser.allowedForInitialAssessment,
      allowedForInsuranceApproval: !!currentUser.allowedForInsuranceApproval,
      allowedForManageInsuranceApproval: !!currentUser.allowedForManageInsuranceApproval,
      allowedForInsuranceClaims: !!currentUser.allowedForInsuranceClaims,
      allowedForInsuranceManagement: !!currentUser.allowedForInsuranceManagement,
      allowedForMassAdding: !!currentUser.allowedForMassAdding,
      allowedForMergePatientProfile: !!currentUser.allowedForMergePatientProfile,
      allowedForNotEncryptedPhoneNumbers: !!currentUser.allowedForNotEncryptedPhoneNumbers,
      allowedForTakeActionReferral: !!currentUser.allowedForTakeActionReferral,
      allowedForTreatmentView: !!currentUser.allowedForTreatmentView,
      allowedForUpdateOrders: !!currentUser.allowedForUpdateOrders,
      allowedForUpdateSpecialityNotes: !!currentUser.allowedForUpdateSpecialityNotes,
      allowedForViewInsuranceContractsAndPolicies: currentUser.allowedForViewInsuranceContractsAndPolicies,
      allowedForViewInvoice: !!currentUser.allowedForViewInvoice,
      allowedForViewOrders: !!currentUser.allowedForViewOrders,
      allowedForViewPatientProfile: !!currentUser.allowedForViewPatientProfile,
      allowedForViewPatientSources: !!currentUser.allowedForViewPatientSources,
      allowedForViewReferral: !!currentUser.allowedForViewReferral,
      allowedForWritePatientSources: !!currentUser.allowedForWritePatientSources,
      allowedForReferralApproval: !!currentUser.allowedForReferralApproval,
      allowedForManageReferralApproval: !!currentUser.allowedForManageReferralApproval,
      allowedToViewClinicAccountBalance: !!currentUser.allowedToViewClinicAccountBalance,
      allowedToViewOtherUserFinancials: !!currentUser.allowedToViewOtherUserFinancials,
      allowedToExportClinicAccountBalance: !!currentUser.allowedToExportClinicAccountBalance,
      allowedForLinkUnlinkPaymentTerminal: !!currentUser.allowedForLinkUnlinkPaymentTerminal,
      allowedForManagePatientStatus: !!currentUser.allowedForManagePatientStatus,
      allowedForUserLogAccess: !!currentUser.allowedForUserLogAccess,
    }),
    [currentUser],
  );
};
