import { Dispatch, SetStateAction, useCallback } from 'react';
import { change } from 'redux-form';
import { useAppDispatch } from 'app/helpers';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { detailedPatientToPatientShortAdapter } from 'app/shared/patientSearch/patientShortDetails/helpers/detailedPatientToPatientShortAdapter';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { Option, Patient, PatientType } from 'app/types';

interface Props {
  formName: string;
  setCreatedPatient: Dispatch<SetStateAction<Patient | null>>;
  setPatient: (payload: PatientInfoShort) => { payload: PatientInfoShort; type: string };
}

export const useSetPatientData = ({ formName, setCreatedPatient, setPatient }: Props) => {
  const dispatch = useAppDispatch();

  return useCallback(
    (data: Patient) => {
      const patientInfo = detailedPatientToPatientShortAdapter(data);
      setCreatedPatient(data);
      const mobileNumber = data.phoneNumbers[0];
      let guardianOption: Option | null = null;
      if (data.patientType.value === PatientType.Dependent && data.guardians?.length) {
        const guardian = data.guardians[0];
        guardianOption = {
          label: guardian.fullNameEnglish,
          value: guardian.id,
          translation: { en: guardian.fullNameEnglish, ar: guardian.fullNameArabic },
        };
      }
      dispatch(change(formName, PatientSearchField.patientId, data.id));
      dispatch(change(formName, PatientSearchField.selectedGuardian, guardianOption));
      dispatch(
        change(formName, PatientSearchField.selectedPhoneNumber, {
          label: mobileNumber.phoneNumber,
          value: mobileNumber.phoneNumber,
        }),
      );
      dispatch(setPatient(patientInfo));
    },
    [dispatch, formName, setCreatedPatient, setPatient],
  );
};
