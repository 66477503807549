import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'app/helpers';
import { useReserveStorageMutation } from 'app/services/storages/storages';
import { StorageEntityType, StorageReservationItem } from 'app/services/storages/types';

interface UseStorageReservationProps {
  reservationId: string | null;
  setReservationId?: (reservationId: string) => void;
  entityType: StorageEntityType;
  entityId?: string;
  branchId?: string;
}

export const useStorageReservation = ({
  reservationId,
  entityType,
  entityId,
  setReservationId,
  branchId,
}: UseStorageReservationProps) => {
  const [reserveInventoryStorage] = useReserveStorageMutation();
  const dispatch = useAppDispatch();

  const updateReservationId = useCallback(
    (reservationId: string) => {
      if (setReservationId) {
        dispatch(setReservationId(reservationId));
      }
    },
    [dispatch, setReservationId],
  );

  const deleteReservation = useCallback(async () => {
    if (!branchId || !reservationId) {
      return;
    }

    try {
      await reserveInventoryStorage({
        branchId,
        body: {
          reservationId,
          entityType,
          isDeleted: true,
          entityId,
          items: [],
        },
      }).unwrap();
      updateReservationId('');
    } catch (error) {
      toast.error('CORE.TEXT.GENERAL-ERROR');
    }
  }, [reservationId, entityType, entityId, reserveInventoryStorage, branchId, updateReservationId]);

  const reserveStorage = useCallback(
    async (items: StorageReservationItem[], isDeleted = false) => {
      if (!branchId || (isDeleted && !reservationId)) {
        return;
      }

      try {
        const data = await reserveInventoryStorage({
          branchId,
          body: {
            ...(reservationId && { reservationId }),
            entityType,
            entityId,
            items,
            isDeleted,
          },
        }).unwrap();

        if (!data.success) {
          toast.error('CORE.TEXT.GENERAL-ERROR');
          return { success: false, error: data.errorMessage };
        }

        if (!reservationId) {
          updateReservationId(data.reservationId);
        } else if (isDeleted) {
          updateReservationId('');
        }

        return { success: true };
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
        return { success: false, error: error };
      }
    },
    [reserveInventoryStorage, reservationId, entityType, entityId, branchId, updateReservationId],
  );

  return { deleteReservation, reserveStorage };
};
