import React from 'react';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { getTranslation, useAppIntl, useCurrentUserPermissions } from 'app/helpers';
import { insuranceContractorsSetupTab, policiesTab } from 'app/routes/insurancesManagement/constants';
import { generateInsurancePoliciesSetUpPath } from 'app/routes/insurancesManagement/helpers';
import { InsuranceContractEntityStatus, InsuranceContractorsScope, InsuranceDetailsItem } from 'app/types';

interface Props {
  item: InsuranceDetailsItem;
}

export const PolicyNumber = ({ item }: Props) => {
  const { locale, formatMessage } = useAppIntl();
  const { allowedForInsuranceManagement, allowedForViewInsuranceContractsAndPolicies } = useCurrentUserPermissions();

  if (!allowedForInsuranceManagement || !allowedForViewInsuranceContractsAndPolicies) {
    return (
      <span>
        {getTranslation(item.insurancePolicy, locale)} {item.insurancePolicy?.label}
      </span>
    );
  }

  if (!item.insurancePolicy) {
    return <Badge color="light-danger">{formatMessage({ id: 'CORE.LABEL.MISSING-INFORMATION' })}</Badge>;
  }

  const isRemovedOrExpired =
    item.insurancePolicy?.details?.status?.value === InsuranceContractEntityStatus.Expired ||
    item.insurancePolicy?.details?.status?.value === InsuranceContractEntityStatus.Removed;

  const policyTab = isRemovedOrExpired ? policiesTab.removedPolicies : policiesTab.policySetup;

  const insuranceContractorsTab = isRemovedOrExpired
    ? insuranceContractorsSetupTab.removedExpiredContractors
    : insuranceContractorsSetupTab.contractPolicySetup;

  const pathTo = generateInsurancePoliciesSetUpPath(
    policyTab,
    item.insurancePolicy?.details.insuranceContractId,
    InsuranceContractorsScope.Global,
    insuranceContractorsTab,
  );

  return (
    <Link to={pathTo}>
      {getTranslation(item.insurancePolicy, locale)} {item.insurancePolicy?.label}
    </Link>
  );
};

export const policyNumberFormatter: ColumnFormatter<InsuranceDetailsItem> = (_: number, row) => (
  <PolicyNumber item={row} />
);
