import React, { useEffect, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector, useCurrentUser } from 'app/helpers';
import { Page } from 'app/components';
import { PrivateRouteState } from 'app/components/privateRoute/privateRoute';
import { UserRoles } from 'app/types';
import { AccessDeniedSection } from 'app/features/accessDenied/components/accessDeniedSection';
import { LoggingSection } from 'app/features/accessDenied/components/loggingSection';

export const AccessDeniedComponent: React.FC = () => {
  const prevRoles = useRef<UserRoles[] | null>(null);
  const userLoading = useAppSelector((state) => state.auth.whileFetching);
  const { isAuthenticated, currentUser } = useCurrentUser();
  const roles = useMemo(() => currentUser.roles.map((r) => r.value), [currentUser.roles]);
  const { push } = useHistory();
  const location = useLocation<PrivateRouteState>();

  const path = location?.state?.referrer ?? '/';

  useEffect(() => {
    if (prevRoles.current !== null && !isEqual(prevRoles.current, roles)) {
      push(path);
    }
    prevRoles.current = roles;
  }, [userLoading, isAuthenticated, push, path, roles]);

  return <Page className="text-center py-6">{userLoading ? <LoggingSection /> : <AccessDeniedSection />}</Page>;
};
