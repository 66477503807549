declare global {
  interface Window {
    Tawk_API: any;
    Tawk_LoadStart: Date | null;
  }
}

const tawkId = 'clinicyTawk';

export const createTawkToScript = (src) => {
  if (window) {
    // Create tawk.to variables
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Check if the chat already exists
    if (document.getElementById(tawkId)) {
      return window.Tawk_API;
    }

    // Get script tag
    const scriptTag = document.getElementsByTagName('script')[0];

    // Check if script tag is available
    if (!scriptTag || !scriptTag.parentNode) {
      throw new Error('Script tag is not available');
    }

    // Create tawk.to script
    const tawkScript = document.createElement('script');

    // Set tawk.to script params
    tawkScript.id = tawkId;
    tawkScript.async = true;
    tawkScript.src = src;
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');

    // Insert tawk.to script
    scriptTag.parentNode.insertBefore(tawkScript, scriptTag);

    // Custom styles
    window.Tawk_API.customStyle = {
      zIndex: 1,
    };

    return window.Tawk_API;
  }
};
