import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import toast from 'react-hot-toast';
import { Dispatch } from 'redux';
import { touch } from 'redux-form';
import {
  FORMS_INVOICING_PROCESS_ADD_ITEM,
  FORMS_INVOICING_PROCESS_DETAILS,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcess, InvoicingProcessMode, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { InvoiceCreditNotePayment, InvoiceItem, InvoicePaymentMethodItem, InvoiceStatus, InvoiceType } from 'app/types';
import { DetailsFormData } from 'app/features/invoicingProcess/process/details/form/types';
import { validate as validateDetailsForm } from 'app/features/invoicingProcess/process/details/form/validate';
import { checkIsPaymentModeItemsInvalid } from 'app/features/invoicingProcess/process/helpers/checkIsPaymentModeItemsInvalid';
import { scrollToInvalidField } from 'app/features/invoicingProcess/process/helpers/scrollToInvalidField';
import { generateExtendedItems } from 'app/features/invoicingProcess/process/items/helpers';
import { ItemField } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { getLeftToPayValue } from 'app/features/invoicingProcess/process/paymentMethod/helpers/getLeftToPayValue';
import {
  PaymentConfigurationField,
  PaymentConfigurationFormData,
} from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';
import { validate as validatePaymentMethodConfiguration } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/validate';
import { validate as validatePaymentModeForm } from 'app/features/invoicingProcess/process/paymentMode/form/helpers/validate';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const effectsDelay = 200; // ms

interface CheckStepsParams {
  appointmentId: string | null;
  creditNotePayments: InvoiceCreditNotePayment[];
  detailsValues?: DetailsFormData;
  dispatch: Dispatch;
  invoiceStatus: InvoiceStatus | undefined;
  invoiceType: InvoiceType | undefined;
  invoicingProcess: InvoicingProcess | null;
  isPrimaryCareSpeciality: boolean;
  items: InvoiceItem[];
  mode: InvoicingProcessMode;
  paymentConfigurationValues: PaymentConfigurationFormData;
  paymentMethodItems: InvoicePaymentMethodItem[];
  paymentModeValues: PaymentModeFormData;
  step: InvoicingProcessStep;
}

export const checkSteps = ({
  appointmentId,
  creditNotePayments,
  detailsValues,
  dispatch,
  invoiceStatus,
  invoiceType,
  invoicingProcess,
  isPrimaryCareSpeciality,
  items,
  mode,
  paymentConfigurationValues,
  paymentMethodItems,
  paymentModeValues,
  step,
}: CheckStepsParams): boolean => {
  const isDetailsStepValid = detailsValues ? checkDetailsStep({ detailsValues, dispatch, step }) : true;
  const isPaymentModeStepValid = checkPaymentModeStep({
    appointmentId,
    dispatch,
    invoicingProcess,
    items,
    paymentModeValues,
    step,
  });
  const isItemsStepValid = checkItemsStep({ dispatch, items, step });
  const isPaymentMethodStepValid = checkPaymentMethodStep({
    creditNotePayments,
    dispatch,
    invoiceStatus,
    invoiceType,
    isPrimaryCareSpeciality,
    items,
    mode,
    paymentConfigurationValues,
    paymentMethodItems,
    paymentModeValues,
    step,
  });

  if (!isDetailsStepValid || !isPaymentModeStepValid || !isItemsStepValid || !isPaymentMethodStepValid) {
    // Steps are not valid
    return false;
  }

  // Step are valid
  return true;
};

interface CheckDetailsStepParams {
  detailsValues: DetailsFormData;
  dispatch: Dispatch;
  step: InvoicingProcessStep;
}

export const checkDetailsStep = ({ detailsValues, dispatch, step }: CheckDetailsStepParams): boolean => {
  const isSelected = step === InvoicingProcessStep.Details;
  const isValid = isEmpty(validateDetailsForm(detailsValues));

  if (!isValid) {
    if (!isSelected) {
      // Step is not selected
      dispatch(setStep(InvoicingProcessStep.Details));
    }

    // Effects
    delay(
      () => {
        dispatch(touch(FORMS_INVOICING_PROCESS_DETAILS, ...Object.keys(PaymentModeField)));
        scrollToInvalidField();
      },
      isSelected ? 0 : effectsDelay,
    );

    // Step is not valid
    return false;
  }

  // Step is valid
  return true;
};

interface CheckPaymentModeStepParams {
  appointmentId: string | null;
  dispatch: Dispatch;
  invoicingProcess: InvoicingProcess | null;
  items: InvoiceItem[];
  paymentModeValues: PaymentModeFormData;
  step: InvoicingProcessStep;
}

export const checkPaymentModeStep = ({
  appointmentId,
  dispatch,
  invoicingProcess,
  items,
  paymentModeValues,
  step,
}: CheckPaymentModeStepParams): boolean => {
  const isSelected = step === InvoicingProcessStep.PaymentMode;
  const isValid = isEmpty(validatePaymentModeForm(paymentModeValues, { appointmentId, invoicingProcess }));
  const invoicePayBy = paymentModeValues['payBy']?.value;
  const invoiceEligibility = paymentModeValues['eligibility']?.value;
  const isPaymentModeItemsInvalid = checkIsPaymentModeItemsInvalid({ invoiceEligibility, invoicePayBy, items });

  // Default flow
  if (!isValid) {
    if (!isSelected) {
      // Step is not selected
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    // Effects
    delay(
      () => {
        dispatch(touch(FORMS_INVOICING_PROCESS_PAYMENT_MODE, ...Object.keys(PaymentModeField)));
        scrollToInvalidField();
      },
      isSelected ? 0 : effectsDelay,
    );

    // Step is not valid
    return false;
  }

  // Custom flow (items are required for Referral-In and Precious Approved eligibility)
  if (isPaymentModeItemsInvalid) {
    if (!isSelected) {
      // Step is not selected
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    toast.error('ERRORS.ADD-AT-LEAST-ONE-ITEM');
    scrollToInvalidField(); // RequiredSectionHeading has is-invalid class
    return false;
  }

  // Step is valid
  return true;
};

interface CheckItemsStepParams {
  dispatch: Dispatch;
  items: InvoiceItem[];
  step: InvoicingProcessStep;
}

export const checkItemsStep = ({ dispatch, items, step }: CheckItemsStepParams): boolean => {
  const isSelected = step === InvoicingProcessStep.Items;
  const isValid = !!items.length;

  if (!isValid) {
    if (!isSelected) {
      // Step is not selected
      dispatch(setStep(InvoicingProcessStep.PaymentMode));
    }

    // Effects
    delay(
      () => {
        toast.error('ERRORS.ADD-AT-LEAST-ONE-ITEM');
        dispatch(
          touch(FORMS_INVOICING_PROCESS_ADD_ITEM, ItemField.itemSourceType, ItemField.item, ItemField.totalQuantity),
        );
        scrollToInvalidField();
      },
      isSelected ? 0 : effectsDelay,
    );

    // Step is not valid
    return false;
  }

  // Step is valid
  return true;
};

interface CheckPaymentMethodStepParams {
  creditNotePayments: InvoiceCreditNotePayment[];
  dispatch: Dispatch;
  invoiceStatus: InvoiceStatus | undefined;
  invoiceType: InvoiceType | undefined;
  isPrimaryCareSpeciality: boolean;
  items: InvoiceItem[];
  mode: InvoicingProcessMode;
  paymentConfigurationValues: PaymentConfigurationFormData;
  paymentMethodItems: InvoicePaymentMethodItem[];
  paymentModeValues: PaymentModeFormData;
  step: InvoicingProcessStep;
}

export const checkPaymentMethodStep = ({
  creditNotePayments,
  dispatch,
  invoiceStatus,
  invoiceType,
  isPrimaryCareSpeciality,
  items,
  mode,
  paymentConfigurationValues,
  paymentMethodItems,
  paymentModeValues,
  step,
}: CheckPaymentMethodStepParams): boolean => {
  const extendedItems = generateExtendedItems({
    invoiceType,
    isPrimaryCareSpeciality,
    items,
    paymentModeValues,
  });
  const isOverpaid =
    mode === InvoicingProcessMode.CreateCreditNote
      ? false
      : getLeftToPayValue({ creditNotePayments, extendedItems, paymentMethodItems, invoiceStatus }) < 0; // ignore overpaid for credit note
  const isSelected = step === InvoicingProcessStep.PaymentMethod;
  const isFormValid = validatePaymentMethodConfiguration(paymentConfigurationValues);
  const hasItems = !!paymentMethodItems.length;
  const isValid = isFormValid && !isOverpaid;

  if (!isValid) {
    if (!isSelected) {
      // Step is not selected
      dispatch(setStep(InvoicingProcessStep.PaymentMethod));
    }

    // Effects
    delay(
      () => {
        if (!isFormValid) {
          dispatch(touch(FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG, ...Object.keys(PaymentConfigurationField)));
        }

        if (hasItems && isOverpaid) {
          toast.error('ERRORS.OVERPAYMENT-NOT-ALLOWED');
        }

        scrollToInvalidField();
      },
      isSelected ? 0 : effectsDelay,
    );

    // Step is not valid
    return false;
  }

  // Step is valid
  return true;
};
