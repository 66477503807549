import React, { FC, useState } from 'react';
import { reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppSelector } from 'app/helpers';
import { FORMS_BOOK_APPOINTMENT } from 'app/shared';
import { usePatientSearchHandlers } from 'app/shared/patientSearch/helpers/usePatientSearchHandlers';
import { compareTime } from 'app/shared/validation/helpers/compareTime';
import { resetPatient, setPatient } from 'app/redux/book-appointment/book-appointment.actions';
import { SelectedDay } from 'app/redux/calendar/types';
import { CalendarItem, PatientSourceOption } from 'app/types';
import { useCreatePatientHandlers } from 'app/features/appointment/form/helpers';
import { CreatePatientModals } from 'app/features/appointment/shared';
import { useWizardEffects, useWizardHandlers } from 'app/features/bookAppointment/bookAppointmentWizard/helpers';
import {
  PatientStep,
  ReferralSpecialityStep,
  ServiceWithDetailsStep,
  SpecialityStep,
  SubSpecialityStep,
} from 'app/features/bookAppointment/bookAppointmentWizard/steps';
import { PatientSourceStep } from 'app/features/bookAppointment/bookAppointmentWizard/steps/patientSourceStep/patientSourceStep';
import { formConfig } from 'app/features/bookAppointment/shared';
import { BookAppointmentFormData, StepType } from 'app/features/bookAppointment/types';
import { BookingFormField } from 'app/features/bookingWizardModal/types';
import { useLiveRefresh } from 'app/features/calendar/helpers';

export interface BookAppointmentWizardProps {
  currentStep: StepType;
  setCurrentStep: (step: StepType) => void;
  handleFetchBranches: (patientSourceId?: string) => void;
}

const BookAppointmentWizardComponent: FC<
  InjectedFormProps<BookAppointmentFormData, BookAppointmentWizardProps> & BookAppointmentWizardProps
> = ({ currentStep, setCurrentStep, handleFetchBranches }: BookAppointmentWizardProps) => {
  const { selectedDoctor, selectedService, selectedSpeciality, selectedSubSpeciality, referralInfo } = useAppSelector(
    (state) => state.bookAppointment,
  );
  const [hasPatientSources, setHasPatientSources] = useState(false);
  const [selectedDayItem, setSelectedDayItem] = useState<CalendarItem | null>(null);
  const [selectedDay, setSelectedDay] = useState<SelectedDay | null>(null);

  const selectedPatientSource: PatientSourceOption | null = useAppSelector(
    (state) => state.form[formConfig.form]?.values?.[BookingFormField.patientSource],
  );
  const selectedSlot = useAppSelector((state) => state.bookAppointment.selectedSlot);
  const selectedBranch = useAppSelector((state) => state.bookAppointment.selectedBranch);
  const clinicId = selectedSlot?.doctor.clinic?.value;

  const { createdPatient, onAddNewPatient, onResetCreated, setPatientData, initialPhoneNumber } =
    useCreatePatientHandlers({
      formName: formConfig.form,
      setPatient,
      resetPatient,
    });
  const searchHandlers = usePatientSearchHandlers({
    formName: formConfig.form,
    clinicId,
    resetPatient: resetPatient,
    setPatient: setPatient,
  });
  const {
    fetchDoctorFilterOptions,
    handleBranchChange,
    handleCustomizedAppointmentSlot,
    handleDoctorChange,
    handleDoctorFilterChange,
    handleFetchCalendarItems,
    handlePatientSourceChange,
    handleSpecialityChange,
    handleServiceChange,
    handleSubSpecialityChange,
    handleSelectedDay,
    handleTimeChange,
  } = useWizardHandlers({
    selectedBranch,
    setCurrentStep,
    setSelectedDay,
    selectedPatientSource,
    handleFetchBranches,
  });

  useWizardEffects({
    selectedBranch,
    selectedDay,
    selectedPatientSource,
    selectedSlot,
    selectedSpeciality,
    selectedSubSpeciality,
    setCurrentStep,
    setSelectedDay,
    setSelectedDayItem,
    setHasPatientSources,
  });

  useLiveRefresh();

  return (
    <>
      {hasPatientSources && <PatientSourceStep onPatientSourceChange={handlePatientSourceChange} />}
      {currentStep === StepType.Speciality && (
        <>
          {!referralInfo ? (
            <SpecialityStep
              onSpecialityChange={handleSpecialityChange}
              onDoctorChange={handleDoctorChange}
              onBranchChange={handleBranchChange}
              selectedSpeciality={selectedSpeciality}
              selectedDoctor={selectedDoctor}
            />
          ) : (
            <ReferralSpecialityStep
              onSpecialityChange={handleSpecialityChange}
              onDoctorChange={handleDoctorChange}
              onBranchChange={handleBranchChange}
              selectedSpeciality={selectedSpeciality}
              selectedDoctor={selectedDoctor}
            />
          )}
        </>
      )}
      {currentStep === StepType.SubSpeciality && (
        <SubSpecialityStep
          selectedSubSpeciality={selectedSubSpeciality}
          onSubSpecialityChange={handleSubSpecialityChange}
          onBranchChange={handleBranchChange}
        />
      )}
      {currentStep === StepType.ServiceWithWorkingDay && (
        <ServiceWithDetailsStep
          onFetchCalendarItems={handleFetchCalendarItems}
          onDoctorFilterChange={handleDoctorFilterChange}
          onFetchDoctorFilterOptions={fetchDoctorFilterOptions}
          onServiceChange={handleServiceChange}
          onSelectDate={handleSelectedDay}
          onCustomizeAppointmentSlot={handleCustomizedAppointmentSlot}
          onTimeChange={handleTimeChange}
          selectedDayItem={selectedDayItem}
          selectedService={selectedService}
          subSpeciality={selectedSubSpeciality}
        />
      )}
      {currentStep === StepType.Patient && (
        <>
          <PatientStep
            createdPatientMode={!!createdPatient}
            onAddNewPatient={onAddNewPatient}
            resetMode={onResetCreated}
            searchHandlers={searchHandlers}
          />
          <CreatePatientModals
            initialPhoneNumber={initialPhoneNumber}
            clinicId={clinicId}
            setPatientData={setPatientData}
          />
        </>
      )}
    </>
  );
};

export const BookAppointmentWizard = reduxForm<BookAppointmentFormData, BookAppointmentWizardProps>({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: FORMS_BOOK_APPOINTMENT,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate: compareTime,
})(BookAppointmentWizardComponent);
