import { useCallback } from 'react';
import { change } from 'redux-form';
import { useAppDispatch } from 'app/helpers';
import { FORMS_BOOKING_WIZARD } from 'app/shared';
import { PatientInfoShort } from 'app/shared/patientSearch';
import { appointmentToPatientShortAdapter } from 'app/shared/patientSearch/patientShortDetails/helpers';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { setPatient } from 'app/redux/bookingWizard/bookWizard.actions';
import { Appointment, Option } from 'app/types';

export const useSetPatientFromAppointment = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (appointment: Appointment) => {
      const patientData: PatientInfoShort = appointmentToPatientShortAdapter(appointment);
      let guardian: Option | null = appointment.guardianId
        ? { label: appointment.guardianName, value: appointment.guardianId }
        : null;
      dispatch(change(FORMS_BOOKING_WIZARD, PatientSearchField.patientId, patientData.id));
      dispatch(change(FORMS_BOOKING_WIZARD, PatientSearchField.selectedGuardian, guardian));
      dispatch(
        change(FORMS_BOOKING_WIZARD, PatientSearchField.selectedPhoneNumber, {
          label: appointment.patientPhoneNumber,
          value: appointment.patientPhoneNumber,
        }),
      );
      dispatch(setPatient(patientData));
    },
    [dispatch],
  );
};
