import { BillingItemType, ClassBillingItemsGroup, Option, Status } from 'app/types';

export type Service = {
  id: string;
  clinicyId: string;
  note: string;
  activationsCount: number;
  status: Option<Status>;
  nameAr: string;
  nameEn: string;
  subSpeciality: Option<BillingItemType>;
  speciality: Option;
  serviceType: Option<ClassBillingItemsGroup>;
};

export enum ServiceItemsForExportType {
  Default = 0,
  Filtered = 1,
  All = 2,
}
