import { useCallback } from 'react';
import { change } from 'redux-form';
import { useAppDispatch } from 'app/helpers';
import { FORMS_BOOKING_WIZARD } from 'app/shared';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { PreselectedPatient } from 'app/redux/appointment/types';
import { setPatient } from 'app/redux/bookingWizard/bookWizard.actions';

export const useSetPatientFromPreselectedPatient = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (patient: PreselectedPatient) => {
      const defaultNumber = patient.phoneNumbers.find((number) => number.isDefault) || patient.phoneNumbers[0];
      dispatch(change(FORMS_BOOKING_WIZARD, PatientSearchField.patientId, patient.clinicPatientId));
      dispatch(change(FORMS_BOOKING_WIZARD, PatientSearchField.selectedGuardian, null));
      dispatch(
        change(FORMS_BOOKING_WIZARD, PatientSearchField.selectedPhoneNumber, {
          label: defaultNumber.phoneNumber,
          value: defaultNumber.phoneNumber,
        }),
      );
      dispatch(setPatient(patient));
    },
    [dispatch],
  );
};
