import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FORMS_RESET_PASSWORD } from 'app/shared';
import { resetPassword } from 'app/redux/auth/auth.actions';
import { FormComponent } from 'app/features/resetPassword/form/form.component';

const mapStateToProps = ({ router }) => ({
  code: new URLSearchParams(router.location.search).get('token'),
  form: FORMS_RESET_PASSWORD,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values, code) => dispatch(resetPassword({ ...values, code })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
  onSubmit: (values) => dispatchProps.onSubmit(values, stateProps.code),
});

export const FormContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm(),
)(FormComponent);
