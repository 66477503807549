import { useCallback } from 'react';
import classNames from 'classnames';
import { FormatOptionLabelMeta } from 'react-select';
import { getTranslation, useAppIntl } from 'app/helpers';
import { BillingItemSourceType, InvoiceBillingItemOption, Option } from 'app/types';

interface UseItemFormatOptionLabelProps {
  itemSourceType?: Option<BillingItemSourceType>;
  selectedItem?: InvoiceBillingItemOption;
}

export const useItemFormatOptionLabel = ({ selectedItem, itemSourceType }: UseItemFormatOptionLabelProps) => {
  const { locale, formatMessage } = useAppIntl();

  return useCallback(
    (data: InvoiceBillingItemOption, formatOptionLabelMeta: FormatOptionLabelMeta<InvoiceBillingItemOption>) => {
      if (formatOptionLabelMeta.context === 'value') {
        return data.label;
      }

      const isSelected = selectedItem?.value === data.value;

      return (
        <>
          <h6 className="mb-1">{data.label}</h6>
          <div>
            <span className="text-dark">{formatMessage({ id: 'BILLING-ITEMS.LABEL.ITEM-TYPE' })}: </span>
            <span
              className={classNames('text-primary', {
                'text-white': isSelected,
              })}
            >
              {getTranslation(data.details.billingItemType, locale) || getTranslation(itemSourceType, locale)}
            </span>
          </div>
          {itemSourceType?.value !== BillingItemSourceType.Package && (
            <div>
              <span className="text-dark">{formatMessage({ id: 'BILLING-ITEMS-LISTS.LABEL.UNIT-MEASURE' })}: </span>
              <span
                className={classNames('text-primary', {
                  'text-white': isSelected,
                })}
              >
                {data.details.unitMeasure || '-'}
              </span>
            </div>
          )}
          <div>
            <span className="text-dark">{formatMessage({ id: 'BILLING-ITEMS.LABEL.INTERNAL-CODE' })}: </span>
            <span
              className={classNames('text-primary', {
                'text-white': isSelected,
              })}
            >
              {data.details.internalCode || '-'}
            </span>
          </div>
          {itemSourceType?.value !== BillingItemSourceType.Package && (
            <div>
              <span className="text-dark">{formatMessage({ id: 'CORE.LABEL.AVAILABLE-QUANTITY' })}: </span>
              <span
                className={classNames('text-primary', {
                  'text-white': isSelected,
                })}
              >
                {data.details.availableQuantity ?? '-'}
              </span>
            </div>
          )}
        </>
      );
    },
    [formatMessage, selectedItem, itemSourceType, locale],
  );
};
