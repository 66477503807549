import { Option } from 'app/types/Option';
import { SfdaCode } from 'app/types/Prescription';

export interface Medication {
  id: string;
  sourceId?: string;
  clinicyId: string;
  internalCode: string;
  gtinCode: string;
  sfdaCode: SfdaCode | null;
  drugGroup: Option;
  drugType: Option<MedicationDrugType>;
  drugSubType: Option<MedicationDrugSubType> | null;
  drugScientificName: Option;
  drugTradeName: Option;
  replaceableDrug: boolean;
  replaceableDrugs: MedicationItem[];
  strength: Option;
  strengthUnit: Option;
  pharmaceuticalForm: Option;
  administrationRoute: Option;
  unitMeasure: Option;
  measureValue: Option;
  packageType: Option;
  packageSize: Option;
  vatExempted: boolean;
  price: number;
  isControlled: boolean;
  enabled: boolean;
  otherDrugTypeText: string | null;
  otherDrugSubTypeText: string | null;
}

export interface PerformerMedication extends Medication {
  branchBillingItemMedicationId: string;
  performer: PerformerInformation;
}

export interface MedicationOption extends Option {
  details: {
    internalCode: string;
    clinicyId: string;
    sfdaCode: string;
    drugType: Option<MedicationDrugType>;
    drugSubType: Option<MedicationDrugSubType> | null;
    drugScientificName: string;
    drugTradeName: string;
    replicable: boolean;
    strength: string;
    strengthUnit: string;
    administrationRoute: string;
    pharmaceuticalForm: string;
    measureValue: string;
    unitMeasure: string;
    packageType: string;
    packageSize: string;
    saudiVatExempted: boolean;
    price: string;
    otherDrugTypeText: string | null;
    otherDrugSubTypeText: string | null;
  };
}

export type MedicationItem = {
  id: string;
  clinicyId: string;
  sourceId: string;
  internalCode: string;
  drugGroup: Option;
  drugScientificName: string;
  drugType: Option<MedicationDrugType>;
  drugSubType: Option<MedicationDrugSubType> | null;
  drugTradeName: string;
  sfdaCode: Option;
  strength: string;
  strengthUnit: string;
  pharmaceuticalForm: string;
  administrationRoute: string;
  unitMeasure: string;
  measureValue: string;
  packageType: string;
  packageSize: string;
  replaceableDrug: boolean;
  isControlled: boolean;
  vatExempted: boolean;
  price: string;
  enabled: boolean;
  otherDrugTypeText: string | null;
  otherDrugSubTypeText: string | null;
  inventoryLocations: string[];
};

export type PerformerMedicationItem = Omit<MedicationItem, 'price' | 'isControlled'> & {
  branchBillingItemMedicationId: string;
  performerId: string;
};

export enum MedicationDrugType {
  Generic = '0',
  NCE = '1',
  Biological = '2',
  Radiopharmaceutical = '3',
  Other = '4',
}

export enum MedicationDrugSubType {
  AllergenProduct = '0',
  Biological = '1',
  Biosimilar = '2',
  BiotechnologyProduct = '3',
  BloodProduct = '4',
  IVSolution = '5',
  KnownActiveSubstance = '6',
  NewChemicalEntity = '7',
  Vaccine = '8',
  Other = '9',
}

interface PerformerInformation {
  id: string;
  clinicyId: string;
  nameAr: string;
  nameEn: string;
}
