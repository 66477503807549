import * as types from './clinic.types';

const initialState = {
  loading: true,
  error: false,
  list: [],
  item: null,
  systemOwner: null,
  systemOwnerLoading: false,
  specialitesLoading: false,
  specialities: [],
  branches: [],
  branchesLoading: false,
  mode: {},
};

export const clinicReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLINIC_REQUEST:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case types.FETCH_CLINIC_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
      };
    case types.FETCH_CLINIC_FAILURE:
      return {
        ...state,
        item: action.payload,
        loading: false,
        error: true,
      };
    case types.SET_CLINIC:
      return {
        ...state,
        item: action.payload,
      };
    case types.APPROVE_CLINIC_REQUEST:
      return {
        ...state,
      };
    case types.APPROVE_CLINIC_SUCCESS:
      return {
        ...state,
      };
    case types.APPROVE_CLINIC_FAILURE:
      return {
        ...state,
      };
    case types.FETCH_SYSTEM_OWNER_REQUEST:
      return { ...state, systemOwner: initialState.systemOwner, systemOwnerLoading: true };
    case types.FETCH_SYSTEM_OWNER_SUCCESS:
      return { ...state, systemOwnerLoading: false, systemOwner: action.payload.data };
    case types.FETCH_SYSTEM_OWNER_FAILURE:
      return { ...state, systemOwnerLoading: false };
    case types.FETCH_CLINIC_SPECIALITIES_REQUEST:
      return { ...state, specialitesLoading: true };
    case types.FETCH_CLINIC_SPECIALITIES_SUCCESS:
      return { ...state, specialitesLoading: false, specialities: action.payload.data };
    case types.FETCH_CLINIC_SPECIALITIES_FAIL:
      return { ...state, specialitesLoading: false };
    case types.FETCH_CLINIC_BRANCHES_REQUEST:
      return { ...state, branchesLoading: true };
    case types.FETCH_CLINIC_BRANCHES_SUCCESS:
      return { ...state, branchesLoading: false, branches: action.payload.data };
    case types.FETCH_CLINIC_BRANCHES_FAIL:
      return { ...state, branchesLoading: false };
    case types.FETCH_CLINIC_MODE_REQUEST:
      return { ...state, mode: {} };
    case types.FETCH_CLINIC_MODE_SUCCESS:
      return { ...state, mode: action.payload.data };
    case types.FETCH_CLINIC_MODE_FAILURE:
      return { ...state, mode: {} };
    default:
      return state;
  }
};
