import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { CloseButton, ModalBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { Loader } from 'app/shared';
import { setMode } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcessMode, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { Actions } from 'app/features/invoicingProcess/process/actions/actions';
import { Appointment } from 'app/features/invoicingProcess/process/appointment/appointment';
import { Details } from 'app/features/invoicingProcess/process/details/details';
import { Items } from 'app/features/invoicingProcess/process/items/items';
import { PaymentMethod } from 'app/features/invoicingProcess/process/paymentMethod/paymentMethod';
import { PaymentMode } from 'app/features/invoicingProcess/process/paymentMode/paymentMode';
import { Steps } from 'app/features/invoicingProcess/process/steps/steps';
import { Summary } from 'app/features/invoicingProcess/process/summary/summary';
import styles from 'app/features/invoicingProcess/process/process.module.scss';

interface Props {
  closeInvoicingProcess: () => void;
}

export const invoicingProcessContentId = 'invoicingProcessContent';

export const Process = ({ closeInvoicingProcess }: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const loading = useAppSelector((state) => state.invoicingProcess.loading);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);

  const onClose = useCallback(() => {
    if (appointmentId && pathname.includes('appointments')) {
      // Back to the appointment invoice list
      dispatch(setMode(InvoicingProcessMode.Invoices));
    } else {
      closeInvoicingProcess();
    }
  }, [appointmentId, closeInvoicingProcess, dispatch, pathname]);

  return (
    <ModalBody className="p-0">
      {loading && <Loader style={{ height: 400 }} />}
      {!loading && (
        <>
          <div className={styles.wrapper}>
            <div className={styles.sidebar}>
              <Steps />
            </div>
            <hr className="d-block d-lg-none m-0" />
            <div className={styles.content} id={invoicingProcessContentId}>
              <CloseButton className={styles.closeButton} onClick={onClose} />
              {step === InvoicingProcessStep.Details && <Details />}
              {step === InvoicingProcessStep.Appointment && <Appointment />}
              {step === InvoicingProcessStep.PaymentMode && <PaymentMode />}
              {step === InvoicingProcessStep.Items && <Items />}
              {step === InvoicingProcessStep.PaymentMethod && <PaymentMethod />}
              {step === InvoicingProcessStep.Summary && <Summary />}
            </div>
          </div>
          <Actions />
        </>
      )}
    </ModalBody>
  );
};
