import { useCallback, useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { change, Field } from 'redux-form';
import { useAppDispatch, useAppIntl } from 'app/helpers';
import { QuantityInputComponent } from 'app/shared';
import { StorageItem } from 'app/features/inventoryStorage/types';

interface UseStorageColumnsProps {
  form: string;
  storageQuantity: Record<StorageItem['storageId'], number>;
  totalQuantity?: number;
  storageQuantityField: string;
}

interface formatExtraData {
  storageQuantity: Record<StorageItem['storageId'], number>;
  totalQuantity?: number;
}

export const useStorageColumns = ({
  form,
  storageQuantity,
  totalQuantity,
  storageQuantityField,
}: UseStorageColumnsProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();

  const handleAddAllFromHere = useCallback(
    (storageId: string) => {
      Object.keys(storageQuantity).forEach((key) => {
        dispatch(change(form, `${storageQuantityField}.${key}`, key === storageId ? totalQuantity : 0));
      });
    },
    [form, storageQuantity, storageQuantityField, totalQuantity, dispatch],
  );

  const hasSigleQuantity = !totalQuantity || totalQuantity === 1;
  return useMemo<ColumnDescription<StorageItem, formatExtraData>[]>(
    () => [
      {
        dataField: 'storageName',
        text: formatMessage({ id: 'CORE.LABEL.STORAGE-NAME' }),
        formatter: (_, row) => row.storageName || '-',
      },
      {
        dataField: 'storageLocation',
        text: formatMessage({ id: 'CORE.LABEL.STORAGE-LOCATION' }),
        formatter: (_, row) => row.storageLocation || '-',
      },
      {
        dataField: 'contact',
        text: formatMessage({ id: 'CORE.LABEL.CONTACT' }),
        classes: 'text-nowrap',
        formatter: (_, row) => row.storageContact || '-',
      },
      {
        dataField: 'availableQuantity',
        text: formatMessage({ id: 'CORE.LABEL.AVAILABLE-QUANTITY' }),
        classes: 'text-center',
        headerClasses: 'text-center',
        formatter: (_, row) => Math.abs(row.availableQuantity) || 0,
      },
      {
        dataField: 'quantityToTake',
        text: formatMessage({ id: 'CORE.LABEL.QUANTITY-TO-TAKE' }),
        formatter: (_, row) => (
          <Field
            component={QuantityInputComponent}
            name={`${storageQuantityField}.${row.storageId}`}
            size="sm"
            max={Math.abs(row.availableQuantity)}
            noMargin
          />
        ),
        hidden: hasSigleQuantity,
        formatExtraData: {
          storageQuantity,
        },
      },
      {
        dataField: 'actions',
        text: formatMessage({ id: 'CORE.TABLE.ACTIONS' }),
        classes: 'text-center sticky sticky--end',
        headerClasses: 'text-center sticky sticky--end',
        formatter: (_, row, __, formatExtraData) => {
          return (
            <Button
              onClick={() => handleAddAllFromHere(row.storageId)}
              size="sm"
              color="primary"
              type="submit"
              disabled={Math.abs(row.availableQuantity) < (formatExtraData?.totalQuantity ?? 0)}
            >
              {formatMessage({ id: hasSigleQuantity ? 'CORE.LABEL.ADD-FROM-HERE' : 'CORE.LABEL.ADD-ALL-FROM-HERE' })}
            </Button>
          );
        },
        formatExtraData: {
          storageQuantity,
          totalQuantity,
        },
      },
    ],
    [formatMessage, hasSigleQuantity, storageQuantity, totalQuantity, storageQuantityField, handleAddAllFromHere],
  );
};
