import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  ClinicBodyParams,
  ClinicModuleInformation,
  EditClinicSystemOwnerBodyParams,
  GetClinicSystemOwnerResponse,
  IntegrateWithClinicBody,
  RejectInstitutionBodyParams,
} from 'app/services/clinics/types';
import {
  MedicalInstitution,
  MedicalInstitutionOption,
  MedicalInstitutionRequest,
  MedicalInstitutionStatus,
  Option,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RejectedMedicalInstitution,
} from 'app/types';
import { BranchBodyParams } from 'app/features/branch/modals/form/types';
import { ClinicRequestType } from 'app/features/clinicsRequests/types';

export const clinicsApi = createApi({
  reducerPath: 'clinicsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'Clinic',
    'RegisteredClinics',
    'PendingClinics',
    'CurrentRequests',
    'RejectedClinics',
    'DeletedClinics',
    'ClinicRequest',
    'ClinicModulesInformation',
    'ClinicSystemOwner',
  ],
  endpoints: (builder) => ({
    getClinicShortInfo: builder.query<QueryResponse<MedicalInstitutionOption>, string>({
      query: (clinicId) => ({
        method: 'GET',
        url: `/clinics/${clinicId}/short-info`,
      }),
    }),

    getClinic: builder.query<QueryResponse<MedicalInstitution>, string>({
      query: (clinicId) => ({
        method: 'GET',
        url: `clinics/${clinicId}`,
      }),
      providesTags: ['Clinic'],
    }),

    getRegisteredClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            status: params?.status
              ? params.status
              : [MedicalInstitutionStatus.Online, MedicalInstitutionStatus.Blocked, MedicalInstitutionStatus.Offline],
          },
        };
      },
      providesTags: ['RegisteredClinics'],
    }),

    getDeletedClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            statuses: [MedicalInstitutionStatus.Deleted],
          },
        };
      },
      providesTags: ['DeletedClinics'],
    }),

    getRejectedClinics: builder.query<
      QueryResponseWithPagination<RejectedMedicalInstitution[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'rejected-clinics',
        params,
      }),
      providesTags: ['RejectedClinics'],
    }),

    getPendingClinics: builder.query<QueryResponseWithPagination<MedicalInstitution[]>, QueryParams | undefined>({
      query: (params) => {
        return {
          url: 'clinics',
          params: {
            ...params,
            statuses: [MedicalInstitutionStatus.Pending],
          },
        };
      },
      providesTags: ['PendingClinics'],
    }),

    approveClinic: builder.mutation<void, { clinicId: string }>({
      query: ({ clinicId }) => ({
        url: `clinics/${clinicId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['PendingClinics', 'RejectedClinics'],
    }),

    rejectClinic: builder.mutation<void, { clinicId: string; body: RejectInstitutionBodyParams }>({
      query: ({ clinicId, body }) => ({
        url: `clinics/${clinicId}/reject`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PendingClinics'],
    }),

    getCurrentClinicsRequests: builder.query<
      QueryResponseWithPagination<MedicalInstitutionRequest[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'requests',
        method: 'GET',
        params,
      }),
      providesTags: ['CurrentRequests'],
    }),

    getPastClinicsRequests: builder.query<
      QueryResponseWithPagination<MedicalInstitutionRequest[]>,
      QueryParams | undefined
    >({
      query: (params) => ({
        url: 'requests/history',
        method: 'GET',
        params,
      }),
    }),

    getPatientClinics: builder.query<
      QueryResponseWithPagination<MedicalInstitution[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({
        url: `patients/${patientId}/global-booking-statistic/clinics`,
        method: 'GET',
        params,
      }),
    }),

    changeClinicStatus: builder.mutation<void, { clinicId: string; body: any }>({
      query: ({ clinicId, body }) => ({
        url: `/clinics/${clinicId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Clinic'],
    }),

    editClinic: builder.mutation<void, { body: ClinicBodyParams; clinicId: string }>({
      query: ({ body, clinicId }) => ({
        url: `/clinics/${clinicId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Clinic', 'PendingClinics', 'ClinicModulesInformation'],
    }),

    getClinicSystemOwner: builder.query<QueryResponse<GetClinicSystemOwnerResponse>, string>({
      query: (clinicId) => ({
        url: `/clinics/${clinicId}/systemOwner`,
        method: 'GET',
      }),
      providesTags: ['ClinicSystemOwner'],
    }),

    editClinicSystemOwner: builder.mutation<void, { clinicId: string; body: EditClinicSystemOwnerBodyParams }>({
      query: ({ clinicId, body }) => ({
        url: `/clinics/${clinicId}/systemOwner`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ClinicSystemOwner'],
    }),

    editClinicRequestClinic: builder.mutation<void, { body: ClinicBodyParams; requestId: string }>({
      query: ({ body, requestId }) => ({
        url: `/requests/${requestId}`,
        method: 'PUT',
        body: { editClinic: body },
      }),
      invalidatesTags: ['ClinicRequest'],
    }),

    editClinicRequestBranch: builder.mutation<
      void,
      {
        body: BranchBodyParams;
        requestId: string;
        requestType: Option<ClinicRequestType>;
      }
    >({
      query: ({ body, requestId, requestType }) => ({
        url: `/requests/${requestId}`,
        method: 'PUT',
        body: requestType.value === ClinicRequestType.EditBranch ? { editBranch: body } : { addBranch: body },
      }),
      invalidatesTags: ['ClinicRequest'],
    }),

    getClinicRequest: builder.query<QueryResponse<MedicalInstitutionRequest>, { requestId: string }>({
      query: ({ requestId }) => ({
        url: `requests/${requestId}`,
        method: 'GET',
      }),
      providesTags: ['ClinicRequest'],
    }),

    approveClinicRequest: builder.mutation<void, { requestId: string }>({
      query: ({ requestId }) => ({
        url: `requests/${requestId}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: ['Clinic', 'CurrentRequests', 'ClinicModulesInformation'],
    }),

    rejectClinicRequest: builder.mutation<void, { requestId: string }>({
      query: ({ requestId }) => ({
        url: `/requests/${requestId}/reject`,
        method: 'PUT',
      }),
      invalidatesTags: ['CurrentRequests'],
    }),

    cancelClinicRequest: builder.mutation<void, { requestId: string }>({
      query: ({ requestId }) => ({
        method: 'PUT',
        url: `/requests/${requestId}/cancel`,
      }),
      invalidatesTags: ['CurrentRequests'],
    }),

    integrateWithClinic: builder.mutation<void, { body: IntegrateWithClinicBody }>({
      query: ({ body }) => ({
        url: 'integration/clinic',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Clinic'],
    }),

    getClinicModulesInformation: builder.query<QueryResponse<ClinicModuleInformation>, string>({
      query: (clinicId) => ({
        method: 'GET',
        url: `/clinics/${clinicId}/modules`,
      }),
      providesTags: ['ClinicModulesInformation'],
    }),
  }),
});

export const {
  useApproveClinicRequestMutation,
  useRejectClinicRequestMutation,
  useCancelClinicRequestMutation,

  useGetClinicRequestQuery,
  useEditClinicRequestBranchMutation,
  useEditClinicRequestClinicMutation,
  useGetClinicSystemOwnerQuery,
  useEditClinicSystemOwnerMutation,
  useEditClinicMutation,
  useGetClinicQuery,
  useApproveClinicMutation,
  useGetCurrentClinicsRequestsQuery,
  useGetDeletedClinicsQuery,
  useGetPastClinicsRequestsQuery,
  useGetPatientClinicsQuery,
  useGetPendingClinicsQuery,
  useGetRegisteredClinicsQuery,
  useGetRejectedClinicsQuery,
  useRejectClinicMutation,
  useChangeClinicStatusMutation,
  useIntegrateWithClinicMutation,
  useGetClinicModulesInformationQuery,
  useGetClinicShortInfoQuery,
} = clinicsApi;
