import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import {
  createDraftWithAppointment,
  createDraftWithoutAppointment,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { bodyAdapter } from 'app/features/invoicingProcess/process/actions/helpers/bodyAdapter';
import { checkPaymentModeStep } from 'app/features/invoicingProcess/process/actions/helpers/checkSteps';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const useOnSaveAsDraft = () => {
  const dispatch = useAppDispatch();
  const { step, invoicePackageSnapshotId, reservationId } = useInvoicingProcessState();
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const invoiceRequestId = useAppSelector((state) => state.invoicingProcess.requestId);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const saveAsDraftLoading = useAppSelector((state) => state.invoicingProcess.saveAsDraftLoading);
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;
  const paymentConfigurationValues = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG]?.values,
  ) as PaymentConfigurationFormData | undefined;
  const declarationValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_DECLARATION]?.values) as
    | DeclarationFormData
    | undefined;

  const onSaveAsDraft = useCallback(async () => {
    if (invoicingProcess && step && paymentModeValues) {
      const doctorId = invoicingProcess.doctor.value;
      const isPrimaryCareSpeciality = invoicingProcess.doctor.details?.isPrimaryCareSpeciality;
      const patientId = invoicingProcess.patient?.id;
      const guardianPatientId = invoicingProcess.guardianPatient?.id;
      const branchId = invoicingProcess.clinicBranch.value;
      const draftInvoiceId = invoicingProcess.draftInvoiceId;
      const invoiceType = invoicingProcess.invoiceType?.value;

      const isPaymentModeStepValid = checkPaymentModeStep({
        appointmentId,
        dispatch,
        invoicingProcess,
        items,
        paymentModeValues,
        step,
      });

      if (isPaymentModeStepValid && paymentConfigurationValues) {
        const body = bodyAdapter({
          declarationValues,
          draftInvoiceId,
          guardianPatientId,
          invoicePackageSnapshotId,
          invoiceRequestId,
          invoiceType,
          isPrimaryCareSpeciality,
          items,
          paymentConfigurationValues,
          paymentMethodItems,
          paymentModeValues,
          reservationId,
        });

        if (appointmentId) {
          dispatch(createDraftWithAppointment(appointmentId, body));
        } else if (branchId && doctorId && patientId) {
          dispatch(createDraftWithoutAppointment(branchId, doctorId, patientId, body));
        }
      }
    }
  }, [
    appointmentId,
    declarationValues,
    dispatch,
    invoicePackageSnapshotId,
    invoiceRequestId,
    invoicingProcess,
    items,
    paymentConfigurationValues,
    paymentMethodItems,
    paymentModeValues,
    reservationId,
    step,
  ]);

  return {
    onSaveAsDraft,
    saveAsDraftLoading,
  };
};
