import { useMemo } from 'react';
import { formattedDate, getDoctorNameWithTitle, getTranslation, useAppIntl } from 'app/helpers';
import { getProductTranslation } from 'app/helpers/getProductTranslation/getProductTranslation';
import { ProtectedPhoneNumber } from 'app/components';
import { FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { Appointment } from 'app/types';
import { DateAndTime } from 'app/features/appointment/shared';
import { AppointmentReferral, AppointmentStatusWithAction } from 'app/features/appointment/ticket/components';

export const useAppointmentDetailItems = (
  appointment: Appointment | null | undefined,
  isWaiting: boolean,
  statusChangeAllowed?: boolean,
) => {
  const { formatMessage, locale } = useAppIntl();
  return useMemo(
    () =>
      appointment
        ? [
            ...(!isWaiting && appointment.status
              ? [
                  {
                    label: formatMessage({ id: 'CORE.LABEL.STATUS' }),
                    value: (
                      <AppointmentStatusWithAction
                        appointment={appointment}
                        statusChangeAvailable={statusChangeAllowed}
                      />
                    ),
                  },
                ]
              : []),
            {
              label: formatMessage({ id: 'CORE.TABLE.DATE-AND-TIME' }),
              value: <DateAndTime appointment={appointment} conflictingLabel={false} inline />,
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.PATIENT-NAME' }),
              value: appointment.guardianName || appointment.patientName,
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
              value: <ProtectedPhoneNumber phoneNumber={appointment.patientPhoneNumber} withoutProtection />,
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.PATIENT-INTERNAL-FILE-NUMBER' }),
              value: appointment.internalFileNumber,
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
              value: getDoctorNameWithTitle(appointment.doctorName, appointment.doctorTitle, locale),
            },
            {
              label: formatMessage({ id: 'CORE.TABLE.MEDICAL-INSTITUTION' }),
              value: getTranslation(appointment.clinic, locale),
            },
            {
              label: formatMessage({ id: 'CORE.TABLE.BRANCH' }),
              value: getTranslation(appointment.branch, locale),
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SPECIALITY' }),
              value: getTranslation(appointment.speciality, locale),
            },
            {
              label: formatMessage({ id: 'CORE.TABLE.MEDICAL-SERVICE' }),
              value: getTranslation(appointment.subSpeciality, locale),
            },
            {
              label: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
              value: getProductTranslation(appointment?.product, locale, 'nameInAppointmentTicket'),
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.CREATOR' }),
              value: appointment.createdBy,
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.CREATE-DATE' }),
              value: formattedDate(appointment.createdAt, FORMATS_EXTENDED_DATE_AND_TIME),
            },
            {
              label: formatMessage({ id: 'REFERRALS.TEXT.REFERRAL-DETAILS' }),
              value: appointment.referralId && <AppointmentReferral referralId={appointment.referralId} />,
            },
          ]
        : [],
    [appointment, formatMessage, isWaiting, locale, statusChangeAllowed],
  );
};
