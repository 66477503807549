import { useCallback } from 'react';
import moment from 'moment';
import { getApiDate, useAppDispatch, useAppSelector } from 'app/helpers';
import { fetchAppointment } from 'app/redux/appointment/appointment.actions';
import {
  fetchDoctor,
  fetchDoctorSubSpecialities,
  fetchDoctorWorkingTimes,
  selectWorkingTime,
  setInitialData,
} from 'app/redux/bookingWizard/bookWizard.actions';
import { FETCH_WORKING_TIMES_SUCCESS } from 'app/redux/bookingWizard/bookWizard.types';
import { WorkingTime } from 'app/redux/bookingWizard/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { InitialBookingData } from 'app/features/bookingWizardModal/types';

export const useBookingWizard = () => {
  const dispatch = useAppDispatch();
  const fullEdit = useAppSelector((state) => state.appointment.fullEdit.data);
  const rebook = useAppSelector((state) => state.appointment.rebook.data);

  const fetchAndSelectWorkingTime = useCallback(
    async (doctorId: string, branchId: string, date: Date, workingTimeId: string, isNoTime: boolean) => {
      const response: any = await dispatch(fetchDoctorWorkingTimes(doctorId, branchId, getApiDate(date)));
      if (response.type === FETCH_WORKING_TIMES_SUCCESS) {
        const workingTime = response.payload.data.find((wt: WorkingTime) => {
          return isNoTime
            ? wt.id === workingTimeId
            : wt.id === workingTimeId && moment(date).isBetween(wt.startTime, wt.endTime, 'minute', '[)');
        });
        if (workingTime) {
          dispatch(selectWorkingTime(workingTime));
        } else {
          dispatch(selectWorkingTime(response.payload.data[0]));
        }
      }
    },
    [dispatch],
  );

  return useCallback(
    (data: InitialBookingData) => {
      dispatch(
        setInitialData({
          branchId: data.branchId,
          timePeriod: { from: data.start, to: data.end },
          isWaiting: data.isWaiting,
          appointmentId: rebook?.id || fullEdit?.id || data.appointmentId,
        }),
      );

      dispatch(fetchDoctor(data.doctorId));
      if (data.workingTimeId) {
        dispatch(fetchDoctorSubSpecialities(data.workingTimeId));

        const isNoTime = !data.isWaiting && !data.end;
        fetchAndSelectWorkingTime(data.doctorId, data.branchId, data.start, data.workingTimeId, isNoTime).then();
      }
      if (data.appointmentId) {
        dispatch(fetchAppointment(data.appointmentId, data.isWaiting));
      }
      dispatch(toggleModal('bookingWizard', true));
    },
    [dispatch, fetchAndSelectWorkingTime, fullEdit, rebook],
  );
};
