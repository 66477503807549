import { useMemo } from 'react';
import { Row } from 'reactstrap';
import { formattedDate, getDoctorNameWithTitle, getTranslation, useAppIntl, useAppSelector } from 'app/helpers';
import { DescriptionList } from 'app/components';
import { FORMATS_EXTENDED_DATE, FORMATS_EXTENDED_DATE_AND_TIME } from 'app/shared';
import { Appointment, DoctorRank, Locale } from 'app/types';
import { Location } from 'app/features/confirm-appointment/location/location';

interface Props {
  lang: Locale;
}

export const Items = ({ lang }: Props) => {
  const { formatMessage } = useAppIntl();
  const appointment: Appointment | null = useAppSelector((state) => state.appointments.confirmation.data);

  const items = useMemo(() => {
    if (!appointment) {
      return [];
    }

    return [
      ...(appointment.status
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.STATUS' }),
              value: getTranslation(appointment.status, lang),
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CONFIRMATION.DATE-TIME' }),
        value: formattedDate(
          appointment.start,
          appointment.end ? FORMATS_EXTENDED_DATE_AND_TIME : FORMATS_EXTENDED_DATE,
        ),
      },
      {
        label: formatMessage({ id: 'CONFIRMATION.PATIENT-NAME' }),
        value: appointment.guardianName || appointment.patientName,
      },
      {
        label: formatMessage({ id: 'CONFIRMATION.BRANCH' }),
        value: getTranslation(appointment.branch, lang),
      },
      {
        label: formatMessage({ id: 'CONFIRMATION.DOCTOR-NAME' }),
        value: getDoctorNameWithTitle(appointment.doctorName, appointment.doctorTitle, lang),
      },
      ...(appointment.doctorRank.value !== DoctorRank.None
        ? [
            {
              label: formatMessage({ id: 'CONFIRMATION.RANK' }),
              value: getTranslation(appointment.doctorRank, lang),
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.LABEL.COORDINATION-NUMBER' }),
        value: appointment.coordinatorPhoneNumber,
      },
      {
        label: formatMessage({ id: 'CONFIRMATION.SPECIALITY' }),
        value: getTranslation(appointment.speciality, lang),
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.MEDICAL-SERVICE' }),
        value: getTranslation(appointment.subSpeciality, lang),
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.ADDRESS' }),
        value: appointment.branchAddress,
      },
      {
        label: formatMessage({ id: 'CONFIRMATION.LOCATION' }),
        value: <Location appointment={appointment} />,
      },
    ].filter((item) => item.value);
  }, [appointment, formatMessage, lang]);

  return (
    <Row tag="dl">
      <DescriptionList items={items} />
    </Row>
  );
};
