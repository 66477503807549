import moment from 'moment';
import { Button } from 'reactstrap';
import { formatDateToTime, formattedPeriod, getApiDateAndTime } from 'app/helpers';
import { FORMATS_DATE_AND_TIME, FORMATS_TIME } from 'app/shared';
import { CalendarSlot } from 'app/types';

interface Props {
  date: string;
  productDuration: number;
  selectSlot: (date: string, slot?: CalendarSlot) => void;
  slots: CalendarSlot[];
  workingTimeStart: string;
  workingTimeEnd: string;
}

const generateCustomEnd = (start: string, workingTimeEnd: string, productDuration: number): string => {
  const end = moment(start).add(productDuration, 'minute');
  return end.isAfter(moment(workingTimeEnd)) ? workingTimeEnd : getApiDateAndTime(end.toDate()); // end cannot extend beyond working time
};

export const TimeFormatterSlots = ({
  date,
  productDuration,
  selectSlot,
  slots,
  workingTimeStart,
  workingTimeEnd,
}: Props) => {
  return (
    <div className="timeFormatter__slots">
      {slots.map((slot) => {
        const numberOfAppointments = slot.overlappingAppointmentsIds.length;
        const hasAppointments = !!numberOfAppointments;
        const end = hasAppointments ? generateCustomEnd(slot.start, workingTimeEnd, productDuration) : slot.end;
        const isSameDay = moment(workingTimeStart).isSame(end, 'day');
        const title = formattedPeriod(slot.start, end, isSameDay ? FORMATS_TIME : FORMATS_DATE_AND_TIME);

        return (
          <Button
            className="timeFormatter__slot"
            color={hasAppointments ? 'danger' : 'success'}
            key={slot.start}
            onClick={() => selectSlot(date, { ...slot, end })}
            outline
            title={title}
          >
            {formatDateToTime(slot.start)}
            {!!numberOfAppointments && ` (${numberOfAppointments})`}
          </Button>
        );
      })}
    </div>
  );
};
