import { useCallback, useEffect, useRef, useState } from 'react';
import {
  IconCalendarEvent,
  IconCheckbox,
  IconEye,
  IconEyeOff,
  IconInfoCircle,
  IconPencil,
  IconSquareX,
  IconX,
} from '@tabler/icons-react';
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector, useClickOutside, useCurrentUser, useEscape } from 'app/helpers';
import { IconButton } from 'app/components';
import { appointmentToPatientShortAdapter } from 'app/shared/patientSearch/patientShortDetails/helpers';
import {
  cancelAppointment,
  cancelWaitingAppointment,
  changeAppointmentStatus,
  fetchWaitingAppointmentDetails,
  setAppointment,
  setPatient,
  setTicket,
} from 'app/redux/appointment/appointment.actions';
import { StatusChangeValue } from 'app/redux/appointment/types';
import { appointmentCheckIn, appointmentUncheckIn } from 'app/redux/appointments/appointments.actions';
import { selectEvent } from 'app/redux/calendar/calendar.actions';
import { toggleConfirmationModal, toggleModal } from 'app/redux/modals/modals.actions';
import { setVerificationProcess } from 'app/redux/patient/patientSlice';
import { RequireVerificationType } from 'app/redux/patient/types';
import { Appointment, CalendarType, DayCalendarAppointment } from 'app/types';
import { useBookingWizard } from 'app/features/bookingWizardModal/helpers';
import { useEventDetails } from 'app/features/calendar/components/calendar/eventToolbar/helpers';
import styles from 'app/features/calendar/components/calendar/eventToolbar/eventToolbar.module.scss';

interface Props {
  loading: boolean;
}

const ICON_SIZE = 24;
const STROKE_WIDTH = 1.8;

export const EventToolbar = ({ loading }: Props) => {
  const dispatch = useAppDispatch();
  const toolbarRef = useRef(null);
  const {
    currentUser: { allowedForEditAppointments, allowedForAppointmentsActions },
  } = useCurrentUser();
  const openBookingWizard = useBookingWizard();
  const calendarType = useAppSelector((state) => state.calendar.type);
  const selectedEvent = useAppSelector((state) => state.calendar.selectedEvent);
  const isWaiting = calendarType === CalendarType.Waiting;

  // Event details
  const {
    canBeModified,
    eventDetails,
    eventStart,
    hasInvoice,
    isAttended,
    isCheckedIn,
    isConflicting,
    isNoShow,
    isNoTime,
    isPast,
  } = useEventDetails(selectedEvent);

  // Loading state
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [moveToRegularLoading, setMoveToRegularLoading] = useState(false);

  // Disabled rules
  const isMax12HoursDiff = Math.abs(moment(eventStart).diff(moment(), 'minutes')) < 12 * 60;

  // Disabled state
  const isCancelDisabled =
    !isConflicting && ((isPast && !isNoTime) || isCheckedIn || hasInvoice || !allowedForAppointmentsActions);
  const isChangeCheckInDisabled =
    !canBeModified ||
    isConflicting ||
    !isMax12HoursDiff ||
    hasInvoice ||
    loading ||
    checkInLoading ||
    !allowedForAppointmentsActions;
  const isChangeStatusDisabled =
    !canBeModified ||
    isConflicting ||
    isNoTime ||
    !(isAttended || isNoShow) ||
    loading ||
    changeStatusLoading ||
    !allowedForAppointmentsActions;
  const isEditDisabled = (!canBeModified && !isWaiting) || isCheckedIn || hasInvoice || !allowedForEditAppointments;
  const isMoveToRegularDisabled = moveToRegularLoading || !allowedForEditAppointments;

  // Actions
  const onTicket = useCallback(() => {
    if (eventDetails) {
      dispatch(setTicket({ id: eventDetails.appointment.id, isWaiting }));
      dispatch(toggleModal('appointmentTicket'));
    }
  }, [dispatch, eventDetails, isWaiting]);

  const onEdit = useCallback(() => {
    if (eventDetails) {
      openBookingWizard({
        appointmentId: eventDetails.appointment.id,
        branchId: eventDetails.branchId,
        doctorId: eventDetails.doctorId,
        end: eventDetails.appointment.end ? moment(eventDetails.appointment.end).toDate() : undefined,
        isWaiting,
        start: moment(eventDetails.appointment.start).toDate(),
        workingTimeId: eventDetails.workingTime?.id,
      });
    }
  }, [eventDetails, isWaiting, openBookingWizard]);

  const onCancel = useCallback(() => {
    if (eventDetails) {
      dispatch(
        toggleConfirmationModal(
          () =>
            dispatch(
              isWaiting
                ? cancelWaitingAppointment(eventDetails.appointment.id)
                : cancelAppointment(eventDetails.appointment.id),
            ),
          'APPOINTMENTS.TEXT.CANCEL-CONFIRMATION',
        ),
      );
    }
  }, [dispatch, eventDetails, isWaiting]);

  const onChangeCheckIn = useCallback(async () => {
    if (eventDetails) {
      const appointment = eventDetails.appointment as DayCalendarAppointment;

      setCheckInLoading(true);
      if (eventDetails.appointment.isVerificationRequired && !appointment.checkedIn) {
        dispatch(
          setVerificationProcess({
            appointmentId: eventDetails.appointment.id,
            patientId: eventDetails.appointment.patient.value,
            clinicId: eventDetails.appointment.patient.details.clinicId,
            actionType: RequireVerificationType.CheckIn,
          }),
        );
        dispatch(toggleModal('patientVerification', true));
      } else {
        await dispatch(
          appointment.checkedIn
            ? appointmentUncheckIn(eventDetails.appointment.id)
            : appointmentCheckIn(eventDetails.appointment.id),
        );
      }
      setCheckInLoading(false);
    }
  }, [dispatch, eventDetails]);

  const onChangeStatus = useCallback(async () => {
    if (eventDetails && (isAttended || isNoShow)) {
      setChangeStatusLoading(true);
      await dispatch(
        changeAppointmentStatus(eventDetails.appointment.id, {
          status: isNoShow ? StatusChangeValue.Attended : StatusChangeValue.NoShow,
        }),
      );
      setChangeStatusLoading(false);
    }
  }, [dispatch, eventDetails, isNoShow, isAttended]);

  const onMoveToRegular = useCallback(async () => {
    setMoveToRegularLoading(true);

    if (eventDetails) {
      const res = await dispatch(fetchWaitingAppointmentDetails(eventDetails.appointment.id));
      // @ts-ignore
      const appointment: Appointment | null = res?.payload?.data || null;

      if (appointment) {
        await dispatch(setAppointment(appointment));
        dispatch(setPatient(appointmentToPatientShortAdapter(appointment)));
        dispatch(toggleModal('promoteWaitingAppointment', true));
      }
    }

    setMoveToRegularLoading(false);
  }, [dispatch, eventDetails]);

  const close = useCallback(() => {
    dispatch(selectEvent(null));
  }, [dispatch]);

  // Effects
  useEffect(() => {
    if (selectedEvent && eventDetails === undefined) {
      // Remove toolbar if selectedEvent does not exist
      dispatch(selectEvent(null));
    }
  }, [dispatch, eventDetails, selectedEvent]);

  useClickOutside(toolbarRef, close);
  useEscape(close);

  return (
    <CSSTransition
      classNames={{
        enter: styles.toolbarEnter,
        enterActive: styles.toolbarEnterActive,
        exit: styles.toolbarExit,
        exitActive: styles.toolbarExitActive,
      }}
      in={!!selectedEvent}
      timeout={250}
      unmountOnExit
    >
      <div className={styles.wrapper}>
        <div className={styles.toolbar} ref={toolbarRef}>
          <IconButton
            className="me-1 pe-auto"
            icon={<IconInfoCircle size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}
            onClick={onTicket}
            tooltipMessageId="CORE.LABEL.TICKET"
          />
          <IconButton
            className="me-1 pe-auto"
            disabled={isEditDisabled}
            icon={<IconPencil size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}
            onClick={onEdit}
            tooltipMessageId={allowedForEditAppointments ? 'CORE.BUTTON.EDIT' : 'CORE.TEXT.PERMISSION-DENIED-ACTION'}
          />
          <IconButton
            className="me-1 pe-auto"
            disabled={isCancelDisabled}
            icon={<IconX size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}
            onClick={onCancel}
            tooltipMessageId={
              allowedForAppointmentsActions ? 'CORE.BUTTON.CANCEL' : 'CORE.TEXT.PERMISSION-DENIED-ACTION'
            }
          />
          {!isWaiting && (
            <>
              <IconButton
                className="me-1 pe-auto"
                disabled={isChangeCheckInDisabled}
                icon={
                  isCheckedIn ? (
                    <IconSquareX size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />
                  ) : (
                    <IconCheckbox size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />
                  )
                }
                onClick={onChangeCheckIn}
                tooltipMessageId={
                  !allowedForAppointmentsActions
                    ? 'CORE.TEXT.PERMISSION-DENIED-ACTION'
                    : isCheckedIn
                    ? 'APPOINTMENTS.BUTTON.CANCEL-CHECK-IN'
                    : 'APPOINTMENTS.BUTTON.CHECK-IN'
                }
              />
              <IconButton
                className="me-1 pe-auto"
                disabled={isChangeStatusDisabled}
                icon={
                  isNoShow ? (
                    <IconEye size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />
                  ) : (
                    <IconEyeOff size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />
                  )
                }
                onClick={onChangeStatus}
                tooltipMessageId={
                  !allowedForAppointmentsActions
                    ? 'CORE.TEXT.PERMISSION-DENIED-ACTION'
                    : isNoShow
                    ? 'APPOINTMENTS.TEXT.ATTENDED'
                    : 'APPOINTMENTS.TEXT.NO-SHOW'
                }
              />
            </>
          )}
          {isWaiting && (
            <IconButton
              className="me-1 pe-auto"
              disabled={isMoveToRegularDisabled}
              icon={<IconCalendarEvent size={ICON_SIZE} strokeWidth={STROKE_WIDTH} />}
              onClick={onMoveToRegular}
              tooltipMessageId={
                allowedForEditAppointments
                  ? 'APPOINTMENTS.BUTTON.MOVE-TO-REGULAR'
                  : 'CORE.TEXT.PERMISSION-DENIED-ACTION'
              }
            />
          )}
          <Button className="small ms-2 p-1" close onClick={close} />
        </div>
      </div>
    </CSSTransition>
  );
};
