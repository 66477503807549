import moment from 'moment';
import { formattedDate, formattedPeriod } from 'app/helpers';
import { WorkingTimeOption } from 'app/features/appointment/form/types';

/**
 * @example
 * generateWorkingTimeOption('ID', '0000-00-00T00:00:00', '0000-00-00T00:00:00')
 */
export const generateWorkingTimeOption = (id: string, start: string, end: string): WorkingTimeOption => {
  const startDate = moment(start).toDate();
  const endDate = moment(end).toDate();
  const isSameDay = moment(endDate).isSame(moment(endDate), 'day');
  let label = formattedPeriod(startDate, endDate);

  if (!isSameDay) {
    label += ` (${formattedDate(endDate)})`;
  }

  return {
    label,
    value: id,
    details: {
      from: startDate,
      to: endDate,
    },
  };
};
