export enum ClinicRequestType {
  EditClinic = '0',
  EditBranch = '1',
  AddBranch = '2',
}

export enum ClinicRequestStatus {
  Approved = '0',
  Rejected = '1',
  Pending = '2',
  Cancelled = '3',
}
