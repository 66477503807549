import { InvoiceItem } from 'app/types';

export const formatInvoicingProcessStorageReservationItems = (billingItems: InvoiceItem[]) => {
  return billingItems.flatMap((item) => {
    if (!item.storageQuantity || Object.keys(item.storageQuantity).length === 0 || !item.billingItem.clinicyId) {
      return [];
    }

    return Object.entries(item.storageQuantity)
      .filter(([_, quantity]) => quantity > 0)
      .map(([storageId, quantity]) => ({
        itemClinicyId: item.billingItem.clinicyId ?? '',
        storageId,
        reservedQty: quantity,
      }));
  });
};
