import { useMemo } from 'react';
import { getPriceLocaleString } from 'app/helpers';
import styles from 'app/components/formattedPrice/formattedPrice.module.scss';

interface Props {
  amount: number | string | null;
  withSign?: boolean;
}

export const FormattedPrice = ({ amount, withSign = false }: Props) => {
  const amountValue = useMemo(() => getPriceLocaleString(amount, withSign), [amount, withSign]);

  return (
    <div className={styles.priceContainer}>
      <span className={styles.currency}>SAR</span>
      <span> {amountValue}</span>
    </div>
  );
};
