import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import {
  Alert,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupText,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import {
  getPriceLocaleString,
  PerformerBillingItemsDropdownParams,
  useAppDispatch,
  useAppIntl,
  useAppSelector,
  useDictionaries,
  useFetchPerformerBillingItemsOptions,
} from 'app/helpers';
import { percentageNormalizer } from 'app/helpers/normalizers/percentageNormalizer';
import { quantityNormalizer } from 'app/helpers/normalizers/quantityNormalizer';
import { useBranchERPSettings } from 'app/helpers/useBranchERPSettings/useBranchERPSettings';
import { SarCurrencySymbol } from 'app/components/sarCurrencySymbol/sarCurrencySymbol';
import {
  AsyncSelectComponent,
  FORMS_INVOICING_PROCESS_EDIT_ITEM,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
  InputComponent,
  QuantityInputComponent,
  required,
  SelectComponent,
} from 'app/shared';
import { toggleModal } from 'app/redux/modals/modals.actions';
import {
  BillingItemNameScope,
  BillingItemSourceType,
  BillingItemType,
  ErrorType,
  InvoiceBillingItemOption,
  InvoiceDiscountType,
  InvoiceEligibility,
  InvoicePayBy,
  Option,
} from 'app/types';
import { StorageTable } from 'app/features/inventoryStorage/storageTable/storageTable';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { useItemFormatOptionLabel } from 'app/features/invoicingProcess/process/items/itemForm/helpers/useItemFormatOptionLabel';
import { useLimits } from 'app/features/invoicingProcess/process/items/itemForm/helpers/useLimits';
import { validate } from 'app/features/invoicingProcess/process/items/itemForm/helpers/validate';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export interface ItemFormProps {
  className?: string;
  invoicePackageSnapshotId?: string | null;
  invoicePayBy?: InvoicePayBy;
}

const FormComponent = ({
  change,
  className,
  error,
  form,
  handleSubmit,
}: ItemFormProps & InjectedFormProps<ItemFormData, ItemFormProps, ErrorType>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { 'billing-item-source-types': itemSourceTypes } = useDictionaries();
  const { isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const isEditMode = form === FORMS_INVOICING_PROCESS_EDIT_ITEM;
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const doctorId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.doctor.value);
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);

  const items = useAppSelector((state) => state.invoicingProcess.items);
  const itemSourceType: Option<BillingItemSourceType> | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.itemSourceType],
  );
  const item: InvoiceBillingItemOption | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.item],
  );
  const totalQuantity: ItemFormData['totalQuantity'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.totalQuantity],
  );
  const storageQuantity: ItemFormData['storageQuantity'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.storageQuantity],
  );
  const discountType: InvoiceDiscountType | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.discountType],
  );
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const insuranceId: PaymentModeFormData['insuranceCompany']['value'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceCompany]?.value,
  );

  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;
  const isEligible = eligibility?.value === InvoiceEligibility.Eligible;
  const isReferralInOrPreviousApproval =
    isPayByInsurance &&
    (eligibility?.value === InvoiceEligibility.ReferralIn ||
      eligibility?.value === InvoiceEligibility.PreviousApproval);
  const isItemSourceTypeDisabled =
    isCreateCreditNoteMode || !!invoicePackageSnapshotId || isReferralInOrPreviousApproval;
  const isItemDisabled =
    isCreateCreditNoteMode || !itemSourceType || !!invoicePackageSnapshotId || isReferralInOrPreviousApproval;
  const isPercentageDiscountType = discountType === InvoiceDiscountType.Percentage;
  const isDiscountDisabled = isPayByInsurance || !item || !!invoicePackageSnapshotId;
  const limits = useLimits(item, itemSourceType?.value, form);

  const performerBillingItemIdsToExclude = useMemo<string[]>(
    () => uniq(items.map((i) => i.billingItem.value)),
    [items],
  );

  const itemTypeOptions = useMemo<Option<BillingItemSourceType>[] | undefined>(() => {
    if (payBy?.value === InvoicePayBy.Insurance && !appointmentId) {
      // Return only Medications option, for pay by insurance mode AND without appointment invoice
      return itemSourceTypes?.filter((o) => o.value === BillingItemSourceType.Medication);
    }

    if (isEligible) {
      // Return only Billing Item option, for invoices with Eligible type
      return itemSourceTypes?.filter((o) => o.value === BillingItemSourceType.BillingItem);
    }

    return itemSourceTypes;
  }, [appointmentId, isEligible, itemSourceTypes, payBy?.value]);
  const billingItemOptionParams = useMemo<Partial<PerformerBillingItemsDropdownParams>>(
    () => ({
      branchId,
      performerId: doctorId,
      billingItemSourceType: itemSourceType?.value,
      insuranceId: isPayByInsurance ? insuranceId : undefined,
      itemType: isEligible ? BillingItemType.Consultation : undefined,
      performerBillingItemIdsToExclude,
      nameScope: BillingItemNameScope.NameInInvoice,
    }),
    [
      branchId,
      doctorId,
      itemSourceType?.value,
      isPayByInsurance,
      insuranceId,
      isEligible,
      performerBillingItemIdsToExclude,
    ],
  );

  const fetchItemOptions = useFetchPerformerBillingItemsOptions(billingItemOptionParams);

  const formatOptionLabel = useItemFormatOptionLabel({
    itemSourceType,
    selectedItem: item,
  });

  const { hasERPIntegration, hasInventoryReservationEnabled } = useBranchERPSettings({ branchId });
  const hasStorageOptions = Object.keys(storageQuantity ?? {}).length > 0;
  const isInventory = !!item?.details.isInventoryItem;
  const availableQuantity = item?.details.availableQuantity ?? 0;

  const shouldShowReservationTable =
    hasERPIntegration &&
    hasInventoryReservationEnabled &&
    ((isInventory && availableQuantity > 0) || hasStorageOptions);

  useEffect(() => {
    if (
      hasERPIntegration &&
      hasInventoryReservationEnabled &&
      item?.details.isInventoryItem &&
      item?.details.availableQuantity === 0
    ) {
      dispatch(toggleModal('zeroInventoryWarning'));
    }
  }, [hasERPIntegration, hasInventoryReservationEnabled, item, dispatch]);

  const discountAppendContent = useMemo<ReactNode>(() => {
    return (
      <InputGroupText>
        <UncontrolledDropdown disabled={isDiscountDisabled}>
          <DropdownToggle
            className={classNames('user-select-none', { 'cursor-pointer': !isDiscountDisabled })}
            tag="div"
          >
            <span className="me-1">{isPercentageDiscountType ? '%' : <SarCurrencySymbol />}</span>
            {!isDiscountDisabled && <IconChevronDown size={14} strokeWidth={2.5} />}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => change(ItemField.discountType, InvoiceDiscountType.Percentage)}>
              {formatMessage({ id: 'CORE.LABEL.PERCENTAGE' })}
            </DropdownItem>
            <DropdownItem onClick={() => change(ItemField.discountType, InvoiceDiscountType.Amount)}>
              {formatMessage({ id: 'CORE.LABEL.AMOUNT' })}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroupText>
    );
  }, [change, formatMessage, isDiscountDisabled, isPercentageDiscountType]);

  const onItemSourceTypeChange = useCallback<EventWithDataHandler<ChangeEvent<any>>>(
    (_, newValue: Option<BillingItemSourceType> | undefined) => {
      change(ItemField.item, null);

      if (newValue?.value === BillingItemSourceType.Package) {
        // Set the default value for packages
        change(ItemField.totalQuantity, 1);
      }
    },
    [change],
  );

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Row xs={1} xl={2}>
        <Col>
          <Field
            disabled={isItemSourceTypeDisabled}
            name={ItemField.itemSourceType}
            component={SelectComponent}
            isRequired
            label={formatMessage({ id: 'BILLING-ITEMS.LABEL.ITEM-TYPE' })}
            onChange={onItemSourceTypeChange}
            options={itemTypeOptions}
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            disabled={isItemDisabled}
            name={ItemField.item}
            component={AsyncSelectComponent}
            cacheUniqs={[itemSourceType?.value, performerBillingItemIdsToExclude]}
            fetchOptions={fetchItemOptions}
            formatOptionLabel={formatOptionLabel}
            label={formatMessage({ id: 'CORE.LABEL.ITEM', defaultMessage: 'Item' })}
            isRequired
            showClinicyId={false}
            validate={[required]}
          />
        </Col>
      </Row>
      <Row xs={1} xl={4}>
        <Col>
          <Field
            name={ItemField.totalQuantity}
            component={QuantityInputComponent}
            disabled={isEligible} // We don't want to block this field for packages because we want to cover existing invoices
            label={formatMessage({ id: 'CORE.LABEL.TOTAL-QUANTITY', defaultMessage: 'Total Quantity' })}
            min={limits.totalQuantity.min}
            max={limits.totalQuantity.max}
            step={1}
            isRequired
            normalize={quantityNormalizer}
            validate={[required]}
            fullWidth
          />
        </Col>
        <Col>
          <Field
            name={ItemField.discountValue}
            appendContent={discountAppendContent}
            component={InputComponent}
            disabled={isDiscountDisabled}
            label={formatMessage({ id: 'CORE.LABEL.DISCOUNT', defaultMessage: 'Discount' })}
            min={limits.discountValue.min}
            max={limits.discountValue.max}
            step={isPercentageDiscountType ? 1 : 0.01}
            normalize={isPercentageDiscountType ? percentageNormalizer : undefined}
            type="number"
          />
        </Col>
        <Col xl={shouldShowReservationTable ? 6 : 3}>
          <Field
            name={ItemField.totalPrice}
            appendContent={
              <InputGroupText>
                <SarCurrencySymbol />
              </InputGroupText>
            }
            component={InputComponent}
            disabled={true}
            label={formatMessage({ id: 'CORE.LABEL.TOTAL-PRICE' })}
            type="text"
            format={(value: number | undefined) => getPriceLocaleString(value || 0)}
          />
        </Col>
        {!shouldShowReservationTable && (
          <Col>
            <Button block className="btn mt-2 mt-xl-4 mb-3" color="primary-gradient" size="md" type="submit">
              {isEditMode ? formatMessage({ id: 'CORE.BUTTON.SAVE' }) : formatMessage({ id: 'CORE.BUTTON.ADD' })}
            </Button>
          </Col>
        )}
      </Row>
      {error && typeof error === 'string' && (
        <Alert className="is-invalid py-2 small" color="danger">
          {error}
        </Alert>
      )}

      {shouldShowReservationTable && (
        <StorageTable
          branchId={branchId}
          performerId={doctorId}
          performerBillingItemId={item?.value}
          form={form}
          totalQuantity={totalQuantity}
          storageQuantity={storageQuantity}
          storageQuantityField={ItemField.storageQuantity}
        />
      )}
    </Form>
  );
};

export const ItemForm = reduxForm<ItemFormData, ItemFormProps, ErrorType>({
  enableReinitialize: true,
  validate,
})(FormComponent);
