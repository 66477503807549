import { ColumnFormatter } from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { AppointmentAction, AppointmentActionType } from 'app/types';

type formatExtraData = {
  onClick: (action: AppointmentAction) => void;
};

export const actionFormatter: ColumnFormatter<AppointmentAction, formatExtraData> = (
  _,
  row,
  rowIndex,
  formatExtraData,
) => {
  const { oldObject, newObject } = row;

  const isNumbersChanged =
    oldObject?.patientPhoneNumber !== newObject?.patientPhoneNumber ||
    oldObject?.internalFileNumber !== newObject?.internalFileNumber;

  const isLink =
    (AppointmentActionType.AppointmentUpdate || row.type === AppointmentActionType.WaitingAppointmentUpdate) &&
    isNumbersChanged;

  return isLink ? (
    <Button className="p-0 m-0" color="link" size="sm" onClick={() => formatExtraData?.onClick(row)}>
      {row.action}
    </Button>
  ) : (
    row.action
  );
};
