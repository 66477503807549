import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useBranchERPSettings } from 'app/helpers/useBranchERPSettings/useBranchERPSettings';
import { useLazyGetItemStoragesQuery, useLazyGetReservationQuery } from 'app/services/storages/storages';
import { StorageEntityType } from 'app/services/storages/types';

interface useGetStorageQuantityMapProps {
  clinicyId?: string;
  branchId?: string;
  performerId?: string;
  performerBillingItemId?: string;
  entityId?: string;
  entityType: StorageEntityType;
}

export const useGetStorageQuantityMap = ({
  clinicyId,
  branchId,
  performerId,
  performerBillingItemId,
  entityId,
  entityType,
}: useGetStorageQuantityMapProps) => {
  const [triggerGetReservation] = useLazyGetReservationQuery();
  const [triggerGetItemStorages] = useLazyGetItemStoragesQuery();

  const { hasInventoryReservationEnabled } = useBranchERPSettings({ branchId });

  const getStorageQuantityMap = useCallback(async () => {
    if (
      !hasInventoryReservationEnabled ||
      !clinicyId ||
      !branchId ||
      !performerId ||
      !performerBillingItemId ||
      !entityId
    ) {
      return null;
    }

    try {
      const reservations = await triggerGetReservation({ entityId, entityType }).unwrap();

      const reservationItems = reservations?.[0]?.items ?? [];
      const itemReservations = reservationItems.filter((item) => item.billingItemClinicyId === clinicyId);

      if (!itemReservations.length) {
        return null;
      }

      const itemStorages = await triggerGetItemStorages({ branchId, performerId, performerBillingItemId }).unwrap();

      if (!itemStorages.length) {
        return null;
      }

      const storageQuantityMap = itemStorages.reduce<Record<string, number>>((acc, storage) => {
        acc[storage.storageId] = 0;
        return acc;
      }, {});

      itemReservations.forEach((reservation) => {
        if (storageQuantityMap.hasOwnProperty(reservation.storageId)) {
          storageQuantityMap[reservation.storageId] = reservation.reservedQty;
        }
      });

      return storageQuantityMap;
    } catch (error) {
      toast.error('CORE.TEXT.GENERAL-ERROR');
      return null;
    }
  }, [
    hasInventoryReservationEnabled,
    clinicyId,
    branchId,
    performerId,
    performerBillingItemId,
    entityId,
    triggerGetReservation,
    entityType,
    triggerGetItemStorages,
  ]);

  return { getStorageQuantityMap };
};
