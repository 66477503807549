import { skipToken } from '@reduxjs/toolkit/query';
import { useGetBranchLoomxSettingsQuery } from 'app/services/branches/branches';

interface UseBranchERPSettingsProps {
  branchId?: string;
}

export const useBranchERPSettings = ({ branchId }: UseBranchERPSettingsProps) => {
  const { data: loomxSettingsData } = useGetBranchLoomxSettingsQuery(branchId ?? skipToken);

  const hasMedicalInstitutionERPIntegration = !!loomxSettingsData?.data?.clinicHasLoomxIntegration;
  const hasERPIntegration = !!loomxSettingsData?.data?.hasActiveLoomxIntegration;
  const hasInventoryReservationEnabled = !!loomxSettingsData?.data?.enableInventoryReservation;
  const hasAllowedZeroCountItemsReservation = !!loomxSettingsData?.data?.allowZeroCountItemsReservation;

  return {
    hasERPIntegration,
    hasInventoryReservationEnabled,
    hasAllowedZeroCountItemsReservation,
    hasMedicalInstitutionERPIntegration,
  };
};
