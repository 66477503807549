import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  ChangeOrderStatusBodyParams,
  CommentOrderParams,
  InsuranceRequestOrderParams,
  OrderBodyParams,
  ReportOrderParams,
} from 'app/services/order/types';
import {
  ExtendedOrder,
  OrderComment,
  OrderDetailsAction,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Order', 'Orders'],
  endpoints: (builder) => ({
    addOrder: builder.mutation<void, OrderBodyParams>({
      query: (body) => ({
        url: `orders`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Orders'],
    }),
    editOrder: builder.mutation<void, { body: OrderBodyParams; orderId: string }>({
      query: ({ body, orderId }) => ({
        url: `orders/${orderId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { orderId }) => [{ type: 'Orders', orderId }],
    }),
    changeOrderStatus: builder.mutation<void, { body: ChangeOrderStatusBodyParams; orderId: string }>({
      query: ({ body, orderId }) => ({
        url: `orders/${orderId}/status`,
        method: 'PUT',
        body,
      }),
    }),
    getOrder: builder.query<{ data: ExtendedOrder }, string>({
      query: (orderId) => ({
        url: `/orders/${orderId}`,
        method: 'GET',
      }),
      providesTags: (_, __, orderId) => [
        {
          type: 'Order',
          id: orderId,
        },
      ],
    }),
    addInvoiceRequestOrder: builder.mutation<void, { id: string; body: InsuranceRequestOrderParams }>({
      query: ({ id, body }) => ({
        url: `orders/${id}/invoice-requests`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Orders', id }],
    }),
    addOrderReport: builder.mutation<void, { id: string; body: ReportOrderParams }>({
      query: ({ id, body }) => ({
        url: `orders/${id}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Orders', id }],
    }),
    addOrderComment: builder.mutation<void, { id: string; body: CommentOrderParams }>({
      query: ({ id, body }) => ({
        url: `orders/${id}/order-comments `,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Orders', id }],
    }),
    getClinicOrderTicket: builder.query<{ data: ExtendedOrder }, string>({
      query: (orderId) => ({
        url: `orders/${orderId}`,
      }),
    }),
    getClinicOrderComments: builder.query<{ data: OrderComment[] }, string>({
      query: (orderId) => ({
        url: `orders/${orderId}/order-comments`,
      }),
    }),
    getClinicOrderActions: builder.query<
      QueryResponseWithPagination<OrderDetailsAction[]>,
      { orderId: string; params: QueryParams | undefined }
    >({
      query: ({ orderId, params }) => ({
        url: `orders/${orderId}/logs`,
        params,
      }),
    }),
    getCoveredByInsuranceStatus: builder.query<QueryResponse<{ coveredByInsurance: boolean }>, string>({
      query: (orderId) => ({
        url: `orders/${orderId}/is-covered-by-insurance`,
      }),
    }),
  }),
});

export const {
  useAddOrderMutation,
  useEditOrderMutation,
  useChangeOrderStatusMutation,
  useGetOrderQuery,
  useAddInvoiceRequestOrderMutation,
  useAddOrderCommentMutation,
  useAddOrderReportMutation,
  useGetClinicOrderTicketQuery,
  useGetClinicOrderCommentsQuery,
  useGetClinicOrderActionsQuery,
  useGetCoveredByInsuranceStatusQuery,
} = orderApi;
