import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  BalanceParams,
  ClinicSummaryBillingReport,
  ClinicSummaryDashboard,
  ClinicSummaryDashboardGeneralTotals,
  ClinicSummaryDashboardPaymentTotals,
  ClinicSummaryDashboardTransactionTotals,
  ClinicSummaryGeneralReport,
  ClinicSummaryPractitionersReport,
  ClinicSummaryUsersReport,
  FullSummaryParams,
  SummaryTabParams,
  TransactionParams,
} from 'app/services/accountBalances/types';
import {
  AccountBalanceClinicTransaction,
  AccountBalanceSummary,
  ClinicBalance,
  PatientClinicBalance,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';

export const accountBalancesApi = createApi({
  reducerPath: 'accountBalancesApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getClinicBalances: builder.query<QueryResponseWithPagination<ClinicBalance[]>, BalanceParams | undefined>({
      query: (params) => ({ url: 'wallets/summary/clinics', params }),
    }),
    getPatientBalances: builder.query<
      QueryResponseWithPagination<PatientClinicBalance[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({ url: `wallets/summary/patients/clinics/${clinicId}`, params }),
    }),
    getPatientClinicBalances: builder.query<
      QueryResponseWithPagination<PatientClinicBalance[]>,
      { patientId: string; params: QueryParams | undefined }
    >({
      query: ({ patientId, params }) => ({ url: `wallets/summary/patients/${patientId}/clinics`, params }),
    }),
    getClinicTransactions: builder.query<
      QueryResponseWithPagination<AccountBalanceClinicTransaction[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({ url: `wallets/clinics/${clinicId}`, params }),
    }),
    getPatientTransactions: builder.query<
      QueryResponseWithPagination<AccountBalanceClinicTransaction[]>,
      { patientId: string; params: TransactionParams | undefined }
    >({
      query: ({ patientId, params }) => ({ url: `wallets/clinic-patients/${patientId}/transactions`, params }),
    }),
    getClinicTransactionsReport: builder.mutation<void, { clinicId: string; params: QueryParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `wallets/clinics/${clinicId}/excel`, params }),
    }),
    getPatientTransactionsReport: builder.mutation<void, { patientId: string; params: QueryParams | undefined }>({
      query: ({ patientId, params }) => ({ url: `wallets/clinic-patients/${patientId}/excel`, params }),
    }),
    getSummaryReport: builder.mutation<void, { clinicId: string; params: QueryParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `wallets/summary/patients/clinics/${clinicId}/excel`, params }),
    }),
    getClinicBalanceSummary: builder.query<AccountBalanceSummary, QueryParams | undefined>({
      query: (params) => ({ url: 'clinic-balance-summary', params }),
    }),
    getClinicPatientBalanceSummary: builder.query<AccountBalanceSummary, QueryParams | undefined>({
      query: (params) => ({ url: 'clinic-patient-balance-summary', params }),
    }),
    getDashboardSummary: builder.query<
      QueryResponse<ClinicSummaryDashboard>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/account-balance-dashboard`,
        params,
      }),
    }),
    getGeneralSummary: builder.query<
      QueryResponseWithPagination<ClinicSummaryGeneralReport[]>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/general-summary`,
        params,
      }),
    }),
    getPractitionerSummary: builder.query<
      QueryResponseWithPagination<ClinicSummaryPractitionersReport[]>,
      {
        clinicId: string;
        params: SummaryTabParams;
      }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/practitioner-summary`,
        params,
      }),
    }),
    getUserSummary: builder.query<
      QueryResponseWithPagination<ClinicSummaryUsersReport[]>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/user-summary`,
        params,
      }),
    }),
    getBillingItemsSummary: builder.query<
      QueryResponseWithPagination<ClinicSummaryBillingReport[]>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/billing-item-summary`,
        params,
      }),
    }),
    getDashboardGeneralSummary: builder.query<
      QueryResponse<ClinicSummaryDashboardGeneralTotals>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/account-balance-dashboard/general`,
        params,
      }),
    }),
    getDashboardPaymentsSummary: builder.query<
      QueryResponse<ClinicSummaryDashboardPaymentTotals>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/account-balance-dashboard/payments`,
        params,
      }),
    }),
    getDashboardTransactionsSummary: builder.query<
      QueryResponse<ClinicSummaryDashboardTransactionTotals>,
      { clinicId: string; params: SummaryTabParams }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/account-balance-dashboard/transactions`,
        params,
      }),
    }),
    getDashboardSummaryReport: builder.query<void, { clinicId: string; params: SummaryTabParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/account-balance-dashboard/excel`, params }),
    }),
    getGeneralSummaryReport: builder.query<void, { clinicId: string; params: SummaryTabParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/general-summary/excel`, params }),
    }),
    getPractitionerSummaryReport: builder.query<void, { clinicId: string; params: SummaryTabParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/practitioner-summary/excel`, params }),
    }),
    getUserSummaryReport: builder.query<void, { clinicId: string; params: SummaryTabParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/user-summary/excel`, params }),
    }),
    getBillingItemsSummaryReport: builder.query<void, { clinicId: string; params: SummaryTabParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/billing-item-summary/excel`, params }),
    }),
    getFullSummaryReport: builder.query<void, { clinicId: string; params: FullSummaryParams | undefined }>({
      query: ({ clinicId, params }) => ({ url: `clinics/${clinicId}/full-summary/excel`, params }),
    }),
  }),
});

export const {
  useGetClinicBalancesQuery,
  useGetPatientBalancesQuery,
  useGetPatientClinicBalancesQuery,
  useGetClinicTransactionsQuery,
  useGetPatientTransactionsQuery,
  useGetClinicTransactionsReportMutation,
  useGetPatientTransactionsReportMutation,
  useGetSummaryReportMutation,
  useGetClinicBalanceSummaryQuery,
  useGetClinicPatientBalanceSummaryQuery,
  useGetDashboardSummaryQuery,
  useGetGeneralSummaryQuery,
  useGetPractitionerSummaryQuery,
  useGetUserSummaryQuery,
  useGetBillingItemsSummaryQuery,
  useGetDashboardGeneralSummaryQuery,
  useGetDashboardPaymentsSummaryQuery,
  useGetDashboardTransactionsSummaryQuery,
  useLazyGetDashboardSummaryReportQuery,
  useLazyGetGeneralSummaryReportQuery,
  useLazyGetPractitionerSummaryReportQuery,
  useLazyGetUserSummaryReportQuery,
  useLazyGetBillingItemsSummaryReportQuery,
  useLazyGetFullSummaryReportQuery,
} = accountBalancesApi;
