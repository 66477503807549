import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Badge, Button, Spinner, UncontrolledTooltip } from 'reactstrap';
import { ButtonProps } from 'reactstrap/types/lib/Button';
import { useAppDispatch, useAppIntl, useCurrentUser } from 'app/helpers';
import { AppointmentStatusBadge } from 'app/components';
import { ConfirmationModal } from 'app/shared';
import { changeAppointmentStatus, fetchAppointment } from 'app/redux/appointment/appointment.actions';
import { StatusChangeValue } from 'app/redux/appointment/types';
import { Appointment, AppointmentModificationSource, AppointmentStatus } from 'app/types';

interface Props {
  appointment: Pick<Appointment, 'id' | 'start' | 'status' | 'canBeModified'>;
  statusChangeAvailable?: boolean;
}

export const AppointmentStatusWithAction = ({ appointment, statusChangeAvailable }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const [status, setStatus] = useState<StatusChangeValue | null>();
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    currentUser: { allowedForAppointmentsActions },
  } = useCurrentUser();

  // Conditions
  const isButtonDisabled = !appointment.canBeModified;

  // Helper variables
  const startMoment = moment(appointment.start);
  const diff = startMoment.diff(moment(), 'minutes');
  const statusValue = appointment.status?.value;
  const statusTooltip = 'tooltipTarget-status-button';

  // Actions
  const showConfirmation = () => setConfirmationStatus(true);
  const closeConfirmation = () => setConfirmationStatus(false);
  const changeStatus = useCallback((newStatus: StatusChangeValue) => {
    setStatus(newStatus);
    showConfirmation();
  }, []);
  const onConfirm = useCallback(async () => {
    setSubmitting(true);
    if (status) {
      await dispatch(
        changeAppointmentStatus(appointment.id, {
          status,
          appointmentSource: status === StatusChangeValue.Confirm ? AppointmentModificationSource.Ticket : undefined,
        }),
      );
    }
    closeConfirmation();
    setSubmitting(false);
    setStatus(null);
    dispatch(fetchAppointment(appointment.id, false));
  }, [appointment, dispatch, status]);

  const buttonProps: ButtonProps = {
    className: 'border-0 mt-1',
    color: 'primary-gradient',
    size: 'sm',
    tag: Badge,
    disabled: isButtonDisabled,
  };

  return (
    <>
      <span className="me-2">{appointment.status ? <AppointmentStatusBadge status={appointment.status} /> : '-'}</span>

      {submitting && <Spinner size="sm" color="primary" />}

      {allowedForAppointmentsActions && statusChangeAvailable && !submitting && (
        <>
          <span id={statusTooltip} style={(isButtonDisabled && { cursor: 'not-allowed' }) || undefined}>
            {/* Upcoming appointments */}
            {diff > 0 && (
              <>
                {(statusValue === AppointmentStatus.Booked || statusValue === AppointmentStatus.BookedBefore24) && (
                  <Button {...buttonProps} onClick={() => changeStatus(StatusChangeValue.Confirm)}>
                    {formatMessage({ id: 'CORE.BUTTON.CHANGE-TO-CONFIRMED' })}
                  </Button>
                )}

                {statusValue === AppointmentStatus.ConfirmedByStaff && (
                  <Button {...buttonProps} onClick={() => changeStatus(StatusChangeValue.Unconfirm)}>
                    {formatMessage({ id: 'CORE.BUTTON.CHANGE-TO-BOOKED' })}
                  </Button>
                )}
              </>
            )}

            {/* Past appointments */}
            {diff >= -(24 * 60) && diff <= 0 && (
              <>
                {statusValue === AppointmentStatus.Attended && (
                  <Button {...buttonProps} onClick={() => changeStatus(StatusChangeValue.NoShow)}>
                    {formatMessage({ id: 'CORE.BUTTON.CHANGE-TO-NO-SHOW' })}
                  </Button>
                )}

                {statusValue === AppointmentStatus.NoShow && (
                  <Button {...buttonProps} onClick={() => changeStatus(StatusChangeValue.Attended)}>
                    {formatMessage({ id: 'CORE.BUTTON.CHANGE-TO-ATTENDED' })}
                  </Button>
                )}
              </>
            )}
          </span>

          {isButtonDisabled && (
            <UncontrolledTooltip target={statusTooltip} fade={false} delay={{ show: 0, hide: 0 }}>
              {formatMessage({ id: 'APPOINTMENTS.TOOLTIP.CAN-NOT-EDIT' })}
            </UncontrolledTooltip>
          )}
        </>
      )}

      {/* Confirmation modal */}
      <ConfirmationModal
        description="APPOINTMENTS.TEXT.CHANGE-STATUS"
        disabled={submitting}
        isOpen={confirmationStatus}
        onCancel={closeConfirmation}
        onClose={closeConfirmation}
        onConfirm={onConfirm}
      />
    </>
  );
};
