import { useCallback } from 'react';
import { IconLogout, IconUser } from '@tabler/icons-react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { getTranslation, useAppDispatch, useAppIntl, useCurrentUser } from 'app/helpers';
import { Avatar } from 'app/components';
import { logout } from 'app/redux/auth/auth.actions';
import './userDropdown.scss';

interface Props {
  className?: string;
}

export const UserDropdown = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage, isRtl, locale } = useAppIntl();
  const {
    isOwner,
    currentUser: { username, roles },
  } = useCurrentUser();

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  if (!username) {
    return null;
  }

  return (
    <UncontrolledDropdown className={classnames('headerUserDropdown', className)}>
      <DropdownToggle className="headerUserDropdown__dropdownToggle" tag="div">
        {username && (
          <div className="headerUserDropdown__user me-2 text-right">
            <span className="headerUserDropdown__userName">{username}</span>
            <span className="headerUserDropdown__userRole">{getTranslation(roles[0], locale)}</span>
          </div>
        )}
        <Avatar size="sm" status>
          <IconUser size={20} />
        </Avatar>
      </DropdownToggle>
      <DropdownMenu end={!isRtl}>
        <DropdownItem tag={Link} to={isOwner ? '/profile/owner' : '/profile'}>
          <IconUser size={16} />
          <span className="ms-2">{formatMessage({ id: 'CORE.TEXT.PROFILE' })}</span>
        </DropdownItem>
        <DropdownItem onClick={onLogout}>
          <IconLogout size={16} />
          <span className="ms-2">{formatMessage({ id: 'CORE.TEXT.LOGOUT' })}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
