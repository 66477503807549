import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppIntl, useCurrentUserPermissions } from 'app/helpers';
import { useGetClinicModulesInformationQuery } from 'app/services/clinics/clinics';
import { InvoicePayBy, Option } from 'app/types';

export const usePayByOptions = (clinicId?: string) => {
  const { formatMessage } = useAppIntl();
  const { allowedForInsuranceManagement } = useCurrentUserPermissions();
  const { data: clinicModulesInfoData, isLoading } = useGetClinicModulesInformationQuery(
    clinicId ? clinicId : skipToken,
  );

  // Helper Variables
  const isClinicAllowedForInsuranceManagement = clinicId
    ? !isLoading && !!clinicModulesInfoData?.data?.allowedForInsuranceManagement
    : true;

  return useMemo<Option<InvoicePayBy>[]>(() => {
    return [
      ...(allowedForInsuranceManagement && isClinicAllowedForInsuranceManagement
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.INSURANCE', defaultMessage: 'Insurance' }),
              value: InvoicePayBy.Insurance,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT', defaultMessage: 'Patient' }),
        value: InvoicePayBy.Patient,
      },
    ];
  }, [allowedForInsuranceManagement, formatMessage, isClinicAllowedForInsuranceManagement]);
};
