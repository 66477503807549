import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { generateTemporaryId, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_ADD_ITEM } from 'app/shared';
import { useLazyGetReservationQuery } from 'app/services/storages/storages';
import { addInvoiceItem, setReservationId } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { ErrorType, InvoiceDiscountType, InvoiceItemVat } from 'app/types';
import { useStorageReservation } from 'app/features/inventoryStorage/helpers/useStorageReservation';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { usePayByValue } from 'app/features/invoicingProcess/process/items/helpers';
import { formatInvoicingProcessStorageReservationItems } from 'app/features/invoicingProcess/process/items/helpers/formatInvoicingProcessStorageReservationItems';
import { onChange } from 'app/features/invoicingProcess/process/items/itemForm/helpers/onChange';
import { ItemForm, ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';
import { ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';

export const AddItemForm = () => {
  const invoicePayBy = usePayByValue();
  const { entityType, entityId, reservationId } = useInvoicingProcessState();

  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.items);

  const [triggerGetReservationQuery] = useLazyGetReservationQuery();
  const { reserveStorage } = useStorageReservation({
    entityType,
    entityId,
    branchId,
    reservationId,
    setReservationId,
  });

  const onSubmit = useCallback<FormSubmitHandler<ItemFormData, ItemFormProps, ErrorType>>(
    async (values, dispatch, props) => {
      try {
        const newInvoiceItem = {
          billingItem: values.item,
          billingItemSourceType: values.itemSourceType,
          discount: {
            discountTypeView: { label: '', value: values.discountType },
            value: Number(values.discountValue || 0),
          },
          id: generateTemporaryId(),
          price: values.item.details.price,
          quantity: Number(values.totalQuantity),
          storageQuantity: values.storageQuantity,
          vat: InvoiceItemVat.Default,
        };

        const hasInventoryItems = values.storageQuantity && Object.keys(values.storageQuantity).length > 0;
        if (hasInventoryItems) {
          let existingReservationItems: any[] = [];
          if (entityId) {
            const reservations = await triggerGetReservationQuery({
              entityId,
              entityType,
            }).unwrap();
            if (reservations[0]?.items) {
              existingReservationItems = reservations[0].items.map((item) => ({
                ...item,
                itemClinicyId: item.billingItemClinicyId,
              }));
            }
          }

          const newItems = formatInvoicingProcessStorageReservationItems([...invoicingProcessItems, newInvoiceItem]);

          const combinedItems = [
            ...existingReservationItems,
            ...newItems.filter(
              (newItem) =>
                !existingReservationItems.some(
                  (existingItem) =>
                    existingItem.itemClinicyId === newItem.itemClinicyId &&
                    existingItem.storageId === newItem.storageId,
                ),
            ),
          ];

          const res = await reserveStorage(combinedItems);

          if (!res?.success) {
            toast.error('CORE.TEXT.GENERAL-ERROR');
            return;
          }
        }

        dispatch(addInvoiceItem(newInvoiceItem));
        props.reset && props.reset();
        toast.success('CORE.TEXT.ITEM-ADDED-SUCCESSFULLY');
      } catch (error) {
        toast.error('CORE.TEXT.GENERAL-ERROR');
      }
    },
    [reserveStorage, invoicingProcessItems, entityId, entityType, triggerGetReservationQuery],
  );

  const initialValues = useMemo<Partial<ItemFormData> | undefined>(
    () => ({
      discountType: InvoiceDiscountType.Percentage,
      totalQuantity: 1,
    }),
    [],
  );

  return (
    <ItemForm
      form={FORMS_INVOICING_PROCESS_ADD_ITEM}
      initialValues={initialValues}
      invoicePayBy={invoicePayBy}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
