import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { InputComponent } from 'app/shared';

export const FormComponent = ({
  allowedForEditAppointments,
  isMergedPatient,
  downloading,
  exportToPDF,
  handleSubmit,
  isDifferentDoctor,
  intl: { formatMessage },
  submitting,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="note"
      component={InputComponent}
      label={formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' })}
      type="textarea"
      disabled={!allowedForEditAppointments || isDifferentDoctor || isMergedPatient}
    />
    <Row>
      <Col className="text-end">
        {allowedForEditAppointments && !isDifferentDoctor && !isMergedPatient && (
          <Button
            className="btn-responsive btn-responsive--lg"
            color="primary-gradient"
            disabled={submitting}
            type="submit"
          >
            {formatMessage({ id: 'CORE.BUTTON.SAVE-CHANGES' })}
          </Button>
        )}
        <Button
          className="btn-responsive btn-responsive--lg ms-2"
          color="primary"
          disabled={downloading}
          onClick={exportToPDF}
          outline
        >
          {formatMessage({ id: downloading ? 'CORE.TEXT.LOADING' : 'CORE.BUTTON.EXPORT-TO-PDF' })}
        </Button>
      </Col>
      {/*TODO: uncomment or remove and add onClick action (row xs={1} lg={2}) */}
      {/*<Col className="text-end">*/}
      {/*<Button className="btn-responsive btn-responsive--lg" color="success" onClick={() => null}>*/}
      {/*  {formatMessage({ id: 'CORE.BUTTON.START-CHECK-IN', defaultMessage: 'Start Check-in' })}*/}
      {/*</Button>*/}
      {/*</Col>*/}
    </Row>
  </Form>
);

FormComponent.propTypes = {
  downloading: PropTypes.bool.isRequired,
  exportToPDF: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
};
