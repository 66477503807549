import React, { useMemo } from 'react';
import { Button, Form, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { PerformerBillingItemsDropdownParams, useAppIntl, useFetchPerformerBillingItemsOptions } from 'app/helpers';
import { AsyncSelectComponent, FORMS_PROCEDURE_LOCATION_NOTE_BILLING_ITEM } from 'app/shared';
import { BillingItemNameScope, BillingItemSourceType } from 'app/types';
import {
  NoteBillingItemsField,
  NoteBillingItemsFormData,
} from 'app/features/doctorAssessment/components/medicalProfileSection/components/modals/noteBillingItemModal/form/types';

interface Props {
  branchId: string;
  doctorId: string;
  onToggle: () => void;
}

const FormComponent = ({
  handleSubmit,
  submitting,
  pristine,
  branchId,
  doctorId,
  onToggle,
}: InjectedFormProps<NoteBillingItemsFormData, Props> & Props) => {
  const { formatMessage } = useAppIntl();

  const billingItemOptionParams = useMemo<Partial<PerformerBillingItemsDropdownParams>>(
    () => ({
      branchId,
      performerId: doctorId,
      billingItemSourceType: BillingItemSourceType.BillingItem,
      nameScope: BillingItemNameScope.BillingItemName,
    }),
    [branchId, doctorId],
  );
  const fetchBillingItemOptions = useFetchPerformerBillingItemsOptions(billingItemOptionParams);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader toggle={onToggle}>{formatMessage({ id: 'CORE.LABEL.MANAGE-BILLING-ITEMS' })}</ModalHeader>
      <ModalBody>
        <Field
          component={AsyncSelectComponent}
          fetchOptions={fetchBillingItemOptions}
          label={formatMessage({ id: 'CORE.TEXT.BILLING-ITEMS' })}
          name={NoteBillingItemsField.billingItems}
          placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT' })}
          isClearable
          isMulti
        />
      </ModalBody>

      <ModalFooter>
        <Button type="button" disabled={submitting} color="secondary" onClick={onToggle}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button type="submit" disabled={submitting || pristine} color="primary-gradient">
          {formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export const BillingItemsForm = reduxForm<NoteBillingItemsFormData, Props>({
  form: FORMS_PROCEDURE_LOCATION_NOTE_BILLING_ITEM,
})(FormComponent);
