// Doctor Assessment
export const FETCH_EMPTY_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/FETCH_EMPTY_DOCTOR_ASSESSMENT_REQUEST';
export const FETCH_EMPTY_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/FETCH_EMPTY_DOCTOR_ASSESSMENT_SUCCESS';
export const FETCH_EMPTY_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/FETCH_EMPTY_DOCTOR_ASSESSMENT_FAILURE';

export const FETCH_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/FETCH_DOCTOR_ASSESSMENT_REQUEST';
export const FETCH_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/FETCH_DOCTOR_ASSESSMENT_SUCCESS';
export const FETCH_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/FETCH_DOCTOR_ASSESSMENT_FAILURE';

export const FETCH_CONTINUED_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/FETCH_CONTINUED_DOCTOR_ASSESSMENT_REQUEST';
export const FETCH_CONTINUED_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/FETCH_CONTINUED_DOCTOR_ASSESSMENT_SUCCESS';
export const FETCH_CONTINUED_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/FETCH_CONTINUED_DOCTOR_ASSESSMENT_FAILURE';

export const CREATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/CREATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST';
export const CREATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/CREATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS';
export const CREATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/CREATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE';

export const CREATE_DRAFTED_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/CREATE_DRAFTED_DOCTOR_ASSESSMENT_REQUEST';
export const CREATE_DRAFTED_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/CREATE_DRAFTED_DOCTOR_ASSESSMENT_SUCCESS';
export const CREATE_DRAFTED_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/CREATE_DRAFTED_DOCTOR_ASSESSMENT_FAILURE';

export const UPDATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/UPDATE_COMPLETED_DOCTOR_ASSESSMENT_REQUEST';
export const UPDATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/UPDATE_COMPLETED_DOCTOR_ASSESSMENT_SUCCESS';
export const UPDATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/UPDATE_COMPLETED_DOCTOR_ASSESSMENT_FAILURE';

export const SET_INFORMATION = 'doctorAssessment/SET_INFORMATION';

export const UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_REQUEST =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_REQUEST';
export const UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_SUCCESS =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_SUCCESS';
export const UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_FAILURE =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_COMPLETED_FAILURE';

export const UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_REQUEST =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_REQUEST';
export const UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_SUCCESS =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_SUCCESS';
export const UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_FAILURE =
  'doctorAssessment/UPDATE_DOCTOR_ASSESSMENT_TO_SAVE_FOR_LATER_FAILURE';

export const REMOVE_DOCTOR_ASSESSMENT_REQUEST = 'doctorAssessment/REMOVE_DOCTOR_ASSESSMENT_REQUEST';
export const REMOVE_DOCTOR_ASSESSMENT_SUCCESS = 'doctorAssessment/REMOVE_DOCTOR_ASSESSMENT_SUCCESS';
export const REMOVE_DOCTOR_ASSESSMENT_FAILURE = 'doctorAssessment/REMOVE_DOCTOR_ASSESSMENT_FAILURE';

// Attachments
export const SEND_ATTACHMENT_REQUEST = 'attachment/SEND_ATTACHMENT_REQUEST';
export const SEND_ATTACHMENT_SUCCESS = 'attachment/SEND_ATTACHMENT_SUCCESS';
export const SEND_ATTACHMENT_FAILURE = 'attachment/SEND_ATTACHMENT_FAILURE';

// Insurance Requests
export const SEND_REQUEST_FOR_APPROVAL_REQUEST = 'insuranceRequest/SEND_REQUEST_FOR_APPROVAL_REQUEST';
export const SEND_REQUEST_FOR_APPROVAL_SUCCESS = 'insuranceRequest/SEND_REQUEST_FOR_APPROVAL_SUCCESS';
export const SEND_REQUEST_FOR_APPROVAL_FAILURE = 'insuranceRequest/SEND_REQUEST_FOR_APPROVAL_FAILURE';

//Clinic Logo
export const FETCH_CLINIC_LOGO_REQUEST = 'clinicLogo/FETCH_CLINIC_LOGO_REQUEST';
export const FETCH_CLINIC_LOGO_SUCCESS = 'clinicLogo/FETCH_CLINIC_LOGO_SUCCESS';
export const FETCH_CLINIC_LOGO_FAILURE = 'clinicLogo/FETCH_CLINIC_LOGO_FAILURE';

//Attachment Appointment Options
export const FETCH_APPOINTMENT_OPTIONS_REQUEST = 'doctorAssessment/FETCH_APPOINTMENT_OPTIONS_REQUEST';
export const FETCH_APPOINTMENT_OPTIONS_SUCCESS = 'doctorAssessment/FETCH_APPOINTMENT_OPTIONS_SUCCESS';
export const FETCH_APPOINTMENT_OPTIONS_FAILURE = 'doctorAssessment/FETCH_APPOINTMENT_OPTIONS_FAILURE';

// Update ONE "NOT COVERED" Billing Item
export const UPDATE_NOT_COVERED_BILLING_STATUS_REQUEST = 'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUS_REQUEST';
export const UPDATE_NOT_COVERED_BILLING_STATUS_SUCCESS = 'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUS_SUCCESS';
export const UPDATE_NOT_COVERED_BILLING_STATUS_FAILURE = 'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUS_FAILURE';

// Update ONE "COVERED" Billing Item
export const UPDATE_COVERED_BILLING_STATUS_REQUEST = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUS_REQUEST';
export const UPDATE_COVERED_BILLING_STATUS_SUCCESS = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUS_SUCCESS';
export const UPDATE_COVERED_BILLING_STATUS_FAILURE = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUS_FAILURE';

export const SEND_SEEK_FOR_BILLING_REQUEST = 'doctorAssessment/SEND_SEEK_FOR_BILLING_REQUEST';
export const SEND_SEEK_FOR_BILLING_SUCCESS = 'doctorAssessment/SEND_SEEK_FOR_BILLING_SUCCESS';
export const SEND_SEEK_FOR_BILLING_FAILURE = 'doctorAssessment/SEND_SEEK_FOR_BILLING_FAILURE';

export const WS_REFRESH_BILLING_ITEMS_STATUSES = 'doctorAssessment/WS_REFRESH_BILLING_ITEMS_STATUSES';

// Update MANY "COVERED" Billing Items
export const UPDATE_COVERED_BILLING_STATUSES_REQUEST = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUSES_REQUEST';
export const UPDATE_COVERED_BILLING_STATUSES_SUCCESS = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUSES_SUCCESS';
export const UPDATE_COVERED_BILLING_STATUSES_FAILURE = 'doctorAssessment/UPDATE_COVERED_BILLING_STATUSES_FAILURE';

// Update MANY "NOT_COVERED" Billing Items
export const UPDATE_NOT_COVERED_BILLING_STATUSES_REQUEST =
  'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUSES_REQUEST';
export const UPDATE_NOT_COVERED_BILLING_STATUSES_SUCCESS =
  'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUSES_SUCCESS';
export const UPDATE_NOT_COVERED_BILLING_STATUSES_FAILURE =
  'doctorAssessment/UPDATE_NOT_COVERED_BILLING_STATUSES_FAILURE';

export const FETCH_REQUEST_REHYDRATION_DATA_REQUEST = 'insuranceRequest/FETCH_REQUEST_REHYDRATION_DATA_REQUEST';
export const FETCH_REQUEST_REHYDRATION_DATA_SUCCESS = 'insuranceRequest/FETCH_REQUEST_REHYDRATION_DATA_SUCCESS';
export const FETCH_REQUEST_REHYDRATION_DATA_FAILURE = 'insuranceRequest/FETCH_REQUEST_REHYDRATION_DATA_FAILURE';

// Edit selected assessment billing item
export const SET_ASSESSMENT_BILLING_ITEM = 'doctorAssessment/SET_ASSESSMENT_BILLING_ITEM';
export const RESET_ASSESSMENT_BILLING_ITEM = 'doctorAssessment/RESET_ASSESSMENT_BILLING_ITEM';

export const LOG_PRINT_ACTIONS_REQUEST = 'doctorAssessment/LOG_PRINT_ACTIONS_REQUEST';
export const LOG_PRINT_ACTIONS_SUCCESS = 'doctorAssessment/LOG_PRINT_ACTIONS_SUCCESS';
export const LOG_PRINT_ACTIONS_FAILURE = 'doctorAssessment/LOG_PRINT_ACTIONS_FAILURE';

// Edit Referral
export const SET_ASSESSMENT_REFERRAL = 'doctorAssessment/SET_ASSESSMENT_REFERRAL';
export const RESET_ASSESSMENT_REFERRAL = 'doctorAssessment/RESET_ASSESSMENT_REFERRAL';
export const RESET_DOCTOR_ASSESSMENT_DATA = 'doctorAssessment/RESET_DOCTOR_ASSESSMENT_DATA';

// Assessment Orders
export const CANCEL_ASSESSMENT_ORDER_REQUEST = 'doctorAssessment/CANCEL_ASSESSMENT_ORDER_REQUEST';
export const CANCEL_ASSESSMENT_ORDER_SUCCESS = 'doctorAssessment/CANCEL_ASSESSMENT_ORDER_SUCCESS';
export const CANCEL_ASSESSMENT_ORDER_FAILURE = 'doctorAssessment/CANCEL_ASSESSMENT_ORDER_FAILURE';

export const SEND_TO_ORDER_REQUEST = 'doctorAssessment/SEND_TO_ORDER_REQUEST';
export const SEND_TO_ORDER_SUCCESS = 'doctorAssessment/SEND_TO_ORDER_SUCCESS';
export const SEND_TO_ORDER_FAILURE = 'doctorAssessment/SEND_TO_ORDER_FAILURE';

export const WS_REFRESH_ORDERS_STATUSES = 'doctorAssessment/WS_REFRESH_ORDERS_STATUSES';

export const UPDATE_ORDERS_STATUSES_REQUEST = 'doctorAssessment/UPDATE_ORDERS_STATUSES_REQUEST';
export const UPDATE_ORDERS_STATUSES_SUCCESS = 'doctorAssessment/UPDATE_ORDERS_STATUSES_SUCCESS';
export const UPDATE_ORDERS_STATUSES_FAILURE = 'doctorAssessment/UPDATE_ORDERS_STATUSES_FAILURE';

export const SET_ASSESSMENT_ORDER = 'doctorAssessment/SET_ASSESSMENT_ORDER';
export const RESET_ASSESSMENT_ORDER = 'doctorAssessment/RESET_ASSESSMENT_ORDER';

export const SET_ASSESSMENT_ORDER_TICKET_ID = 'doctorAssessment/SET_ASSESSMENT_ORDER_TICKET_ID';
export const RESET_ASSESSMENT_ORDER_TICKET_ID = 'doctorAssessment/RESET_ASSESSMENT_ORDER_TICKET_ID';

export const GET_PACKAGE_ITEMS_REQUEST = 'doctorAssessment/GET_PACKAGE_ITEMS_REQUEST';
export const GET_PACKAGE_ITEMS_SUCCESS = 'doctorAssessment/GET_PACKAGE_ITEMS_SUCCESS';
export const GET_PACKAGE_ITEMS_FAILURE = 'doctorAssessment/GET_PACKAGE_ITEMS_FAILURE';

export const GET_BILLING_ITEM_QUANTITY_INFO_REQUEST = 'doctorAssessment/GET_BILLING_ITEM_QUANTITY_INFO_REQUEST';
export const GET_BILLING_ITEM_QUANTITY_INFO_SUCCESS = 'doctorAssessment/GET_BILLING_ITEM_QUANTITY_INFO_SUCCESS';
export const GET_BILLING_ITEM_QUANTITY_INFO_FAILURE = 'doctorAssessment/GET_BILLING_ITEM_QUANTITY_INFO_FAILURE';

export const GET_GENERAL_ASSESSMENT_INFORMATION_REQUEST = 'insuranceRequest/GET_GENERAL_ASSESSMENT_INFORMATION_REQUEST';
export const GET_GENERAL_ASSESSMENT_INFORMATION_SUCCESS = 'insuranceRequest/GET_GENERAL_ASSESSMENT_INFORMATION_SUCCESS';
export const GET_GENERAL_ASSESSMENT_INFORMATION_FAILURE = 'insuranceRequest/GET_GENERAL_ASSESSMENT_INFORMATION_FAILURE';

export const CHECK_VISIT_NUMBER_INFORMATION_REQUEST = 'insuranceRequest/CHECK_VISIT_NUMBER_INFORMATION_REQUEST';
export const CHECK_VISIT_NUMBER_INFORMATION_SUCCESS = 'insuranceRequest/CHECK_VISIT_NUMBER_INFORMATION_SUCCESS';
export const CHECK_VISIT_NUMBER_INFORMATION_FAILURE = 'insuranceRequest/CHECK_VISIT_NUMBER_INFORMATION_FAILURE';

export const SET_RESERVATION_ID = 'doctorAssessment/SET_RESERVATION_ID';
