import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { NotificationBody } from 'app/services/notifications/types';
import { QueryParams, QueryResponseWithPagination } from 'app/types';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['GlobalNotifications'],
  endpoints: (builder) => ({
    getGlobalScenarios: builder.query<QueryResponseWithPagination<any[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'notifications/global-scenarios',
        method: 'GET',
        params,
      }),
      providesTags: ['GlobalNotifications'],
    }),
    switchGlobalScenarioStatus: builder.mutation<void, { scenarioId: string; body: NotificationBody }>({
      query: ({ scenarioId, body }) => ({
        url: `notifications/global-scenarios/${scenarioId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useGetGlobalScenariosQuery, useSwitchGlobalScenarioStatusMutation } = notificationsApi;
