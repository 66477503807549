import { put, select } from 'redux-saga/effects';
import { insuranceApprovalsApi } from 'app/services/insuranceApprovals/insuranceApprovals';
import { RootState } from 'app/types';

export function* refreshInsuranceApprovalsList() {
  const {
    queryParams: { insuranceApprovals: params },
  }: RootState = yield select();

  if (params) {
    yield put<any>(
      insuranceApprovalsApi.endpoints.getInsuranceApprovals.initiate(params, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  }
}
