import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, InputProps } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { useAppIntl } from 'app/helpers';
import { CustomLabel } from 'app/components/customLabel/customLabel';

interface InputComponentProps extends Omit<InputProps, 'size'> {
  label: string;
  size?: 'lg' | 'sm';
  info?: string;
  text?: string;
  appendContent?: React.ReactNode;
  isRtl?: boolean;
  id?: string;
  pattern?: string;
}

export const InputComponent = ({
  appendContent,
  id,
  info,
  input,
  isRequired,
  isRtl,
  label,
  maxLength,
  meta: { error, touched },
  name,
  noMargin,
  onFocusChange,
  onKeyDown,
  pattern,
  size,
  text,
  type,

  ...rest
}: WrappedFieldProps & InputComponentProps) => {
  const fieldId = id || input.name;
  const { isRtl: defaultIsRtl } = useAppIntl();
  const isRtlDir = isRtl ? isRtl : defaultIsRtl;
  const invalid = !!error && touched;

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (type === 'text') {
      const pastedData = e.clipboardData?.getData('Text');

      if (pastedData) {
        e.preventDefault();
        input.onChange(pastedData.trim());
      }
    }
  };

  const onWheel = useCallback(
    (e: React.WheelEvent<HTMLInputElement>) => {
      if (type === 'number') {
        (e.target as HTMLInputElement).blur();
      }
    },
    [type],
  );

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!!onFocusChange) {
      onFocusChange(e);
    }

    const types = ['text', 'password', 'email', 'textarea'];

    if (types.includes(type)) {
      input.onBlur(e.target.value.trim());
    } else {
      input.onBlur(e.target.value);
    }
  };

  const customMaxLength = useMemo<number>(() => {
    if (!!maxLength) {
      return maxLength;
    }

    const name = input.name.toLowerCase();

    // Return default maxLength
    if (name.includes('phone') || name.includes('mobile')) {
      return 50;
    } else if (input.name === 'keyword') {
      return 150;
    } else if (type === 'text' || type === 'email') {
      return 100;
    } else if (type === 'url') {
      return 5000;
    } else return 1500;
  }, [input.name, maxLength, type]);

  return (
    <FormGroup noMargin={noMargin}>
      {label && <CustomLabel fieldId={fieldId} info={info} isRequired={isRequired} label={label} />}
      <InputGroup style={{ direction: isRtlDir ? 'rtl' : 'ltr' }}>
        <Input
          {...input}
          bsSize={size}
          id={fieldId}
          invalid={invalid}
          maxLength={customMaxLength}
          onBlur={handleOnBlur}
          onKeyDown={onKeyDown}
          onPaste={handleOnPaste}
          onWheel={onWheel}
          pattern={pattern}
          type={type}
          {...rest}
        />
        {type !== 'textarea' &&
          appendContent &&
          (typeof appendContent === 'string' ? <InputGroupText>{appendContent}</InputGroupText> : appendContent)}
      </InputGroup>
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={error.message ? error.message : error} values={{ value: error.value }} />
        </FormFeedback>
      )}
      {!rest.disabled && input.value.length === customMaxLength && (
        <FormText>
          <FormattedMessage id="CORE.TEXT.MAX-LENGTH-MESSAGE" values={{ value: customMaxLength }} />
        </FormText>
      )}
      {!!text && <FormText>{text}</FormText>}
    </FormGroup>
  );
};
