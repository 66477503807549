import { ChangeEvent, useCallback } from 'react';
import { ChangeAction } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { PatientInsuranceField } from 'app/features/patient/patientInsuranceDetailsForm/types';

export const useOnChangeFunctions = (change: ChangeAction) => {
  const onClinicChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(PatientInsuranceField.clinicBranch, null);
    change(PatientInsuranceField.insuranceCompany, null);
    change(PatientInsuranceField.policyNumber, null);
    change(PatientInsuranceField.insuranceClass, null);
  }, [change]);

  const onBranchChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(PatientInsuranceField.insuranceCompany, null);
    change(PatientInsuranceField.policyNumber, null);
    change(PatientInsuranceField.insuranceClass, null);
  }, [change]);

  const onInsuranceCompanyChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(PatientInsuranceField.policyNumber, null);
    change(PatientInsuranceField.insuranceClass, null);
  }, [change]);

  const onPolicyNumberChange: EventWithDataHandler<ChangeEvent> = useCallback(() => {
    change(PatientInsuranceField.insuranceClass, null);
  }, [change]);

  return {
    onClinicChange,
    onBranchChange,
    onInsuranceCompanyChange,
    onPolicyNumberChange,
  };
};
