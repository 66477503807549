import { useCallback } from 'react';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import { Input, InputGroup, InputProps } from 'reactstrap';
import styles from 'app/components/quantityInput/quantityInput.module.scss';

interface QuantityInputProps extends Omit<InputProps, 'size'> {
  name: string;
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'lg';
  initialValue?: number;
  quantity: number;
  max?: number;
  min?: number;
  updateQuantity: (value: number) => void;
  onChange?: (value: number) => void;
}

export const QuantityInput = ({
  name,
  size,
  max,
  className,
  inputClassName,
  buttonClassName,
  disabled = false,
  fullWidth = false,
  min = 0,
  initialValue = 0,
  quantity,
  updateQuantity,
  onChange,
  ...rest
}: QuantityInputProps) => {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === '' ? initialValue : parseInt(e.target.value, 10);
      const clampedValue = Math.min(Math.max(value, min), max ?? Infinity);
      updateQuantity(clampedValue);
      if (onChange) {
        onChange(clampedValue);
      }
    },
    [initialValue, max, min, updateQuantity, onChange],
  );

  const incrementQuantity = useCallback(() => {
    const nextValue = quantity + 1;
    const clampedValue = Math.min(nextValue, max ?? Infinity);
    if (clampedValue === quantity) return;
    updateQuantity(clampedValue);
    if (onChange) {
      onChange(clampedValue);
    }
  }, [quantity, max, updateQuantity, onChange]);

  const decrementQuantity = useCallback(() => {
    const nextValue = quantity - 1;
    const clampedValue = Math.max(nextValue, min);
    if (clampedValue === quantity) return;
    updateQuantity(clampedValue);
    if (onChange) {
      onChange(clampedValue);
    }
  }, [quantity, min, updateQuantity, onChange]);

  return (
    <InputGroup
      className={classNames(styles.wrapper, className, {
        [styles.fullWidth]: fullWidth,
        [styles.sm]: size === 'sm',
        [styles.lg]: size === 'lg',
      })}
    >
      <button
        className={classNames(styles.button, buttonClassName)}
        onClick={decrementQuantity}
        disabled={disabled || quantity <= min}
        aria-label="Decrement quantity"
        type="button"
      >
        <IconMinus className={styles.icon} size={16} strokeWidth={2} />
      </button>
      <Input
        name={name}
        id={name}
        className={classNames(styles.input, inputClassName)}
        type="number"
        bsSize={size}
        min={min}
        max={max}
        disabled={disabled}
        onChange={handleInputChange}
        value={quantity}
        {...rest}
      />
      <button
        className={classNames(styles.button, buttonClassName)}
        onClick={incrementQuantity}
        disabled={disabled || quantity >= (max ?? Infinity)}
        aria-label="Increase quantity"
        type="button"
      >
        <IconPlus className={styles.icon} size={16} strokeWidth={2} />
      </button>
    </InputGroup>
  );
};
