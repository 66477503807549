import { useMemo } from 'react';
import round from 'lodash/round';
import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { BillingItemSourceType, InvoiceBillingItemOption, InvoiceDiscountType, InvoiceEligibility } from 'app/types';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

interface Limit {
  min: number;
  max: number;
}

interface Limits {
  [ItemField.totalQuantity]: Limit;
  [ItemField.discountValue]: Limit;
}

export const useLimits = (
  item: InvoiceBillingItemOption | undefined,
  itemSourceType: BillingItemSourceType | undefined,
  form: string,
) => {
  const { isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const selectedItem = useAppSelector((state) => state.invoicingProcess.selectedItem);
  const invoicingProcessItems = useAppSelector((state) => state.invoicingProcess.data?.steps?.items);
  const totalQuantity: ItemFormData['totalQuantity'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.totalQuantity],
  );
  const discountType: ItemFormData['discountType'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.discountType],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const isPercentageDiscountType = discountType === InvoiceDiscountType.Percentage;
  const isReferralInOrPreviousApproval =
    eligibility?.value === InvoiceEligibility.ReferralIn || eligibility?.value === InvoiceEligibility.PreviousApproval;

  return useMemo<Limits>(() => {
    // Existing item values
    const invoicingProcessItem = invoicingProcessItems?.find((i) => i.id === selectedItem?.id);

    // Current item values (may not be added yet)
    const isPackageItem = itemSourceType === BillingItemSourceType.Package;
    const itemPrice = item?.details.price || 0;
    const itemQuantity = item?.details.quantity || totalQuantity || 1;
    const itemTotalPrice = round(itemPrice * itemQuantity, 2);
    const itemDiscountMax = isPercentageDiscountType ? 100 : itemTotalPrice;
    const maxItemQuantity = isPackageItem ? 1 : itemQuantity;

    // Credit Note with existing item
    if (isCreateCreditNoteMode && invoicingProcessItem) {
      return {
        totalQuantity: {
          min: 1,
          max: isPackageItem ? 1 : invoicingProcessItem.quantity,
        },
        discountValue: {
          min: 0,
          max: itemDiscountMax,
        },
      };
    }

    // Eligibility is set to Referral-in or Previous Approved
    if (isReferralInOrPreviousApproval) {
      return {
        totalQuantity: {
          min: 1,
          max: maxItemQuantity,
        },
        discountValue: {
          min: 0,
          max: itemDiscountMax,
        },
      };
    }

    // Item from the package
    if (!!invoicePackageSnapshotId) {
      return {
        totalQuantity: {
          min: 1,
          max: maxItemQuantity,
        },
        discountValue: {
          min: 0,
          max: 0,
        },
      };
    }

    // Default limits
    return {
      totalQuantity: {
        min: 1,
        max: isPackageItem ? 1 : 99999,
      },
      discountValue: {
        min: 0,
        max: itemDiscountMax,
      },
    };
  }, [
    invoicePackageSnapshotId,
    invoicingProcessItems,
    isCreateCreditNoteMode,
    isPercentageDiscountType,
    isReferralInOrPreviousApproval,
    item,
    itemSourceType,
    totalQuantity,
    selectedItem?.id,
  ]);
};
