import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  AdminUser,
  ClinicUser,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  UserLog,
} from 'app/types';
import { baseQueryWithReAuth } from 'app/helpers';
import { AdminUserBodyParams, ClinicUserBodyParams } from 'app/services/users/types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['AdminUsers', 'ClinicUsers', 'UserLog', 'ClinicUser', 'AdminUser'],
  endpoints: (builder) => ({
    getAdminUsers: builder.query<QueryResponseWithPagination<AdminUser[]>, QueryParams | undefined>({
      query: (params) => ({
        url: 'admins',
        method: 'GET',
        params,
      }),
      providesTags: ['AdminUsers'],
    }),

    getClinicUsers: builder.query<
      QueryResponseWithPagination<ClinicUser[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `clinics/${clinicId}/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['ClinicUsers'],
    }),

    getUserLogs: builder.query<
      QueryResponseWithPagination<UserLog[]>,
      { userId: string; params: QueryParams | undefined }
    >({
      query: ({ params, userId }) => ({
        url: `/users/${userId}/logs`,
        params,
      }),
      providesTags: ['UserLog'],
    }),

    // Connected with Clinic Modules and Permissions
    getClinicUserActualConfiguration: builder.query<QueryResponse<ClinicUser>, { clinicId: string; userId: string }>({
      query: ({ clinicId, userId }) => ({
        method: 'GET',
        url: `clinics/${clinicId}/users/${userId}/actual-configuration`,
      }),
      providesTags: ['ClinicUser'],
    }),

    addClinicUser: builder.mutation<void, { clinicId: string; body: ClinicUserBodyParams }>({
      query: ({ clinicId, body }) => ({
        method: 'POST',
        url: `/clinics/${clinicId}/users`,
        body,
      }),
      invalidatesTags: ['ClinicUsers'],
    }),

    editClinicUser: builder.mutation<void, { userId: string; body: ClinicUserBodyParams }>({
      query: ({ userId, body }) => ({
        method: 'PUT',
        url: `/clinics/users/${userId}`,
        body,
      }),
      invalidatesTags: ['ClinicUsers', 'ClinicUser'],
    }),

    getAdminUser: builder.query<QueryResponse<AdminUser>, string>({
      query: (adminUserId) => ({
        method: 'GET',
        url: `/users/${adminUserId}`,
      }),
      providesTags: ['AdminUser'],
    }),

    addAdminUser: builder.mutation<void, AdminUserBodyParams>({
      query: (body) => ({
        method: 'POST',
        url: '/admins',
        body,
      }),
      invalidatesTags: ['AdminUsers'],
    }),

    editAdminUser: builder.mutation<void, { adminUserId: string; body: AdminUserBodyParams }>({
      query: ({ adminUserId, body }) => ({
        method: 'PUT',
        url: `/admins/${adminUserId}`,
        body,
      }),
      invalidatesTags: ['AdminUser', 'AdminUsers'],
    }),

    getUserLogsReport: builder.query<void, { userId: string; params: QueryParams | undefined }>({
      query: ({ params, userId }) => ({
        params,
        url: `users/${userId}/logs/excel`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAdminUsersQuery,
  useGetClinicUsersQuery,
  useGetUserLogsQuery,
  useGetClinicUserActualConfigurationQuery,
  useAddClinicUserMutation,
  useEditClinicUserMutation,
  useGetAdminUserQuery,
  useAddAdminUserMutation,
  useEditAdminUserMutation,
  useLazyGetUserLogsReportQuery,
} = usersApi;
