import { put, select } from 'redux-saga/effects';
import { getIdFromPathname } from 'app/helpers/getIdFromPathname/getIdFromPathname';
import { patientsApi } from 'app/services/patients/patients';
import { RootState } from 'app/types';

export function* refreshPatientList() {
  const {
    router: {
      location: { pathname },
    },
    queryParams: { clinicPatients: clinicPatientsParams, patients: patientsParams },
  }: RootState = yield select();

  if (pathname && pathname.includes('patients')) {
    const clinicId = getIdFromPathname('clinics', pathname);

    if (clinicId) {
      // Refresh clinic's patient list
      yield put<any>(
        patientsApi.endpoints.getClinicPatients.initiate(
          { clinicId, params: clinicPatientsParams },
          { subscribe: false, forceRefetch: true },
        ),
      );
    } else {
      // Refresh patient list
      yield put<any>(
        patientsApi.endpoints.getPatients.initiate(patientsParams, { subscribe: false, forceRefetch: true }),
      );
    }
  }
}
