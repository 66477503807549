import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  BranchShortInfoOption,
  DeclarationBody,
  IntegrateWithBranchBody,
  ProductBody,
} from 'app/services/branches/types';
import {
  Branch,
  BranchDeclaration,
  BranchDeclarationLogsItem,
  BranchDeclarationStatus,
  BranchIntegrationSettings,
  BranchLoomxSettings,
  BranchProduct,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';
import { BranchBodyParams } from 'app/features/branch/modals/form/types';

export const branchesApi = createApi({
  reducerPath: 'branchesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['BranchDeclarations', 'Branches', 'BranchProducts', 'Branch', 'BranchLoomxSettings'],
  endpoints: (builder) => ({
    getBranchShortInfo: builder.query<QueryResponse<BranchShortInfoOption>, string>({
      query: (branchId) => ({
        method: 'GET',
        url: `branches/${branchId}/short-info`,
      }),
    }),

    getBranch: builder.query<QueryResponse<Branch>, string>({
      query: (branchId) => ({
        method: 'GET',
        url: `/branches/${branchId}`,
      }),
      providesTags: ['Branch'],
    }),

    getBranchLoomxSettings: builder.query<QueryResponse<BranchLoomxSettings>, string>({
      query: (branchId) => ({
        method: 'GET',
        url: `/branches/${branchId}/integration-flags`,
      }),
      providesTags: ['BranchLoomxSettings'],
    }),

    getBranchDeclaration: builder.query<BranchDeclaration, string>({
      query: (declarationId) => {
        return {
          url: `/branch-declarations/${declarationId}`,
        };
      },
    }),

    getBranchDeclarations: builder.query<
      QueryResponseWithPagination<BranchDeclaration[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `/branches/${branchId}/declarations/active`,
          params,
        };
      },
      providesTags: ['BranchDeclarations'],
    }),

    getBranchDeclarationLogs: builder.query<
      QueryResponseWithPagination<BranchDeclarationLogsItem[]>,
      { declarationId: string; params: QueryParams | undefined }
    >({
      query: ({ declarationId, params }) => {
        return {
          url: `/branch-declarations/${declarationId}/actions`,
          params,
        };
      },
    }),

    getDeletedBranchDeclarations: builder.query<
      QueryResponseWithPagination<BranchDeclaration[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `/branches/${branchId}/declarations/deleted`,
          params,
        };
      },
      providesTags: ['BranchDeclarations'],
    }),

    createDeclaration: builder.mutation<void, { branchId: string; body: DeclarationBody }>({
      query: ({ branchId, body }) => ({
        url: `/branches/${branchId}/declarations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),

    editDeclaration: builder.mutation<void, { declarationId: string; body: DeclarationBody }>({
      query: ({ declarationId, body }) => ({
        url: `/branch-declarations/${declarationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),

    changeDeclarationStatus: builder.mutation<void, { declarationId: string; status: BranchDeclarationStatus }>({
      query: ({ declarationId, status }) => ({
        url: `/branch-declarations/${declarationId}/change-status/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),

    getBranchesByClinic: builder.query<
      QueryResponseWithPagination<Branch[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/branches`,
        params,
      }),
      providesTags: ['Branches'],
    }),

    getDeletedBranchesByClinic: builder.query<
      QueryResponseWithPagination<Branch[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/deleted-branches`,
        params,
      }),
      providesTags: ['Branches'],
    }),

    getBranchProducts: builder.query<
      QueryResponseWithPagination<BranchProduct[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `branches/${branchId}/products`,
          params,
        };
      },
      providesTags: ['BranchProducts'],
    }),

    addBranchProducts: builder.mutation<
      void,
      {
        body: ProductBody;
        branchId: string;
        params: QueryParams | undefined;
      }
    >({
      query: ({ body, params, branchId }) => ({
        url: `/branches/${branchId}/products`,
        method: 'POST',
        params,
        body,
      }),
      invalidatesTags: ['BranchProducts'],
    }),

    editBranchProducts: builder.mutation<void, { body: ProductBody; branchId: string; serviceId: string }>({
      query: ({ body, branchId, serviceId }) => ({
        url: `/branches/${branchId}/products/${serviceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchProducts'],
    }),

    changeServiceStatus: builder.mutation<void, { branchId: string; serviceId: string; status: string }>({
      query: ({ branchId, serviceId, status }) => ({
        url: `/branches/${branchId}/products/${serviceId}/status`,
        method: 'PUT',
        params: {
          newStatus: status,
        },
      }),
      invalidatesTags: ['BranchProducts'],
    }),

    changeBranchStatus: builder.mutation<void, { status: string; branchId: string }>({
      query: ({ branchId, status }) => ({
        url: `/branches/${branchId}/status`,
        method: 'PUT',
        body: { status },
      }),
    }),

    editBranchImages: builder.mutation<
      void,
      {
        branchId: string;
        body: BranchBodyParams;
      }
    >({
      query: ({ branchId, body }) => ({
        url: `/branches/${branchId}`,
        method: 'PUT',
        body,
      }),
    }),

    addBranch: builder.mutation<void, { body: BranchBodyParams; clinicId: string }>({
      query: ({ body, clinicId }) => ({
        url: `/clinics/${clinicId}/branches`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Branches'],
    }),

    editBranch: builder.mutation<void, { body: BranchBodyParams; branchId: string }>({
      query: ({ body, branchId }) => ({
        url: `branches/${branchId}`,
        method: 'PUT',
        body,
      }),
    }),

    integrateWithBranch: builder.mutation<void, { body: IntegrateWithBranchBody }>({
      query: ({ body }) => ({
        url: 'integration/branch',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Branch'],
    }),

    updateBranchIntegrationSettings: builder.mutation<void, { body: BranchIntegrationSettings; branchId: string }>({
      query: ({ body, branchId }) => ({
        url: `branches/update-integration-settings/${branchId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Branch'],
    }),
  }),
});

export const {
  useGetBranchShortInfoQuery,
  useChangeDeclarationStatusMutation,
  useCreateDeclarationMutation,
  useEditDeclarationMutation,
  useGetBranchDeclarationLogsQuery,
  useGetBranchDeclarationsQuery,
  useGetBranchQuery,
  useGetBranchLoomxSettingsQuery,
  useGetDeletedBranchDeclarationsQuery,
  useLazyGetBranchDeclarationQuery,
  useGetBranchesByClinicQuery,
  useGetDeletedBranchesByClinicQuery,
  useGetBranchProductsQuery,
  useAddBranchProductsMutation,
  useEditBranchProductsMutation,
  useChangeServiceStatusMutation,
  useChangeBranchStatusMutation,
  useEditBranchImagesMutation,
  useAddBranchMutation,
  useEditBranchMutation,
  useIntegrateWithBranchMutation,
  useUpdateBranchIntegrationSettingsMutation,
} = branchesApi;
