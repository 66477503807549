import { put, select } from 'redux-saga/effects';
import { patientsArrivalApi } from 'app/services/patientsArrival/patientsArrival';
import { RootState } from 'app/types';

export function* refreshPatientsArrival() {
  const {
    queryParams: { patientsArrival: params },
  }: RootState = yield select();
  if (params && params.branchId) {
    yield put<any>(patientsArrivalApi.endpoints.getArrivals.initiate(params, { subscribe: false, forceRefetch: true }));
  }
}
