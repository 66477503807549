import { useCallback } from 'react';
import moment from 'moment';
import { defineMessage } from 'react-intl';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { formattedDate, useAppIntl } from 'app/helpers';
import { Locale } from 'app/types';
import { SlotOption } from 'app/features/appointment/form/types';

export const useSlotLabelFormatter = (showSlotsWithNumber: boolean, selectedDate: Date | null) => {
  const { formatMessage, locale } = useAppIntl();

  return useCallback(
    (option: SlotOption, meta: FormatOptionLabelMeta<SlotOption>) => {
      const numberOfAppointments = option.details?.numberOfAppointments;
      const showNumberOfAppointments = showSlotsWithNumber && !!numberOfAppointments && numberOfAppointments > 0;
      const isSameDate = moment(selectedDate).isSame(moment(option.value.start), 'day');

      const message = defineMessage(
        locale === Locale.ar
          ? {
              id: 'bookedAppointmentsCount',
              defaultMessage: 'يوجد {count, plural, one {# موعد محجوز} other {# موعد محجوز}}',
            }
          : {
              id: 'bookedAppointmentsCount',
              defaultMessage: '{count, plural, one {# booked appointment} other {# booked appointments}}',
            },
      );

      return (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="text-nowrap">{option.label}</div>
            {!isSameDate && meta.context !== 'value' && (
              <span className="text-nowrap small">{formattedDate(option.value.start)}</span>
            )}
          </div>
          {showNumberOfAppointments && (
            <small className="text-right text-danger ms-2 bg-white px-2 py-1 rounded-3">
              {formatMessage({ ...message }, { count: numberOfAppointments })}
            </small>
          )}
        </div>
      );
    },
    [formatMessage, locale, selectedDate, showSlotsWithNumber],
  );
};
