import { useRef } from 'react';
import { ContactShadows, useBVH, useGLTF } from '@react-three/drei';
import { Mesh, MeshPhongMaterial } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

const filesUrl = `${process.env.PUBLIC_URL}/files/models/female-transformed.glb`;

const material = new MeshPhongMaterial({
  color: '#dedede',
  flatShading: false,
  shininess: 120,
});

type ModelResult = GLTF & {
  nodes: {
    Body: Mesh;
  };
  materials: {};
};

export const FemaleBodyModel = (props: JSX.IntrinsicElements['group']) => {
  const meshRef = useRef<Mesh>(null!);
  const { nodes } = useGLTF(filesUrl) as unknown as ModelResult;
  useBVH(meshRef);

  return (
    <group dispose={null}>
      <group scale={1.35} position={[0, 0.12, 0]} rotation={[Math.PI / 2, 0, 0]} {...props}>
        <mesh geometry={nodes.Body.geometry} material={material} ref={meshRef} />
      </group>
      <ContactShadows opacity={0.35} scale={5} blur={5} position={[0, -1.22, 0]} />
    </group>
  );
};
