import { RSAA } from 'redux-api-middleware';
import * as types from './clinic.types';

export const addClinic = (body, registration) => {
  return {
    [RSAA]: {
      types: [
        types.ADD_CLINIC_REQUEST,
        {
          type: types.ADD_CLINIC_SUCCESS,
          meta: {
            registration,
            notification: {
              type: 'success',
              title: 'CLINICS.NOTIFICATIONS.ADD-SUCCESS',
            },
          },
        },
        {
          type: types.ADD_CLINIC_FAILURE,
          meta: {
            notification: {
              type: 'danger',
              title: 'CLINICS.NOTIFICATIONS.ADD-FAILURE',
            },
          },
        },
      ],
      endpoint: '/clinics',
      method: 'POST',
      body,
    },
  };
};

export const fetchClinic = (clinicId) => {
  return {
    [RSAA]: {
      endpoint: `/clinics/${clinicId}`,
      method: 'GET',
      types: [
        types.FETCH_CLINIC_REQUEST,
        {
          type: types.FETCH_CLINIC_SUCCESS,
          meta: { clinicId },
        },
        types.FETCH_CLINIC_FAILURE,
      ],
    },
  };
};

export const fetchClinicMode = (clinicId) => {
  return {
    [RSAA]: {
      endpoint: `/clinics/${clinicId}/mode`,
      method: 'GET',
      types: [types.FETCH_CLINIC_MODE_REQUEST, types.FETCH_CLINIC_MODE_SUCCESS, types.FETCH_CLINIC_MODE_FAILURE],
    },
  };
};

export const fetchClinicSystemOwner = (clinicId) => {
  return {
    [RSAA]: {
      types: [types.FETCH_SYSTEM_OWNER_REQUEST, types.FETCH_SYSTEM_OWNER_SUCCESS, types.FETCH_SYSTEM_OWNER_FAILURE],
      endpoint: `/clinics/${clinicId}/systemOwner`,
      method: 'GET',
    },
  };
};
