import { createAction } from 'redux-api-middleware';
import * as types from 'app/redux/invoicingProcess/invoicingProcess.types';
import {
  CreditNoteBody,
  CreditNoteConfiguration,
  InvoicingProcessBody,
  InvoicingProcessInitialValues,
  InvoicingProcessMode,
  InvoicingProcessPayload,
  InvoicingProcessStep,
  Payment,
  ResetOptions,
} from 'app/redux/invoicingProcess/types';
import {
  InvoiceInsuranceApprovalItem,
  InvoiceInsuranceReferralItem,
  InvoiceItem,
  InvoicePaymentMethodItem,
  InvoicePaymentMode,
  QueryResponse,
} from 'app/types';

export const setMode = (mode: InvoicingProcessMode | null) => ({
  type: types.SET_MODE,
  payload: mode,
});

export const setStep = (step: InvoicingProcessStep | null) => ({
  type: types.SET_STEP,
  payload: step,
});

export const setAppointment = (appointmentId: string | null) => ({
  type: types.SET_APPOINTMENT,
  payload: appointmentId,
});

export const setRequest = (requestId: string | null) => ({
  type: types.SET_REQUEST,
  payload: requestId,
});

export const setInvoicePackageSnapshotId = (packageSnapshotId: string | null) => ({
  type: types.SET_INVOICE_PACKAGE_SNAPSHOT_ID,
  payload: packageSnapshotId,
});

export const setInsuranceCompanyCacheUniq = (insuranceCompanyCacheUniq: string | null) => ({
  type: types.SET_INSURANCE_COMPANY_CACHE_UNIQ,
  payload: insuranceCompanyCacheUniq,
});

export const selectItem = (item: InvoiceItem) => ({
  type: types.SELECT_ITEM,
  payload: item,
});

export const resetItems = () => ({
  type: types.RESET_INVOICE_ITEMS,
});

export const selectPaymentMethodItem = (paymentMethodItem: InvoicePaymentMethodItem) => ({
  type: types.SELECT_PAYMENT_METHOD_ITEM,
  payload: paymentMethodItem,
});

export const addInvoiceItem = (item: InvoiceItem) => ({
  type: types.ADD_INVOICE_ITEM,
  payload: item,
});

export const editInvoiceItem = (item: InvoiceItem) => ({
  type: types.EDIT_INVOICE_ITEM,
  payload: item,
});

export const deleteInvoiceItem = (id: string) => ({
  type: types.DELETE_INVOICE_ITEM,
  payload: id,
});

export const addPaymentMethodItem = (item: InvoicePaymentMethodItem) => ({
  type: types.ADD_PAYMENT_METHOD_ITEM,
  payload: item,
});

export const eitPaymentMethodItem = (item: InvoicePaymentMethodItem) => ({
  type: types.EDIT_PAYMENT_METHOD_ITEM,
  payload: item,
});

export const deletePaymentMethodItem = (id: string) => ({
  type: types.DELETE_PAYMENT_METHOD_ITEM,
  payload: id,
});

export const setCreditNoteConfiguration = (configuration: CreditNoteConfiguration) => ({
  type: types.SET_CREDIT_NOTE_CONFIGURATION,
  payload: configuration,
});

export const initialize = (initialValues: InvoicingProcessInitialValues) => ({
  type: types.INITIALIZE,
  payload: initialValues,
});

export const reset = (options?: ResetOptions) => ({
  type: types.RESET,
  payload: options,
});

export const setReservationId = (reservationId: string) => ({
  type: types.SET_RESERVATION_ID,
  payload: reservationId,
});

export const fetchInvoice = (invoiceId: string) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `invoices/new/${invoiceId}`,
    method: 'GET',
    types: [types.FETCH_INVOICE_REQUEST, types.FETCH_INVOICE_SUCCESS, types.FETCH_INVOICE_FAILURE],
  });

export const fetchDraft = (draftInvoiceId: string) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `draft-invoices/${draftInvoiceId}`,
    method: 'GET',
    types: [types.FETCH_DRAFT_REQUEST, types.FETCH_DRAFT_SUCCESS, types.FETCH_DRAFT_FAILURE],
  });

export const fetchCreditNote = (invoiceId: string) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `invoices/new/${invoiceId}/create-credit-note`,
    method: 'GET',
    types: [types.FETCH_CREDIT_NOTE_REQUEST, types.FETCH_CREDIT_NOTE_SUCCESS, types.FETCH_CREDIT_NOTE_FAILURE],
  });

export const fetchEmptyInvoiceWithAppointment = (appointmentId: string) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `appointments/${appointmentId}/empty-invoice`,
    method: 'GET',
    types: [
      types.FETCH_EMPTY_WITH_APPOINTMENT_REQUEST,
      types.FETCH_EMPTY_WITH_APPOINTMENT_SUCCESS,
      types.FETCH_EMPTY_WITH_APPOINTMENT_FAILURE,
    ],
  });

export const fetchEmptyInvoiceWithoutAppointment = (
  branchId: string,
  doctorId: string,
  patientId: string,
  guardianPatientId?: string,
) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `branches/${branchId}/doctors/${doctorId}/patients/${patientId}/empty-invoice`,
    method: 'GET',
    types: [
      types.FETCH_EMPTY_WITHOUT_APPOINTMENT_REQUEST,
      types.FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS,
      types.FETCH_EMPTY_WITHOUT_APPOINTMENT_FAILURE,
    ],
    // @ts-ignore
    params: { guardianPatientId },
  });

export const fetchEmptyByRequestId = (requestId: string) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `invoice-requests/${requestId}/empty-invoice`,
    method: 'GET',
    types: [
      types.FETCH_EMPTY_BY_REQUEST_REQUEST,
      types.FETCH_EMPTY_BY_REQUEST_SUCCESS,
      types.FETCH_EMPTY_BY_REQUEST_FAILURE,
    ],
  });

export const fetchInsuranceReferralItems = (referralInsuranceApprovalId: string, insuranceClassId: string) =>
  createAction<any, QueryResponse<InvoiceInsuranceReferralItem>, undefined>({
    endpoint: `referral-insurance-approvals/${referralInsuranceApprovalId}/items-for-invoice`,
    method: 'GET',
    types: [
      types.FETCH_INSURANCE_REFERRAL_ITEMS_REQUEST,
      types.FETCH_INSURANCE_REFERRAL_ITEMS_SUCCESS,
      types.FETCH_INSURANCE_REFERRAL_ITEMS_FAILURE,
    ],
    // @ts-ignore custom params middleware
    params: { insuranceClassId },
  });

export const fetchInsuranceApprovalItems = (insuranceApprovalId: string) =>
  createAction<any, QueryResponse<InvoiceInsuranceApprovalItem>, undefined>({
    endpoint: 'insurance-approvals/items-for-invoice',
    method: 'GET',
    types: [
      types.FETCH_INSURANCE_APPROVAL_ITEMS_REQUEST,
      types.FETCH_INSURANCE_APPROVAL_ITEMS_SUCCESS,
      types.FETCH_INSURANCE_APPROVAL_ITEMS_FAILURE,
    ],
    // @ts-ignore custom params middleware
    params: { insuranceApprovalId },
  });

export const createInvoiceWithAppointment = (appointmentId: string, body: InvoicingProcessBody) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `appointments/${appointmentId}/create-invoice`,
    method: 'POST',
    types: [
      types.CREATE_WITH_APPOINTMENT_REQUEST,
      types.CREATE_WITH_APPOINTMENT_SUCCESS,
      types.CREATE_WITH_APPOINTMENT_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const createInvoiceWithoutAppointment = (
  branchId: string,
  doctorId: string,
  patientId: string,
  body: InvoicingProcessBody,
) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `branches/${branchId}/doctors/${doctorId}/patients/${patientId}/create-invoice`,
    method: 'POST',
    types: [
      types.CREATE_WITHOUT_APPOINTMENT_REQUEST,
      types.CREATE_WITHOUT_APPOINTMENT_SUCCESS,
      types.CREATE_WITHOUT_APPOINTMENT_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const createDraftWithAppointment = (appointmentId: string, body: InvoicingProcessBody) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `appointments/${appointmentId}/create-draft-invoice`,
    method: 'POST',
    types: [
      types.CREATE_DRAFT_WITH_APPOINTMENT_REQUEST,
      types.CREATE_DRAFT_WITH_APPOINTMENT_SUCCESS,
      types.CREATE_DRAFT_WITH_APPOINTMENT_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const createDraftWithoutAppointment = (
  branchId: string,
  doctorId: string,
  patientId: string,
  body: InvoicingProcessBody,
) =>
  createAction<any, InvoicingProcessPayload, undefined>({
    endpoint: `branches/${branchId}/doctors/${doctorId}/patients/${patientId}/create-draft-invoice`,
    method: 'POST',
    types: [
      types.CREATE_DRAFT_WITHOUT_APPOINTMENT_REQUEST,
      types.CREATE_DRAFT_WITHOUT_APPOINTMENT_SUCCESS,
      types.CREATE_DRAFT_WITHOUT_APPOINTMENT_FAILURE,
    ],
    // @ts-ignore custom body middleware
    body,
  });

export const createCreditNote = (body: CreditNoteBody, isCompleteRefund: boolean = false) =>
  createAction({
    endpoint: `credit-notes`,
    method: 'POST',
    types: [types.CREATE_CREDIT_NOTE_REQUEST, types.CREATE_CREDIT_NOTE_SUCCESS, types.CREATE_CREDIT_NOTE_FAILURE],
    params: {
      isCompleteRefund,
    },
    // @ts-ignore custom body middleware
    body,
  });

export const updatePayments = (invoiceId: string, body: Payment[]) =>
  createAction({
    endpoint: `invoices/${invoiceId}/patient-payments/update-payments`,
    method: 'POST',
    types: [types.UPDATE_PAYMENTS_REQUEST, types.UPDATE_PAYMENTS_SUCCESS, types.UPDATE_PAYMENTS_FAILURE],
    // @ts-ignore custom body middleware
    body,
  });

export const fetchPackageSnapshotPaymentMode = (packageSnapshotId: string) =>
  createAction<any, QueryResponse<InvoicePaymentMode>, undefined>({
    endpoint: `package-snapshots/${packageSnapshotId}/payment-mode`,
    method: 'GET',
    types: [
      types.FETCH_PACKAGE_PAYMENT_MODE_REQUEST,
      types.FETCH_PACKAGE_PAYMENT_MODE_SUCCESS,
      types.FETCH_PACKAGE_PAYMENT_MODE_FAILURE,
    ],
  });

export const calculatePackageActions = (body: CreditNoteBody, isCompleteRefund: boolean = false) =>
  createAction({
    endpoint: 'credit-notes/calculate-package-actions',
    method: 'POST',
    types: [
      types.CALCULATE_PACKAGE_ACTIONS_REQUEST,
      types.CALCULATE_PACKAGE_ACTIONS_SUCCESS,
      types.CALCULATE_PACKAGE_ACTIONS_FAILURE,
    ],
    params: {
      isCompleteRefund,
    },
    // @ts-ignore custom body middleware
    body,
  });
