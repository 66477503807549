import { useCallback } from 'react';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { useBranchERPSettings } from 'app/helpers/useBranchERPSettings/useBranchERPSettings';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { toggleModal } from 'app/redux/modals/modals.actions';

interface ZeroInventoryWarningModalProps {
  branchId?: string;
  onClose: () => void;
}
export const ZeroInventoryWarningModal = ({ branchId, onClose }: ZeroInventoryWarningModalProps) => {
  const { formatMessage } = useAppIntl();
  const dispatch = useAppDispatch();

  const { isOpen: isZeroInventoryWarningOpen } = useAppSelector((state) => state.modal.zeroInventoryWarning);

  const { hasERPIntegration, hasAllowedZeroCountItemsReservation } = useBranchERPSettings({ branchId });

  const allowForZeroCountItems = hasERPIntegration && hasAllowedZeroCountItemsReservation;

  const toggleZeroInventoryWarningModal = useCallback(() => {
    dispatch(toggleModal('zeroInventoryWarning'));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    onClose();
    toggleZeroInventoryWarningModal();
  }, [onClose, toggleZeroInventoryWarningModal]);

  return (
    <ConfirmationModal
      isOpen={isZeroInventoryWarningOpen}
      onClose={handleClose}
      onConfirm={allowForZeroCountItems ? toggleZeroInventoryWarningModal : handleClose}
      description={formatMessage({
        id: allowForZeroCountItems
          ? 'CORE.TEXT.ZERO-INVENTORY-ALLOWED-WARNING'
          : 'CORE.TEXT.ZERO-INVENTORY-DENIED-WARNING',
      })}
    />
  );
};
