import { Option } from 'app/types/Option';
import { RemoteFile } from './RemoteFile';

export enum MassAddingType {
  PatientMedicalRecords = '1',
  PatientsAndAppointments = '0',
}

export enum MassAddingBranchStatus {
  Offline = 'Offline',
  Online = 'Online',
}

export enum MassAddingImportStatus {
  NotStarted = '0',
  NotAllRowsUploaded = '1',
  NoRowsUploaded = '2',
  AllRowsUploaded = '3',
}

export interface MassAddingProgress {
  error: number;
  percent: number;
  processed: number;
  status: Option<MassAddingImportStatus>;
  success: number;
  total: number;
}

export interface MassAddingRow {
  errors: string[];
  isProcessingFinished: boolean;
  isSuccess: boolean;
  rowNumber: number;
  warnings: string[];
}

export interface MassAddingItem {
  branch: Option;
  clinic: Option;
  createdBy: Option;
  endedAt: string;
  id: string;
  progress: MassAddingProgress;
  rowsCount: number;
  startedAt: string;
  timeOfCreation: string;
  type: Option<MassAddingType>;
  uploadedFileId: string;
}

export interface MassAddingItemDetails {
  branch: Option;
  clinic: Option;
  createdBy: Option;
  endedAt: string | null;
  id: string;
  startedAt: string;
  timeOfCreation: string;
  type: Option<MassAddingType>;
  uploadedFile: RemoteFile | null;
}
