import React, { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import { hasSubmitSucceeded } from 'redux-form';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_PRE_SEARCH_CLINIC_PATIENT } from 'app/shared';
import { useLazySearchClinicPatientByPhoneNumberQuery } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { QueryParams } from 'app/types';
import { PreSearchClinicPatientForm } from 'app/features/patient/preSearchClinicPatientModal/form/preSearchClinicPatientForm';
import {
  PreSearchClinicPatientFormData,
  PreSearchDependentResult,
} from 'app/features/patient/preSearchClinicPatientModal/form/types';

interface Props {
  clinicId: string;
  openAddPatientModal: (phoneNumber: string) => void;
  openAddDependentModal: (result: PreSearchDependentResult) => void;
}

export const PreSearchClinicPatientModal = ({ clinicId, openAddPatientModal, openAddDependentModal }: Props) => {
  const dispatch = useAppDispatch();

  // Local State
  const [params, setParams] = useState<QueryParams | undefined>();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.preSearchClinicPatient.isOpen);
  const submitSucceeded = useAppSelector((state) => hasSubmitSucceeded(FORMS_PRE_SEARCH_CLINIC_PATIENT)(state));

  // Requests
  const [triggerSearchClinicPatient, { data, isLoading, isFetching }] = useLazySearchClinicPatientByPhoneNumberQuery();

  // Helper Variables
  const isNumberUnique = !data?.data.length;
  const modalSize = useMemo(
    () => (submitSucceeded && !!data?.data?.length ? 'lg' : 'md'),
    [data?.data, submitSucceeded],
  );

  const onToggle = useCallback(() => {
    dispatch(toggleModal('preSearchClinicPatient'));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: PreSearchClinicPatientFormData) => {
      try {
        const response = await triggerSearchClinicPatient({
          params: {
            ...params,
            searchPhoneNumber: values.phoneNumber,
            pageNumber: '1',
            pageSize: '10',
          },
          clinicId,
        }).unwrap();

        if (response?.data.length === 0) {
          dispatch(toggleModal('preSearchClinicPatient', false));
          openAddPatientModal(values.phoneNumber);
        }
      } catch (error) {
        toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
      }
    },
    [clinicId, dispatch, openAddPatientModal, params, triggerSearchClinicPatient],
  );

  // Effects
  useModalCleaner('preSearchClinicPatient');

  return (
    <Modal size={modalSize} backdrop="static" keyboard={false} isOpen={isOpen} toggle={onToggle}>
      <PreSearchClinicPatientForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        isFetching={isFetching}
        isNumberUnique={isNumberUnique}
        data={data}
        params={params}
        setParams={setParams}
        openAddPatientModal={openAddPatientModal}
        openAddDependentModal={openAddDependentModal}
      />
    </Modal>
  );
};
