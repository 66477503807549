import { useMemo } from 'react';
import { useCurrentUser } from 'app/helpers';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers/useInvoicingProcessState';

export const usePaymentsState = () => {
  const { isCreateCreditNoteMode } = useInvoicingProcessState();
  const { currentUser, isViewer } = useCurrentUser();

  const canUserManagePayments = useMemo(
    () =>
      (!!currentUser.allowedForCreateInvoice || (!!currentUser.allowedForEditInvoice && isCreateCreditNoteMode)) &&
      !isViewer,
    [currentUser, isCreateCreditNoteMode, isViewer],
  );

  return {
    canUserManagePayments,
  };
};
