import { BillingItemSourceType, InvoiceBillingItemOption, InvoiceDiscountType, Option } from 'app/types';

export const ItemField = {
  discountType: 'discountType',
  discountValue: 'discountValue',
  item: 'item',
  itemSourceType: 'itemSourceType',
  totalQuantity: 'totalQuantity',
  totalPrice: 'totalPrice',
  storageQuantity: 'storageQuantity',
} as const;

export interface ItemFormData {
  [ItemField.discountType]: InvoiceDiscountType;
  [ItemField.discountValue]?: string;
  [ItemField.itemSourceType]: Option<BillingItemSourceType>;
  [ItemField.item]: InvoiceBillingItemOption;
  [ItemField.totalQuantity]: number;
  [ItemField.totalPrice]: number;
  [ItemField.storageQuantity]?: Record<string, number>;
}
