import { useMemo } from 'react';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { Button, Col, InputGroupText, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { Field, Form, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl, useAppSelector, useDictionaries } from 'app/helpers';
import { SarCurrencySymbol } from 'app/components/sarCurrencySymbol/sarCurrencySymbol';
import { FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD, InputComponent, required, SelectComponent } from 'app/shared';
import { PatientPaymentMethod, Status } from 'app/types';
import { useAccountCredit } from 'app/features/invoicingProcess/process/helpers/useAccountCredit';
import { useLeftToPay } from 'app/features/invoicingProcess/process/paymentMethod/helpers/useLeftToPay';
import {
  PaymentMethodField,
  PaymentMethodFormData,
} from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/types';

const FormComponent = ({ form, handleSubmit, initialValues }: InjectedFormProps<PaymentMethodFormData>) => {
  const { formatMessage } = useAppIntl();
  const { 'patient-payment-methods': patientPaymentMethods } = useDictionaries();
  const isEditMode = form === FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD;
  const paymentMethodValue: PatientPaymentMethod | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentMethodField.paymentMethod]?.value,
  );
  const updatePaymentsLoading = useAppSelector((state) => state.invoicingProcess.updatePaymentsLoading);
  const isOtherPaymentMethod = paymentMethodValue === PatientPaymentMethod.Other;
  const isAccountCreditPaymentMethod = paymentMethodValue === PatientPaymentMethod.FromAccountCredit;
  const rowProps: RowProps = isOtherPaymentMethod ? { xs: 1, lg: 2, xl: 3 } : { xs: 1, lg: 2, xl: 4 };

  // Limits
  const leftToPay = useLeftToPay();
  const accountCredit = useAccountCredit();
  const initialAmountValue = Number(initialValues?.amount || 0);
  const disabled = (!isEditMode && leftToPay <= 0) || updatePaymentsLoading;

  const maxAmount = useMemo(() => {
    const limit = isAccountCreditPaymentMethod && accountCredit < leftToPay ? accountCredit : leftToPay;

    if (isEditMode) {
      if (limit < 0) {
        return round(initialAmountValue, 2);
      }

      return round(initialAmountValue + limit, 2);
    }

    return round(limit, 2);
  }, [accountCredit, initialAmountValue, isAccountCreditPaymentMethod, isEditMode, leftToPay]);

  const paymentMethodOptions = useMemo(() => {
    // Take active payment methods
    const activePatientPaymentMethods = patientPaymentMethods?.filter((pm) => pm.details?.status === Status.Enabled);

    // "From Account Credit" method first
    const sortedMethods = sortBy(
      activePatientPaymentMethods,
      (o) => o.value !== PatientPaymentMethod.FromAccountCredit,
    );

    if (accountCredit <= 0) {
      // Skip the "From Account Credit" method if credit is not available
      return sortedMethods.filter((o) => o.value !== PatientPaymentMethod.FromAccountCredit);
    }

    return sortedMethods;
  }, [accountCredit, patientPaymentMethods]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col>
          <Field
            name={PaymentMethodField.paymentMethod}
            component={SelectComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' })}
            options={paymentMethodOptions}
            validate={[required]}
            isRequired
          />
        </Col>
        {isOtherPaymentMethod && (
          <Col>
            <Field
              name={PaymentMethodField.otherPaymentMethod}
              component={InputComponent}
              disabled={disabled}
              label={formatMessage({
                id: 'CORE.LABEL.OTHER-PAYMENT-METHOD',
                defaultMessage: 'Other Payment Method',
              })}
              type="text"
              validate={[required]}
              isRequired
            />
          </Col>
        )}
        <Col>
          <Field
            name={PaymentMethodField.amount}
            appendContent={
              <InputGroupText>
                <SarCurrencySymbol />
              </InputGroupText>
            }
            component={InputComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.AMOUNT' })}
            min={0.01}
            max={maxAmount}
            step={0.01}
            type="number"
            validate={[required]}
            isRequired
          />
        </Col>
        <Col>
          <Field
            name={PaymentMethodField.notes}
            component={InputComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.NOTES', defaultMessage: 'Notes' })}
            type="text"
          />
        </Col>
        <Col>
          <Button block className="btn mb-3 mt-0 mt-lg-4" color="primary-gradient" disabled={disabled} type="submit">
            {isEditMode ? formatMessage({ id: 'CORE.BUTTON.SAVE' }) : formatMessage({ id: 'CORE.BUTTON.ADD' })}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const PaymentMethodForm = reduxForm<PaymentMethodFormData>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormComponent);
