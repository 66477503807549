import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useIsMounted } from 'app/helpers';
import { FORMS_CALENDAR_DAY_FILTER, FORMS_CALENDAR_WEEK_FILTER } from 'app/shared';
import { useLazyGetPatientSourceOptionsQuery } from 'app/services/patientSources/patientSources';
import { PatientSourceOptionParams } from 'app/services/patientSources/types';
import {
  fetchDoctorProducts,
  selectService,
  selectSubSpeciality,
  setActiveStep,
} from 'app/redux/bookingWizard/bookWizard.actions';
import { WizardStep } from 'app/redux/bookingWizard/types';
import { Option, PatientSourceStatus } from 'app/types';
import {
  useSetPatientFromAppointment,
  useSetPatientFromPreselectedPatient,
} from 'app/features/bookingWizardModal/helpers';

interface Props {
  isEdit: boolean;
  handleServiceChange: (value: Option) => void;
  patientSourceId?: string;
  setHasPatientSources: (value: boolean) => void;
}

export const useBookingWizardEffects = ({
  isEdit,
  handleServiceChange,
  patientSourceId,
  setHasPatientSources,
}: Props) => {
  const dispatch = useAppDispatch();
  const [patientSourceChecked, setPatientSourceChecked] = useState(false);
  const [subSpecialityInitialized, setSubSpecialityInitialized] = useState(false);
  const [serviceInitialized, setServiceInitialized] = useState(false);

  const isMounted = useIsMounted();
  const { tabId } = useParams<{ tabId: string }>();
  const selectPatient = useSetPatientFromAppointment();
  const selectPreselectedPatient = useSetPatientFromPreselectedPatient();
  const [fetchPatientSources] = useLazyGetPatientSourceOptionsQuery();

  const appointment = useAppSelector((state) => state.appointment.data);
  const branchId = useAppSelector((state) => state.bookingWizard.branchId);
  const doctorData = useAppSelector((state) => state.bookingWizard.doctor?.data);
  const rebookData = useAppSelector((state) => state.appointment.rebook.data);
  const services = useAppSelector((state) => state.bookingWizard.services.data);
  const selectedWorkingTimeId = useAppSelector((state) => state.bookingWizard.selectedWorkingTime?.id);
  const subSpecialities = useAppSelector((state) => state.bookingWizard.subSpecialities.data);
  const preselectedPatient = useAppSelector((state) => state.appointment.preselectedPatient);
  const subSpecialityDay: Option | null | undefined = useAppSelector(
    (state) => state.form?.[FORMS_CALENDAR_DAY_FILTER]?.values?.subSpeciality,
  );
  const subSpecialityWeek: Option | null | undefined = useAppSelector(
    (state) => state.form?.[FORMS_CALENDAR_WEEK_FILTER]?.values?.subSpeciality,
  );
  const dayCalendarService: Option | null | undefined = useAppSelector(
    (state) => state.form?.[FORMS_CALENDAR_DAY_FILTER]?.values?.service,
  );
  const serviceWeek: Option | null | undefined = useAppSelector(
    (state) => state.form?.[FORMS_CALENDAR_WEEK_FILTER]?.values?.service,
  );

  const subSpeciality = subSpecialityDay || subSpecialityWeek;
  const service = tabId === 'day' ? dayCalendarService : serviceWeek;

  // select editing subSpeciality if still valid
  useEffect(() => {
    if (isEdit && appointment && !subSpecialityInitialized && selectedWorkingTimeId && subSpecialities?.length) {
      if (subSpecialities.some((sub) => sub.value === appointment.subSpeciality.value)) {
        dispatch(selectSubSpeciality(appointment.subSpeciality));
        dispatch(fetchDoctorProducts(selectedWorkingTimeId, appointment.subSpeciality.value));
      }
      setSubSpecialityInitialized(true);
    } else if (rebookData && !subSpecialityInitialized && selectedWorkingTimeId && subSpecialities?.length) {
      // if rebook active preselect subSpeciality if still valid
      if (subSpecialities.some((sub) => sub.value === rebookData.subSpeciality.value)) {
        dispatch(selectSubSpeciality(rebookData.subSpeciality));
        dispatch(fetchDoctorProducts(selectedWorkingTimeId, rebookData.subSpeciality.value));
      }
      setSubSpecialityInitialized(true);
    } else if (subSpeciality && selectedWorkingTimeId && service) {
      dispatch(selectSubSpeciality(subSpeciality));
      dispatch(fetchDoctorProducts(selectedWorkingTimeId, subSpeciality.value, patientSourceId));
      setSubSpecialityInitialized(true);
      dispatch(selectService(service));
      setServiceInitialized(true);
      dispatch(setActiveStep(WizardStep.Patient));
    } else if (subSpeciality && selectedWorkingTimeId) {
      dispatch(selectSubSpeciality(subSpeciality));
      dispatch(fetchDoctorProducts(selectedWorkingTimeId, subSpeciality.value, patientSourceId));
      setSubSpecialityInitialized(true);
      dispatch(setActiveStep(WizardStep.Service));
    }
  }, [
    dispatch,
    isEdit,
    appointment,
    patientSourceId,
    rebookData,
    selectedWorkingTimeId,
    subSpecialityInitialized,
    subSpecialities,
    subSpeciality,
    service,
  ]);

  // select editing service if still valid
  useEffect(() => {
    if (isEdit && appointment && subSpecialityInitialized && !serviceInitialized && services?.length) {
      if (services.some((prod) => prod.value === appointment.product.value)) {
        dispatch(selectService(appointment.product));
        handleServiceChange(appointment.product);
      }
      setServiceInitialized(true);
    } else if (rebookData && subSpecialityInitialized && !serviceInitialized && services?.length) {
      // if rebook active preselect service if still valid
      if (services.some((prod) => prod.value === rebookData.product.value)) {
        dispatch(selectService(rebookData.product));
        handleServiceChange(rebookData.product);
      }
      setServiceInitialized(true);
    }
  }, [
    dispatch,
    isEdit,
    appointment,
    handleServiceChange,
    rebookData,
    services,
    serviceInitialized,
    subSpecialityInitialized,
  ]);

  useEffect(() => {
    if (isEdit && appointment) {
      selectPatient(appointment);
    } else if (rebookData) {
      selectPatient(rebookData);
    } else if (preselectedPatient) {
      selectPreselectedPatient(preselectedPatient);
    }
  }, [dispatch, isEdit, appointment, preselectedPatient, rebookData, selectPatient, selectPreselectedPatient]);

  useEffect(() => {
    const clinicId = doctorData && doctorData.clinic && doctorData.clinic.value;
    if (!patientSourceChecked && branchId && clinicId) {
      const checkPatientSource = async () => {
        const params: PatientSourceOptionParams = {
          branchIds: branchId,
          practitionerIds: doctorData.id,
          statuses: PatientSourceStatus.Active,
        };
        try {
          const response = await fetchPatientSources({ clinicId, params }).unwrap();
          if (isMounted()) {
            setHasPatientSources(!!response.data.length);
            setPatientSourceChecked(true);
          }
        } catch (error) {
          toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
        }
      };

      checkPatientSource();
    }
  }, [isMounted, patientSourceChecked, branchId, doctorData, fetchPatientSources, setHasPatientSources]);
};
