import React, { CSSProperties, ElementType, ReactNode, useMemo } from 'react';
import { IconDots, IconDotsVertical } from '@tabler/icons-react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

export type DotsMenuItem = {
  disabled?: boolean;
  isVisible: boolean;
  label: ReactNode | string;
  onClick?: () => void;
  tag?: ElementType;
  to?: string;
  style?: CSSProperties;
  title?: string;
};

interface Props {
  className?: string;
  container?: string;
  disabled?: boolean;
  items: DotsMenuItem[];
  horizontal?: boolean;
}

export const DotsMenu = ({ className, container = 'body', disabled, items, horizontal }: Props) => {
  const menuItems = useMemo(() => items.filter((item) => item.isVisible), [items]);

  return (
    <UncontrolledDropdown className={className} disabled={disabled}>
      <DropdownToggle className="btn btn-icon" disabled={!menuItems.length} tag="div">
        {horizontal ? <IconDots size={22} strokeWidth={1.8} /> : <IconDotsVertical size={22} strokeWidth={1.8} />}
      </DropdownToggle>
      <DropdownMenu container={container}>
        {menuItems.map((item, index) => (
          <DropdownItem
            disabled={item.disabled}
            key={index}
            onClick={item.onClick}
            tag={item.tag}
            to={item.to}
            style={item.style}
            title={item.title}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
