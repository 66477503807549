import React from 'react';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { Loader } from 'app/shared';
import { Option, ProductOption } from 'app/types';
import './servicesList.scss';
import { getProductTranslation } from 'app/helpers/getProductTranslation/getProductTranslation';

interface Props {
  selectedService: Option | null;
  onServiceChange: (option: Option) => void;
  serviceOptions: Option[];
  multipleColumns?: boolean;
  loading: boolean;
}

export const ServicesList = ({ selectedService, onServiceChange, serviceOptions, multipleColumns, loading }: Props) => {
  const { locale } = useAppIntl();

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <Row className="fadeIn">
          {serviceOptions.map((s: ProductOption, i: number) => {
            return (
              <Col key={i} {...(multipleColumns ? { xl: 4, lg: 6, md: 12 } : {})}>
                <UncontrolledTooltip target={`serviceListOption-${i}`}>
                  {getProductTranslation(s, locale, 'nameInBookingCalendar')}
                </UncontrolledTooltip>
                <Button
                  block
                  color="primary"
                  className="mt-2 btn text-truncate"
                  id={`serviceListOption-${i}`}
                  outline={selectedService?.value !== s.value}
                  onClick={() => onServiceChange(s)}
                >
                  {getProductTranslation(s, locale, 'nameInBookingCalendar')}
                </Button>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
