import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { DEFAULT_TIME_FROM, DEFAULT_TIME_TO } from 'app/shared';
import {
  fetchWorkingHourMedicalServices,
  fetchWorkingHourMedicalServicesForWaiting,
} from 'app/redux/appointment/appointment.actions';

/**
 * Generates Medical Service Options for Regular Appointments
 */
export const useMedicalServiceOptions = () => {
  const dispatch = useAppDispatch();
  const appointment = useAppSelector((state) => state.appointment.data);
  const medicalServiceOptions = useAppSelector((state) => state.appointment.medicalServices.data);

  useEffect(() => {
    if (appointment) {
      dispatch(
        fetchWorkingHourMedicalServices({
          branchId: appointment.branch.value,
          date: appointment.start,
          doctorId: appointment.doctorId,
          from: DEFAULT_TIME_FROM,
          to: DEFAULT_TIME_TO,
        }),
      );
    }
  }, [appointment, dispatch]);

  return medicalServiceOptions;
};

/**
 * Generates Medical Service Options for Waiting Appointments
 */
export const useWaitingMedicalServiceOptions = (workingTimeId: string | undefined) => {
  const dispatch = useAppDispatch();
  const medicalServiceOptions = useAppSelector((state) => state.appointment.medicalServices.data);

  useEffect(() => {
    if (workingTimeId) {
      dispatch(fetchWorkingHourMedicalServicesForWaiting(workingTimeId));
    }
  }, [dispatch, workingTimeId]);

  return medicalServiceOptions;
};
