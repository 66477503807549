import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import { setActiveStep } from 'app/redux/bookingWizard/bookWizard.actions';
import { WizardStep } from 'app/redux/bookingWizard/types';

interface Props {
  isConflicting?: boolean;
  isNoTime?: boolean;
  isWaiting?: boolean;
}

export const useStepNavigation = ({ isConflicting, isNoTime, isWaiting }: Props) => {
  const dispatch = useAppDispatch();
  const activeStep = useAppSelector((state) => state.bookingWizard.activeStep);
  const isSlotFree = useAppSelector((state) => state.bookingWizard.isSlotFree);

  const isBackAvailable = activeStep !== WizardStep.SubSpeciality && !isConflicting;

  const selectPreviousStep = useCallback(() => {
    switch (activeStep) {
      case WizardStep.Service:
        dispatch(setActiveStep(WizardStep.SubSpeciality));
        break;
      case WizardStep.ConflictingAppointments:
        dispatch(setActiveStep(WizardStep.Service));
        break;
      case WizardStep.Patient:
        dispatch(
          setActiveStep(isSlotFree || isNoTime || isWaiting ? WizardStep.Service : WizardStep.ConflictingAppointments),
        );
    }
  }, [activeStep, dispatch, isSlotFree, isNoTime, isWaiting]);

  return {
    isBackAvailable,
    selectPreviousStep,
  };
};
