import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Button, Col, Form, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useAppIntl, useAppSelector, useDate } from 'app/helpers';
import {
  AsyncSelectComponent,
  DatePickerComponent,
  dateTodayOrInFuture,
  FORMS_PATIENT_PROFILE_INSURANCE,
  InputComponent,
  required,
  SwitchComponent,
} from 'app/shared';
import { useFetchOptions } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useFetchOptions';
import { useOnChangeFunctions } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useOnChangeFunctions';
import { usePolicyNumberOptionFormatter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/usePolicyNumberOptionFormatter';
import { validate } from 'app/features/patient/patientInsuranceDetailsForm/helpers/validator';
import {
  PatientInsuranceField,
  PatientInsuranceFormData,
} from 'app/features/patient/patientInsuranceDetailsForm/types';

interface Props {
  branchId?: string;
  clinicId?: string;
  closeModal: () => void;
  headerText: string;
  isEdit?: boolean;
  submitText: string;
}

const FormComponent: FC<Props & InjectedFormProps<PatientInsuranceFormData, Props>> = ({
  branchId,
  change,
  clinicId,
  closeModal,
  handleSubmit,
  headerText,
  isEdit,
  pristine,
  submitting,
  touch,
  form,
}) => {
  const { formatMessage } = useAppIntl();
  const { todayStart } = useDate();

  // Selectors
  const selectedInsuranceCompany: PatientInsuranceFormData['insuranceCompany'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientInsuranceField.insuranceCompany],
  );
  const selectedPolicyNumber: PatientInsuranceFormData['policyNumber'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientInsuranceField.policyNumber],
  );
  const selectedClinic: PatientInsuranceFormData['clinic'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientInsuranceField.clinic],
  );
  const selectedBranch: PatientInsuranceFormData['clinicBranch'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PatientInsuranceField.clinicBranch],
  );
  const expiryDate: PatientInsuranceFormData['end'] = useAppSelector(
    (state) => state.form[form]?.values?.[PatientInsuranceField.end],
  );

  // Helper Variables
  const rowProps: RowProps = { xs: 1, lg: 2 };

  // Conditions
  const isClinicBranch = !clinicId && !selectedClinic;
  const isExpired = useMemo(() => moment(expiryDate).isBefore(todayStart), [expiryDate, todayStart]);

  // Helper Hooks
  const {
    fetchInsuranceOptions,
    fetchInsurancePoliciesOptions,
    fetchInsuranceClassesOptions,
    fetchClinicOptions,
    fetchBranchOptions,
  } = useFetchOptions({
    selectedInsuranceCompany,
    selectedPolicyNumber,
    selectedClinic,
    selectedBranch,
  });
  const formatPolicyNumberOption = usePolicyNumberOptionFormatter();
  const { onClinicChange, onBranchChange, onInsuranceCompanyChange, onPolicyNumberChange } =
    useOnChangeFunctions(change);

  useEffect(() => {
    if (isEdit) {
      touch(PatientInsuranceField.end);
    }
  }, [isEdit, touch]);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalHeader toggle={closeModal}>{formatMessage({ id: headerText })}</ModalHeader>

      <ModalBody>
        <Row {...rowProps}>
          {!clinicId && (
            <Col>
              <Field
                name={PatientInsuranceField.clinic}
                component={AsyncSelectComponent}
                fetchOptions={fetchClinicOptions}
                isRequired
                disabled={isEdit}
                label={formatMessage({ id: 'CORE.LABEL.MEDICAL-INSTITUTION' })}
                onChange={onClinicChange}
              />
            </Col>
          )}
          {!branchId && (
            <Col>
              <Field
                info={
                  !clinicId && !selectedClinic && formatMessage({ id: 'CORE.TEXT.PLEASE-SELECT-MEDICAL-INSTITUTION' })
                }
                name={PatientInsuranceField.clinicBranch}
                cacheUniqs={[selectedClinic?.value]}
                component={AsyncSelectComponent}
                disabled={isClinicBranch || isEdit}
                fetchOptions={fetchBranchOptions}
                onChange={onBranchChange}
                isRequired
                label={formatMessage({ id: 'CORE.LABEL.BRANCH-NAME' })}
              />
            </Col>
          )}
        </Row>

        <h4 className="mt-2">{formatMessage({ id: 'INSURANCES.TEXT.INSURANCE-DETAILS' })}</h4>
        <Row {...rowProps}>
          <Col>
            <Field
              info={!selectedBranch && formatMessage({ id: 'CORE.TEXT.PLEASE-SELECT-MEDICAL-INSTITUTION-BRANCH' })}
              cacheUniqs={[selectedBranch?.value]}
              name={PatientInsuranceField.insuranceCompany}
              component={AsyncSelectComponent}
              fetchOptions={fetchInsuranceOptions}
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-COMPANY' })}
              onChange={onInsuranceCompanyChange}
              disabled={isEdit || !selectedBranch}
              isRequired
            />
          </Col>

          <Col>
            <Field
              info={!selectedInsuranceCompany && formatMessage({ id: 'CORE.TEXT.PLEASE-SELECT-INSURANCE-COMPANY' })}
              name={PatientInsuranceField.policyNumber}
              component={AsyncSelectComponent}
              cacheUniqs={[selectedInsuranceCompany?.value, selectedBranch?.value]}
              fetchOptions={fetchInsurancePoliciesOptions}
              label={formatMessage({ id: 'CORE.LABEL.POLICY-NUMBER' })}
              formatOptionLabel={formatPolicyNumberOption}
              onChange={onPolicyNumberChange}
              disabled={!selectedInsuranceCompany || !selectedBranch}
              isRequired
            />
          </Col>
          <Col>
            <Field
              name={PatientInsuranceField.number}
              type="text"
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-NUMBER' })}
              component={InputComponent}
              isRequired
            />
          </Col>
          <Col>
            <Field
              info={!selectedPolicyNumber && formatMessage({ id: 'CORE.TEXT.PLEASE-SELECT-POLICY-NUMBER' })}
              name={PatientInsuranceField.insuranceClass}
              component={AsyncSelectComponent}
              cacheUniqs={[selectedPolicyNumber?.value]}
              fetchOptions={fetchInsuranceClassesOptions}
              label={formatMessage({ id: 'CORE.LABEL.INSURANCE-CLASS' })}
              disabled={!selectedPolicyNumber}
              isRequired
            />
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col>
            <Field
              name={PatientInsuranceField.start}
              component={DatePickerComponent}
              label={formatMessage({ id: 'CORE.LABEL.START-DATE' })}
              placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
              disabled={isEdit}
              isRequired
            />
          </Col>
          <Col>
            <Field
              name={PatientInsuranceField.end}
              component={DatePickerComponent}
              label={formatMessage({ id: 'INSURANCES.LABEL.EXPIRY-DATE' })}
              placeholder={formatMessage({ id: 'CORE.PLACEHOLDER.SELECT-DATE' })}
              isRequired
              validate={[required, dateTodayOrInFuture]}
            />
          </Col>
        </Row>

        {isEdit && (
          <Row {...rowProps}>
            <Col>
              <Field
                className="pt-1"
                name={PatientInsuranceField.activated}
                component={SwitchComponent}
                label={formatMessage({ id: 'CORE.LABEL.ACTIVATED' })}
                isRequired
                disabled={isExpired}
              />
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" disabled={submitting} color="secondary" onClick={closeModal}>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL' })}
        </Button>
        <Button disabled={pristine || submitting} type="submit" color="primary-gradient">
          {formatMessage({ id: isEdit ? 'CORE.BUTTON.CONFIRM' : 'CORE.BUTTON.CREATE' })}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export const PatientInsuranceForm = reduxForm<PatientInsuranceFormData, Props>({
  form: FORMS_PATIENT_PROFILE_INSURANCE,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
})(FormComponent);
