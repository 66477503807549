import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { getApiDateAndTime, isWithinAgeRestrictions, useAppDispatch, useAppSelector } from 'app/helpers';
import { FORMS_BOOKING_WIZARD } from 'app/shared';
import {
  addAppointment,
  addWaitingAppointment,
  editAppointment,
  editWaitingAppointment,
} from 'app/redux/appointment/appointment.actions';
import { AppointmentValues, WaitingAppointmentValues } from 'app/redux/appointment/types';
import { BookingFormData } from 'app/features/bookingWizardModal/types';

interface Props {
  appointmentId?: string;
  branchId: string;
  isEdit?: boolean;
  isNoTime?: boolean;
  isWaiting?: boolean;
}

export const useBookAppointmentHandler = ({ appointmentId, branchId, isEdit, isNoTime, isWaiting }: Props) => {
  const dispatch = useAppDispatch();

  const formValues: Partial<BookingFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_BOOKING_WIZARD]?.values,
  );
  const doctor = useAppSelector((state) => state.bookingWizard.doctor.data);
  const selectedPatient = useAppSelector((state) => state.bookingWizard.selectedPatient);
  const selectedSubSpeciality = useAppSelector((state) => state.bookingWizard.selectedSubSpeciality);
  const selectedService = useAppSelector((state) => state.bookingWizard.selectedService);
  const selectedTime = useAppSelector((state) => state.bookingWizard.selectedTime);
  const selectedWorkingTime = useAppSelector((state) => state.bookingWizard.selectedWorkingTime);
  const isSlotFree = useAppSelector((state) => state.bookingWizard.isSlotFree.data);

  const handleBookAppointment = useCallback(async () => {
    if (
      (formValues?.patientId || selectedPatient?.id) &&
      branchId &&
      doctor &&
      selectedService &&
      selectedSubSpeciality &&
      formValues?.selectedPhoneNumber
    ) {
      if (isWaiting && selectedTime.to && selectedWorkingTime) {
        const values: WaitingAppointmentValues = {
          branchId: branchId,
          guardianId: formValues.selectedGuardian?.value || null,
          doctorId: doctor.id,
          end: getApiDateAndTime(selectedTime.to),
          note: formValues.note || '',
          patientId: formValues?.patientId || selectedPatient?.id,
          productId: selectedService.value,
          start: getApiDateAndTime(selectedTime.from),
          subSpecialityId: selectedSubSpeciality.value,
          phoneNumber: formValues.selectedPhoneNumber.label,
          workingTimeId: selectedWorkingTime.id,
          referralId: formValues.referralId,
        };

        if (isEdit && appointmentId) {
          return dispatch(editWaitingAppointment(appointmentId, values));
        } else {
          return dispatch(addWaitingAppointment(values, false /* TODO PT-3293 how to get isPhantom?*/));
        }
      } else if (!isWaiting) {
        const values: AppointmentValues = {
          branchId: branchId,
          guardianId: formValues.selectedGuardian?.value || null,
          doctorId: doctor.id,
          end: !isNoTime && selectedTime.to ? getApiDateAndTime(selectedTime.to) : null,
          note: formValues.note || '',
          patientId: formValues.patientId || selectedPatient?.id,
          productId: selectedService.value,
          start: getApiDateAndTime(isNoTime ? moment(selectedTime.from).startOf('day').toDate() : selectedTime.from),
          subSpecialityId: selectedSubSpeciality.value,
          phoneNumber: formValues.selectedPhoneNumber.label,
          referralId: formValues.referralId,
          patientSourceId: formValues.patientSource?.value,
        };

        if (isEdit && appointmentId) {
          return dispatch(editAppointment(appointmentId, values));
        } else {
          return dispatch(addAppointment(values, !isNoTime && !isSlotFree));
        }
      }
    }
  }, [
    dispatch,
    appointmentId,
    branchId,
    formValues,
    isEdit,
    isNoTime,
    isWaiting,
    isSlotFree,
    doctor,
    selectedService,
    selectedSubSpeciality,
    selectedTime,
    selectedWorkingTime,
    selectedPatient,
  ]);

  const meetsAgeRestrictions = useMemo(
    () =>
      isWithinAgeRestrictions({
        age: selectedPatient?.age,
        min: doctor?.acceptablePatientAgeFrom,
        max: doctor?.acceptablePatientAgeTo,
      }),
    [doctor, selectedPatient],
  );

  const canBookAppointment = useMemo(() => {
    return (
      formValues &&
      (formValues.patientId || selectedPatient?.id) &&
      formValues.selectedPhoneNumber?.value &&
      branchId &&
      doctor &&
      selectedService &&
      selectedSubSpeciality &&
      meetsAgeRestrictions
    );
  }, [formValues, branchId, doctor, selectedService, selectedSubSpeciality, meetsAgeRestrictions, selectedPatient]);

  return { canBookAppointment, handleBookAppointment };
};
