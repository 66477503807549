import { Button, Spinner } from 'reactstrap';
import { useAppIntl, useAppSelector, useCurrentUser } from 'app/helpers';
import { ConfirmationModal } from 'app/components/confirmationModal/confirmationModal';
import { InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { InvoiceStatus } from 'app/types';
import { useCheckCreditNotePackageActions } from 'app/features/invoicingProcess/process/actions/helpers/useCheckCreditNotePackageActions';
import { useOnBack } from 'app/features/invoicingProcess/process/actions/helpers/useOnBack';
import { useOnCancelCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCancelCreditNote';
import { useOnContinue } from 'app/features/invoicingProcess/process/actions/helpers/useOnContinue';
import { useOnCreate } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreate';
import { useOnCreateCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreateCreditNote';
import { useOnCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreditNote';
import { useOnNext } from 'app/features/invoicingProcess/process/actions/helpers/useOnNext';
import { useOnSaveAsDraft } from 'app/features/invoicingProcess/process/actions/helpers/useOnSaveAsDraft';
import { useOnUpdatePayments } from 'app/features/invoicingProcess/process/actions/helpers/useOnUpdatePayments';
import { useRelatedInvoicesRefundConfirmation } from 'app/features/invoicingProcess/process/actions/helpers/useRelatedInvoicesRefundConfirmation';
import { useWithoutPaymentConfirmation } from 'app/features/invoicingProcess/process/actions/helpers/useWithoutPaymentConfirmation';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import styles from 'app/features/invoicingProcess/process/actions/actions.module.scss';

export const Actions = () => {
  const { formatMessage } = useAppIntl();
  const { currentUser } = useCurrentUser();
  const { requestId, isCreated, isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const step = useAppSelector((state) => state.invoicingProcess.step);
  const invoiceStatus = useAppSelector((state) => state.invoicingProcess.data?.invoiceStatus);
  const isClearedByCreditNote = invoiceStatus?.value === InvoiceStatus.ClearedByCreditNote;

  // Actions
  const onBack = useOnBack();
  const onNext = useOnNext();
  const onCreditNote = useOnCreditNote();
  const { createLoading, onCreate } = useOnCreate();
  const onCancelCreditNote = useOnCancelCreditNote();
  const { onSaveAsDraft, saveAsDraftLoading } = useOnSaveAsDraft();
  const { createCreditNoteLoading, onCreateCreditNote } = useOnCreateCreditNote();
  const { onUpdatePayments, updatePaymentsDisabled, updatePaymentsLoading } = useOnUpdatePayments();
  const { extendedLimitModal, onConfirmExtendedLimitModal, onCancelExtendedLimitModal, onContinue, continueLoading } =
    useOnContinue();
  const {
    checkRelatedInvoicesRefundConfirmation,
    onCancelRelatedInvoicesRefundConfirmation,
    onConfirmRelatedInvoicesRefundConfirmation,
    relatedInvoicesRefundConfirmation,
  } = useRelatedInvoicesRefundConfirmation();
  const {
    checkWithoutPaymentConfirmation,
    onCancelWithoutPaymentConfirmation,
    onConfirmWithoutPaymentConfirmation,
    withoutPaymentConfirmation,
  } = useWithoutPaymentConfirmation();
  const checkCreditNotePackageActions = useCheckCreditNotePackageActions();

  return (
    <div className={styles.wrapper}>
      {/* Back */}
      {step !== InvoicingProcessStep.Details && (
        <Button className={styles.navButton} color="primary" onClick={onBack} outline>
          {formatMessage({ id: 'CORE.BUTTON.BACK', defaultMessage: 'Back' })}
        </Button>
      )}

      {/* Next */}
      {step !== InvoicingProcessStep.Summary && isCreated && (
        <Button className={styles.navButton} color="primary" onClick={onNext} outline>
          {formatMessage({ id: 'CORE.BUTTON.NEXT', defaultMessage: 'Next' })}
        </Button>
      )}

      {/* Save as Draft */}
      {step &&
        !invoicePackageSnapshotId &&
        !requestId &&
        ![InvoicingProcessStep.Details, InvoicingProcessStep.Appointment, InvoicingProcessStep.Summary].includes(
          step,
        ) &&
        !isCreated &&
        currentUser.allowedForCreateInvoice && (
          <Button
            className={styles.button}
            color="primary"
            disabled={saveAsDraftLoading}
            onClick={onSaveAsDraft}
            outline
          >
            {saveAsDraftLoading ? (
              <Spinner size="sm" />
            ) : (
              formatMessage({ id: 'CORE.BUTTON.SAVE-AS-DRAFT', defaultMessage: 'Save as Draft' })
            )}
          </Button>
        )}

      {/* Continue */}
      {step !== InvoicingProcessStep.PaymentMethod && step !== InvoicingProcessStep.Summary && !isCreated && (
        <Button className={styles.button} color="primary-gradient" disabled={continueLoading} onClick={onContinue}>
          {continueLoading ? (
            <Spinner size="sm" />
          ) : (
            formatMessage({ id: 'CORE.BUTTON.CONTINUE', defaultMessage: 'Continue' })
          )}
        </Button>
      )}

      {/* Create */}
      {step === InvoicingProcessStep.PaymentMethod && !isCreated && currentUser.allowedForCreateInvoice && (
        <>
          <Button
            className={styles.button}
            color="primary-gradient"
            disabled={createLoading}
            onClick={() => checkWithoutPaymentConfirmation(onCreate)}
          >
            {createLoading ? (
              <Spinner size="sm" />
            ) : (
              formatMessage({ id: 'CORE.BUTTON.CREATE', defaultMessage: 'Create' })
            )}
          </Button>
        </>
      )}

      {/* Update Payments - this action is limited to allowedForCreateInvoice only */}
      {step === InvoicingProcessStep.PaymentMethod &&
        isCreated &&
        !isCreateCreditNoteMode &&
        !isClearedByCreditNote &&
        currentUser.allowedForCreateInvoice && (
          <Button
            className={styles.button}
            color="primary-gradient"
            disabled={updatePaymentsDisabled || updatePaymentsLoading}
            onClick={onUpdatePayments}
          >
            {updatePaymentsLoading ? (
              <Spinner size="sm" />
            ) : (
              formatMessage({ id: 'CORE.BUTTON.UPDATE-PAYMENTS', defaultMessage: 'Update Payments' })
            )}
          </Button>
        )}

      {/* Create Credit Note */}
      {isCreated && !isCreateCreditNoteMode && !isClearedByCreditNote && currentUser.allowedForEditInvoice && (
        <Button className={styles.button} color="primary-gradient" onClick={onCreditNote}>
          {formatMessage({ id: 'CORE.BUTTON.CREATE-CREDIT-NOTE', defaultMessage: 'Create Credit Note' })}
        </Button>
      )}

      {/* Cancel Credit Note */}
      {isCreateCreditNoteMode && (
        <Button className={styles.button} color="primary" onClick={onCancelCreditNote} outline>
          {formatMessage({ id: 'CORE.BUTTON.CANCEL', defaultMessage: 'Cancel' })}
        </Button>
      )}

      {/* Create Credit Note (final) */}
      {isCreateCreditNoteMode && currentUser.allowedForEditInvoice && (
        <Button
          className={styles.button}
          color="primary-gradient"
          disabled={createCreditNoteLoading}
          onClick={() =>
            checkRelatedInvoicesRefundConfirmation(() =>
              checkWithoutPaymentConfirmation(() => checkCreditNotePackageActions(onCreateCreditNote)),
            )
          }
        >
          {createCreditNoteLoading ? (
            <Spinner size="sm" />
          ) : (
            formatMessage({ id: 'CORE.BUTTON.CREATE', defaultMessage: 'Create' })
          )}
        </Button>
      )}

      {/* Exceeds share - confirmation modal */}
      <ConfirmationModal
        description={formatMessage({
          id: 'CORE.TEXT.EXCEEDS-SHARE-CONFIRMATION',
          defaultMessage:
            'The amount to be paid exceeds the insurance benefit limit.\n Are you sure you want to continue?',
        })}
        isOpen={extendedLimitModal}
        onClose={onCancelExtendedLimitModal}
        onConfirm={onConfirmExtendedLimitModal}
        confirmButtonText={formatMessage({ id: 'CORE.BUTTON.CONTINUE', defaultMessage: 'Continue' })}
      />

      {/* Invoice without payment - confirmation modal */}
      <ConfirmationModal
        confirmButtonText={formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        description={formatMessage({ id: 'CORE.TEXT.INVOICE-WITHOUT-PAYMENT-CONFIRMATION' })}
        isOpen={withoutPaymentConfirmation}
        onClose={onCancelWithoutPaymentConfirmation}
        onConfirm={onConfirmWithoutPaymentConfirmation}
      />

      {/* Related invoices refund - confirmation modal */}
      <ConfirmationModal
        confirmButtonText={formatMessage({ id: 'CORE.BUTTON.CONFIRM' })}
        description={formatMessage({ id: 'CORE.TEXT.RELATED-INVOICES-REFUND-CONFIRMATION' })}
        isOpen={relatedInvoicesRefundConfirmation}
        onClose={onCancelRelatedInvoicesRefundConfirmation}
        onConfirm={onConfirmRelatedInvoicesRefundConfirmation}
      />
    </div>
  );
};
