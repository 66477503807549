import React, { useMemo } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import moment from 'moment';
import { Badge, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  formattedDate,
  formattedDateAndTime,
  formattedPeriod,
  getDoctorNameWithTitle,
  getTranslation,
  useAppIntl,
} from 'app/helpers';
import { FORMATS_DATE_AND_TIME } from 'app/shared';
import { AppointmentAction, AppointmentActionType } from 'app/types';
import { getServiceName } from './helpers';

interface Props {
  action: AppointmentAction;
  appointmentNumber: string;
  isOpen: boolean;
  toggle: () => void;
}

type Item = {
  label: string;
  newValue: string;
  oldValue: string;
};

export const ActionDetailsModal = ({ action, appointmentNumber, isOpen, toggle }: Props) => {
  const { formatMessage, locale, isRtl } = useAppIntl();
  const isWaitingAction = action?.type === AppointmentActionType.WaitingAppointmentUpdate;

  const changedItems: Item[] = useMemo(() => {
    if (!action) {
      return [];
    }

    let items: Item[] = [];

    const { oldObject, newObject } = action;

    const fields = [
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT-NAME' }),
        oldValue: oldObject?.guardianName || oldObject?.patientName || '',
        newValue: newObject?.guardianName || newObject?.patientName || '',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PATIENT-INTERNAL-FILE-NUMBER' }),
        oldValue: oldObject?.internalFileNumber || '',
        newValue: newObject?.internalFileNumber || '',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.PHONE-NUMBER' }),
        oldValue: oldObject?.patientPhoneNumber || '',
        newValue: newObject?.patientPhoneNumber || '',
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.MEDICAL-INSTITUTION' }),
        oldValue: oldObject ? getTranslation(oldObject.clinic, locale) : '',
        newValue: newObject ? getTranslation(newObject.clinic, locale) : '',
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.BRANCH' }),
        oldValue: oldObject ? getTranslation(oldObject.branch, locale) : '',
        newValue: newObject ? getTranslation(newObject.branch, locale) : '',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.MEDICAL-SPECIALITY' }),
        oldValue: oldObject ? getTranslation(oldObject.speciality, locale) : '',
        newValue: newObject ? getTranslation(newObject.speciality, locale) : '',
      },
      {
        label: formatMessage({ id: 'CORE.TABLE.MEDICAL-SERVICE' }),
        oldValue: oldObject ? getTranslation(oldObject.subSpeciality, locale) : '',
        newValue: newObject ? getTranslation(newObject.subSpeciality, locale) : '',
      },
      {
        label: formatMessage({ id: 'CORE.TEXT.SERVICE' }),
        oldValue: oldObject ? getServiceName(oldObject, locale) : '',
        newValue: newObject ? getServiceName(newObject, locale) : '',
      },
      {
        label: formatMessage({ id: 'CORE.LABEL.DOCTOR' }),
        oldValue: `${oldObject ? getDoctorNameWithTitle(oldObject.doctorName, oldObject.doctorTitle, locale) : ''}`,
        newValue: `${newObject ? getDoctorNameWithTitle(newObject.doctorName, newObject.doctorTitle, locale) : ''}`,
      },
      {
        label: formatMessage({ id: 'APPOINTMENTS.TEXT.APPOINTMENT-NOTE' }),
        oldValue: oldObject?.note || '',
        newValue: newObject?.note || '',
      },
      ...(isWaitingAction
        ? [
            {
              label: formatMessage({ id: 'CORE.LABEL.DATE' }),
              oldValue: oldObject ? formattedDate(oldObject.start) : '',
              newValue: newObject ? formattedDate(newObject.start) : '',
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.PREFERRED-TIME-RANGE' }),
              oldValue:
                oldObject && oldObject.start && oldObject.end ? formattedPeriod(oldObject.start, oldObject.end) : '',
              newValue:
                newObject && newObject.start && newObject.end ? formattedPeriod(newObject.start, newObject.end) : '',
            },
          ]
        : [
            {
              label: formatMessage({ id: 'CORE.LABEL.FROM-DATE' }),
              oldValue: oldObject ? formattedDateAndTime(oldObject.start) : '',
              newValue: newObject ? formattedDateAndTime(newObject.start) : '',
            },
            {
              label: formatMessage({ id: 'CORE.LABEL.TO-DATE' }),
              oldValue: oldObject && oldObject.end ? formattedDateAndTime(oldObject.end) : '',
              newValue: newObject && newObject.end ? formattedDateAndTime(newObject.end) : '',
            },
          ]),
    ];

    fields.forEach(({ label, oldValue, newValue }) => {
      if (oldValue !== newValue) {
        items.push({ label, oldValue, newValue });
      }
    });

    return items;
  }, [action, formatMessage, isWaitingAction, locale]);

  if (!action) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{formatMessage({ id: 'APPOINTMENTS.TEXT.ACTION-DETAILS' })}</ModalHeader>
      <ModalBody>
        <Row tag="dl" className="mb-3">
          <Col xs={5} tag="dt">
            {formatMessage({ id: 'CORE.LABEL.ACTION-DATE-AND-TIME' })}:
          </Col>
          <Col xs={7} tag="dd">
            {moment(action.time).format(FORMATS_DATE_AND_TIME)}
          </Col>
          <Col xs={5} tag="dt">
            {formatMessage({ id: 'CORE.LABEL.TICKET-NUMBER' })}:
          </Col>
          <Col xs={7} tag="dd">
            {appointmentNumber}
          </Col>
        </Row>

        <h6>{formatMessage({ id: 'CORE.TEXT.LIST-OF-CHANGES' })}:</h6>

        {changedItems.map((item, index) => (
          <dl className="mb-1" key={index}>
            <dt className="mb-1">{item.label}</dt>
            <dd className="text-secondary">
              {item.oldValue && (
                <Badge className="my-1 text-wrap" color="light-secondary">
                  {item.oldValue}
                </Badge>
              )}
              {item.oldValue && item.newValue && (
                <span className="mx-2 text-secondary">
                  {isRtl ? <IconChevronLeft size={12} /> : <IconChevronRight size={12} />}
                </span>
              )}
              <Badge className="my-1 text-wrap" color="light-success">
                {item.newValue}
              </Badge>
            </dd>
            {index < changedItems.length - 1 && <hr className="my-2" />}
          </dl>
        ))}
      </ModalBody>
    </Modal>
  );
};
