import { useAppIntl } from 'app/helpers';
import { Appointment } from 'app/types';

interface Props {
  appointment: Appointment;
}

export const Location = ({ appointment }: Props) => {
  const { formatMessage } = useAppIntl();

  return (
    <a
      className="align-middle"
      href={`https://maps.google.com/?q=${appointment.branchLocation.lat},${appointment.branchLocation.lng}`}
      rel="noreferrer noopener"
      target="_blank"
    >
      <div className="d-inline-flex align-items-center">
        <span>{formatMessage({ id: 'CORE.LABEL.SEE-ON-MAP' })}</span>
      </div>
    </a>
  );
};
